
import * as React from 'react'
import { OverlayView, OverlayViewF } from '@react-google-maps/api'
import mapStore from '../../../store/mapStore.ts'

interface Props {
	name: string
	position: {lat: number, lng: number}
}

export const SafeZoneLabel: React.FC<Props> = (props) => {
	const { name, position } = props
	const [label, setLabel] = React.useState<any>(null)

	const zoom = mapStore(s => s.zoom)

	React.useEffect(() => {
		if (zoom) {
			// console.log(zoom)
			const lbl = getLabel(zoom)
			setLabel(lbl)
		}
	}, [zoom])

    const getLabel = (zoom: number) =>
		<OverlayViewF
			position={position}
			mapPaneName={OverlayView.MAP_PANE}
			getPixelPositionOffset={(width, height) => ({
				x: -(width * 0.5),
				y: -(height * 0.5),
			})}
		>
			<div className={`prose text-nc font-bold text-[${zoom -10}px]`}>
				<article>{name}</article>
			</div>
		</OverlayViewF>

	return label
}

import * as React from 'react'
import AnimateHeight from 'react-animate-height'
import './PetDrawer.css'
import { useSwipeable } from 'react-swipeable'
import drawerStore from '../../store/drawerStore.ts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { clearRoute } from '../map/Directions/directionHelper.tsx'
import mapStore from '../../store/mapStore.ts'

// const getVal = (px: string) => parseInt(px.split('px')[0])
// const heightPresets = [20, 80, 160, 240, 330, 400]
export const PetDrawer: React.FC = () => {
    const height = drawerStore(s => s.drawerSize)
    const open = drawerStore(s => s.drawerOpen)
    const children = drawerStore(s => s.children)
    const travelMode: google.maps.TravelMode = mapStore(s => s.travelMode)

    const onSwipedUp = () => {
        // console.log('swipe up')
        drawerStore.getState().setDrawerOpen(true)
    }

    const onSwipedDown = () => {
        // console.log('swipe down')
        drawerStore.getState().setDrawerOpen(false)
    }

    const swipeHandlers = useSwipeable({
        onSwipedUp,
        onSwipedDown,
    });

    const handle = (
        <div className={'bg-transparent flex justify-center items-center relative'}
             onClick={() => open ? onSwipedDown() : onSwipedUp()}
        >
            <div className={'bg-primary text-primary-content text-xs w-16 text-center center rounded absolute h-[20px] -top-[22px] p-2'} {...swipeHandlers}>
                <hr className={'bg-primary-content/50 w-8 ml-auto mr-auto'}/>
            </div>
        </div>
    )

    return (
        <div>
            {handle}
            <AnimateHeight
                id="pet-drawer"
                duration={500}
                height={height as any}
                className={'h-full w-[100vw]'}
            >
                <div className={'h-full bg-white'}>
                    {children}
                    {drawerStore.getState().children === null ? (
                        <div id="directions-header" className={'w-full flex flex-row justify-between items-center border-b-2 border-b-accent'}>
                            <div className={'p-2 text-md font-semibold text-primary'}>
                                {travelMode} Directions
                            </div>
                            <div
                                className={'pr-4 cursor-pointer'}
                                onClick={() => clearRoute()}>
                                <FontAwesomeIcon icon={faClose} fontSize={'medium'} size={'lg'}/>
                            </div>
                        </div>
                    ) : null}
                    <div id="directions-panel" style={{maxHeight: '25vh', overflowY: 'auto', padding: '0.25rem', borderBottom: children === null ? 'solid 2px hsl(var(--a))' : ''}}></div>
                </div>
            </AnimateHeight>
        </div>
    )
}

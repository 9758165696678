import * as React from 'react'
import { IDevice } from '../../../interfaces/types.ts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeCheckInRate } from '../ChangeCheckInRate/ChangeCheckInRate.tsx'
import { faHeartCircleCheck } from '@fortawesome/free-solid-svg-icons/faHeartCircleCheck'

interface Props {
    iconOnly?: boolean
    tracker: IDevice
    textColor?: string
    textOnly?: boolean
    outline?: boolean
    full?: boolean
}

export const CheckinRateButton: React.FC<Props> = (props) => {
    const {full, iconOnly, tracker, textOnly, outline} = props
    const [open, setOpen] = React.useState(false)
    const disabled = !!tracker.status.deviceCommandInProgressStatus

    const button = iconOnly ? (
            <div className={`text-secondary text-xl`} onClick={() => setOpen(true)}>
                <FontAwesomeIcon icon={faHeartCircleCheck}/>
            </div>
        ) : outline ? (
            <button className={`btn btn-xs btn-secondary without-ring h-fit ${full ? 'w-full':''}`} onClick={() =>  setOpen(true)}>
                Check-in rate
            </button>
        )
        :
        <div className={`flex flex-col items-center rounded-lg bg-secondary py-1 px-3 border-white border-2 ${disabled ? 'opacity-50' : ''}`}>
            <button className={`btn btn-xs btn-ghost text-white without-ring h-fit py-0`}
                    onClick={() => !disabled && setOpen(true)} disabled={disabled}>
                <FontAwesomeIcon icon={faHeartCircleCheck} size={'2xl'}/>
            </button>
            <div className={'text-sm font-semibold text-white'}>
                Check-in<br/>Rate
            </div>
        </div>

    const textButton = (
        <button className={`btn btn-secondary btn-xs btn-ghost text-secondary without-ring h-fit`}
                onClick={() => setOpen(true)}
                disabled={disabled}
        >
            Check-in rate
        </button>
    )

    return (
        <div className={`cursor-pointer ${full ? 'w-full px-2' : ''}`} onClick={() => setOpen(!open)}>
            {textOnly ? textButton : button}
            <ChangeCheckInRate tracker={tracker} open={open} setOpen={setOpen} full/>
        </div>
    )
}

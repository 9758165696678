import * as React from 'react'
import controlStore from '../../../store/controlStore.ts'
import mapStore from '../../../store/mapStore.ts'
import { mapStyleOptions } from '../components/GoogleMap/mapStyles.ts'
import { SelectMyMarkerColor } from './MyLocationSettings/SelectColor/SelectMyMarkerColor.tsx'
import { useLocalStorage } from '../../../hooks/useLocalStorage.ts'

interface Props {
    close: () => void
}

export const MapSettingsPanel: React.FC<Props> = (props) => {
    const {close} = props

    const selectedColor = controlStore(s => s.myPositionColor)
    const mapStyle = mapStore(s => s.mapStyle)
    const [_mapStyle, _setMapStyle] = useLocalStorage('mapStyle', '')
    const [_myMarker, _setMyMarker] = useLocalStorage('myMarker', '')

    const isMobile = controlStore(s => s.isOnMobileDevice)

    const panelHeader = (
        <div className={'prose flex flex-col justify-start items-center gap-2'}>
            <h2 className={'mt-0 mb-0 text-primary'}>Map style</h2>
        </div>
    )

    const chooseStyle = (
        <div className={'flex flex-row flex-wrap items-center justify-center gap-4'}>
            {
                mapStyleOptions.map(o => {
                    return (
                        <label key={o.value} className="label cursor-pointer flex flex-col items-center">
                            <div className={`${mapStyle === o.value ? 'bg-accent' : 'bg-transparent'} p-1`}
                                 onClick={() => {
                                     if (_mapStyle !== (o as any).value) {
                                         _setMapStyle((o as any).value)
                                     }
                                     mapStore.getState().setMapStyle((o as any).value)
                                 }}>
                                <img className={'w-24'} src={o.img}/>
                            </div>
                            <span className="label-text px-2"> {o.label}</span>
                        </label>
                    )
                })
            }
        </div>
    )

    const myMarkerHeader = (
        <div className={'prose flex flex-col justify-start items-center gap-2'}>
            <h2 className={'mt-0 mb-0 text-primary'}>My location</h2>
        </div>
    )

    const myMarker = (
        <div className={'flex flex-col justify-center items-center gap-4'}>
            {myMarkerHeader}
            <SelectMyMarkerColor selected={selectedColor}
                                 onChange={(color: string) => {
                                     if (_myMarker !== color) {
                                         _setMyMarker(color)
                                     }
                                     controlStore.getState().setMyPositionColor(color)
                                 }}/>
        </div>
    )

    const height = isMobile ? 'h-[90vh]' : 'h-[100vh]'
    const panel = (
        <div id={'settings'}
             className={`fixed z-[100000] left-0 top-0 w-[100vw] ${height} overflow-auto bg-black/70 flex justify-center items-center`}
            data-theme={'gibi'}
        >
            <div className={'flex flex-col justify-center items-center gap-4 bg-white rounded m-12 p-2'}>
                {panelHeader}
                {chooseStyle}
                {myMarker}
                <div className={'btn btn-ghost text-secondary'} onClick={close}> Close </div>
            </div>
        </div>
    )

    return panel
}

import * as React from 'react'
import dataStore from '../../../../store/dataStore.ts'
import historyStore from '../../../../store/historyStore.ts'
import { IPet, IPoint } from '../../../../interfaces/types.ts'
import { centerMap } from '../../../map/mapHelpers.tsx'
import { HistoryListItem } from './HistoryListItem.tsx'
import { SVGSkeleton, Skeleton } from './Skeleton.tsx'

interface Props {
    listRef?: any
}

export const HistoryList: React.FC<Props> = (props) => {
    const locations = historyStore(s => s.locations)
    const selectedPetId = historyStore(s => s.selectedPetId)
    const pets = dataStore(s => s.pets)
    const pet = pets.find(p => p._id === selectedPetId)

    const generateLocationList = (locations: IPoint[], pet: IPet) => {
        return locations.map((location, index) => {
            return <HistoryListItem
                key={location._id}
                location={location}
                pet={pet}
                index={index}
                centerMap={centerMap}
                flex={locations.length < 10 ? 'flex-0.25' : 'flex-1'}
                desktop
            />
        })
    }

    const noHistory = (
        <>
            <div className="relative p-1 border-2 border-solid flex-1 w-[10vw]" style={{lineHeight: '0.75rem'}}>
                <div className="w-4 h-4 absolute bottom-1 left-0.5">
                    <SVGSkeleton className="w-4 h-4" />
                </div>
                <div className="flex flex-col justify-center gap-0">
                    <div className="self-center">
                        <Skeleton className="w-[64px] max-w-full" />
                    </div>
                    <div className={'self-center'}>
                        <Skeleton className="w-[96px] max-w-full" />
                    </div>
                    <div className={'self-center'}>
                        <Skeleton className="w-[64px] max-w-full" />
                    </div>
                </div>
            </div>
        </>
    )

    return locations?.length ?
        <div className={'flex flex-row justify-between items-center p-2 pb-0 w-full min-h-[4.25rem]'} ref={props.listRef}>
            <div className={'flex flex-row justify-start items-center gap-2 w-full overflow-x-auto'}>
                {/*{lists[listIndex] ? lists[listIndex] : noHistory}*/}
                {generateLocationList(locations, pet)}
            </div>
            {/*{listControl}*/}
        </div>
        :
        <div className={'flex flex-col justify-start items-center p-2 pb-0 w-full min-h-[4.25rem] relative'} ref={props.listRef}>
            <div className={'flex flex-row justify-start items-center gap-2 w-full overflow-x-auto'}>
                <div className={'w-fit self-center bg-white p-2 flex flex-row justify-start items-center gap-2 w-full overflow-hidden'}>
                    {noHistory} {noHistory} {noHistory}{noHistory} {noHistory} {noHistory}{noHistory} {noHistory} {noHistory}
                </div>
            </div>
            <div className={'w-full absolute top-6 left-0'}>
                <div className={'flex justify-center w-full'}>
                    <div className={'bg-white rounded p-1 text-lg text-accent w-fit text-center'}>
                        No history available
                    </div>
                </div>
            </div>
        </div>
}

import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons/faCircleChevronLeft'
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons/faCircleChevronRight'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { getNextPet, getPrevPet } from '../../../../helpers/historyHelper.ts'
import dataStore from '../../../../store/dataStore.ts'
import historyStore from '../../../../store/historyStore.ts'
import { fetchMovieData } from '../movie/movieHelper.tsx'

interface Props {
    showName?: boolean
}

export const SwitchPet: React.FC<Props> = (props) => {
    const {showName} = props
    const historyPets = historyStore(s => s.historyPets)
    const selectedPetId = historyStore(s => s.selectedPetId)
    const pets = dataStore(s => s.pets)
    const selectedPet = pets.find(p => p._id === selectedPetId)
    const isMovieMode = historyStore(s => s.isMovieMode)


    const forwardOne = () => {
        historyStore.getState().setSelectedPetId(getNextPet(historyPets, selectedPetId))
        if (isMovieMode) {
            void fetchMovieData('init')
        }
    }

    const backOne = () => {
        historyStore.getState().setSelectedPetId(getPrevPet(historyPets, selectedPetId))
        if (isMovieMode) {
            void fetchMovieData('init')
        }
    }

    const petLabel = (
        <div className={'flex items-center justify-center w-full'}>
            <div className={'flex items-center justify-center p-2 w-fit bg-accent text-white rounded-xl gap-2 '}>
                <div className="avatar">
                    <div className="w-8 rounded">
                        <img src={selectedPet?.imageURL}/>
                    </div>
                </div>
                <div className={'text-2xl font-semibold'}>
                    {selectedPet?.petName}
                </div>
            </div>
        </div>
    )

    return (
        <div className={'flex flex-row gap-6 justify-start items-center mt-0 pt-1'}>
            <div className={`bg-secondary-content text-secondary rounded-xl ${historyPets && historyPets.length > 1 ? 'opacity-100' : 'opacity-30'}`}
                 onClick={backOne}
            >
                <FontAwesomeIcon icon={faCircleChevronLeft} size={'2xl'}/>
            </div>
            {showName ? (
                <div className={''}>
                    {petLabel}
                </div>
            ) : null}
            <div className={`bg-secondary-content text-secondary rounded-xl ${historyPets && historyPets.length > 1 ? 'opacity-100' : 'opacity-30'}`}
                 onClick={forwardOne}
            >
                <FontAwesomeIcon icon={faCircleChevronRight} size={'2xl'} />
            </div>
        </div>
    )
}

import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { CheckinRateButton } from '../../../components/common/buttons/CheckinRateButton.tsx'
import { RemoveTrackerButton } from '../../../components/common/buttons/RemoveTrackerButton.tsx'
import { TrackerAssignmentButton } from '../../../components/common/buttons/TrackerAssignmentButton.tsx'
import { petFromDevice, readableSeconds } from '../../../helpers/dataHelpers.ts'
import controlStore from '../../../store/controlStore.ts'
import dataStore from '../../../store/dataStore.ts'


interface Props {
}


export const TrackerList: React.FC<Props> = () => {
    const devices = dataStore(s => s.devices)
    const navigate = useNavigate()
    const isMobile = controlStore(s => s.isOnMobileDevice)

    const header = (
        <div className={'pt-2'}>
            <h1 className="text-3xl font-semibold text-center text-primary mb-2">Gibi Trackers</h1>
        </div>
    )

    const altList = (
        <div className={'flex flex-col gap-4'}>
            {devices.map(c => {
                const pet = petFromDevice(c)
                return (
                    <div key={c._id}
                         className={'flex flex-col justify-start border-l-primary border-l-4 w-full bg-white px-4'}>

                        <div className={'flex items-center justify-between p-2 pb-3'}>
                            <div className={'text-lg text-left font-semibold'}>
                                {c.deviceName}
                            </div>

                            <div className="text-base-content font-medium min-w-[50%] text-right">
                                {pet ?
                                    <span>
                                        <span className={'text-neutral-content/50'}>Assigned to: </span>
                                        <span className={'font-semibold'}>
                                            {pet.petName}
                                        </span>
                                    </span>
                                    : <span className={'font-semibold'}>
                                            unassigned
                                    </span>}
                            </div>
                        </div>

                        <div className={'flex justify-between w-full rounded-lg bg-primary/5 p-2 border-2 border-primary/10'}
                        >
                            <div className={'flex flex-col text-left text-base-content font-medium p-2 pt-0'}>
                                <div className={'text-neutral-content/50'}>ID</div>
                                <div className={'font-semibold pl-2'}>{c.iccid}</div>
                                <div className={'text-neutral-content/50'}>IMSI</div>
                                <div className={'font-semibold pl-2'}>{c.imsi}</div>
                            </div>

                            <div className={'flex flex-col gap-0 text-left text-base-content font-medium p-2 pt-0'}>
                                <div className={'text-neutral-content/50'}>Firmware Version</div>
                                <div className={'font-semibold pl-2'}>{c.status.firmwareVersion}</div>
                                <div className={'text-neutral-content/50'}>Check-in rate</div>
                                <div className={'font-semibold pl-2'}>{readableSeconds(c.status.backgroundInterval)}</div>
                            </div>
                        </div>

                        <div className={'flex items-center justify-around w-full p-2'}>
                            <TrackerAssignmentButton tracker={c} unassign/>
                            <TrackerAssignmentButton tracker={c} short/>
                            <CheckinRateButton tracker={c} textOnly/>
                            <RemoveTrackerButton tracker={c}/>
                        </div>
                    </div>
                )
            })
            }
        </div>
    )

    const width = !isMobile ? 'w-[32rem]' : ''

    return (
        <div className={`${width} h-[90vh] overflow-y-auto`}>
            {header}
            {altList}
            <input
                type={'button'}
                className={'mt-4 btn btn-ghost text-primary'}
                onClick={() => navigate('/' + controlStore.getState().currentPage)}
                value={'Close'}
            />
        </div>
    )
}

import * as React from 'react'
import { getTextColoHex, hexToRgbA } from '../../helpers/colorHelper.ts'
import dataStore from '../../store/dataStore.ts'
import { DashboardItemDesktop } from './DashboardItemDesktop.tsx'

interface Props {
}

export const DesktopDashboard: React.FC<Props> = () => {
    const pets = dataStore(s => s.pets).sort((a, b) => a.petName > b.petName ? 1 : -1)
    const [index, setIndex] = React.useState(0)

    const petList = pets.map((p, i) => (
        <div key={p._id} className={`flex items-center gap-2 border-l-8 ${i === index ? 'border-r-8' : ''}`}
             onClick={() => setIndex(i)}
             style={{
                 borderColor: p.iconColor,
                 backgroundColor: hexToRgbA(p.iconColor, '.5')
             }}
        >
            <div className="avatar">
                <div className="w-24 rounded">
                    <img src={p?.imageURL} alt={'pet-photo'}/>
                </div>
            </div>

            <div className={'text-3xl font-semibold'}
                 style={{
                     color: getTextColoHex(p.iconColor)
                 }}>
                {p.petName}
            </div>
        </div>
    ))

    // const style = {
    //     backgroundColor: hexToRgbA(pets[index]?.iconColor, '.2')
    // }

    return (
        <div className={'flex items-center bg-primary'}>
            <div className={'flex flex-col gap-4 w-[40vw] p-4 self-start h-[90vh] overflow-y-auto'}>
                {petList}
            </div>
            <div className={'w-[60vw] p-4 bg-primary'}>
                <DashboardItemDesktop pet={pets[index]}/>
            </div>
        </div>
    )
}

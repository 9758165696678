import * as React from 'react'
import dataStore from '../../../store/dataStore.ts'
import { IDevice, IPet } from '../../../interfaces/types.ts'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons/faPowerOff'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SubItem } from './SubItem.tsx'

interface Props {
    pet: IPet
}

// function getIcon(deviceStatus: IDeviceStatus, color: any) {
//     if (deviceStatus?.beaconInRange) {
//         return <FontAwesomeIcon icon={faHeart} color={color}/>
//     } else {
//         return <FontAwesomeIcon icon={faEmptyHeart} color={color}/>
//     }
// }

export const PowerSaveStatus = (props: Props) => {
    const {pet} = props
    // const [backgroundColor, setBackgroundColor] = React.useState('bg-primary')
    const [text, setText] = React.useState('')
    const [isSaving, setIsSaving] = React.useState(false)
    const [device, setDevice] = React.useState<IDevice | null>(null)

    const devices = dataStore((s) => s.devices)

    React.useEffect(() => {
        if (devices) {
            const dev = devices.find(d => d.petId === pet._id)
            // const color = 'inherit'
            if (dev) {
                setDevice(dev)
                if (dev.status.beaconInRange) {
                    setText('saving on')
                    setIsSaving(true)
                } else {
                    setText(`saving off`)
                }
            }
        }
    }, [devices, pet])
    const subItem = (
        <SubItem pet={pet} title={'Power Saving'}
                 icon={<FontAwesomeIcon icon={faPowerOff} fontSize={'18px'} className={isSaving ? 'text-success' : 'text-error'}/>}>
            <div className={'flex items-center justify-end'}>
                <div className={`${isSaving ? 'text-success' : 'text-accent'} font-semibold`}>
                    {text}
                </div>
            </div>

        </SubItem>
    )

    return device ? subItem : null
}

import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { States } from '../../assets/usaStates.ts'
import { useForm as useFormHandler } from '../../hooks/useForm.ts'
import controlStore from '../../store/controlStore.ts'
import dataStore from '../../store/dataStore.ts'
import { Section } from './components/Section.tsx'
import { LabeledInput } from './widgets/LabeledInput.tsx'
import { PhoneNumber } from './widgets/PhoneNumber.tsx'
import { PopupSelect } from './widgets/PopupSelect.tsx'
import { RadioGroup } from './widgets/RadioGroup.tsx'
import { FormLayout } from './components/FormLayout.tsx'
import { SubmitCancelButtons } from './components/SubmitCancelButtons.tsx'
import { Country, SMSOptIn } from './widgets/options.tsx'

export const ProfileForm: React.FC = () => {
    const profile = dataStore(s => s.account)
    const timezones = dataStore(s => s.timeZones)
        .filter(t => t.status === 'active')
        .sort((a, b) => a.offset <= b.offset ? 1 : -1)
        .map(t => ({ label: t.timezone_name, value: t._id }))

    const defaultValues = {
        ...profile,
        addr1: profile.homeAddress.line1,
        addr2: profile.homeAddress.line2,
        city: profile.homeAddress.city,
        state: profile.homeAddress.state,
        postalCode: profile.homeAddress.postalCode,
        country: profile.homeAddress.country
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        control,
        trigger,
        watch,
    } = useForm({
        defaultValues: defaultValues,
        mode: 'onChange',
        reValidateMode: 'onBlur',
    })

    React.useEffect(() => {
        void trigger()
    }, [])

    const navigate = useNavigate()
    const isMobile = controlStore(s => s.isOnMobileDevice)

    const [onSubmit] = useFormHandler()

    const cancel = () => {
        navigate('/' + controlStore.getState().currentPage)
    }
    const processForm = (data: any) => {
        void onSubmit('profile', data)
    }

    const header = (
        <div className={'pt-2'}>
            <h1 className="text-3xl font-semibold text-center text-primary mb-2">Profile</h1>
        </div>
    )
    
    const width = !isMobile ? 'w-[32rem]' : ''

    return (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <form onSubmit={handleSubmit(processForm)} className={`h-full ${width}`}>
            <FormLayout trigger={trigger} header={header}>

                <div className={'flex flex-col gap-2 h-[90%] overflow-y-auto'}>
                    <div className={'flex flex-col item-center gap-4'}>
                        {/*  name  */}
                        <Section>
                            <LabeledInput
                                formField={'firstName'}
                                title={'First Name'}
                                {...register('firstName' as any, {
                                    required: {
                                        value: true,
                                        message: 'Required'
                                    },
                                })}
                                errors={errors}
                            />
                            <LabeledInput
                                formField={'lastName'}
                                title={'Last Name'}
                                {...register('lastName' as any, {
                                    required: {
                                        value: true,
                                        message: 'Required'
                                    },
                                })}
                                errors={errors}
                            />
                        </Section>

                        {/*  email / cell  */}
                        <Section>
                            <PhoneNumber
                                formField={'mobile'}
                                title={'Mobile number'}
                                errors={errors}
                                control={control}
                                required
                            />
                            {/*<LabeledInput*/}
                            {/*    formField={'email'}*/}
                            {/*    title={'Email'}*/}
                            {/*    {...register('email' as any, {})}*/}
                            {/*    errors={errors}*/}
                            {/*    {...{readOnly: true, disabled: true}}*/}
                            {/*/>*/}
                            <PopupSelect
                                control={control}
                                formField={'timeZone'}
                                title={'Timezone'}
                                errors={errors}
                                options={timezones}
                                required
                                showValueLabel
                                btnSize={'lg'}
                                watch={watch}
                                hideComplete
                            />
                            <RadioGroup
                                formField={'smsOptIn'}
                                control={control}
                                options={SMSOptIn}
                                title={'Receive Text Alerts'}
                                errors={errors}
                                required
                            />
                        </Section>

                        {/*  address  */}
                        <Section>
                            <LabeledInput
                                formField={'addr1'}
                                title={'Address'}
                                {...register('addr1' as any, {
                                    required: {
                                        value: true,
                                        message: 'Required'
                                    },
                                })}
                                errors={errors}
                            />
                            <LabeledInput
                                formField={'addr2'}
                                title={'Apt'}
                                {...register('addr2' as any, {})}
                                errors={errors}
                            />
                            <LabeledInput
                                formField={'city'}
                                title={'City'}
                                {...register('city' as any, {
                                    required: {
                                        value: true,
                                        message: 'Required'
                                    },
                                })}
                                errors={errors}
                            />
                            <PopupSelect
                                control={control}
                                formField={'state'}
                                title={'State'}
                                errors={errors}
                                options={States}
                                required
                                showValueLabel
                                btnSize={'sm'}
                                watch={watch}
                                hideComplete
                            />
                            <LabeledInput
                                formField={'postalCode'}
                                title={'Zip'}
                                {...register('postalCode' as any, {
                                    required: {
                                        value: true,
                                        message: 'Required'
                                    },
                                })}
                                errors={errors}
                            />
                            <RadioGroup
                                formField={'country'}
                                control={control}
                                options={Country}
                                title={'Country'}
                                errors={errors}
                                required/>
                        </Section>
                    </div>

                    <SubmitCancelButtons errors={errors} cancel={cancel} changed={isDirty}/>

                </div>

            </FormLayout>
        </form>
    )
}

import { DateTime } from 'luxon'
import { recordFindRequest, findRequestError } from '../interfaces/findState.ts'
import authStore from '../store/authStore.ts'
import dataStore from '../store/dataStore.ts'
import findStore from '../store/findStore.ts'
import { IPet } from '../interfaces/types.ts'
import { post } from './apiHelper.ts'
import { TRACKER_COMMAND_URL } from './urls.ts'

export const findPet = async (pet: IPet) => {
    recordFindRequest(pet)
    const date = DateTime.now().setZone(authStore.getState().timezone.value).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
    try {
        const devices = dataStore.getState().devices
        const device = devices.find(d => d.petId === pet._id)
        const data = {
            iccid: device?.iccid,
            command: 'find',
        }
        findStore.getState().setInProgress(pet._id, true)
        const result = await post(TRACKER_COMMAND_URL, data)
        if (result?.status === 200) {
            findStore.getState().setStatus(pet._id, {msg: 'Find command sent to server', date})
            findStore.getState().setInProgress(pet._id, true)
        } else {
            findStore.getState().setStatus(pet._id, {msg: 'Find command failed at server', date})
            findRequestError(pet, 'Gibi server error')
            setTimeout(() => {
                findStore.getState().setStatus(pet._id, {msg:'', date: ''})
                findStore.getState().setInProgress(pet._id, false)
            }, 1500)
        }
    }
    catch (e) {
        findStore.getState().setStatus(pet._id, {msg: 'Find command failed at server', date})
        findRequestError(pet, 'Gibi server error')
    }
}

export const sendCommand = async (command: string, iccid: string, value: any) => {
    try {
        const data = {
            iccid,
            command,
            value
        }
        const res = await post(TRACKER_COMMAND_URL, data)
        return res && res.status === 200 ? (res.data?.data || true) : false
    }
    catch (e) {
        return false
    }
}

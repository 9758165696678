import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import AnimateHeight from 'react-animate-height'
import drawerStore from '../../store/drawerStore.ts'
import mapStore from '../../store/mapStore.ts'
import { clearRoute } from '../map/Directions/directionHelper.tsx'
import './PetDrawer.css'

// const getVal = (px: string) => parseInt(px.split('px')[0])
// const heightPresets = [20, 80, 160, 240, 330, 400]
export const DesktopPetDrawer: React.FC = () => {
    const width = drawerStore(s => s.drawerSize)
    const children = drawerStore(s => s.children)
    const travelMode: google.maps.TravelMode = mapStore(s => s.travelMode)
    
    return (
        <div className={'flex items-end'}>
            <AnimateHeight
                id="desktop-pet-drawer"
                duration={500}
                height={width as any}
                className={'h-auto'}
                disableDisplayNone
            >
                <div className={'h-auto bg-white min-w-[20vw]'}>
                    {children}
                    {drawerStore.getState().children === null ? (
                        <div id="directions-header"
                             className={'w-full flex flex-row justify-between items-center border-b-2 border-b-accent'}>
                            <div className={'p-2 text-md font-semibold text-primary'}>
                                {travelMode} Directions
                            </div>
                            <div
                                className={'pr-4 cursor-pointer'}
                                onClick={() => clearRoute()}>
                                <FontAwesomeIcon icon={faClose} fontSize={'medium'} size={'lg'}/>
                            </div>
                        </div>
                    ) : null}
                    <div id="directions-panel"
                         style={{maxHeight: '25vh', overflowY: 'auto', padding: '0.25rem', borderBottom: children === null ? 'solid 2px hsl(var(--a))' : ''}}></div>
                </div>
            </AnimateHeight>
        </div>
    )
}

import { useEffect, useState } from 'react'
import ReconnectingWebSocket from 'reconnecting-websocket'
import authStore from '../store/authStore'
import mapStore from '../store/mapStore'
import { getUserIPaddress, processGibiMessage } from './webSocketHelper'

export const WebSocket = () => {
    const [notStarted, setNotStarted] = useState(true)

    useEffect(() => {
        void getUserIPaddress().then(() => console.log('got ws uuid'));
    }, []);

    const userToken: string = authStore(s => s.token)
    const userIP: string = authStore(s => s.userIP)

    const processWebSocketMessage = (message: any) => {
        if (message.status === 'ok' || message.type === 'echo') {
            mapStore.getState().setWebSocketConnected(true)
            return
        }
        processGibiMessage(message)
    }

    const reloadAllStores = (userToken: string) => {
        console.log('reload', userToken)
    }

    useEffect(() => {
        if (userToken && userIP && notStarted) {
            const startWebSocket = (userToken: string) => {
                const login = () => {
                    const msg = {
                        type: 'login',
                        token: userToken
                    }
                    // ws.current.send(JSON.stringify(msg))
                    rws.send(JSON.stringify(msg))
                    reloadAllStores(userToken)
                }

                const url = import.meta.env.VITE_WEBSOCKET_URL + `/${userIP}`
                // const socket = new WebSocket(url);
                const rws = new ReconnectingWebSocket(url)

                rws.addEventListener('open', () => {
                    login()
                })

                rws.addEventListener('close', () => {
                    mapStore.getState().setWebSocketConnected(false)
                })

                rws.addEventListener('error', (error) => {
                    console.log('websocket error', error)
                    mapStore.getState().setWebSocketConnected(false)
                })

                rws.addEventListener('message', (event) => {
                    let message
                    try {
                        message = event?.data ? JSON.parse(event.data) : {}
                        if (message.status === 'ok' || message.type === 'echo') {
                            mapStore.getState().setWebSocketConnected(true)
                            return
                        } else {
                            console.log('got message', message)
                            processWebSocketMessage(message)
                        }
                    } catch (e) {
                        console.log('web socket', e)
                    }
                })

                return () => {
                    rws.close()
                }
            }

            if (userToken) {
                console.log('websock', userToken)
                startWebSocket(userToken)
                setNotStarted(false)
            }
        }
    }, [userToken, userIP])

    return <div/>
}

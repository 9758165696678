import { faBattery, faClose } from '@fortawesome/free-solid-svg-icons'
import { faBicycle } from '@fortawesome/free-solid-svg-icons/faBicycle'
import { faCarSide } from '@fortawesome/free-solid-svg-icons/faCarSide'
import { faPersonWalking } from '@fortawesome/free-solid-svg-icons/faPersonWalking'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MarkerF } from '@react-google-maps/api'
import { DateTime } from 'luxon'
import * as React from 'react'
import TimeAgo from 'react-timeago'
import { deviceFromPet } from '../../../helpers/dataHelpers.ts'
import authStore from '../../../store/authStore.ts'
import mapStore from '../../../store/mapStore.ts'
import { IDeviceStatus, IPet } from '../../../interfaces/types.ts'
import { getPawIcon } from '../components/icons.ts'
import { OverlayPanel } from '../components/OverlayPanel.tsx'
import './style.css'

// const radiusMap: Record<number, number> = {
//     14: 10,
//     15: 5,
//     16: 2.75,
//     17: 2,
//     18: 1,
//     19: 0.5,
//     20: 0.25,
//     21: 0.125,
//     22: 0.08
// }
// const distanceMap: Record<number, number> = {
//     14: 0.125,
//     15: 0.065,
//     16: 0.03,
//     17: 0.015,
//     18: 0.0075,
//     19: 0.004,
//     20: 0.002,
//     21: 0.001,
//     22: 0.0005
// }

interface Props {
    pet: IPet
    position: google.maps.LatLng
    status: IDeviceStatus
}

export const PetMarker: React.FC<Props> = (props) => {
    const { pet, position } = props
    const [open, setOpen] = React.useState(false)
    const [near, setNear] = React.useState('')

    // const zoom = mapStore(s => s.zoom)
    const markerInfo = mapStore(s => s.markerInfo)
    const myPosition = mapStore(s => s.myPosition)
    const directionsTo = mapStore(s => s.directionsTo)

    const geocoder = new google.maps.Geocoder()

    const valid = Boolean(!!myPosition && !directionsTo)
    // const indicator = parseFloat(status.batteryPercent) > parseFloat("20%")
    React.useEffect(() => {
        if (!markerInfo) {
            setOpen(false)
        }
    }, [markerInfo])

    const handleClick = () => {
        setOpen(!open)
        mapStore.getState().setMarkerInfo(!open ? pet._id : null)
    }

    const getDirections = (mode: google.maps.TravelMode) => {
        mapStore.getState().setDirectionsTo(position)
        mapStore.getState().setTravelMode(mode)
    }

    const device = deviceFromPet(pet)
    if (!device.lastLocation || !Object.keys(device.lastLocation).length) {
        // bad data, can't render a device on the map with no location
        return null
    }

    const date = DateTime.fromSeconds(device.lastLocation.properties.timeStamp).setZone(authStore.getState().timezone.value)
    const latLng = new google.maps.LatLng({
        lat: device.lastLocation.properties?.latitude,
        lng: device.lastLocation.properties?.longitude,
    })
    // @ts-ignore
    void geocoder.geocode({ location: latLng }).then((response) => {
        if (response.results[0]) {
            setNear(response.results[0].formatted_address)
        }
    })

    const petInfo = (
        <div className={'flex flex-col justify-start gap-0.5'}>
            <div className={'text-lg font-semibold -mb-2'}>{pet.petName}</div>
            <div className={'flex flex-row justify-between gap-4 align-center'}>
                <div className={'w-4 h-4'}/>
                <div className={'flex gap-2 items-center'}>
                    <TimeAgo date={DateTime.fromISO(device.lastLocation?.properties.receivedTime)
                        .setZone(authStore.getState().timezone.value).toJSDate()}/>
                    <div className={'flex gap-2 items-center'}>
                        <FontAwesomeIcon icon={faBattery} fontSize={'small'}/>
                        <div>{device.lastLocation.properties.batteryPercent}</div>
                    </div>
                </div>
                <div className={''}
                     onClick={() => setOpen(false)}>
                    <FontAwesomeIcon icon={faClose} fontSize={'small'}/>
                </div>
            </div>
            <div className={'text-xs'}>Near {near}</div>
            <div className={'text-xs'}>{date.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}</div>
            <div className={'flex flex-row justify-around align-center'}>
                <button className={'btn btn-xs btn-primary'}
                        onClick={() => getDirections(google.maps.TravelMode.WALKING)}
                        disabled={!valid}
                >
                    <FontAwesomeIcon icon={faPersonWalking}/>
                    Directions
                </button>
                <button className={'btn btn-xs btn-primary'}
                        onClick={() => getDirections(google.maps.TravelMode.BICYCLING)}
                        disabled={!valid}
                >
                    <FontAwesomeIcon icon={faBicycle}/>
                    Directions
                </button>
                <button className={'btn btn-xs btn-primary'}
                        onClick={() => getDirections(google.maps.TravelMode.DRIVING)}
                        disabled={!valid}
                >
                    <FontAwesomeIcon icon={faCarSide}/>
                    Directions
                </button>
            </div>
        </div>
    )


    // const distance = zoom < 14 ? distanceMap[14] : distanceMap[zoom]
    // const radius = zoom < 14 ? radiusMap[14] : radiusMap[zoom]

    // const bearing = 45
    // const point = turf.point([position.lng(), position.lat()])
    // const indicatorMarker = turf.destination(point, distance, bearing)
    // const indicatorCoords = {lat: indicatorMarker.geometry.coordinates[1], lng: indicatorMarker.geometry.coordinates[0]}

    return (
        <div>
            {open ?
                <OverlayPanel onClick={handleClick} position={position}>
                    {petInfo}
                </OverlayPanel>
                : null}
            <MarkerF key={pet._id}
                     position={position}
                     icon={getPawIcon(pet.iconColor, google)}
                     onClick={handleClick}
            />
            {/*<CircleF key={`${pet._id}-circle`}*/}
            {/*         center={indicatorCoords}*/}
            {/*         radius={radius}*/}
            {/*         options={{*/}
            {/*             fillColor: indicator ? '#3ec964' : '#c93e3e',*/}
            {/*             fillOpacity: 1,*/}
            {/*             strokeColor: indicator ? '#3ec964' : '#c93e3e',*/}
            {/*             strokeWeight: 2,*/}
            {/*         }}*/}
            {/*/>*/}
        </div>
    )
}


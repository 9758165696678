import { create } from 'zustand'

import {
    AccountDataSet,
    ApplicationDataSet,
    IDevice,
    EventsDataState,
    MapDataSet,
    IPet,
    RegistrationDataSet,
    IZone, IContact, IEvent, IPoint, IAccount
} from '../interfaces/types.ts'

interface DataState extends MapDataSet, AccountDataSet, ApplicationDataSet, EventsDataState {
    setPets: (pets: IPet[]) => void
    setDevices: (pets: IDevice[]) => void
    setLastLocation: (iccid: string, position: any) => void
    setLastReport: (iccid: string, position: any) => void
    setLastEvent: (iccid: string, event: any) => void
    updateDeviceStatus: (iccid: string, deviceStatus: any) => void
    loadMapData: (data: MapDataSet) => void
    loadAccountData: (data: AccountDataSet) => void
    loadApplicationData: (data: ApplicationDataSet) => void
    loadRegistrationData: (data: RegistrationDataSet) => void
    loadEventData: (data: any) => void

    currentPet: IPet | null
    setCurrentPet: (p: IPet | null) => void

    currentDevice: IDevice | null
    setCurrentDevice: (d: IDevice | null) => void

    currentContact: any
    setCurrentContact: (d: any) => void

    setContacts: (c: IContact[]) => void

    setProfile: (p: any) => void
    setHomeAddress: (p: any) => void
    setDevicePlans: (p: any[]) => void
    setBilling: (p: any[]) => void
    setPaymentMethod: (p: string) => void
    setBreeds: (b: any) => void
    setAccount: (account: IAccount) => void
    reset: () => void
}

const useStore = create<DataState>((set) => ({
    pets: [],
    setPets: (pets: IPet[]) => {
        set((state) => {
            return {
                ...state,
                pets
            }
        })

    },

    devices: [],
    setDevices: (devices: IDevice[]) => {
        set((state) => {
            return {
                ...state,
                devices
            }
        })
    },

    safeZones: [],
    setSafeZones: (safeZones: IZone[]) => {
        set((state) => {
            return {
                ...state,
                safeZones
            }
        })
    },
    powerZones: [],

    loadMapData: (data: MapDataSet) =>
        set((state) => {
            console.log(data)
            return {
                ...state,
                ...data,
            }
        }),

    setLastLocation: (iccid: string, position: IPoint) => {
        set((state) => {
            const index = state.devices.findIndex(d => d.iccid === iccid)
            if (index > -1) {
                if (state.devices[index]) {
                    const d = {...state.devices[index]}
                    const device = Object.assign({}, d)
                    device.lastLocation = position
                    const update = state.devices.slice(0)
                    update[index] = device
                    return {
                        ...state,
                        devices: update
                    }
                }
            }
            return state
        })
    },

    setLastReport: (iccid: string, position: IPoint) => {
        set((state) => {
            const index = state.devices.findIndex(d => d.iccid === iccid)
            if (index > -1) {
                if (state.devices[index]) {
                    const d = {...state.devices[index]}
                    const device = Object.assign({}, d)
                    device.lastReport = position
                    const update = state.devices.slice(0)
                    update[index] = device
                    return {
                        ...state,
                        devices: update
                    }
                }
            }
            return state
        })
    },

    updateDeviceStatus: (iccid: string, deviceStatus: any) => {
        set((state) => {
            const index = state.devices.findIndex(d => d.iccid === iccid)
            if (index > -1) {
                if (state.devices[index]) {
                    const d = {...state.devices[index]}
                    const device = Object.assign({}, d)
                    device.status = deviceStatus
                    const update = state.devices.slice(0)
                    update[index] = device
                    return {
                        ...state,
                        devices: update
                    }
                }
            }
            return state
        })
    },

    setLastEvent: (iccid: string, event: IEvent) => {
        set((state) => {
            const index = state.devices.findIndex(d => d.iccid === iccid)
            if (index > -1) {
                if (state.devices[index]) {
                    const d = {...state.devices[index]}
                    const device = Object.assign({}, d)
                    device.lastEvent = event
                    const update = state.devices.slice(0)
                    update[index] = device
                    return {
                        ...state,
                        devices: update
                    }
                }
            }
            return state
        })
    },

    account: {},
    setAccount: (account: IAccount) =>
        set((state) => {
            return {
                ...state,
                account,
            }
        }),
    billing: [],
    contacts: [],
    setContacts: (contacts: IContact[]) =>
        set((state) => {
            return {
                ...state,
                contacts,
            }
        }),
    devicePlans: [],
    paymentMethod: '',
    setPaymentMethod: (paymentMethod: string) =>
        set((state) => {
            return {
                ...state,
                paymentMethod
            }
        }),
    loadAccountData: (data: AccountDataSet) =>
        set((state) => {
            console.log(data)
            return {
                ...state,
                account: data.account,
                billing: data.billing,
                contacts: data.contacts,
                devicePlans: data.devicePlans,
                paymentMethod: data.paymentMethod,
            }
        }),

    species: [],
    breeds: [],
    setBreeds: (breeds: any) =>
        set((state) => {
            return {
                ...state,
                breeds
            }
        }),

    timeZones: [],
    iconColors: [],
    rates: [],
    alertTypes: [],
    plans: [],
    codes: [],
    loadApplicationData: (data: ApplicationDataSet) =>
        set((state) => {
            console.log(data)
            return {
                ...state,
                ...data,
            }
        }),

    loadRegistrationData: (data: RegistrationDataSet) =>
        set((state) => {
            console.log(data)
            return {
                ...state,
                ...data,
            }
        }),

    events: [],
    unreadCount: 0,
    loadEventData: (data: any[]) =>
        set((state) => {
            console.log(data)
            return {
                ...state,
                events: data,
                unreadCount: data.length
            }
        }),

    currentPet: null,
    setCurrentPet: (pet: IPet | null) =>
        set((state) => {
            return {
                ...state,
                currentPet: pet,
            }
        }),

    currentDevice: null,
    setCurrentDevice: (d: IDevice | null) =>
        set((state) => {
            return {
                ...state,
                currentDevice: d,
            }
        }),

    currentContact: null,
    setCurrentContact: (d: any) =>
        set((state) => {
            return {
                ...state,
                currentContact: d,
            }
        }),

    setProfile: (p: any) =>
        set((state) => {
            return {
                ...state,
                account: {...state.account, ...p}
            }
        }),

    setHomeAddress: (a: any) =>
        set((state) => {
            return {
                ...state,
                account: {
                    ...state.account,
                    homeAddress: {...state.account.homeAddress, ...a}
                }
            }
        }),

    setDevicePlans: (devicePlans: any[]) =>
        set((state) => {
            return {
                ...state,
                devicePlans
            }
        }),

    setBilling: (billing: any[]) =>
        set((state) => {
            return {
                ...state,
                billing
            }
        }),

    reset: () =>
        set((state) => ({
            ...state,
            pets: [],
            devices: [],
            safeZones: [],
            powerZones: [],
            account: {},
            billing: [],
            contacts: [],
            species: [],
            breeds: [],
            timeZones: [],
            iconColors: [],
            rates: [],
            alertTypes: [],
            plans: [],
            devicePlans: [],
            currentPet: null,
            currentDevice: null,
        })),
}))

export default useStore

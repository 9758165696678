import * as React from 'react'
import toast from 'react-hot-toast'
import { removeFormObject } from '../../../handlers/formDataHandler.ts'
import dataStore from '../../../store/dataStore.ts'
import { IContact } from '../../../interfaces/types.ts'
import { AsyncButton } from '../AsyncButton/AsyncButton.tsx'
import { PopupPortal } from '../Popup/PopupPortal.tsx'

interface Props {
    contact: IContact
    open: boolean
    setOpen: (o: boolean) => void
}

export const RemoveContact: React.FC<Props> = (props) => {
    const { contact, open, setOpen } = props

    const sendCommand = async () => {
        try {
            const resultData = await removeFormObject('contact', contact._id)
            setOpen(false)
            if (resultData) {
                dataStore.getState().setContacts(resultData)
                toast.success('Pet family member removed')
            } else {
                toast.error('Error, family member remove failed, try again later.')
            }
        } catch (e) {
            // TODO: handle error
            toast.error('Fatal error, family member remove failed try again later.')
            setOpen(false)
        }
    }

    const cancel = () => {
        setOpen(false)
    }

    const panelHeader = (
        <div className={'prose flex flex-col justify-start items-center gap-2'}>
            <h2 className={'mt-0 mb-0 text-primary'}>Remove a Follower</h2>
        </div>
    )

    const panel = (
        <div className={'flex flex-col justify-start items-center gap-2 h-full p-4'}>
            {panelHeader}
            <div className={'text-neutral-content flex flex-col justify-start items-center gap-4'}>
                <div className={'font-medium text-center text-lg w-full'}>
                    <div>Are you sure you want to remove<div/>
                        <div className={'font-semibold'}>{contact.first_name} {contact.last_name}</div>
                        <div>from your pets Followers?</div>
                    </div>
                </div>
                <article className={'text-accent text-center'}>
                    This action cannot be undone.
                </article>
            </div>
        </div>
    )

    const buttons = (
        <div className={'p-4 flex justify-between items-center w-full'}>
            <button className={'btn text-secondary btn-ghost btn-sm'} onClick={cancel}>Cancel</button>
            <AsyncButton classNames={'btn btn-outlined btn-secondary btn-sm'} onClick={sendCommand}
                         disabled={false}>Remove</AsyncButton>
        </div>
    )

    const popUp = (
        <PopupPortal open={open}>
            {panel}
            {buttons}
        </PopupPortal>
    )

    return (<>
            {popUp}
        </>
    )
}

import { create } from 'zustand'
import { ZonePath } from '../interfaces/types.ts'
import { SafeZoneInstructions } from '../components/safeZones/components/Instructions.tsx'

export type SafeZoneControlViewType = 'list' | 'choose' | 'drawing' | 'search' | 'finish'

interface SafeZoneState {
    safeZonePolygons: React.ReactElement[]
    setSafeZonePolygons: (p: React.ReactElement[]) => void

    newSafeZone: React.ReactElement | null
    setNewSafeZone: (p: React.ReactElement | null) => void

    newSafeZoneGeometry: ZonePath
    setNewSafeZoneGeometry: (p: ZonePath) => void

    editedZoneName: string
    setEditedSafeZoneName: (n: string) => void

    safeZoneBeingEdited: string
    setSafeZoneBeingEdited: (b: string) => void

    updatedZonePath: ZonePath,
    setUpdatedZonePath: (updatedZonePath: ZonePath) => void

    active: boolean
    setActive: (o: boolean) => void

    view: SafeZoneControlViewType
    setView: (v: SafeZoneControlViewType) => void

    instructions: SafeZoneInstructions | null
    setInstructions: (i: SafeZoneInstructions | null) => void

    drawingManager: any | null
    setDrawingManager: (d: any | null) => void

    reset: () => void
}

const useStore = create<SafeZoneState>((set) => ({
    safeZonePolygons: [],
    setSafeZonePolygons: (polygons: React.ReactElement[]) =>
        set((state) => ({
            ...state,
            safeZonePolygons: polygons
        })),

    newSafeZone: null,
    setNewSafeZone: (newSafeZone: React.ReactElement | null) =>
        set((state) => ({
            ...state,
            newSafeZone
        })),

    newSafeZoneGeometry: [],
    setNewSafeZoneGeometry: (newSafeZoneGeometry: any[]) =>
        set((state) => ({
            ...state,
            newSafeZoneGeometry
        })),

    editedZoneName: '',
    setEditedSafeZoneName: (newSafeZoneName: string) =>
        set((state) => ({
            ...state,
            editedZoneName: newSafeZoneName
        })),

    editedSafeZoneGeometry: [],
    setEditedSafeZoneGeometry: (editedSafeZoneGeometry: ZonePath) =>
        set((state) => ({
            ...state,
            editedSafeZoneGeometry
        })),

    safeZoneBeingEdited: '',
    setSafeZoneBeingEdited: (editingSafeZoneId: string) =>
        set((state) => ({
            ...state,
            safeZoneBeingEdited: editingSafeZoneId,
        })),

    updatedZonePath: [],
    setUpdatedZonePath: (updatedZonePath: ZonePath) =>
        set((state) => ({
            ...state,
            updatedZonePath,
        })),

    active: false,
    setActive: (open: boolean) =>
        set((state) => ({
            ...state,
            active: open,
        })),

    view: 'list',
    setView: (view: SafeZoneControlViewType) =>
        set((state) => ({
            ...state,
            view,
        })),

    instructions: null,
    setInstructions: (instructions: SafeZoneInstructions | null) =>
        set((state) => ({
            ...state,
            instructions,
        })),

    drawingManager: null,
    setDrawingManager: (drawingManager: any | null) =>
        set((state) => ({
            ...state,
            drawingManager,
        })),

    reset: () =>
        set((state) => ({
            ...state,
            safeZonePolygons: [],
            newSafeZone: null,
            editedZoneName: '',
            safeZoneBeingEdited: '',
            updatedZonePath: [],
            view: 'list',
            active: false,
            instructions:  null,
            drawingManager: null,
        })),
}))

export default useStore

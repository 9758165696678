import * as React from 'react'
import { Controller } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import SelectContact from '../../../../components/common/SelectContact/SelectContact.tsx'
import SelectPet2 from '../../../../components/common/SelectPet/SelectPet2.tsx'
import { sendCommand } from '../../../../helpers/commandHelper.ts'
import controlStore from '../../../../store/controlStore.ts'
import dataStore from '../../../../store/dataStore.ts'
import { Section } from '../../components/Section.tsx'

interface Props {
    control: any
    disabled: boolean
    data: any
    watch: any
    setValue: (n: any, v: any) => void
    petCreated: boolean
    STEPS: any
    createAccount?: boolean
}

export const ChoosePetAndContact: React.FC<Props> = (props) => {
    const { disabled, data, control, watch, petCreated, STEPS, createAccount } = props

    const navigate = useNavigate()

    const petId = watch('petId')
    const pets = dataStore(s => s.pets)
    const contactId = watch('contactId')
    const contacts = dataStore(s => s.contacts)

    React.useEffect(() => {
        const assignPet = async (petId: string) => {
            const resultData = await sendCommand('assign', data.iccid, petId)
            if (resultData) {
                dataStore.getState().setDevices(resultData)
                const petName = pets.find(p => p._id === petId).petName
                toast.success(`Gibi Tracker assigned to ${petName}`)
            } else {
                toast.error('Error, Gibi Tracker assignment failed, try again later.')
            }
        }
        if (petId) {
            if (petId === 'new') {
                controlStore.getState().setCurrentPage('registration')
                const state = { data: data }
                navigate('/pets', { state })
            } else {
                void assignPet(petId)
            }
        }

    }, [petId])

    React.useEffect(() => {
        const followPet = async (contactId: string) => {
            const resultData = await sendCommand('follow', data.iccid, contactId)
            if (resultData) {
                dataStore.getState().setContacts(resultData)
                const petName = pets.find(p => p._id === petId).petName
                const c = contacts.find(c => c._id === contactId)
                toast.success(`${c.first_name} ${c.last_name} following ${petName}`)
            } else {
                toast.error('Error, follow pet failed, try again later.')
            }
        }

        if (contactId) {
            if (contactId === 'new') {
                controlStore.getState().setCurrentPage('registration')
                const state = { data: data }
                navigate('/contactForm', { state })
            } else {
                void followPet(contactId)
            }
        }

    }, [contactId])

    const title = createAccount ? 'Add your pet and followers' : 'Assign a pet and followers'

    return (
        <Section disabled={disabled} title={title} step={STEPS.pet}>

            {!disabled ? <div>
                <Controller
                    name={'petId'}
                    render={({ field }) =>
                        <Section title={'Assign a pet'} disabled={!!petId}>
                            <SelectPet2
                                onChange={function (p: {
                                    label: string,
                                    value: string
                                }): void {
                                    field.onChange(p.value)
                                }}
                                value={petId}
                                onlyShowInactive
                                disabled={!!petId}
                                displayOnly={petCreated}
                            />
                        </Section>
                    }
                    control={control}
                />

                <div className={'pt-4'}>
                    <Controller
                        name={'contactId'}
                        render={({ field }) =>
                            <Section title={'Assign a follower'} disabled={!petId}>
                                <SelectContact
                                    onChange={function (p: {
                                        label: string,
                                        value: string
                                    }): void {
                                        field.onChange(p.value)
                                    }}
                                    value={contactId}
                                    petId={petId}
                                    disabled={!petId}
                                    listFollowers={true}
                                />
                            </Section>
                        }
                        control={control}
                    />
                </div>
            </div> : null}

        </Section>
    )
}

import { fa2, fa3, fa4, fa5, fa6 } from '@fortawesome/free-solid-svg-icons'
import { fa1 } from '@fortawesome/free-solid-svg-icons/fa1'
import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle'

const iconMap: Record<number, any> = {
	1: fa1,
	2: fa2,
	3: fa3,
	4: fa4,
	5: fa5,
	6: fa6,
}

interface Props {
	step: number
}

export const StepIcon: React.FC<Props> = (props) => {
	const {step} = props

    return (
		<div className={'flex justify-start items-center gap-1 fa-layers fa-fw fa-lg border-primary border-2 rounded-full'}>
			<FontAwesomeIcon color={'black'} icon={faCircle} size={'xs'}/>
			<FontAwesomeIcon color={'white'} inverse icon={iconMap[step]} size={'sm'} mask={faCircle}/>
		</div>
	)
}

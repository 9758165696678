export const SMSOptIn = [
    { label: 'Yes', value: 'Opt-in' },
    { label: 'No', value: 'Opt-out' },
]

export const Country = [
    { label: 'USA', value: 'US' },
    { label: 'Canada', value: 'CA' },
]

export const PetSpeciesOptions = [
    {label: 'Dog', value: 'dog'},
    {label: 'Cat', value: 'cat'},
]

export const ChooseSpecies = [
    {label: 'Choose Species first', value: ''},
]

export const DogBreeds = [
    {label: 'Lab', value: 'lab'},
    {label: 'Doberman', value: 'doberman'},
    {label: 'Smelly', value: 'smelly'},
]

export const CatBreeds = [
    {label: 'Standard Issue', value: 'sic'},
    {label: 'Smelly', value: 'smelly'},
    {label: 'Crazy', value: 'crazy'},
]

export const GenderOptions = [
    {label: 'Female', value: 'Female'},
    {label: 'Male', value: 'Male'},
    {label: 'Non-Binary', value: 'NonBinary'},
]

export const Seasons = [
    {label: 'Winter', value: 'winter'},
    {label: 'Spring', value: 'spring'},
    {label: 'Summer', value: 'summer'},
    {label: 'Fall', value: 'fall'},
]
export const Months = [
    { label: 'January', value: '01' },
    { label: 'February', value: '02' },
    { label: 'March', value: '03' },
    { label: 'April', value: '04' },
    { label: 'May', value: '05' },
    { label: 'June', value: '06' },
    { label: 'July', value: '07' },
    { label: 'August', value: '08' },
    { label: 'September', value: '09' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' },
]
export const Days = [
    { label: '1', value: '01' },
    { label: '2', value: '02' },
    { label: '3', value: '03' },
    { label: '4', value: '04' },
    { label: '5', value: '05' },
    { label: '6', value: '06' },
    { label: '7', value: '07' },
    { label: '8', value: '08' },
    { label: '9', value: '09' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },
    { label: '20', value: '20' },
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '23', value: '23' },
    { label: '24', value: '24' },
    { label: '25', value: '25' },
    { label: '26', value: '26' },
    { label: '27', value: '27' },
    { label: '28', value: '28' },
    { label: '29', value: '29' },
    { label: '30', value: '30' },
    { label: '31', value: '31' },
]
export const Years = [
    { label: '2024', value: '2024' },
    { label: '2023', value: '2023' },
    { label: '2022', value: '2022' },
    { label: '2021', value: '2021' },
    { label: '2020', value: '2020' },
    { label: '2019', value: '2019' },
    { label: '2018', value: '2018' },
    { label: '2017', value: '2017' },
    { label: '2016', value: '2016' },
    { label: '2015', value: '2015' },
    { label: '2014', value: '2014' },
    { label: '2013', value: '2013' },
    { label: '2012', value: '2012' },
    { label: '2011', value: '2011' },
    { label: '2010', value: '2010' },
    { label: '2009', value: '2009' },
    { label: '2008', value: '2008' },
    { label: '2007', value: '2007' },
    { label: '2006', value: '2006' },
    { label: '2005', value: '2005' },
    { label: '2004', value: '2004' },
    { label: '2003', value: '2003' },
    { label: '2002', value: '2002' },
    { label: '2001', value: '2001' },
    { label: '2000', value: '2000' },
]

export const get20YearOptions = () => {
    const d = new Date();
    let year = d.getFullYear();
    const options = []
    for (let i=0; i<20; i++) {
        options.push({
            label: String(year),
            value: String(year)
        })
        year += 1
    }

    return options
}

import { Label } from './Label.tsx'
import { Controller } from 'react-hook-form'
import { createPortal } from 'react-dom'
import { Input } from './Input.tsx'

export const createPopupWidget = (
	opts: {
		name: string
		title: string
		options: { value: string, label: string }[]
		cols: number
		rules?: any
		setOpen: (o: string) => void
		open: string
		control: any
		errors: any
		isSub?: boolean
		center?: boolean
		showValueLabel?: boolean
		btnSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'day' | 'year'
		curVal?: string
		shadowVal?: string
		compareAsNum?: boolean
		hideComplete?: boolean
		disabled?: boolean
	}
	) => {
	return (
		<div className={'w-full flex flex-col items-start'}>
			<Label formField={opts.name} title={opts.title} isSub={opts.isSub} center={opts.center} errors={opts.errors} complete={!!opts.shadowVal && !opts.hideComplete}/>
			<div className={'w-full'}>
				<Controller
					name={opts.name}
					rules={opts.rules}
					render={({ field }) => {
						const pop = (
							<div id={'modal'} className={'fixed z-[100000] left-0 top-0 w-[100vw] h-[100vh] overflow-auto bg-black/70 flex justify-center items-center'}>
								<div className={'flex flex-col items-center'}>
									<div className={'text-3xl text-white pb-2'}>Select {opts.title}</div>
									<div className={`grid grid-cols-${opts.cols} gap-2 px-8`}>
										{opts.options.map(s => <div
											key={s.value}
											className={`btn btn-${opts.btnSize ? opts.btnSize : 'sm'} h-auto btn-outline p-2 ${field.value === s.value ? 'text-white bg-secondary' : 'text-secondary bg-white'}`}
											onClick={() => {
												field.onChange(s.value)
												opts.setOpen('')
											}}
										>
											{s.label}</div>)}
									</div>
									<div className={'p-6'} onClick={() => opts.setOpen('')}>
										<button className={'btn btn-ghost btn-secondary'}>Cancel</button>
									</div>
								</div>
							</div>
						)
						const curVal = field.value || opts.curVal
						// console.log(opts.name, opts.curVal, field.value)
						const displayValue = (!opts.showValueLabel ? curVal : opts.options.find(o => opts.compareAsNum ? Number(o.value) === Number(curVal) : o.value === curVal)?.label) || `Select ${opts.title}`
						const bundle = {
							type: 'text',
							defaultValue: displayValue,
							readOnly: true
						} as any
						const val = (
							<div className={'w-full'}
								onClick={() => opts.setOpen(opts.name)}>
								<Input {...bundle} isSub={opts.isSub}/>
							</div>
						)
						return (opts.open === opts.name) && !opts.disabled ? createPortal(pop, document.body) : val
					}}
					control={opts.control}
				/>
			</div>
		</div>
	)
}

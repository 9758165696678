import * as React from 'react'
import { Controller } from 'react-hook-form'
import { Label } from '../components/Label.tsx'
import dataStore from '../../../store/dataStore.ts'

interface Props {
	control: any
	formField: string
	title: string,
	errors: Record<string, any>
	required?: boolean
}

// eslint-disable-next-line react/display-name
export const SelectPets: React.FC<Props> = ((props) => {
	const { control, formField, title, errors, required } = props

	const pets = dataStore(s => s.pets)

	const handleChange = (onChange: any, value: string[], checked: boolean, id: string) => {
			let update: string[] = value.slice(0)
			if (!checked && update.includes(id)) {
				update = update.filter(v => v !== id)
			} else if (checked && !update.includes(id)) {
				update = [...update, id]
			} else {
				return
			}
			onChange([...new Set(update)])
		}

	const rules = required ? {
		required: {
			value: true,
			message: 'Required'
		},
	} : {}

	return (
		<div className={'w-full flex flex-col items-start'}>
			<Label formField={formField} title={title} errors={errors}/>
			<div className={'w-full'}>
				<Controller
					name={formField}
					rules={rules}
					render={({ field }) => {

						const allPets = (
							<div className="join join-horizontal flex-wrap">
								{pets.map(v => {
									return <PetSelectItem
										key={v._id}
										label={v.petName}
										value={v._id}
										checked={field.value?.includes(v._id)}
										onChange={(checked: boolean, id: string) => handleChange(field.onChange, field.value, checked, id)}
									/>
								})}
							</div>
						)
						return allPets
					}}
					control={control}
					/>
				{/*<input id={formField} type={inputType} ref={ref} {...rest} />*/}
			</div>
		</div>
	)
})

interface RowProps {
	label: string
	value: string
	checked: boolean
	onChange: (checked: boolean, id: string) => void
}
const PetSelectItem = (props: RowProps) => {
	const {label, value, onChange, checked} = props

	return (
		<div className="join-item flex-1">
			<label className="label cursor-pointer flex flex-col justify-start gap-1">
				<input
					type="checkbox"
					id={label}
					checked={checked}
					onChange={(e) => onChange(e.target.checked, value)}
					// onBlur={handleBlur}
					// onFocus={handleFocus}
					className={'checkbox checkbox-primary'}
				/>
				<span className="label-text">{label}</span>
			</label>
		</div>
	);
}

import * as React from 'react'
import { menuRoute } from '../navHelper.tsx'
import { useNavigate } from 'react-router-dom'

interface Props {
    setSelected: (s: string) => void
}

export const MapToggle: React.FC<Props> = (props) => {
    const { setSelected } = props
    const [val, setVal] = React.useState<'current' | 'history'>('current')
    const navigate = useNavigate()

    const goToThere = (place: string) => {
        setSelected(menuRoute[place] || place)
        navigate(`/${menuRoute[place]}`)
    }

    const handleChange = (e: any) => {
        setVal(e.target.value)
        goToThere(e.target.value)
    }

    const component = (
        <div className="map-toggle">
            <input type="radio" id="map-toggle-now" name="map" value="current"
                   checked={val === 'current'}
                   onChange={handleChange}/>
            <label className="radio-button font-semibold" htmlFor="map-toggle-now"> Now </label>

            <input type="radio" id="map-toggle-history" name="map" value="history"
                   checked={val === 'history'}
                   onChange={handleChange}/>
            <label className="radio-button font-semibold" htmlFor="map-toggle-history"> History </label>
        </div>
    )

    return component
}
import { DateTime } from 'luxon'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { CancelSubscriptionButton } from '../../../components/common/buttons/CancelSubscriptionButton.tsx'
import { UpgradeSubscriptionButton } from '../../../components/common/buttons/UpgradelSubscriptionButton.tsx'
import { renewsOn } from '../../../helpers/dataHelpers.ts'
import { ly } from '../../../helpers/petHelper.tsx'
import controlStore from '../../../store/controlStore.ts'
import dataStore from '../../../store/dataStore.ts'
import { ISubscription } from '../../../interfaces/types.ts'


interface Props {
}

export const SubscriptionList: React.FC<Props> = () => {
    const subscriptions = dataStore(s => s.devicePlans)
    const billingRecords = dataStore(s => s.billing)
    const devices = dataStore(s => s.devices)
    const navigate = useNavigate()
    const isMobile = controlStore(s => s.isOnMobileDevice)

    const [sortedSubscriptions, setSortedSubscriptions] = React.useState<ISubscription[]>([])
    const timezones = dataStore(s => s.timeZones)
    const tzId = dataStore.getState().account.timeZone

    const timeZone = timezones.find(t => t._id === tzId)

    React.useEffect(() => {
        if (subscriptions.length) {
            const activeDevices = devices.map(d => d.iccid)
            const withDevices = subscriptions.filter((a: ISubscription) => activeDevices.includes(a.device_id)).sort((a: ISubscription, b: ISubscription) => a.planStart > b.planStart ? -1 : 1)
            const withOut = subscriptions.filter((a: ISubscription) => !activeDevices.includes(a.device_id)).sort((a: ISubscription, b: ISubscription) => a.planStart > b.planStart ? -1 : 1)
            const sort = withDevices.concat(withOut)
            setSortedSubscriptions(sort)
        } else {
            setSortedSubscriptions([])
        }
    }, [subscriptions, devices])

    const header = (
        <div className={'pt-2'}>
            <h1 className="text-3xl font-semibold text-center text-primary mb-2">Gibi Subscriptions</h1>
        </div>
    )

    const altList = (
        <div className={'flex flex-col gap-4'}>
            {sortedSubscriptions.map(c => {
                const tracker = devices.find(d => d.iccid === c.device_id)
                const bill = billingRecords.find(b => b.plan_id === c._id) || {}
                const planType = c.plan_type.includes('ly') ? c.plan_type : ly(c.plan_type)
                return (
                    <div key={c._id}
                         className={'flex flex-col justify-start border-l-primary border-l-4 w-full bg-white px-4'}>

                        <div className={'flex items-center justify-between p-2 pb-3'}>
                            <div className={'text-lg text-left font-semibold'}>
                                {planType} Service Plan
                            </div>

                            <div className="text-base-content font-medium text-right">
                                {tracker?.deviceName || 'No Gibi Tracker'}
                            </div>
                        </div>

                        <div className={'flex justify-between w-full rounded-lg bg-primary/5 p-2 border-2 border-primary/10'}>
                            <div className={'flex flex-col text-left text-base-content font-medium p-2 pt-0 leading-5'}>
                                <div className={'text-neutral-content/50'}><span className={'font-bold'}>${c.plan_amount}</span><span className={'text-neutral-content/50'}> billed to</span></div>
                                <div className={'font-semibold pl-2'}>{bill.first_name} {bill.last_name}</div>
                                <div className={'font-semibold pl-2'}>{bill.address1 || bill.streetAddr}</div>
                                <div className={'font-semibold pl-2'}>{bill.address2 || bill.apartment}</div>
                                <div
                                    className={'font-semibold pl-2'}>{bill.usa_city || bill.canada_city} {bill.state || bill.province} {bill.zip_code || bill.postal_code}</div>
                                <div className={'pt-2 text-neutral-content/50'}>Payment method</div>
                                <div className={'font-semibold pl-2'}> {bill.last4 || 'xxxx'}</div>
                            </div>

                            <div className={'flex flex-col gap-0 text-left text-base-content font-medium p-2 pt-0'}>
                                <div className={'text-neutral-content/50'}>Started</div>
                                <div
                                    className={'font-semibold pl-2'}>{DateTime.fromISO(c.plan_start!).setZone(timeZone.value).toLocaleString(DateTime.DATE_MED)}</div>
                                <div className={'text-neutral-content/50'}>Renews</div>
                                <div className={'font-semibold pl-2'}>{renewsOn(timeZone, c.plan_start!, c.plan_type)}</div>
                            </div>
                        </div>

                        <div className={'flex items-center justify-around w-full p-3'}>
                            <CancelSubscriptionButton subscription={c} tracker={tracker}/>
                            <UpgradeSubscriptionButton subscription={c}/>
                        </div>
                    </div>
                )
            })
            }
        </div>
    )
    
    const width = !isMobile ? 'w-[32rem]' : ''
    
    return (
        <div className={`${width} h-[90vh] overflow-y-auto`}>
            {header}
            {altList}
            <input
                type={'button'}
                className={'mt-4 btn btn-ghost text-primary'}
                onClick={() => navigate('/' + controlStore.getState().currentPage)}
                value={'Close'}
            />
        </div>
    )
}

import * as React from 'react'
import { Controller } from 'react-hook-form'
import { Label } from '../components/Label.tsx'
import { createPopupWidget } from '../components/popupHelper.tsx'
import { Days, Months, Years } from './options.tsx'

interface Props {
    control: any
    formField: string
    title: string,
    errors: Record<string, any>
    required?: boolean
}

export const BirthDate: React.FC<Props> = (props) => {
    const { control, formField, title, errors, required } = props
    const [open, setOpen] = React.useState('')

    const rules = required ? {
        required: {
            value: true,
            message: 'Required'
        },
    } : {}

    const birthDate = (curVal : {month: string, day: string, year: string}) => {
        return (
            <div className={'flex justify-start items-center gap-2'}>
            {createPopupWidget({
                name: `${formField}.month`,
                title: 'Month',
                options: Months,
                cols: 3,
                isSub: true,
                center: true,
                setOpen,
                open,
                control,
                errors,
                btnSize: '2xl',
                curVal: curVal?.month || ''
            })}
            {createPopupWidget({
                name: `${formField}.day`,
                title: 'Day',
                options: Days,
                cols: 5,
                isSub: true,
                center: true,
                setOpen,
                open,
                control,
                errors,
                btnSize: 'day',
                curVal: curVal?.day || ''
            })}
            {createPopupWidget({
                name: `${formField}.year`,
                title: 'Year',
                options: Years,
                cols: 4,
                isSub: true,
                center: true,
                setOpen,
                open,
                control,
                errors,
                btnSize: 'year',
                curVal: curVal?.year || ''
            })}
        </div>)
    }

    return (
        <div>
            <Label formField={formField} title={title} errors={errors}/>
            <div className={'w-full'}>
                <Controller
                    name={formField}
                    rules={rules}
                    control={control}
                    render={({ field }) => {
                        if (typeof field.value === 'string') {
                            const splt = field.value.split('-')
                            const parsed = {
                                year: splt[0],
                                month: splt[1],
                                day: splt[2],
                            }
                            return birthDate(parsed)
                        }
                        return birthDate(field.value)
                    }}
                />
            </div>
            {/*<Error formField={formField} errors={errors}/>*/}
        </div>
    )
}

import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IDevice, ISubscription } from '../../../interfaces/types.ts'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan'
import { CancelTrackerSubscription } from '../CancelTrackerSubscription/CancelTrackerSubscription.tsx'

interface Props {
    iconOnly?: boolean
    subscription: ISubscription
    tracker?: IDevice
}

export const CancelSubscriptionButton: React.FC<Props> = (props) => {
    const {iconOnly, subscription, tracker} = props
    const [open, setOpen] = React.useState(false)

    const button = iconOnly ? (
        <div className={'text-secondary text-xl'} onClick={() => setOpen(true)}>
            <FontAwesomeIcon icon={faTrashCan}/>
        </div>
        ) :
        <button className={'btn btn-xs btn-ghost text-secondary without-ring h-fit'}
                onClick={() => setOpen(true)}>
            Cancel Subscription
        </button>

    return (
        <div>
            {button}
            <CancelTrackerSubscription subscription={subscription} tracker={tracker} open={open} setOpen={setOpen}/>
        </div>
    )
}

import * as React from 'react'
import dataStore from '../../store/dataStore.ts'
import historyStore from '../../store/historyStore.ts'
import { IDevice } from '../../interfaces/types.ts'
import { SwitchPet } from './components/list/SwitchPet.tsx'
import { HistoryEngine } from './HistoryEngine.tsx'
import { HistoryButtons } from './HistoryButtons.tsx'
import controlStore from '../../store/controlStore.ts'
import { petFromDevice } from '../../helpers/dataHelpers.ts'
import { InvalidToggle } from './components/InvalidToggle/InvalidToggle.tsx'

export enum PlaybackMode {
    MOVIE,
    PAGE,
}

interface Props {
    controlRef?: any
    noPets?: boolean
    showLabel?: boolean
}

export const HistoryControl: React.FC<Props> = (props) => {
    const devices: IDevice[] = dataStore(s => s.devices)
    const isMobile = controlStore(s => s.isOnMobileDevice)

    React.useEffect(() => {
        if (devices) {
            // const all = devices.map(d => d.petId).filter(n => n)
            const all = devices.map(d => petFromDevice(d)).filter(n => n).map(p => p._id)
            console.log('historyPets', all)
            historyStore.getState().setHistoryPets(all)
            historyStore.getState().setSelectedPetId(all[0])
        }
    }, [devices])

    const desktop = (
        <div className={'absolute top-0 p-2'}>
            <div className={'flex flex-col gap-1 justify-center items-center'}>
                <SwitchPet showName/>
                <InvalidToggle/>
            </div>
        </div>
    )

    return (
        <div className={`relative flex flex-col w-ful h-full justify-start items-center pb-1 py-2 ${!isMobile ? '!h-[85px]' : ''}`}
             ref={props.controlRef}>
            <HistoryButtons/>
            <HistoryEngine/>
            {!isMobile ? desktop : null}
        </div>
    )
}

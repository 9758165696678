import { faSliders } from '@fortawesome/free-solid-svg-icons/faSliders'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { createPortal } from 'react-dom'
import { MapSettingsPanel } from './MapSettingsPanel.tsx'

export const MapSettingsButton: React.FC = () => {
    const [open, setOpen] = React.useState(false)
    
    const icon = (
        <div
            onClick={() => setOpen(!open)}
            className={'flex justify-center items-center'}
        >
            <div className={'flex justify-center items-center'}>
                {/*<FontAwesomeIcon style={{color: 'hsl(var(--s))'}} icon={faSquare} fontSize={'34px'}/>*/}
                <FontAwesomeIcon color={'hsl(var(--s))'}  icon={faSliders} fontSize={'24px'}/>
            </div>
        </div>
    )
    
    // const toolTip = (
    //     <div className="prose text-left px-1 pb-4 flex flex-col justify-start gap-0">
    //         <div>
    //             <h2 className={'opacity-80 mt-0 mb-0'}>Map settings</h2>
    //         </div>
    //     </div>
    // )
    
    
    const close = () => setOpen(false)
    
    return (
        <div className={'w-[24px]'} id={'setting-button'}>
            {icon}
            {open ? createPortal(<MapSettingsPanel close={close}/>, document.body) : null}
        </div>
    )
}

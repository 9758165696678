import * as React from 'react'
import { ALL_FILTERS_ON, countEventTypes } from '../../helpers/eventHelper.tsx'
import { IEvent } from '../../interfaces/types.ts'

interface Props {
    onChange: (u: string[]) => void
    list: IEvent[]
}

export const EventFilter: React.FC<Props> = (props) => {
    const {onChange, list} = props

    const [selectedFilters, setSelectedFilters] = React.useState<string[]>(ALL_FILTERS_ON)
    const [powerCount, setPowerCount] = React.useState(0)
    const [chargerCount, setChargerCount] = React.useState(0)
    const [safeCount, setSafeCount] = React.useState(0)
    // const [beaconCount, setBeaconCount] = React.useState(0)
    const [commandCount, setCommandCount] = React.useState(0)

    const FilterTypes = [
        {name: 'Power Events', type: 'powerEvents', count: powerCount, on: false},
        {name: 'Charger Events', type: 'chargerEvents', count: chargerCount, on: false},
        {name: 'Safe Zone Events', type: 'safeZoneEvents', count: safeCount, on: false},
        // {name: 'Power Saving Events',type: 'beaconEvents', count: beaconCount, on: false},
        {name: 'Gibi Commands', type: 'commandEvents', count: commandCount, on: false},
    ]

    React.useEffect(() => {
        if (list) {
            const {powerCount, safeCount, commandCount, chargerCount} = countEventTypes(list)
            setPowerCount(powerCount)
            setSafeCount(safeCount)
            // setBeaconCount(beaconCount)
            setCommandCount(commandCount)
            setChargerCount(chargerCount)
        }
    }, [list])

    const select = (
        type: string
    ) => {
        let update
        if (selectedFilters.includes(type)) {
            update = selectedFilters.filter(f => f !== type)
        } else {
            update = selectedFilters.slice(0)
            update.push(type)
        }
        setSelectedFilters(update)
        onChange(update)
    }

    return (
        <div className={'grid grid-cols-4'}>
            {FilterTypes.map(f => {
                return (
                    <div key={f.type}
                         className={`h-auto m-2 rounded p-2 ${!selectedFilters.includes(f.type) ? 'btn-neutral' : 'btn-secondary'}`}
                            onClick={() => select(f.type)}>
                        <div className={'h-full flex flex-col justify-between items-center gap-1 w-full text-center font-bold font'}>
                            <div className={''}>{f.name}</div>
                            <div className={''}>
                                ({f.count})
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

import * as React from 'react'
import dataStore from '../../../store/dataStore.ts'
import { ContactForm } from '../ContactForm.tsx'
import { AddContactButton } from '../../../components/common/buttons/AddContactButton.tsx'


interface Props {

}

export const ContactListDesktop: React.FC<Props> = () => {
    const contacts = dataStore(s => s.contacts).sort((a, b) => a.first_name > b.first_name ? 1 : -1)
    const [index, setIndex] = React.useState(0)

    const contactList = contacts.map((c, i) => (
        <div key={i} className={`bg-white border-accent ${i === index ? 'border-r-8' : ''}`}>
            <div className={'flex items-center gap-1 border-l-8 border-accent'} onClick={() => setIndex(i)}>
                <div className={'text-3xl font-semibold pl-2'}>
                    {c.first_name}
                </div>
                <div className={'text-3xl font-semibold'}>
                    {c.last_name}
                </div>
            </div>
        </div>
    ))

    return (
        <div className={'flex flex-col items-start justify-center'}>
            <AddContactButton/>
            <div className={'flex items-start justify-center'}>
                <div
                    className={'mt-12 flex flex-col gap-6 w-[40vw] p-4 self-start max-h-[70vh] overflow-y-auto rounded-xl bg-primary/30'}>
                    {contactList}
                </div>
                <div className={'w-[35rem] flex justify-center'}>
                    <ContactForm init={contacts[index]}/>
                </div>
            </div>
        </div>
    )
}


import { DateTime } from 'luxon'
import * as React from 'react'
import movieStore from '../../../../store/movieStore'
import {
    faAnglesLeft,
    faAnglesRight,
    faJetFighter,
    faPause,
    faPlay,
    faStepBackward,
    faStepForward,
    faTractor,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import authStore from '../../../../store/authStore.ts'
import { SwitchPet } from '../list/SwitchPet.tsx'
import { faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlassPlus'
import { faMagnifyingGlassMinus } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlassMinus'
import { fetchMovieData, setMapViewByIndex, sleep } from './movieHelper.tsx'

interface Props {
    controlRef?: any
}

export const MovieController: React.FC<Props> = (props) => {
    const {} = props

    const timezone = authStore(s => s.timezone)
    const state = movieStore(s => s)
    // const frameCount = locations.length

    const [selected, setSelected] = React.useState<string>('rewind')

    React.useEffect(() => {
        console.log('MovieController mount')
        void fetchMovieData('init')
        return () => {
            movieStore.getState().setLocations([])
            console.log('MovieController unmount')
        }
    }, [])

    const startDateTime = [
        state.startDate.setZone(timezone.value).toFormat(`EEE LLL dd yyyy`),
        state.startDate.setZone(timezone.value).toFormat('hh:mm a')
    ]

    const endDateTime = [
        state.endDate.setZone(timezone.value).toFormat(`EEE LLL dd yyyy`),
        state.endDate.setZone(timezone.value).toFormat('hh:mm a')
    ]

    const isDisabled = () => {
        return (!state.locations.length)
    }

    const ifNotDisabled = (fn: any) => {
        if (!isDisabled()) {
            fn()
        }
    }

    const showMarkerAtIndex = (index: number) => {
        const lat = state.locations[index].properties.latitude
        const lng = state.locations[index].properties.longitude
        movieStore.getState().setAnimatePosition(lat, lng)
        setMapViewByIndex(index, state.locations)
    }

    const playForward = () => {
        if (state.locations.length) {
            movieStore.getState().setPlayForward(true)
            setSelected('forward')
        }
    }

    const playBackward = () => {
        if (state.locations.length) {
            movieStore.getState().setPlayReverse(true)
            setSelected('backward')
        }
    }

    const pause = () => {
        movieStore.getState().setPlayForward(false)
        movieStore.getState().setPlayReverse(false)
        setSelected('')
        showMarkerAtIndex(state.playIndex)
    }

    const stop = () => {
        movieStore.getState().setPlayForward(false)
        movieStore.getState().setPlayReverse(false)
        setSelected('stop')
        showMarkerAtIndex(state.playIndex)
    }

    const stepBack = async () => {
        if (state.playIndex > 0) {
            if (isPlaying()) {
                stop()
                await sleep(200)
            }
            movieStore.getState().setPlayIndex(state.playIndex - 1)
            showMarkerAtIndex(state.playIndex - 1)
            movieStore.getState().setFrameDates(
                DateTime.fromISO(state.locations[state.playIndex - 1].properties.receivedTime),
                DateTime.fromISO(state.locations[state.playIndex].properties.receivedTime)
            )
        }
    }

    const stepForward = async () => {
        if (state.playIndex < state.locations.length) {
            if (isPlaying()) {
                stop()
                await sleep(200)
            }
            movieStore.getState().setPlayIndex(state.playIndex + 1)
            showMarkerAtIndex(state.playIndex + 1)
            movieStore.getState().setFrameDates(
                DateTime.fromISO(state.locations[state.playIndex + 1].properties.receivedTime),
                DateTime.fromISO(state.locations[state.playIndex + 2].properties.receivedTime)
            )
        }
    }

    const rewind = async () => {
        if (isPlaying()) {
            stop()
            await sleep(200)
        }
        movieStore.getState().setPlayIndex(0)
        showMarkerAtIndex(0)
        movieStore.getState().setFrameDates(
            DateTime.fromISO(state.locations[0].properties.receivedTime),
            DateTime.fromISO(state.locations[1].properties.receivedTime)
        )
    }

    const fastForward = async () => {
        if (isPlaying()) {
            stop()
            await sleep(200)
        }
        const end = state.locations.length - 2
        movieStore.getState().setPlayIndex(end)
        showMarkerAtIndex(end)
        movieStore.getState().setFrameDates(
            DateTime.fromISO(state.locations[end].properties.receivedTime),
            DateTime.fromISO(state.locations[end + 1].properties.receivedTime)
        )
    }

    const fast = () => {
        movieStore.getState().setPlaybackSpeed('fast')
    }

    const slow = () => {
        movieStore.getState().setPlaybackSpeed('slow')
    }

    const zoomIn = () => {
        movieStore.getState().setPlaybackView('close')
    }

    const zoomOut = () => {
        movieStore.getState().setPlaybackView('far')
    }

    const isPlaying = () => {
        // console.log('isPlaying', state.playForward || state.playReverse)
        return state.playForward || state.playReverse
    }
    // const rotate = {
    //     // '-webkit-transform': 'rotateY(180deg)',
    //     transform: 'rotateY(180deg)',
    // }

    const dateTime = (label: string, dt: string[]) => {
        return (
            <div className={'p-2 flex flex-col items-center justify-center min-w-[12rem]'}>
                <div className={'font-semibold text-md text-primary/40'}>
                    {label}
                </div>
                <div className={'p-2 text-primary-content bg-primary rounded-lg'}>
                    <div className={'text-xl font-bold'}>
                        {dt[0]}
                    </div>
                    <div className={'text-lg font-bold'}>
                        {dt[1]}
                    </div>
                </div>
            </div>
        )
    }

    const speedControl = (
        <div className={'flex flex-col gap-1'}>
            <div className={'flex items-center gap-6'}>
                <div onClick={slow}>
                    <FontAwesomeIcon icon={faTractor}
                                     color={state.playbackSpeed === 'slow' ? '#f26c61' : 'gray'}
                                     size={'2xl'}
                    />
                    {/*<img src={turtle(state.playbackSpeed === 'slow' ? '#f26c61' : 'gray')} className={'w-12 h-12'}/>*/}
                </div>
                <div onClick={fast}>
                    {/*<img src={rabbit(state.playbackSpeed === 'fast' ? '#f26c61' : 'gray')} className={'w-12 h-12'}/>*/}
                    <FontAwesomeIcon icon={faJetFighter}
                                     color={state.playbackSpeed === 'fast' ? '#f26c61' : 'gray'}
                                     size={'2xl'}
                    />
                </div>
            </div>
            <div className={'text-sm font-semibold text-primary/50'}>Speed</div>
        </div>
    )

    const viewControl = (
        <div className={'flex flex-col gap-1'}>
            <div className={'flex items-center gap-6'}>
                <div onClick={zoomIn}>
                    <FontAwesomeIcon icon={faMagnifyingGlassPlus}
                                     color={state.playbackView === 'close' ? '#f26c61' : 'gray'}
                                     size={'2xl'}
                    />
                </div>
                <div onClick={zoomOut}>
                    <FontAwesomeIcon icon={faMagnifyingGlassMinus}
                                     size={'2xl'}
                                     color={state.playbackView === 'far' ? '#f26c61' : 'gray'}
                    />
                </div>
            </div>
            <div className={'text-sm font-semibold text-primary/50'}>Zoom</div>
        </div>
    )

    const control = (
        <div
            className={`${isDisabled() ? 'bg-gray-200 p-2 border-4 border-gray/40' : 'bg-primary-content p-2 border-4 border-primary'} flex items-center justify-evenly w-full rounded-full max-w-[650px]`}>
            <div onClick={() => state.playIndex > 0 && ifNotDisabled(rewind)}
                 className={`hover:text-primary ${isPlaying() || isDisabled() || state.playIndex === 0 ? 'text-gray-400' : 'text-base-300'}`}
            >
                <FontAwesomeIcon icon={faStepBackward}
                                 size={'xl'}
                />
            </div>
            <div onClick={() => state.playIndex > 0 && ifNotDisabled(stepBack)}
                 className={`hover:text-primary ${isPlaying() || isDisabled() || state.playIndex === 0 ? 'text-gray-400' : 'text-base-300'}`}
            >
                <FontAwesomeIcon icon={faAnglesLeft}
                                 size={'xl'}
                />
            </div>

            <div onClick={() => !isPlaying() && state.playIndex > 0 && ifNotDisabled(playBackward)}
                 className={`rotate-180 hover:text-primary ${state.playForward || isDisabled() || state.playIndex === 0 ? 'text-gray-400' : 'text-base-300'}`}
            >
                <FontAwesomeIcon icon={faPlay}
                                 size={'xl'}
                                 className={`${!isDisabled() && state.playReverse ? 'text-primary' : ''}`}
                />
            </div>

            <div onClick={() => isPlaying() && ifNotDisabled(pause)}
                 className={`hover:text-primary ${isDisabled() || !isPlaying() ? '!text-gray-400' : 'text-base-300'}`}
            >
                <FontAwesomeIcon icon={faPause}
                                 className={`${!isDisabled() && selected === 'pause' ? 'text-primary' : ''}`}
                                 size={'xl'}
                />
            </div>
            {/*<div onClick={() => !isPlaying() && ifNotDisabled(stop)}*/}
            {/*     className={`hover:text-primary ${isDisabled() || !isPlaying() ? 'text-gray-400' : 'text-base-300'}`}*/}
            {/*>*/}
            {/*    <FontAwesomeIcon icon={faStop}*/}
            {/*                     size={'xl'}*/}
            {/*                     className={`${!isDisabled() && selected === 'stop' ? 'text-primary':''}`}*/}
            {/*    />*/}
            {/*</div>*/}

            <div onClick={() => !isPlaying() && ifNotDisabled(playForward)}
                 className={`hover:text-primary ${state.playReverse || isDisabled() ? 'text-gray-400' : 'text-base-300'}`}
            >
                <FontAwesomeIcon icon={faPlay}
                                 size={'xl'}
                                 className={`${!isDisabled() && state.playForward ? 'text-primary' : ''}`}

                />
            </div>

            <div onClick={() => ifNotDisabled(stepForward)}
                 className={`hover:text-primary ${isPlaying() || isDisabled() ? 'text-gray-400' : 'text-base-300'}`}
            >
                <FontAwesomeIcon icon={faAnglesRight}
                                 size={'xl'}
                />
            </div>
            <div onClick={() => ifNotDisabled(fastForward)}
                 className={`hover:text-primary ${isPlaying() || isDisabled() ? 'text-gray-400' : 'text-base-300'}`}
            >
                <FontAwesomeIcon icon={faStepForward}
                                 size={'xl'}
                />
            </div>
        </div>
    )

    return (
        <div className={'flex flex-col items-center justify-center bg-base-100'}
             ref={props.controlRef}
        >
            <div className={'flex items-center justify-around w-full h-[90px]'}>
                {dateTime('Newer', startDateTime)}
                {control}
                {dateTime('Older', endDateTime)}
            </div>
            <div className={'flex items-center justify-around w-full'}>
                <div className={'w-12'}/>
                {speedControl}
                <div className={'-mt-8'}>
                    <SwitchPet showName/>
                </div>
                {viewControl}
                <div className={'w-12'}/>
            </div>
        </div>
    )
}

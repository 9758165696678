import * as React from 'react'

interface Props {
	error?: React.ReactNode
	isSub?: boolean
	color?: string
	scale?: string
}

// eslint-disable-next-line react/display-name
export const Input: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
	const {error = null, isSub, color, scale, ...rest} = props
    return (
		<div className={'relative w-full'}>
			<input className={`h-8 text-base-content border-solid border ${color ? 'border-'+ color : 'border-primary/50'} rounded w-full px-2 focus-visible:outline-0 ${isSub ? 'text-md' : 'text-xl'}`} ref={ref} {...rest} />
			{error}
			{scale ? <div className={'absolute right-3 top-1 text-lg'}>{scale}</div> : null }
		</div>
	)
})

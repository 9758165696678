import * as React from 'react'

// used for async functions, shows busy while waiting for api
interface Props {
	onClick: () => Promise<void>
	classNames?: string
	children?: React.ReactNode
	disabled?: boolean
}

export const AsyncButton: React.FC<Props> = (props) => {
	const {classNames, disabled} = props

	const [buttonState, setButtonState] = React.useState('done');
	const onClick = async () => {
		setButtonState('waiting');
		await props.onClick();
		setButtonState('done');
	};
	return (
		<button className={`btn ${classNames}`} onClick={() =>void onClick()} disabled={disabled || (buttonState === "loading")}>
			{buttonState === 'done' ? props.children :  <span className="loading loading-spinner"></span>}
		</button>
	);
}

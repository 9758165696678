import * as React from 'react'
import Select from 'react-select'
import { Controller } from 'react-hook-form'
import { Label } from '../components/Label.tsx'
import { select2Styles } from '../../../components/common/SelectPet/select2.styles.ts'
import Creatable from 'react-select/creatable'

interface Props {
	control: any
	formField: string
	title: string,
	errors: Record<string, any>
	options: {label: string, value: string}[]
	required?: boolean
	creatable?: boolean
}

// eslint-disable-next-line react/display-name
export const LabeledSelect: React.FC<Props> = ((props) => {
	const { control, formField, title, errors, options, required, creatable } = props

	const rules = required ? {
		required: {
			value: true,
			message: 'Required'
		},
	} : {}

	// const classNames = {
	// 	container: () => ('h-full text-base-content rounded w-full focus-visible:outline-0'),
	// 	input: () => ('h-ful text-base-content rounded w-full focus-visible:outline-0 px-2 text-xl !mt-0 !pt-0'),
	// 	placeholder: () => ('h-8 text-base-content rounded w-full focus-visible:outline-0 px-2 text-xl'),
	// 	control: () => ('!min-h-8 w-full focus-visible:outline-0 !border-0 !border-transparent !shadow-none !outline-0'),
	// 	valueContainer: () => ('h-8 !pt-0 focus-visible:outline-0'),
	// 	indicatorsContainer: () => ('h-8 focus-visible:outline-0 !hover:text-unset')
	// }

	return (
		<div className={'w-full flex flex-col items-start'}>
			<Label formField={formField} title={title} errors={errors}/>
			<div className={'h-8 w-full text-base-content border-solid border border-primary/50 rounded w-full focus-visible:outline-0'}>
				<Controller
					name={formField}
					rules={rules}
					render={({ field }) => {
						const val = options.find(c => c.value === field.value) || null
						const selectControl = !creatable ? (
							<Select
								defaultValue={val}
								// inputRef={field.ref}
								options={options}
								value={val}
								onChange={val => {
									// @ts-ignore
									field.onChange(val?.value)
								}}
								// classNames={classNames}
								styles={{ ...select2Styles }}
							/>
						) : (
							<Creatable
								defaultValue={val}
								// inputRef={field.ref}
								options={options}
								value={val}
								onChange={val => {
									console.log(val)
									// @ts-ignore
									field.onChange(val?.value)
									// // @ts-ignore
									// setOpts([...opts, {label: val.label, value: val.label}])
								}}
								// classNames={classNames}
								styles={{ ...select2Styles }}
							/>
						)
						return selectControl
					}}
					control={control}
				/>
			</div>
		</div>
	)
})

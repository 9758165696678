import { faShield } from '@fortawesome/free-solid-svg-icons/faShield'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation'
import { faShieldDog } from '@fortawesome/free-solid-svg-icons/faShieldDog'
import { faShieldCat } from '@fortawesome/free-solid-svg-icons/faShieldCat'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime } from 'luxon'
import * as React from 'react'
import { petFromDevice } from '../../../helpers/dataHelpers.ts'
import authStore from '../../../store/authStore.ts'
import dataStore from '../../../store/dataStore.ts'
import { IDevice, IPet } from '../../../interfaces/types.ts'
import { SubItem } from './SubItem.tsx'
import { Timestamp } from './Timestamp.tsx'

interface Props {
    pet: IPet
    mini?: boolean
}

export const SafeZoneStatus = (props: Props) => {
    const {pet, mini} = props
    const [color, setColor] = React.useState('text-neutral-content')
    const [status, setStatus] = React.useState('')
    // const [icon, setIcon] = React.useState<React.ReactNode | null>(null)
    const [zoneDate, setZoneDate] = React.useState<DateTime | null>(null)
    const [device, setDevice] = React.useState<IDevice | null>(null)
    const [icon, setIcon] = React.useState<React.ReactNode | null>(null)
    const devices = dataStore((s) => s.devices)
    const safeZones = dataStore(s => s.safeZones)

    React.useEffect(() => {
        if (devices) {
            const dev = devices.find(d => d.petId === pet._id)
            if (dev) {
                console.log('pet', pet.petName, 'device', dev.iccid)
                setDevice(dev)
                if (safeZones.length) {
                    let zoneDate = null
                    if (dev?.status.safeZoneDate) {
                        const timeZone = authStore.getState().timezone
                        const pet = petFromDevice(dev)
                        const speciesRecord = dataStore.getState().species.find(s => s._id === pet.species)
                        const speciesIcon = speciesRecord?.species_name.toLowerCase() === 'dog' ? faShieldDog : faShieldCat
                        const d =  DateTime.fromISO(dev.status.safeZoneDate).setZone(timeZone.value)
                        zoneDate = !mini ? d/*.toLocaleString(DateTime.DATETIME_MED)*/ :
                            d/*.toFormat(`LLL dd`) + '[]' + d.toFormat('hh:mm a')*/
                        if (dev.status.safeZoneViolation) {
                            setIcon(true)
                            setStatus('Out of all zones')
                            setColor('text-error')
                            setIcon(<FontAwesomeIcon icon={faTriangleExclamation} fontSize={'18px'} className={'text-error'}/>)
                        } else {
                            setIcon(<FontAwesomeIcon icon={speciesIcon} fontSize={'18px'} className={'text-success'}/>)
                            setColor('text-green-600')
                            setStatus(`In zone ${dev.status.occupiedZone}`)
                        }
                        setZoneDate(zoneDate)
                        // setIcon(getIcon(dev.status, color))
                    } else {
                        setStatus('No Safe Zone Events')
                        // setIcon(getIcon(dev?.status, color))
                        setColor('text-primary')
                        setIcon(<FontAwesomeIcon icon={faShield} fontSize={'18px'} className={'text-neutral-content/50'}/>)
                        setZoneDate(null)
                    }
                } else {
                    setStatus('No Zones')
                    setColor('text-primary')
                    setIcon(<FontAwesomeIcon icon={faShield} fontSize={'18px'} className={'text-neutral-content/50'}/>)
                    setZoneDate(null)
                    // setIcon(getIcon(dev.status, color))
                    // setBackgroundColor('bg-info')
                }
            }
        }
    }, [devices, pet])

    const subItem = (
        <SubItem pet={pet} title={'Safe Zones'}
                 icon={icon}>
            <div className={'flex items-center justify-between'}>
                <div className={`text-md  ${color}`}>
                    {status}
                </div>
                <div className={''}>
                    <Timestamp date={zoneDate} safeZone/>
                </div>
            </div>
        </SubItem>
    )

    return device ? subItem : null
}

import { AddressElement, CardCvcElement, useElements } from '@stripe/react-stripe-js'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormLayout } from '../components/FormLayout.tsx'
import { Header } from '../components/Header.tsx'
import { ChoosePetAndContact } from './steps/ChoosePetAndContact.tsx'
import { Payment } from './steps/Payment.tsx'
import { SelectPlan } from './steps/SelectPlan.tsx'
import { ValidateTrackerId } from './steps/ValidateTrackerId.tsx'
import { appearance } from './stripeElementConfig.ts'
import { Section } from '../components/Section.tsx'
import controlStore from '../../../store/controlStore.ts'

type FormDataType = Record<string, any>;

enum STEPS {
    'start',
    'iccid',
    'plan',
    'payDetails',
    'pet',
    'contact'
}

const stepsComplete: Record<string, boolean> = {
    'start': false,
    'iccid': false,
    'plan': false,
    'payDetails': false,
    'pet': false,
    'contact': false,
}

const emptyForm = {
    iccid: '',
    priceId: '',
    email: '',
    address: {
        line1: '',
        line2: '',
        city: '',
        country: '',
        postal_code: '',
        state: '',
        name: '',
    },
    useExistingPayment: true,
    cvcEntered: false,
    petId: '',
    contacts: [''],
}

interface Props {
    value?: FormDataType
}

export const NewTrackerForm: React.FC<Props> = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const addTrackerData = location?.state?.data

    // const logicalStep = addTrackerData ? STEPS.pet : STEPS.iccid
    const [step, setStep] = React.useState(STEPS.iccid)
    const [editIccid, setEditIccid] = React.useState(false)
    const [editPlan, setEditPlan] = React.useState(false)
    const isMobile = controlStore(s => s.isOnMobileDevice)

    const _setStep = (s: any) => {
        setStep(s)
    }

    const elements = useElements()
    // @ts-ignore
    elements?.update({appearance})

    const {
        register,
        // handleSubmit,
        control,
        getValues,
        formState: {errors},
        watch,
        // resetField,
        trigger,
        reset,
        setValue,
    } = useForm({
        defaultValues: addTrackerData || emptyForm,
        mode: 'onChange',
        reValidateMode: 'onBlur',
    })

    const trackerId = watch('iccid')
    const priceId = watch('priceId')

    const resetForm = () => {
        reset()
        elements?.getElement(AddressElement)?.clear()
        elements?.getElement(CardCvcElement)?.clear()
        setStep(STEPS.iccid)
    }
    
    const width = !isMobile ? 'w-[32rem]' : ''
    
    return ( // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <FormLayout trigger={trigger} header={<Header title={'New Gibi Tracker'}/>} top>
            <form className={`${width} max-w-[100vw] bg-primary/10 py-2`}>
                {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                <div className={'flex flex-col gap-2 px-4'}>

                    <Section disabled={step !== STEPS.iccid && !editIccid}
                             title={'Validate your Gibi Tracker ID'}
                             step={STEPS.iccid}
                             done={stepsComplete.iccid && !editIccid}
                             onTap={() => setEditIccid(true)}>

                        {!(step !== STEPS.iccid && !editIccid) ?
                            <ValidateTrackerId
                                trackerId={trackerId}
                                errors={errors}
                                register={register}
                                onComplete={(b) => {
                                    stepsComplete.iccid = b
                                    if (b) {
                                        if (editIccid) {
                                            setEditIccid(false)
                                        } else {
                                            _setStep(STEPS.plan)
                                        }
                                    }
                                }}
                            /> : null}
                    </Section>

                    <Section disabled={step !== STEPS.plan && !editPlan}
                             title={'Select your Gibi plan'}
                             step={STEPS.plan}
                             done={stepsComplete.plan && !editPlan}
                             onTap={() => {
                                 if (!editPlan) {
                                     setEditPlan(true)
                                 } else if (priceId) {
                                     setEditPlan(false)
                                 }
                             }}>

                        {!(step !== STEPS.plan && !editPlan) ?
                            <SelectPlan
                                watch={watch}
                                onComplete={(b) => {
                                    stepsComplete.plan = b
                                    if (b) {
                                        if (editPlan) {
                                            setEditPlan(false)
                                        } else {
                                            _setStep(STEPS.payDetails)
                                        }
                                    }
                                }}
                                errors={errors}
                                control={control}
                                createAccount={false}
                            /> : null}
                    </Section>

                    <Payment
                        control={control}
                        createAccount={false}
                        disabled={step !== STEPS.payDetails}
                        errors={errors}
                        register={register}
                        watch={watch}
                        getValues={getValues}
                        onComplete={(r) => {
                            if (r.status && !r.error) {
                                _setStep(STEPS.pet)
                            }
                        }}
                        STEPS={STEPS}
                    />

                    <ChoosePetAndContact
                        control={control}
                        data={getValues()}
                        disabled={step !== STEPS.pet}
                        watch={watch}
                        setValue={setValue}
                        petCreated={addTrackerData}
                        STEPS={STEPS}
                    />

                    {step === STEPS.pet || step === STEPS.contact ? <div className={'btn mt-4'}
                                                                         onClick={() => navigate(`/map`)}> Done </div> : null}
                    {step === STEPS.iccid || step === STEPS.plan || step === STEPS.payDetails ?
                        <input className={'btn mt-4'} type="reset" onClick={resetForm}/> : null}
                    <input
                        type={'button'}
                        className={'btn btn-ghost btn-sm text-primary mt-4'}
                        onClick={() => navigate('/' + controlStore.getState().currentPage, {replace: true})}
                        value={'Cancel'}
                    />
                </div>
            </form>
        </FormLayout>
    )
}



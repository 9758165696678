// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { DateTime, DateTimeFormatOptions, } from 'luxon'
import * as React from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { post } from '../../helpers/apiHelper.ts'
import { ALL_FILTERS_ON, applyFiltersToEvents, categorizeEvents, MAX_FILTERS } from '../../helpers/eventHelper.tsx'
import { PET_EVENTS_URL } from '../../helpers/urls.ts'
import dataStore from '../../store/dataStore.ts'
import { IDevice, IEvent } from '../../interfaces/types.ts'
import { EventItem } from './EventItem.tsx'
import './Events.css'
import { EventOptions } from './EventOptions.tsx'
import controlStore from '../../store/controlStore.ts'

export const DATETIME_MED_WITH_WEEKDAY: DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
}

const EMPTY_EVENTS = {
    powerEvents: [],
    chargerEvents: [],
    beaconEvents: [],
    safeZoneEvents: [],
    commandEvents: [],
}

interface Props {
    selectedPetId: string
}

export const ScrollControl: React.FC<Props> = (props) => {
    const {selectedPetId} = props

    const [startDate, setStartDate] = React.useState<DateTime>(DateTime.now())
    const [endDate, setEndDate] = React.useState<DateTime>(DateTime.now())
    const [events, setEvents] = React.useState<IEvent[]>([])
    const [filteredEvents, setFilteredEvents] = React.useState<Record<string, IEvent[]>>(EMPTY_EVENTS)
    const [list, setList] = React.useState<React.ReactElement[]>([])
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = React.useState<string[]>(ALL_FILTERS_ON)
    const [filtersChanged, setFiltersChanged] = React.useState(false)
    const [readAll, setReadAll] = React.useState(false)

    const isMobile = controlStore(s => s.isOnMobileDevice)
    
    const devices = dataStore(s => s.devices)
    const loaderRef = useRef(null);
    
    const getEvents = useCallback(async (petId: string, start: string, direction: 'forward' | 'back') => {
        if (isLoading) return;
        setIsLoading(true);
        const device: IDevice | undefined = devices.find(d => d.petId === petId)
        try {
            const res = (await post(PET_EVENTS_URL, {
                petId,
                iccid: device?.iccid,
                startDate: start,
                direction,
            }))!
            if (res.status === 200) {
                if (res.data.length) {
                    setEvents(events.concat(res.data))
                    const last = res.data.length - 1
                    setStartDate(DateTime.fromISO(res.data[last].createdAt))
                } else {
                    setReadAll(true)
                }
            }
        } catch (e) {
            console.log('getHistory', e)
        }
        setIsLoading(false)
    }, [startDate, isLoading])

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const target = entries[0];
            if (target.isIntersecting) {
                if (!readAll && events.length && filters.length  > 1) {
                    console.log('getEvents', events.length)
                    const last = events.length - 1
                    const iso = DateTime.fromISO(events[last].createdAt).toISO()!
                    void getEvents(selectedPetId, iso, 'back').then(() => console.log('events', iso))
                }
            }
        });

        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [getEvents]);

    React.useEffect(() => {
        if (startDate && selectedPetId) {
            const iso = startDate.toISO()!
            setEvents([])
            setReadAll(false)
            setStartDate(DateTime.now())
            setEndDate(DateTime.now())
            void getEvents(selectedPetId, iso, 'back').then(() => console.log('events', selectedPetId, iso))
        }
    }, [selectedPetId])

    React.useEffect(() => {
        if (events?.length) {
            setStartDate(DateTime.fromISO(events[0].createdAt))
            const last = events.length - 1
            setEndDate(DateTime.fromISO(events[last].createdAt))
        }
    }, [events])

    React.useEffect(() => {
        if (events) {
            const update= categorizeEvents(filters, events)
            const didUpdate = didFilteredListChange(filters, filteredEvents, update)
            if (filtersChanged && !didUpdate) {
                doRender()
            } else if (didUpdate) {
                doRender()
            }
            if (filtersChanged) setFiltersChanged(false)
            setFilteredEvents(update)
        }
    }, [events, filters])

    const didFilteredListChange = (filters: string[], prev: Record<string, IEvent[]>, next: Record<string, IEvent[]>) => {
        if (filters.length === MAX_FILTERS) return true
        let v = false;
        filters.forEach(f => {
            if (prev[f].length !== next[f].length) {
                v = true
            }
        })
        return v
    }

    const renderFilteredEvents = () => {
        const ev: IEvent[] = applyFiltersToEvents(filters, events)
        console.log('events visible', ev.length)
        return ev.map((event: IEvent, i: number) => {
            return <EventItem key={`${event._id}-${i}`} event={event} index={i}/>
        })
    }

    const doRender = () => {
        const list = renderFilteredEvents()
        setList(list)
    }

    const loader = <span className="loading loading-bars loading-md"></span>

    const scrollHeight = isMobile ? 'h-[63vh]' : 'h-[61vh]'
    return (
        <div className={'grid grid-flow-row auto-rows-max h-[75vh]'}>
            <EventOptions setFilters={setFilters} setFiltersChanged={setFiltersChanged} startDate={startDate} endDate={endDate} events={events}/>
            <div id={'content'} className={`scrollable bg-base-100 p-1 ${scrollHeight}`}>
                {list}
                <div key={'load-trigger'} id={'load-trigger'} ref={loaderRef}>{isLoading && loader}</div>
            </div>
        </div>
    )
}

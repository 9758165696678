import * as React from 'react'
import controlStore from '../store/controlStore.ts'
import { Watch } from 'react-loader-spinner'

interface Props {
    className?: string
    style?: any
}

export const Loader: React.FC<Props> = (props) => {
    const showLoader = controlStore(s => s.showLoader)
    return showLoader ? (
        <div className={'relative'}>
            <div className={props.className} style={props.style ? props.style : {top: '37vh'}}>
                <Watch
                    height={'15rem'}
                    width={'15rem'}
                    radius={'48'}
                    color={'#f26c61'}
                    ariaLabel={'watch-loading'}
                    wrapperStyle={{}}
                    visible={true}
                />
            </div>
        </div>
    ) : null

}

import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons/faCircleQuestion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

interface Props {

}

export const HelpButton: React.FC<Props> = () => {
    // const isOnMobile = controlStore(s => s.isOnMobileDevice)
    
    const openHelp = () => {
        window.open('https://getgibi.com/FAQ', '_blank', 'noreferrer')
    }
    
    const icon = (
        <div className="flex justify-center items-center fa-layers fa-fw fa-lg h-full w-full">
            {/*<FontAwesomeIcon style={{color: 'hsl(var(--a))'}} icon={faCircle} fontSize={'24px'}/>*/}
            <FontAwesomeIcon color={'hsl(var(--s))'} icon={faCircleQuestion} fontSize={'24px'}/>
        </div>
    )

    return (
        <div onClick={openHelp}
             className={'w-[24px] h-[24px]'}>
            {icon}
        </div>
    )
}

import React from 'react'
import mapStore from '../store/mapStore'
import { faSignal } from '@fortawesome/free-solid-svg-icons/faSignal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export const WebSocketIndicator: React.FC = () => {
    const webSocketConnected = mapStore(s => s.webSocketConnected)

    const indicator = {
        width: '1.5rem',
        height: '1.5rem',
        // borderRadius: '50%',
        color: webSocketConnected ? 'green' : 'red'
    }

    return (
        <div style={indicator}>
            <FontAwesomeIcon icon={faSignal}/>
        </div>
        )
}

import * as React from 'react'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
// import { getTextColor } from '../../../helpers/colorHelper.ts'
import { IDevice, IPet } from '../../../interfaces/types.ts'

export interface WrapperFnProps {
    open: boolean,
    setOpen: (b: boolean) => void,
    pet?: IPet,
    tracker?: IDevice
}

interface Props {
    icon: FontAwesomeIconProps
    iconOnly?: boolean
    pet?: IPet
    tracker?: IDevice
    label: string
    onClick: (pet?: IPet) => void
    noIcon?: boolean
    outline?: boolean
    full?: boolean
}

export const ButtonWrapperSimple: React.FC<Props> = (props) => {
    const {full, outline, iconOnly, pet, icon, label, onClick, noIcon } = props
    let button
    // const textColor = getTextColor(pet!.iconColor)
    if (iconOnly) {
        button = (
            <div className={`text-secondary text-xl`} onClick={() => onClick(pet)}>
                <FontAwesomeIcon icon={icon as any}/>
            </div>
        )
    } else if (noIcon) {
        button = (
            <button className={`btn btn-xs btn-secondary without-ring h-fit`} onClick={() =>  onClick(pet)}>
                {label}
            </button>
        )
    } else if (outline) {
        button = (
            <button className={`btn btn-xs btn-secondary without-ring h-fit ${full ? 'w-full' : ''}`} onClick={() =>  onClick(pet)}>
                {label}
            </button>
        )
    } else {
        button = (
            <div className={'flex flex-col items-center rounded-lg bg-secondary py-1 px-3 border-white border-2'}
                 onClick={() =>  onClick(pet)}>
                <button className={`btn btn-xs btn-ghost text-white without-ring h-fit`}>
                    {icon && <FontAwesomeIcon icon={icon as any} size={'2xl'}/>}
                </button>
                <div className={'text-sm font-semibold text-white'}>
                    {label}
                </div>
            </div>
        )
    }
    return button
}

import { create } from 'zustand'

interface IFindStatus {
    date: string
    msg: string
}

interface FindState {
    inProgress: Record<string, boolean>;
    setInProgress: (id: string, b: boolean) => void
    status: Record<string, IFindStatus>;
    setStatus: (petId: string, message: IFindStatus) => void
    reset: () => void
}

const useStore = create<FindState>((set) => ({
    status: {},
    setStatus: (petId: string, message: IFindStatus) =>
        set((state) => ({
            ...state,
            status: {...state.status, [petId]: message}
        })),

    inProgress: {},
    setInProgress: (id: string, b: boolean) =>
        set((state) => {
            console.log('setInProgress', id, b)
            return {
            ...state,
            inProgress: {...state.inProgress, [id]: b}
        }}),

    reset: () =>
        set((state) => ({
            ...state,
            inProgress: {},
            status: {},
        })),

}));

export default useStore;

import * as React from 'react'

import { SafeZoneInstructions } from './Instructions.tsx'
import safeZoneStore from '../../../store/safeZoneStore.ts'

interface Props {
    cancel: () => void
}

export const SafeZoneDrawing: React.FC<Props> = (props) => {

    React.useEffect(() => {
        safeZoneStore.getState().setInstructions(SafeZoneInstructions.DRAW)
    }, [])

    const cancel = (
        <div className={'flex justify-start gap-6 items-center'}>
            <button className={'btn btn-ghost text-secondary'} onClick={props.cancel}>
                cancel
            </button>
        </div>
    )

    return (
        <div className={'flex flex-col justify-center items-center gap-2 w-full'}>
            {/*<div>*/}
            {/*    <FontAwesomeIcon icon={faPencil} pulse/>*/}
            {/*</div>*/}
            <div className={'mt-4'}>
                {cancel}
            </div>
        </div>
    )
}

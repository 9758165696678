import { DateTime } from 'luxon'
// import toast from 'react-hot-toast'
import authStore from '../store/authStore.ts'
import dataStore from '../store/dataStore.ts'
import historyStore from '../store/historyStore.ts'
import mapStore from '../store/mapStore.ts'
import { post } from './apiHelper.ts'
import { HISTORY_BY_TEN_URL } from './urls.ts'

export const getHistoryLabel = (subType: string) => {
    let label
    switch (subType) {
        case 'powerUp':
            label = 'Power on'
            break
        case  'powerDown':
            label = 'Power off'
            break
        case 'fullCharge':
            label = 'Full charge'
            break
        case 'batteryAlert':
            label = 'Low battery'
            break
        case 'find':
            label = 'Find'
            break
        case 'sleepResponse':
            label = 'Sleep set'
            break
        case 'wakeResponse':
            label = 'Wake'
            break
        case 'beaconInRange':
            label = 'Power save on'
            break
        case 'beaconOutOfRange':
            label = 'Power save off'
            break
        case 'inCharger':
            label = 'In charger'
            break
        case 'outOfCharger':
            label = 'Out of charger'
            break
        case 'DeviceIsSleeping':
            label = 'Sleeping'
            break
        default:
            label = 'Check-in'
    }
    return label
}

export const getNextPet = (petList: string[], curPet: string) => {
    let idx = petList.findIndex(p => p === curPet)
    ++idx
    if (idx === petList.length) {
        idx = 0
    }
    console.log('idx', idx, petList.length)
    return petList[idx]
}

export const getPrevPet = (petList: string[], curPet: string) => {
    let idx = petList.findIndex(p => p === curPet)
    --idx
    if (idx < 0) {
        idx = petList.length - 1
    }
    return petList[idx]
}

const getNextStartDate = () => {
    const locations = historyStore.getState().locations
    const last = locations.length - 1
    return locations[last].properties.receivedTime
}

const getPreviousStartDate = () => {
    const locations = historyStore.getState().locations
    return locations?.[0].properties.receivedTime
}

const getDateBasedOnDirection = (direction: 'forward' | 'back' | 'init' | 'reset', start: string) => {
    if (direction === 'init' || direction === 'reset') {
        return start
    }
    if (direction === 'back') {
        return getNextStartDate()
    }
    return getPreviousStartDate()
}

export const bundleApiData = (direction: 'forward' | 'back' | 'init' | 'reset', start: string, petId: string) => {
    const date = getDateBasedOnDirection(direction, start)
    const device = dataStore.getState().devices.find(d => d.petId === petId)

    return {
        pet: petId,
        iccid: device?.iccid,
        date
    }
}

export const getHistoryByTen = async (
    start: string,  // ISO date time string
    direction: 'forward' | 'back' | 'init' | 'reset',
    petId?: string
    , cancelToken?: any) => {
    const zone = authStore.getState().timezone
    const selectedPet = historyStore.getState().selectedPetId
    const includeInvalid = historyStore.getState().includeInvalid

    const apiBundle = bundleApiData(direction, start, selectedPet || petId!)
    try {
        const res = await post(HISTORY_BY_TEN_URL, {
            petIccid: [apiBundle],
            direction,
            includeInvalid,
        }, cancelToken)
        if (res?.status === 200) {
            const flat = res.data.flat
            if (flat.length) {
                // calculate the new start & end dates, set the earliest date if first time
                let start = flat[0].properties.receivedTime
                if (res.data.points)
                    if (!flat.length && direction === 'forward') {
                        const earliestDate = historyStore.getState().earliestDate
                        start = earliestDate.toSeconds()
                    }
                const end = flat[flat.length - 1]?.properties.receivedTime
                const firstDate = DateTime.fromISO(start).setZone(zone.value)
                let update = {}

                if (direction === 'init' || direction === 'reset') {
                    update = {
                        ...update,
                        earliestDate: firstDate,
                        reachedStart: true,
                    }
                } else {
                    const earliestDate = historyStore.getState().earliestDate
                    // console.log('compare earliest', earliestDate.toISO(), 'to', firstDate.toISO())
                    if (firstDate.toISO()! >= earliestDate.toISO()!) {
                        update = {
                            ...update,
                            reachedStart: true,
                        }
                    } else {
                        update = {
                            ...update,
                            reachedStart: false,
                        }
                    }
                }
                update = {
                    ...update,
                    locations: flat,
                    startDate: DateTime.fromISO(start).setZone(zone.value),
                    endDate: DateTime.fromISO(end).setZone(zone.value)
                }
                return update
            } else {
                // const perPet: Record<string, IPoint[]> =  {}
                // historyStore.getState().locations.forEach(l => {
                //     const petId = l.properties.itemId!
                //     if (perPet[petId]) {
                //         perPet[petId].push(l)
                //     } else {
                //         perPet[petId] = [l]
                //     }
                // })
                // historyStore.getState().setHistoryPerPet(perPet)
                // toast.error('No history for the selected pet in this date range found')
                // historyStore.getState().setReachedStart(true)
                const now = DateTime.now().setZone(zone.value)
                return {
                    reachedState: true,
                    locations: [],
                    startDate: now,
                    endDate: now,
                }
            }
        } else {
            // toast.error('Error getting history')
            mapStore.getState().map?.fitBounds(mapStore.getState().bounds!, { left: 100 })
        }
    } catch (e) {
        console.log('getHistory error', e)
    }
}

// back in time
export const backTen = async (e: any) => {
    e.stopPropagation()
    return await getHistoryByTen(getNextStartDate(), 'back', undefined)
}

// forward in time
export const forwardTen = async (e: any) => {
    e.stopPropagation()
    return await getHistoryByTen(getPreviousStartDate(), 'forward', undefined)
}

import * as React from 'react'
import { Controller } from 'react-hook-form'
import { Label } from '../components/Label.tsx'
import AvatarEditor from 'react-avatar-editor'
import { faPaw } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PopupPortal } from '../../../components/common/Popup/PopupPortal.tsx'

interface Props {
    control: any
    formField: string
    title: string,
    errors: Record<string, any>
    required?: boolean
    imageUrl?: string
}

// eslint-disable-next-line react/display-name
export const ImageUpload: React.FC<Props> = ((props) => {
    const { control, formField, title, errors, required, imageUrl, } = props

    const [open, setOpen] = React.useState(false)
    const [zoom, setZoom] = React.useState(1)
    const [rotate, setRotate] = React.useState(0)
    const [fileChosen, setFileChosen] = React.useState<any>({})
    const [fileData, setFileData] = React.useState('')
    const [editorRef, setEditorRef] = React.useState('')

    // @ts-ignore
    const handleSave = (e: any, onChange: any) => {
        if (editorRef) {
            // @ts-ignore
            const canvasScaled = editorRef.getImageScaledToCanvas()
            const croppedImg = canvasScaled.toDataURL()
            onChange({ file: fileChosen, img: croppedImg })
            setOpen(false)
            setZoom(1)
            setRotate(0)
        }
    }
    const chooseFile = (e: any) => {
        e.preventDefault()
        let files
        if (e.dataTransfer) {
            files = e.dataTransfer.files
        } else if (e.target) {
            files = e.target.files
        }

        const reader = new FileReader()
        if (!reader) return

        reader.onload = () => {
            const file = reader.result?.toString()
            setFileData(file || '')
            setOpen(true)
        }
        setFileChosen({
            name: files[0]?.name,
            type: files[0]?.type,
        })
        reader.readAsDataURL(files[0])
    }

    const rules = required ? {
        required: {
            value: true,
            message: 'Required'
        },
    } : {}

    const dragInstructions = (
        <div>
            <div className={'text text-neutral-content text-center'}>Drag image to move</div>
        </div>
    )
    const handleZoom = (e:any) => setZoom(e.target.value)

    const zoomSlider = (
        <div>
            <div className={'text font-semibold'}>Zoom</div>
            <input type="range" min={1} max={10} step={0.1} value={zoom} className="range range-primary"
                   onChange={handleZoom}/>
        </div>
    )

    const handleRotate = (e:any) => setRotate(Number(e.target.value))

    const rotateSlider = (
        <div>
            <div className={'text font-semibold'}>Rotate</div>
            <input type="range" min={0} max={360} value={rotate} className="range range-primary"
                   onChange={handleRotate}/>
        </div>
    )

    const editModal = (onChange: any) => (
        <PopupPortal open={open}>
            <div className={'bg-base-100 p-2'}>
                <div className={'text-xl text-center font-semibold pb-2'}>Adjust the image to fit within the dark square</div>
                <div className={'flex justify-center'}>
                    <AvatarEditor
                        ref={setEditorRef as any}
                        image={fileData}
                        width={250}
                        height={212}
                        border={25}
                        color={[0, 0, 0, 0.7]} // RGBA
                        rotate={Number(rotate)}
                        scale={Number(zoom)}
                    />
                </div>
                <div>
                    {dragInstructions}
                </div>
                <div className={'mt-4'}>
                    {zoomSlider}
                </div>
                <div className={'mt-4'}>
                    {rotateSlider}
                </div>
                <div className={'flex justify-between items-center mt-4'}>
                    <button className={'btn text-secondary btn-ghost btn-sm'} onClick={() => setOpen(false)}>cancel</button>
                    <button className={'btn btn-secondary btn-sm'} onClick={(e) => handleSave(e, onChange)}>Save</button>
                </div>
            </div>
        </PopupPortal>
    )

    const fileSelect = (
        <div className={'flex flex-col justify-center items-center px-2 text-base-content'}>
            <label htmlFor="photo" className="btn btn-sm btn-primary">Select photo</label>
            <input id="photo" style={{visibility:'hidden'}} type="file" onChange={chooseFile}/>
            {/*<input type="file" className="file-input file-input-bordered file-input-primary file-input-sm"*/}
            {/*       onChange={chooseFile}/>*/}
        </div>
    )

    return (
        <div className={'w-full flex flex-col items-start'}>
            <Label formField={formField} title={title} errors={errors}/>
            <div className={'w-full'}>
                <Controller
                    name={formField}
                    rules={rules}
                    render={({ field }) => {
                        return (
                            <div className={'flex flex-row items-center justify-around bg-white rounded p-1'}>
                                <div className={'max-h-[75px] min-w-[30%]'}>
                                    {imageUrl ? <img className={'max-h-[75px]'} src={imageUrl} alt={'pet picture here'}/>
                                        : <FontAwesomeIcon icon={faPaw} size={'3x'}/>}
                                </div>
                                <div className={'h-full flex flex-col items-center justify-around max-w-[70%]'}>
                                    <div className={'text-sm font-medium pt-1 pb-3'}>
                                        Choose a photo to upload
                                    </div>
                                    {fileSelect}
                                    {editModal(field.onChange)}
                                </div>
                            </div>
                        )
                    }}
                    control={control}
                />
            </div>
        </div>
    )
})

import * as React from 'react'
import { Error } from './Error.tsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck'

interface Props {
	formField: string
	title: string,
	required?: boolean
	center?: boolean
	isSub?: boolean
	errors: any
	color?: string
	complete?: boolean
}


export const Label: React.FC<Props> = (props) => {
	const { formField, title, center, isSub, errors, color, complete } = props

    return (
		<div className={'flex items-center justify-between gap-1 w-full'}>
			<div className={`${isSub ? 'w-full' : ''} ${color ? 'text-' + color : 'text-base-300'}`}>
				<label htmlFor={formField} className={`${center ? 'text-center': ''} ${isSub ? 'text-sm font-normal': 'text-md  font-medium'}`}>{title}</label>
			</div>
			{/*{required ? <div className={'text-xs text-red'}>*</div> : null}*/}
			<Error formField={formField} errors={errors}/>
			{complete ? (
				<div className={'self-end pr-2'}>
					<FontAwesomeIcon icon={faCircleCheck} style={{color: 'green'}}/>
				</div>) : null}
		</div>
	)
}

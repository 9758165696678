import axios from 'axios'
import { Base64 } from 'js-base64'
import * as React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ResetPassword } from '../../../../components/common/ChangePassword/ResetPassword.tsx'
import { Toast } from '../../../../components/common/Toast/Toast.tsx'
import { unsecurePost } from '../../../../helpers/apiHelper.ts'
import { V2_CHECK_RESET_TOKEN } from '../../../../helpers/urls.ts'
import { Loader } from '../../../../Router/Loader.tsx'

export const ResetPasswordForm: React.FC = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [valid, setValid] = React.useState(false)
    const [email, setEmail] = React.useState('')
    const [token, setToken] = React.useState('')

    React.useEffect(() => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()

        const e = searchParams.get('email') || ''
        const t = searchParams.get('token') || ''
        setEmail(e)
        setToken(t)

        const checkTokenExits = async (emailBase64: string, token: string) => {
            const email = Base64.decode(emailBase64)
            const data = {email, token}
            try {
                const response: any = await unsecurePost(V2_CHECK_RESET_TOKEN, data);

                if (response?.status) {
                    setValid(true)
                }
            } catch (e) {
                setValid(false)
            }
        }

        if (e && t) {
            void checkTokenExits(email, token).then()
            return () => {
                // cancel the request before component unmounts
                source?.cancel()
            }
        }
    }, [])

    const header = (
        <div className={'pt-8'}>
            <div className="flex justify-center">
                <div>
                    <img className={'w-full'} src={'/logo.svg'} alt={'gibi-logo'}/>
                </div>
            </div>
            <h1 className="text-3xl font-semibold text-center text-primary mb-2">Reset Password</h1>
        </div>
    )

    return (
        <div className="relative flex flex-col justify-center items-center h-screen w-screen overflow-hidden fs-4"
             data-theme="gibi">
            <Loader className={'absolute -left-[7.5rem] z-[10000]'}/>
            <div className="w-full p-6 m-auto bg-white rounded-md shadow-2xl max-w-sm md:max-w-lg h-600">
                {header}
                {valid ?
                    <ResetPassword email={email} token={token}/>
                 :
                    <div className={'p-4 prose text-neutral-content text-lg flex flex-col items-center gap-4'}>
                        <div>
                            Sorry, that link is no longer valid. Please request another password reset.
                        </div>
                        <div>
                            <button className={'btn btn-primary without-ring'} onClick={() => navigate('/')}>
                                Ok
                            </button>
                        </div>
                    </div>
                }
            </div>
            <Toast/>
        </div>
    )
}


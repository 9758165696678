import * as React from 'react'
import { UseFormTrigger } from 'react-hook-form'

interface Props {
    header: React.ReactElement
    trigger:UseFormTrigger<any>
    children: React.ReactNode
    full?: boolean
    top?: boolean
    width?: string
}

export const FormLayout: React.FC<Props> = (props) => {
    const {children, trigger, header, full, top, width} = props
    return (
        <div className={`${width} flex flex-col ${top ? 'justify-start' : 'justify'}-center items-center ${full ? 'h-[v100]' : 'h-full'}`} onClick={() => trigger()}>
            {/*<Loader className={'absolute -left-[7.5rem] z-[10000]'} style={{top: '20vh'}}/>*/}
            <div className="w-full overflow-x-hidden overflow-y-auto pb-8">
                <div>
                    {header}
                    {children}
                </div>
            </div>
        </div>
        )
}

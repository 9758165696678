interface Props {
	className: string
}
export const Skeleton = (props: Props) => (
	<div aria-live="polite" aria-busy="true" className={props.className}>
    <span className="h-2 inline-flex w-full select-none rounded-md bg-gray-300 leading-none">

    </span>
		<br />
	</div>
)

export const SVGSkeleton = (props: Props) => (
	<svg
		className={
			props.className + " rounded-full bg-gray-300"
		}
	/>
)


import * as React from 'react'
import './InvalidToggle.css'
import historyStore from '../../../../store/historyStore.ts'


export const InvalidToggle: React.FC = () => {
    const includeInvalid = historyStore(s => s.includeInvalid)

    const handleChange = () => {
        historyStore.getState().setIncludeInvalid(!includeInvalid)
    }

    const component = (
        <div className="invalid-toggle">
            <input type="radio" id="invalid-toggle-now" name="invalid" value="include"
                   checked={includeInvalid === true}
                   onChange={handleChange}/>
            <label className="radio-button font-semibold text-xs" htmlFor="invalid-toggle-now"> All reports </label>

            <input type="radio" id="invalid-toggle-history" name="invalid" value="exclude"
                   checked={includeInvalid === false}
                   onChange={handleChange}/>
            <label className="radio-button font-semibold text-xs" htmlFor="invalid-toggle-history"> Valid locations only </label>
        </div>
    )

    return component
}
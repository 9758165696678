import { IPoint } from '../../../../interfaces/types.ts'

const accuracyTable: Record<number, number> = {
    0.6: 11.03448276,
    1: 12.93103448,
    1.1: 8.714285714,
    1.2: 14.76470588,
    1.3: 12.14285714,
    1.4: 14.58461538,
    1.5: 14.26923077,
    1.6: 15.875,
    1.7: 15.84210526,
    1.8: 28.6875,
    1.9: 13,
    2: 22.5,
    2.1: 16.44444444,
    2.2: 16.6,
    2.3: 15.86666667,
    2.4: 33.6,
    2.5: 20.2,
    2.6: 16.88235294,
    3: 25.47368421,
    3.5: 28.72727273,
    4: 49.2,
    4.5: 66.45454545,
    5: 35.625,
    6: 46,
    7: 45,
    9: 44.25,
}
const accuracyEstimation: Record<string, any> = {
    "1": {
        "mean": 10.3,
        "std": 6.8
    },
    "1.0": {
        "mean": 10.3,
        "std": 6.8
    },
   "2": {
        "mean": 26.6,
        "std": 27.6
    },
    "2.0": {
        "mean": 26.6,
        "std": 27.6
    },
    "3": {
        "mean": 20.3,
        "std": 10.6
    },
    "3.0": {
        "mean": 20.3,
        "std": 10.6
    },
    "4": {
        "mean": 57.2,
        "std": 58.2
    },
    "4.0": {
        "mean": 57.2,
        "std": 58.2
    },
    "5": {
        "mean": 29.5,
        "std": 15.1
    },
    "5.0": {
        "mean": 29.5,
        "std": 15.1
    },
    "6": {
        "mean": 73.4,
        "std": 43.6
    },
    "6.0": {
        "mean": 73.4,
        "std": 43.6
    },
    "7": {
        "mean": 7,
        "std": 0
    },
    "7.0": {
        "mean": 7,
        "std": 0
    },
    "8": {
        "mean": 74.7,
        "std": 55.9
    },
    "8.0": {
        "mean": 74.7,
        "std": 55.9
    },
    "9": {
        "mean": 246,
        "std": 0
    },
    "9.0": {
        "mean": 246,
        "std": 0
    },
    "16": {
        "mean": 172,
        "std": 0
    },
    "0.6": {
        "mean": 6,
        "std": 4.1
    },
    "0.7": {
        "mean": 19.7,
        "std": 18.3
    },
    "0.8": {
        "mean": 7.6,
        "std": 3.6
    },
    "0.9": {
        "mean": 12.9,
        "std": 14.3
    },
    "1.1": {
        "mean": 12.5,
        "std": 6.8
    },
    "1.2": {
        "mean": 12.4,
        "std": 10.4
    },
    "1.3": {
        "mean": 13.9,
        "std": 10.7
    },
    "1.4": {
        "mean": 13.4,
        "std": 11.8
    },
    "1.5": {
        "mean": 15,
        "std": 12.4
    },
    "1.6": {
        "mean": 16.7,
        "std": 15.2
    },
    "1.7": {
        "mean": 17.5,
        "std": 17.8
    },
    "1.8": {
        "mean": 21.5,
        "std": 18.8
    },
    "1.9": {
        "mean": 25.8,
        "std": 30.7
    },
    "2.1": {
        "mean": 19.4,
        "std": 20.1
    },
    "2.2": {
        "mean": 20.8,
        "std": 33.3
    },
    "2.3": {
        "mean": 21.3,
        "std": 16
    },
    "2.4": {
        "mean": 29.1,
        "std": 21.6
    },
    "2.5": {
        "mean": 24.5,
        "std": 27.6
    },
    "2.6": {
        "mean": 34.7,
        "std": 38.5
    },
    "2.7": {
        "mean": 25.3,
        "std": 15.8
    },
    "2.8": {
        "mean": 14.2,
        "std": 15.5
    },
    "2.9": {
        "mean": 19.8,
        "std": 12.6
    },
    "3.1": {
        "mean": 43.1,
        "std": 38
    },
    "3.2": {
        "mean": 34.3,
        "std": 27.3
    },
    "3.3": {
        "mean": 28.5,
        "std": 26.4
    },
    "3.4": {
        "mean": 47.1,
        "std": 66.1
    },
    "3.5": {
        "mean": 26.7,
        "std": 24.6
    },
    "3.6": {
        "mean": 33.8,
        "std": 42.1
    },
    "3.7": {
        "mean": 60.6,
        "std": 53.3
    },
    "3.8": {
        "mean": 60.5,
        "std": 28.5
    },
    "3.9": {
        "mean": 31.5,
        "std": 16.6
    },
    "4.1": {
        "mean": 43.5,
        "std": 27.2
    },
    "4.2": {
        "mean": 28,
        "std": 7.5
    },
    "4.3": {
        "mean": 43.9,
        "std": 23.7
    },
    "4.4": {
        "mean": 33.4,
        "std": 27.2
    },
    "4.5": {
        "mean": 55.5,
        "std": 7.8
    },
    "4.6": {
        "mean": 97.3,
        "std": 84.3
    },
    "4.7": {
        "mean": 25.4,
        "std": 17.7
    },
    "4.8": {
        "mean": 48.6,
        "std": 51.2
    },
    "4.9": {
        "mean": 89.8,
        "std": 62.3
    },
    "5.1": {
        "mean": 27,
        "std": 0
    },
    "5.2": {
        "mean": 73,
        "std": 0
    },
    "5.3": {
        "mean": 76.3,
        "std": 60.3
    },
    "5.4": {
        "mean": 55,
        "std": 25.9
    },
    "5.5": {
        "mean": 61,
        "std": 0
    },
    "5.6": {
        "mean": 56.5,
        "std": 43.7
    },
    "5.7": {
        "mean": 57.7,
        "std": 4.5
    },
    "5.8": {
        "mean": 76.8,
        "std": 31
    },
    "5.9": {
        "mean": 22,
        "std": 0
    },
    "6.1": {
        "mean": 57.5,
        "std": 8.6
    },
    "6.2": {
        "mean": 67,
        "std": 16.6
    },
    "6.3": {
        "mean": 19.7,
        "std": 7.9
    },
    "6.4": {
        "mean": 69,
        "std": 0
    },
    "6.5": {
        "mean": 53.3,
        "std": 21.9
    },
    "6.6": {
        "mean": 130,
        "std": 100.4
    },
    "6.7": {
        "mean": 35,
        "std": 0
    },
    "6.8": {
        "mean": 55,
        "std": 8.2
    },
    "6.9": {
        "mean": 24,
        "std": 0
    },
    "7.1": {
        "mean": 220,
        "std": 0
    },
    "7.2": {
        "mean": 109,
        "std": 60.4
    },
    "7.3": {
        "mean": 56.7,
        "std": 22.3
    },
    "7.6": {
        "mean": 82.5,
        "std": 33.3
    },
    "7.7": {
        "mean": 76,
        "std": 0
    },
    "8.1": {
        "mean": 50,
        "std": 35.1
    },
    "8.3": {
        "mean": 37,
        "std": 22
    },
    "8.4": {
        "mean": 60.5,
        "std": 3.7
    },
    "8.6": {
        "mean": 246.5,
        "std": 131.9
    },
    "8.7": {
        "mean": 101,
        "std": 32.7
    },
    "8.8": {
        "mean": 43,
        "std": 16.3
    },
    "8.9": {
        "mean": 210,
        "std": 0
    },
    "9.1": {
        "mean": 147,
        "std": 65.3
    },
    "9.2": {
        "mean": 137.5,
        "std": 82.1
    },
    "9.3": {
        "mean": 54,
        "std": 0
    },
    "9.4": {
        "mean": 106,
        "std": 71.9
    },
    "9.7": {
        "mean": 60,
        "std": 0
    },
    "10.2": {
        "mean": 70,
        "std": 0
    },
    "10.4": {
        "mean": 124,
        "std": 0
    },
    "10.6": {
        "mean": 143.5,
        "std": 68.2
    },
    "10.7": {
        "mean": 22,
        "std": 0
    },
    "11.2": {
        "mean": 63,
        "std": 0
    },
    "12.8": {
        "mean": 51.5,
        "std": 11.8
    },
    "13.4": {
        "mean": 68,
        "std": 0
    },
    "13.5": {
        "mean": 72,
        "std": 0
    },
    "13.6": {
        "mean": 172,
        "std": 37.6
    },
    "13.8": {
        "mean": 103,
        "std": 0
    },
    "14.1": {
        "mean": 117,
        "std": 0
    },
    "14.4": {
        "mean": 21,
        "std": 0
    },
    "15.6": {
        "mean": 17,
        "std": 0
    },
    "15.9": {
        "mean": 117,
        "std": 0
    },
    "16.1": {
        "mean": 309,
        "std": 0
    },
    "18.6": {
        "mean": 259,
        "std": 0
    },
    "22.4": {
        "mean": 94,
        "std": 0
    },
    "22.8": {
        "mean": 216,
        "std": 0
    }
}

export const getMeanAndStd = (a: string) => accuracyEstimation[String(a)]

export const estimateDeviationFromReport = (loc: IPoint) => {
    if (loc.properties?.status) {
        const acc: number = Number(Number.parseFloat(String(loc.properties.accuracy)).toFixed(1))
        const dev = accuracyEstimation[String(acc)]
        let radius = 1
        if (dev) {
            radius = dev.mean
        } else {
            let accIndex
            if (acc < 1) {
                accIndex = 0.6
            }
            else if (acc < 2.7) {
                accIndex = acc
            }
            else if (acc < 3.1) {
                accIndex = 3
            }
            else if (acc < 3.6) {
                accIndex = 3.5
            }
            else if (acc < 4.1) {
                accIndex = 4
            }
            else if (acc < 5) {
                accIndex = 4.5
            }
            else if (acc < 6) {
                accIndex = 5
            }
            else if (acc < 7) {
                accIndex = 6
            }
            else if (acc < 9) {
                accIndex = 7
            }
            else {
                accIndex = 9
            }
            radius = accuracyTable[accIndex]
        }
        console.log('radius', loc.properties.accuracy || 0, radius)
        return (radius)
    }
}

import * as React from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import Label from '../../../forms/rjsf/common/rjsf-daisyui-theme/templates/FieldTemplate/Label.tsx'
import { unsecurePost } from '../../../helpers/apiHelper.ts'
import { V2_RESET_PASSWORD_URL } from '../../../helpers/urls.ts'
import { AsyncButton } from '../AsyncButton/AsyncButton.tsx'
import { PasswordInput } from './PasswordInput.tsx'

const mustHave = 'Password must have six characters or more' /*and contain at least 1 uppercase, 1 lowercase and 1 number'*/
const mustMatch = 'Confirmation must match password'

interface Props {
    email: string,
    token: string,
}
export const ResetPassword: React.FC<Props> = (props) => {
    const [password, setPassword] = React.useState('')
    const [confirm, setConfirm] = React.useState('')
    const [buttonEnabled, setButtonEnabled] = React.useState(false)
    const [pwErr, setPwErr] = React.useState(false)
    const [newPwErr, setNewPwErr] = React.useState(false)

    const navigate = useNavigate()

    const doChange = async () => {
        const data = {
            email:  props.email,
            token: props.token,
            password,
        }
        const response: any = await unsecurePost(V2_RESET_PASSWORD_URL, data);
        setPassword('')
        setConfirm('')
        setPwErr(false)
        setNewPwErr(false)
        if (!response?.data?.status) {
            toast.success('Password has been reset.')
        } else {
            toast.error(`Error, password reset failed, try again later`)
        }
        setTimeout(() => {
            navigate('/')
        })
    }

    React.useEffect(() => {
        if (password && confirm) {
            setButtonEnabled(password.length >= 6 && confirm === password)
        }
    }, [password, confirm])

    const changePass = (value: string) => {
            setPwErr(/*!pwRegex.test(v)*/ value.length < 6)
            setPassword(value)
        }

    const changeConfirm =
        (value: string) => {
            setNewPwErr(password !== value)
            setConfirm(value)
        }

    const cancel = () => {
        setPassword('')
        setConfirm('')
    }

    const error = (message: string) => {
        return (
            <div className={'relative'}>
                <ul className="error-detail bs-callout bs-callout-info text-danger absolute -top-4 left-1">
                    <li className="text-error text-xs" key={1}>
                        {message}
                    </li>
                </ul>
            </div>
        )
    }

    const newPasswordControl = (
        <PasswordInput
            value={password}
            onChange={changePass}
            placeholder={'new password'}
        />
    )

    const confirmPasswordControl = (
        <PasswordInput
            value={confirm}
            onChange={changeConfirm}
            placeholder={'confirm password'}
        />
    )

    const body = (
        <div className={'flex flex-col justify-start gap-8 items-center w-full h-full'}>
            <div className={'relative flex-1 w-[75%]'}>
                <Label label={'New Password'} required={true}/>
                {newPasswordControl}
                {pwErr ? error(mustHave) : null}
            </div>
            <div className={'relative flex-1 w-[75%]'}>
                <Label label={'Confirm password'} required={true}/>
                {confirmPasswordControl}
                {newPwErr ? error(mustMatch) : null}
            </div>
        </div>
    )

    const panel = (
        <div className={'flex flex-col justify-start items-center gap-2 w-full'}>
            <div className={'text-neutral-content font-sm pb-4'}>
                Enter a new password for you Gibi Pet Tracker account
            </div>
            {body}
        </div>
    )

    const buttons = (
        <div className={'p-4 flex justify-between items-center w-full'}>
            <button className={'btn btn-secondary btn-ghost btn-sm'} onClick={cancel}>Dismiss</button>
            <AsyncButton classNames={'btn btn-outlined btn-primary btn-sm'} onClick={doChange}
                         disabled={!buttonEnabled}>Change</AsyncButton>
        </div>
    )

    return (
        <div className={'flex flex-col items-center gap-4'}>
            {panel}
            {buttons}
        </div>
    )
}

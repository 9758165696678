import { create } from 'zustand'

export interface timezoneType {value: string, offset: number, status: string}
const emptyTz: timezoneType = {value: 'na', offset: 0, status: 'inactive'}
interface AuthState {
    user: string;
    setUser: (user: string) => void;
    token: string;
    setToken: (token: string) => void;
    reset: () => void
    userIP: string
    setUserIP: (s: string) => void
    timezone: timezoneType
    setTimezone: (t: timezoneType) => void
    firstName: string
    email: string
    userSince: string
    setUserDetails: (u: any) => void
}

const useStore = create<AuthState>((set) => ({
    user: '',
    setUser: (user) =>
        set((state) => ({
            ...state,
            user
        })),

    token: '',
    setToken: (token) =>
        set((state) => ({
            ...state,
            token
        })),

    userIP: '',
    setUserIP: (userIP) =>
        set((state) => ({
            ...state,
            userIP
        })),

    reset: () =>
        set((state) => ({
            ...state,
            user: '',
            token: '',
            userIP: '',
            timezone: emptyTz,
            firstName: '',
            email: '',
            userSince: '',
        })),

    timezone: emptyTz,
    setTimezone: (tz: timezoneType) =>
        set((state) => ({
            ...state,
            timezone: tz,
        })),
    firstName: '',
    email: '',
    userSince: '',
    setUserDetails: (u: any) =>
        set((state) => ({
            ...state,
            timezone: u.timezone,
            firstName: u.firstName,
            email: u.email,
            userSince: u.createdAt,
        })),

}));

export default useStore;

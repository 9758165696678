import Battery0BarTwoToneIcon from '@mui/icons-material/Battery0BarTwoTone'
import Battery1BarTwoToneIcon from '@mui/icons-material/Battery1BarTwoTone'
import Battery2BarTwoToneIcon from '@mui/icons-material/Battery2BarTwoTone'
import Battery3BarTwoToneIcon from '@mui/icons-material/Battery3BarTwoTone'
import Battery4BarTwoToneIcon from '@mui/icons-material/Battery4BarTwoTone'
import Battery5BarTwoToneIcon from '@mui/icons-material/Battery5BarTwoTone'
import Battery6BarTwoToneIcon from '@mui/icons-material/Battery6BarTwoTone'
import BatteryChargingFullTwoToneIcon from '@mui/icons-material/BatteryChargingFullTwoTone'
import BatteryFullTwoToneIcon from '@mui/icons-material/BatteryFullTwoTone'
import BatterySaverTwoToneIcon from '@mui/icons-material/BatterySaverTwoTone'
import * as React from 'react'
import dataStore from '../../../store/dataStore.ts'
import { IDevice, IDeviceStatus, IPet } from '../../../interfaces/types.ts'
import { SubItem } from './SubItem.tsx'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBattery } from '@fortawesome/free-solid-svg-icons/faBattery'
// import { faBolt } from '@fortawesome/free-solid-svg-icons/faBolt'

interface Props {
    pet: IPet
    mini?: boolean
    mobile?: boolean
    dashboard?: boolean
    iconOnly?: boolean
}

export const getIcon = (deviceStatus: IDeviceStatus, color: any, val: number, isDashboard?: boolean) => {
    const wrapIcon = !isDashboard
    if (!deviceStatus.powerOn) {
        return wrapIcon ? <div className={'rounded-full p-1 bg-white'}>
            <Battery0BarTwoToneIcon
                fontSize={'large'}
                color={'disabled'}
            />
        </div> : <Battery0BarTwoToneIcon
            fontSize={'large'}
            color={'disabled'}
        />
    } else if (deviceStatus?.charging) {
        // return  <span className="fa-layers fa-fw fa-lg">
        //         <FontAwesomeIcon style={{color: 'green'}} icon={faBattery} fontSize={'3rem'}/>
        //         <FontAwesomeIcon color={'white'} inverse icon={faBolt} fontSize={'3rem'} transform="shrink-3" mask={faBattery}/>
        //     </span>
        return  wrapIcon ? <div className={'rounded-full p-1 bg-white'}>
            <BatteryChargingFullTwoToneIcon
                fontSize={'large'}
                color={color}
            />
        </div> : <BatteryChargingFullTwoToneIcon
            fontSize={'large'}
            color={color}
        />
    } else if (deviceStatus?.beaconInRange) {
        return wrapIcon ? <div className={'rounded-full p-1 bg-white'}>
            <BatterySaverTwoToneIcon
                fontSize={'large'}
                color={color}
            />
        </div> : <BatterySaverTwoToneIcon
            fontSize={'large'}
            color={color}
        />
    } else if (val < 3) {
        return wrapIcon ? <div className={'rounded-full p-1 bg-white'}>
            <Battery0BarTwoToneIcon
                fontSize={'large'}
                color={color}
            />
        </div> :  <Battery0BarTwoToneIcon
            fontSize={'large'}
            color={color}
        />
    } else if (val < 16) {
        return wrapIcon ? <div className={'rounded-full p-1 bg-white'}>
            <Battery1BarTwoToneIcon
                fontSize={'large'}
                color={color}
            />
        </div> : <Battery1BarTwoToneIcon
            fontSize={'large'}
            color={color}
        />
    } else if (val < 32) {
        return wrapIcon ? <div className={'rounded-full p-1 bg-white'}>
            <Battery2BarTwoToneIcon
                fontSize={'large'}
                color={color}
            />
        </div> : <Battery2BarTwoToneIcon
            fontSize={'large'}
            color={color}
        />
    } else if (val < 48) {
        return wrapIcon ? <div className={'rounded-full p-1 bg-white'}>
            <Battery3BarTwoToneIcon
                fontSize={'large'}
                color={color}
            />
        </div> :  <Battery3BarTwoToneIcon
            fontSize={'large'}
            color={color}
        />
    } else if (val < 64) {
        return wrapIcon ? <div className={'rounded-full p-1 bg-white'}>
            <Battery4BarTwoToneIcon
                fontSize={'large'}
                color={color}
            />
        </div> : <Battery4BarTwoToneIcon
            fontSize={'large'}
            color={color}
        />
    } else if (val < 80) {
        return wrapIcon ? <div className={'rounded-full p-1 bg-white'}>
            <Battery5BarTwoToneIcon
                fontSize={'large'}
                color={color}
            />
        </div> : <Battery5BarTwoToneIcon
            fontSize={'large'}
            color={color}
        />
    } else if (val < 96) {
        return wrapIcon ? <div className={'rounded-full p-1 bg-white'}>
            <Battery6BarTwoToneIcon
                fontSize={'large'}
                color={color}
            />
        </div> : <Battery6BarTwoToneIcon
            fontSize={'large'}
            color={color}
        />
    } else {
        return wrapIcon ? <div className={'rounded-full p-1 bg-white'}>
            <BatteryFullTwoToneIcon
                fontSize={'large'}
                color={color}
            />
        </div> : <BatteryFullTwoToneIcon
            fontSize={'large'}
            color={color}
        />
    }
}

export const Battery = (props: Props) => {
    const { pet, mobile, dashboard, iconOnly } = props
    const [status, setStatus] = React.useState('')
    const [icon, setIcon] = React.useState<React.ReactNode | null>(null)
    const [color, setColor] = React.useState<any>('inherit')
    const [percent, setPercent] = React.useState('-')
    const [device, setDevice] = React.useState<IDevice | null>(null)
    const [isOff, setIsOff] = React.useState(false)

    const devices = dataStore((s) => s.devices)

    // const getBatteryDate = (dev: IDevice, mini?: boolean) => {
    //     if (!dev) return '--'
    //     const loc = dev.lastLocation
    //     const rep = dev.lastReport
    //     const evt = dev.lastEvent
    //     let locDate
    //     let repDate
    //     let evtDate
    //     if (loc && Object.keys(loc).length) {
    //         locDate = DateTime.fromSeconds(loc.properties.timeStamp).setZone(authStore.getState().timezone.value)
    //     }
    //     if (rep && Object.keys(rep).length) {
    //         repDate = DateTime.fromSeconds(rep.properties.timeStamp).setZone(authStore.getState().timezone.value)
    //     }
    //     if (evt && Object.keys(evt).length) {
    //         evtDate = DateTime.fromISO(evt.createdAt).setZone(authStore.getState().timezone.value)
    //     }
    //     let highest
    //     if (locDate) {
    //         if (repDate) {
    //             highest = locDate > repDate ? locDate : repDate
    //         } else if (evtDate) {
    //             highest = locDate > evtDate ? locDate : evtDate
    //         } else {
    //             highest = locDate
    //         }
    //     } else if (repDate) {
    //         if (evtDate) {
    //             highest = evtDate > repDate ? evtDate : repDate
    //         } else {
    //             highest = repDate
    //         }
    //     } else if (evtDate) {
    //         highest = evtDate
    //     } else {
    //         return('--')
    //     }
    //     return highest ?
    //         !mini ? highest.toLocaleString(DateTime.DATETIME_MED) :
    //             highest.toFormat(`LLL dd`) + '[]' + highest.toFormat('hh:mm a')
    //             :'No battery reports'
    // }

    React.useEffect(() => {
        if (devices) {
            const dev = devices.find(d => d.petId === pet._id)
            if (dev) {
                let color = 'success'
                let percent = '-'
                if (dev) {
                    setDevice(dev)
                    percent = dev.status.batteryPercent
                    if (!dev.status.powerOn) {
                        setStatus('off')
                        color = 'black'
                        setIsOff(true)
                        setStatus('off')
                    } else {
                        setIsOff(false)
                    }
                    if (dev.status.charging) {
                        setStatus('charging')
                        color = 'info'
                    } else if (dev.status?.batteryAlert) {
                        setStatus('low')
                        color = 'error'
                    } else {
                        setStatus('on')
                    }
                    let val = 0
                    if (!isOff) {
                        val = parseFloat(percent)
                        console.log('battery val', val)
                        if (color !== 'info') {
                            if (color !== 'black' && val >= 50 && val <= 100) {
                                color = 'success'
                            }
                            if (val < 50 && val >= 20) {
                                color = 'warning'
                            }
                            if (val < 20) {
                                color = 'error'
                            }
                        }
                        console.log('color', color)
                    }
                    setPercent(percent)
                    setIcon(getIcon(dev.status, color, val, dashboard))
                    setColor(color !== 'info' ? `text-${color}` : 'text-primary')
                }
            }
        }
    }, [devices, pet])

    const subItem = (
        <SubItem pet={pet} title={'Battery'}
                 icon={icon} className={'-ml-1 -mr-2'} mobile={mobile}>
            <div className={'flex items-center justify-between'}>
                <div className={`${color} font-md text-lg`}>
                    {!isOff ? <span> Battery at <span className={'font-semibold'}>{percent}</span></span> : <span className={'font-semibold'}>Off</span>}
                </div>

                <div className={`text-md ${color}`}>
                    Tracker is {status}
                </div>
            </div>
        </SubItem>
    )

    const batteryIcon = (
        <div className={'flex flex-col items-center'}>
            <div>{icon}</div>
            <div className={'bg-transparent text-primary-content font-semibold pl-2'}>{percent}</div>
        </div>
    )
    return device ? iconOnly ? batteryIcon : subItem : null
}

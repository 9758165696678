import * as React from 'react'
import { useRef } from 'react'
import '../SafeZone.css'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { editStyle, getDefaultSafeZoneCoords } from '../helpers/safeZoneHelper.ts'
import { SafeZonePolygon } from '../mapComponents/SafeZonePolygon.tsx'
import safeZoneStore from '../../../store/safeZoneStore.ts'
import { getCenterOfSafeZone } from '../../map/mapHelpers.tsx'
import mapStore from '../../../store/mapStore.ts'
import { LatLng } from '../../map/types.ts'
import { SafeZoneInstructions } from './Instructions.tsx'
import PlaceResult = google.maps.places.PlaceResult

interface Props {
    placeholder: string
}

export const MapSearchBox: React.FC<Props> = (props) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const map = mapStore(s => s.map)
    const placeChanged = (place: PlaceResult[] | undefined) => {
        if (place?.[0]?.geometry && map) {
            const placeCenter = {
                lat: place[0].geometry.location?.lat(),
                lng: place[0].geometry.location?.lng()
            }
            if (placeCenter.lat && placeCenter.lng) {
                map.setCenter(placeCenter as LatLng)
                const coords = getDefaultSafeZoneCoords(placeCenter.lat, placeCenter.lng)
                const poly = <SafeZonePolygon isNew={true} coords={coords} options={editStyle}/>
                // TODO: Make function to update properties in the store
                safeZoneStore.getState().setNewSafeZoneGeometry(coords)
                safeZoneStore.getState().setNewSafeZone(poly)
                safeZoneStore.getState().setInstructions(null)
                safeZoneStore.getState().setView('finish')

                const center = getCenterOfSafeZone(coords)
                map.panTo(center as LatLng)
                map.setZoom(18)
            }
        }
    }

    React.useEffect(() => {
        if (inputRef.current) {
            const searchBox = new google.maps.places.SearchBox(inputRef.current)
            searchBox.addListener('places_changed', () => {
                if (placeChanged) {
                    placeChanged(searchBox.getPlaces())
                }
            })
            return () => google.maps.event.clearInstanceListeners(searchBox)
        }
        safeZoneStore.getState().setInstructions(SafeZoneInstructions.ADDRESS)
    }, [])

    const clear = () => {
        if (inputRef.current) {
            inputRef.current.value = ''
        }
    }

    const doCancel = () => {
        safeZoneStore.getState().setInstructions(null)
        safeZoneStore.getState().setView('list')
    }

    const cancel = (
        <div className={'flex justify-start gap-6 items-center'}>
            <button className={'btn btn-ghost text-secondary'} onClick={doCancel}>
                cancel
            </button>
        </div>
    )

    const searchField = (
        <div className={'flex flex-col gap-2 justify-start items-center relative w-full mb-24'}>
            <div className={'w-full px-4'}><input className={'gibi-input w-full text-base-content focus:outline-0'} ref={inputRef} {...props} type={'text'}/></div>
            <div>
                <div className={'absolute top-3 right-6 opacity-50'} onClick={clear}>
                    <HighlightOffIcon sx={{ width: '18px', height: '18px' }}/>
                </div>
            </div>
            <div className={'mt-4'}>
                {cancel}
            </div>
        </div>
    )

    return searchField
}

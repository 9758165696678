import * as React from 'react'
import historyStore from '../../../../store/historyStore.ts'
import "./ModeToggle.css"

interface Props {
}

export const ModeToggle: React.FC<Props> = () => {
    const isMovieMode = historyStore(s => s.isMovieMode)

    const toggleMovieMode = () => {
        historyStore.getState().setDisplayTen(isMovieMode)
        historyStore.getState().setIsMovieMode(!isMovieMode)
    }

    const component = (
        <div className="mode-toggle">
            <input type="radio" id="map-toggle-report" name="mode" value="report"
                   checked={!isMovieMode}
                   onChange={toggleMovieMode}/>
            <label className="radio-button font-semibold text-sm" htmlFor="map-toggle-report"> Report View </label>
            
            <input type="radio" id="mode-toggle-movie" name="mode" value="movie"
                   checked={isMovieMode}
                   onChange={toggleMovieMode}/>
            <label className="radio-button font-semibold text-sm" htmlFor="mode-toggle-movie"> Movie View </label>
        </div>
    )

    return component
}

import { faShield } from '@fortawesome/free-solid-svg-icons/faShield'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AxiosResponse } from 'axios'
import { DateTime } from 'luxon'
import { IDevice, IPet } from '../interfaces/types.ts'
import { timezoneType } from '../store/authStore.ts'
import dataStore from '../store/dataStore.ts'
import { post } from './apiHelper.ts'
import { getAge, petFromDevice } from './dataHelpers.ts'
import { uploadAndSavePhoto } from './uploadHelper.ts'
import { CREATE_CONTRACT_URL, CREATE_PET_URL } from './urls.ts'
import { faCat, faDog, faMars, faVenus } from '@fortawesome/free-solid-svg-icons'


export const createPetAndAssignTracker = async (iccid: string, data: any) => {
    await uploadAndSavePhoto(data)
    delete data.uploadImage

    // {pets, breeds, devices, status, petId}
    const result: AxiosResponse | null = await post(CREATE_PET_URL, {iccid, data})
    if (result?.status) {
        if (result.data.update.breeds) {
            dataStore.getState().setBreeds(result.data.update.breed)
        }
        if (result.data.update.pets) {
            dataStore.getState().setPets(result.data.update.pets)
        }
        if (result.data.update.devices) {
            dataStore.getState().setDevices(result.data.update.devices)
        }
        return result.data.petId
    }
    return  null
}

export const createContactAndAssignPet = async (petId: string, data: any) => {
    // {contactId, contacts}
    const result: AxiosResponse | null = await post(CREATE_CONTRACT_URL, {petId, data})
    if (result?.status) {
        if (result.data.update.contacts) {
            dataStore.getState().setContacts(result.data.update.contacts)
        }
        return result.data.contactId
    }
    return  null
}


export const parseBirthDate = (birthDate: string) => {
    const bd = birthDate.split('-')
    return {
        year: bd[0],
        month: bd[1],
        day: bd[2],
    }
}

export const ly = (s: string) => s.charAt(0).toUpperCase() + s.slice(1) + 'ly'

export const petFromIccid = (iccid: string): IPet => {
    const petDevice = dataStore.getState().devices.find(p => p.iccid == iccid)
    return petFromDevice(petDevice)
}

export const checkImage = (path: string) =>
    new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => resolve(img)
        img.onerror = () => reject()
        
        img.src = path
    })

export const getDeviceDetails = (dev: IDevice, timeZone: timezoneType) => {
    const lastPos = dev?.lastLocation
    const lastReport = dev?.lastReport
    let lpDate = null
    let lrDate = null
    
    if (lastPos && Object.keys(lastPos).length) {
        lpDate = DateTime.fromSeconds(lastPos.properties.timeStamp).setZone(timeZone.value)//.toLocaleString(DateTime.DATETIME_MED)
    }
    if (lastReport && Object.keys(lastReport).length) {
        lrDate = DateTime.fromSeconds(lastReport.properties.timeStamp).setZone(timeZone.value)//.toLocaleString(DateTime.DATETIME_MED)
    }
    return {
        lastContactDate: lrDate,
        lastPositionDate: lpDate
    }
}

export const getDeviceEventDetails = (dev: IDevice, timeZone: timezoneType) => {
    const lastEvent = dev?.lastEvent
    let date = null
    if (lastEvent && Object.keys(lastEvent).length) {
        date = DateTime.fromISO(lastEvent.createdAt).setZone(timeZone.value)//.toLocaleString(DateTime.DATETIME_MED)
    }
    return {
        lastEvent,
        lastEventDate: date || 'no events received'
    }
}

export const getSafeZoneDetails = (dev: IDevice, timeZone: timezoneType) => {
    const safeZoneDateTime = dev?.status?.safeZoneDate ? DateTime.fromISO(dev.status.safeZoneDate).setZone(timeZone.value) : null

    const safeZones = dataStore.getState().safeZones
    const safeZoneIcon = safeZones.length ? (dev?.status.safeZoneViolation ? <FontAwesomeIcon icon={faTriangleExclamation} fontSize={'18px'} className={'text-error'}/> : <FontAwesomeIcon icon={faShield} fontSize={'18px'} className={'text-success'}/>)
        : null

    const safeZoneLabel = safeZones.length ? (dev?.status.safeZoneViolation ? 'Out of all zones' : `In zone ${dev?.status.occupiedZone}`)
        : 'No safe zones'

    return {
        safeZoneDate: safeZoneDateTime ? safeZoneDateTime.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY) : '',
        safeZoneIcon,
        safeZoneLabel,
    }
}

export const getPetDetails = (pet: IPet) => {
    const speciesRecord = dataStore.getState().species.find(s => s._id === pet.species)
    const speciesIcon = speciesRecord?.species_name.toLowerCase() === 'dog' ? faDog : faCat
    const genderIcon = pet.gender.toLowerCase() === 'male' ? faMars : faVenus
    const breed = dataStore.getState().breeds?.find(b => b._id === pet.breed)?.breed_name
    const age = getAge(pet.birthDate, true)
    const weight = pet.petWeight;
    const country = dataStore.getState().account?.homeAddress?.country
    const scale = country?.includes('CA') ? 'kg' : 'lbs'
    const petWeight = `${weight} ${scale}`
    return {
        speciesIcon,
        genderIcon,
        breed,
        age,
        petWeight,
    }
}
import { createFormObject, updateFormObject } from '../handlers/formDataHandler.ts'
import { loginHandler } from '../handlers/loginHandler.ts'
import { useNavigate } from 'react-router-dom'
import controlStore from '../store/controlStore.ts'
import dataStore from '../store/dataStore.ts'
import { uploadAndSavePhoto } from '../helpers/uploadHelper.ts'
import { shapeAddress } from '../helpers/dataHelpers.ts'

export const useForm = () => {
    const navigate = useNavigate();

    const onSubmit = async (form: string, data: any): Promise<void> => {
        console.log(form, data)
        // if (form !== 'login') return

        switch (form) {
            case 'login': {
                const route = await loginHandler(data)
                route !== '/' && navigate(route)
                controlStore.getState().setShowLoader(false)
            }
                break

            // @ts-ignore
            case 'pet': {
                console.log('pet', data)
                let petsUpdate
                let breedsUpdate
                // upload photo
                await uploadAndSavePhoto(data)
                if (data._id && data._id !== 'new') {
                    const {pets, breeds} = await updateFormObject('pet', data)
                    petsUpdate = pets
                    breedsUpdate = breeds
                } else {
                    const {pets, breeds} = await createFormObject('pet', data)
                    petsUpdate = pets
                    breedsUpdate = breeds
                }
                if (breedsUpdate) {
                    dataStore.getState().setBreeds(breedsUpdate)
                }
                if (petsUpdate) {
                    dataStore.getState().setPets(petsUpdate)
                }
                navigate(`/${controlStore.getState().currentPage}`)
            }
                break

            // @ts-ignore
            case 'profile': {
                // console.log('profile', data)
                const profile = await updateFormObject('profile', shapeAddress(data))
                if (profile) {
                    dataStore.getState().setProfile(profile)
                    navigate('/' + controlStore.getState().currentPage)
                }
            }
                break

            // @ts-ignore
            case 'homeAddress': {
                console.log('homeAddress', data)
                const profile = await updateFormObject('homeAddress', data)
                if (profile) {
                    dataStore.getState().setProfile(profile)
                }
            }
                break

            // @ts-ignore
            case 'contact': {
                // console.log('contact', data)
                let contacts
                if (data._id && data._id !== 'new') {
                    contacts = await updateFormObject('contact', data)
                } else {
                    contacts = await createFormObject('contact', data)
                }
                if (contacts) {
                    dataStore.getState().setContacts(contacts)
                }
            }
                break

            default:
                console.log(form, data)
        }
    }

    return [onSubmit] as const
}

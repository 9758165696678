import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle'
import { faPaw } from '@fortawesome/free-solid-svg-icons/faPaw'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import drawerStore from '../../store/drawerStore.ts'
// import safeZoneStore from '../../store/safeZoneStore.ts'
// import { DesktopPetList } from './DesktopPetList.tsx'

export const DesktopPetDrawerButton = () => {
    const [open, setOpen] = React.useState(false)
    const drawerOpen = drawerStore(s => s.drawerOpen)
    // const safeZoneActive = safeZoneStore(s => s.active)
    const showPetList = drawerStore(s => s.petList)
    
    React.useEffect(() => {
        if (!drawerOpen && open) {
            setOpen(false)
        }
    }, [drawerOpen])
    
    const icon = (
        <div
            className={'flex justify-center items-center h-fit w-fit rounded-full bg-white border-primary border-solid border-4'}>
            <span className="fa-layers fa-fw fa-lg h-[36px] w-[36px]">
                <FontAwesomeIcon style={{color: showPetList ? 'white' : 'hsl(var(--p))'}} icon={faCircle} fontSize={'36px'}/>
                <FontAwesomeIcon inverse icon={faPaw} fontSize={'36px'} transform="shrink-3" mask={faCircle}
                                 style={{color: showPetList ? 'hsl(var(--p))' : 'white'}}/>
            </span>
        </div>
    )
    const button = (
        <div
            onClick={() => {
                // if (safeZoneActive) {
                //     safeZoneStore.getState().setActive(false)
                //     drawerStore.getState().setDrawerOpen(false)
                //     setTimeout(() => {
                //         setOpen(true)
                //         drawerStore.getState().setChildren(<DesktopPetList/>)
                //         drawerStore.getState().setDrawerOpen(true)
                //     }, 500)
                // } else {
                //     setOpen(!open)
                //     drawerStore.getState().setDrawerOpen(!open)
                // }
                drawerStore.getState().showPetList(!showPetList)
            }}
        >
            {icon}
        </div>
    )
    return (
        <div className={'relative'}>
            {button}
        </div>
    )
}

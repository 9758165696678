import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IContact } from '../../../interfaces/types.ts'
import { faPencil } from '@fortawesome/free-solid-svg-icons/faPencil'
import { useNavigate } from 'react-router-dom'

interface Props {
    iconOnly?: boolean
    contact: IContact
    color?: string
}

export const EditContactButton: React.FC<Props> = (props) => {
    const {iconOnly, contact, color} = props
    const navigate = useNavigate()

    const doEdit = () => {
        const state = {contact}
        navigate('/contactForm',{state})
    }

    const textColor = color || 'text-secondary'
    const button = iconOnly ? (
        <div className={`${textColor} text-xl`} onClick={doEdit}>
            <div className={'tooltip'} data-tip={`Edit ${contact.last_name}`} onClick={doEdit}>
                <FontAwesomeIcon icon={faPencil}/>
            </div>
        </div>
        ) :
        <button className={`btn btn-xs btn-ghost ${textColor} without-ring h-fit`}
                onClick={doEdit}>
            Edit
        </button>

    return (
        <div>
            {button}
        </div>
    )
}

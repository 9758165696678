import { faPowerOff } from '@fortawesome/free-solid-svg-icons/faPowerOff'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime } from 'luxon'
import * as React from 'react'
import { getEventName } from '../../helpers/eventHelper.tsx'
import { getDeviceDetails, getDeviceEventDetails, getPetDetails, getSafeZoneDetails } from '../../helpers/petHelper.tsx'
import { IDevice } from '../../interfaces/types.ts'
import authStore from '../../store/authStore.ts'
import dataStore from '../../store/dataStore.ts'
import { petFromDevice } from '../../helpers/dataHelpers.ts'

interface Props {
    device: IDevice
    show: boolean
}

export const DeviceDetail: React.FC<Props> = (props) => {
    const {device, show} = props
    const timeZone = authStore(s => s.timezone)
    const {lastPositionDate, lastContactDate} = getDeviceDetails(device, timeZone)
    const {lastEvent, lastEventDate} = getDeviceEventDetails(device, timeZone)
    const eventLabel = lastEvent ? getEventName(lastEvent) : 'No events reported'
    const {
        safeZoneDate,
        safeZoneIcon,
        safeZoneLabel,
    } = getSafeZoneDetails(device, timeZone)
    const isSaving = device?.status.beaconInRange
    const savingStatus = isSaving ? 'saving on' : 'saving off'
    const contacts = dataStore(s => s.contacts)
    const pet = petFromDevice(device)
    const myFollowers = contacts.filter(c => c.pet_list?.includes(pet?._id))
    const {
        speciesIcon,
        genderIcon,
        breed,
        age,
        petWeight,
    } = getPetDetails(pet)
    
    const panel = (
        <div id={'detail-panel'}
             className={`${show ? 'show' : ''} popup min-w-[28rem] w-full h-full bg-base-200 text-primary-content rounded ml-1 p-2 flex flex-col`}>
            <div className={'flex items-center justify-around bg-base-100 text-primary p-1'}>
                <div>
                    <FontAwesomeIcon icon={speciesIcon} style={{color: 'hsl(var(--p))'}}/>
                </div>
                <div>
                    <FontAwesomeIcon icon={genderIcon} style={{color: 'hsl(var(--p))'}}/>
                </div>
                <div>
                    {breed}
                </div>
                <div>
                    {age}
                </div>
                <div>
                    {petWeight}
                </div>
            </div>

            <div className={'flex items-center justify-between bg-base-300 px-2'}>
                <div className={'text-lg font-medium w-[9rem] text-start'}>
                    Last Contact
                </div>
                <div className={'w-[14rem] text-end'}>
                    {lastContactDate?.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY) || 'no reports received'}
                </div>
            </div>
            <div className={'flex items-center justify-between bg-primary px-2'}>
                <div className={'text-lg font-medium w-[9rem] text-start'}>
                    Last Location
                </div>
                <div className={'w-[14rem] text-end'}>
                    {lastPositionDate?.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY) || 'no reports received'}
                </div>
            </div>
            <div className={'flex items-center justify-between bg-base-300 px-2 gap-4'}>
                <div className={'text-lg font-medium w-[9rem] text-start'}>
                    Last Event
                </div>
                <div className={'w-[12rem] text-center'}>
                    {eventLabel}
                </div>
                <div className={'w-[14rem] text-end'}>
                    {lastEventDate?.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
                </div>
            </div>
            <div className={'flex items-center justify-between gap-4 bg-primary px-2'}>
                <div className={'text-lg font-medium w-[9rem] text-start'}>
                    Safe Zone Status
                </div>
                <div className={'w-[12rem] flex items-center justify-center gap-2'}>
                    <div className={'mt-1'}>{safeZoneIcon}</div>
                    <div className={''}>{safeZoneLabel}</div>
                </div>
                <div className={'w-[14rem] text-end'}>
                    {safeZoneDate}
                </div>
            </div>
            <div className={'flex items-center justify-between gap-4 bg-base-300 px-2'}>
                <div className={'text-lg font-medium w-[9rem] text-start'}>
                    Power Saving
                </div>
                <div className={'w-[12rem] flex items-center justify-center gap-2'}>
                    <div className={''}>
                        <FontAwesomeIcon icon={faPowerOff} fontSize={'18px'}
                                         className={`mt-1 ${isSaving ? 'text-success' : 'text-error'}`}/>
                    </div>
                    <div className={''}>
                        {savingStatus}
                    </div>
                </div>
                <div className={'w-[14rem] h-4'}/>
            </div>
            <div className={'flex items-center justify-start gap-4 bg-primary px-2'}>
                <div className={'text-lg font-medium text-start'}>
                    Followers
                </div>
                <div className={'pt-1'}>
                    <div className={'flex items-center justify-start flex-wrap gap-4'}>
                        {myFollowers.map(c => (
                            <div key={c._id} className={'text-sm flex items-center gap-1'}>
                                {c.first_name.charAt(0)} {c.last_name}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
    return panel
}

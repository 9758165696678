import { ZonePath } from '../../../interfaces/types.ts'
import { getCenterOfSafeZone } from '../../map/mapHelpers.tsx'
import { SafeZonePolygon } from '../mapComponents/SafeZonePolygon.tsx'
import { editStyle } from './safeZoneHelper.ts'
import safeZoneStore from '../../../store/safeZoneStore.ts'
import { LatLng } from '../../map/types.ts'

export const rotatePoint = (point: { x: number; y: number }, origin: { x: number; y: number }, angle: number) => {
    const angleRad = angle * Math.PI / 180.0
    return {
        x: Math.cos(angleRad) * (point.x - origin.x) - Math.sin(angleRad) * (point.y - origin.y) + origin.x,
        y: Math.sin(angleRad) * (point.x - origin.x) + Math.cos(angleRad) * (point.y - origin.y) + origin.y
    }
}
export const rotatePolygon = (map: google.maps.Map, coords: ZonePath, angle: number) => {
    const editedZone = safeZoneStore.getState().safeZoneBeingEdited
    const prj = map.getProjection()
    const center = getCenterOfSafeZone(coords)
    const origin = prj?.fromLatLngToPoint(center as LatLng) //rotate around center

    const update = coords.map((latLng: any) => {
        const point = prj?.fromLatLngToPoint(latLng)
        // @ts-ignore
        const rotatedLatLng = prj?.fromPointToLatLng(rotatePoint(point, origin, angle))
        return {lat: rotatedLatLng?.lat(), lng: rotatedLatLng?.lng()}
    })
    if (!editedZone) {
        const poly = <SafeZonePolygon isNew={true} coords={update as ZonePath} options={editStyle}/>
        safeZoneStore.getState().setNewSafeZoneGeometry(update as ZonePath)
        safeZoneStore.getState().setNewSafeZone(poly)
    } else {
        safeZoneStore.getState().setUpdatedZonePath(update as ZonePath)
    }
}

import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { StepIcon } from '../multiStep/components/StepIcon.tsx'
import { useSwipeable } from 'react-swipeable'
import { useRef } from 'react'

interface Props {
    children: React.ReactNode
    disabled?: boolean
    title?: string
    step?: number
    done?: boolean
    onTap?: () => void
}

export const Section: React.FC<Props> = (props) => {
    const {children, disabled, title, step, done, onTap} = props

    const ref = useRef(null)

    React.useEffect(() => {
        if (ref?.current) {
            // setOverlayStyle({
            //     // @ts-ignore
            //     height: ref.current.clientHeight,
            //     // @ts-ignore
            //     width: ref.current.clientWidth,
            // })
        }
    }, [ref])

    const handlers = useSwipeable({
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onTap: (eventData) => {
            onTap && onTap()
        },
    });

    const header = title || step ? (
        <div className={`flex items-center justify-between p-1 w-full bg-primary/20 rounded`}>
            <div className={'flex items-center gap-1'}>
                {step ? <StepIcon step={step}/> : null}
                {title}
            </div>
            <div className={'self-end'}>
                {done ? <FontAwesomeIcon icon={faCircleCheck} style={{color: 'green'}}/> : null}
            </div>
        </div>
    ) : null

    return (
        <div className={`flex flex-col items-center gap-0 pt-0 rounded-lg bg-primary/10 ${disabled ? 'opacity-50':'opacity-100'}`}>
            <div id={'header'} className={'w-full'}
                {...handlers}
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                }}>
                {header}
            </div>
            <div className={'p-2 w-full relative flex flex-col gap-2'} ref={ref}>
                {children}
            </div>
        </div>
    )
}

import * as React from 'react'
import './HistoryDrawer.css'
import AnimateHeight from 'react-animate-height'
import { useSwipeable } from 'react-swipeable'
import historyStore from '../../../../store/historyStore.ts'
import useStore from '../../../../store/historyStore.ts'
// import { useHistoryMovie } from '../useHistoryMovie.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faStepForward, faStepBackward, faBackward, faAnglesLeft, faAnglesRight, faFilm, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
//import { set } from 'lodash'

export const HistoryDrawer: React.FC = () => {
    const [height, setHeight] = React.useState<number|string>(0)
    const [open, setOpen] = React.useState(false)
    const includeInvalid = historyStore(s => s.includeInvalid)
    // @ts-ignore
    const map = historyStore((s) => s.map);
    // @ts-ignore
    const { isPlaying, togglePlay, pause, step, speedUp, slowDown } = {} // useHistoryMovie(map);
    const {isMovieMode, setIsMovieMode, setDisplayTen, displayTen} = useStore(); 
     
    const renderHistoryControls = () => (
        <div className='bg-primary '>
            <button onClick={() => slowDown()} className="text-primary btn btn-outline btx-xs border-white">
            <FontAwesomeIcon icon={faAnglesLeft} inverse />
            </button>
             <button className={`text-primary btn btn-outline btx-xs border-white`} onClick={() => step('forward')} disabled={isPlaying}>
            <FontAwesomeIcon icon={faStepBackward} inverse/>
            </button>
             <button className={`text-primary btn btn-outline btx-xs border-white`} onClick={() => togglePlay('forward')} disabled={isPlaying}>
            <FontAwesomeIcon icon={faBackward} inverse/>
            </button>
            <label className="swap swap-rotate">
                <input type="checkbox" onChange={() => isPlaying ? pause() : togglePlay('reverse')} />
                {/* Pause Icon (when checked) */}
                <span className="swap-on text-primary btn btn-outline btn-lg border-white rounded-lg">
                    <FontAwesomeIcon icon={faPause} inverse className='fa-xl' />
                </span>

                {/* Play Icon (when unchecked) */}
                <span className="swap-off text-primary btn btn-outline btn-lg border-white rounded-lg">
                    <FontAwesomeIcon icon={faPlay} inverse className='fa-xl' />
                </span>
            </label>
            <button className={`text-primary btn btn-outline btx-xs border-white`} onClick={() => step('backward')} disabled={isPlaying}>
            <FontAwesomeIcon icon={faStepForward} inverse/>
            </button> 
            <button onClick={() => speedUp()} className="text-primary btn btn-outline btx-xs border-white">
            <FontAwesomeIcon icon={faAnglesRight} inverse/>
                </button>
        </div>
    );

    const onSwipeUp = () => {
        setHeight('auto')
        setOpen(true)
    }

    const onSwipeDown = () => {
        setHeight(0)
        setOpen(false)
        setDisplayTen(true)
        setIsMovieMode(false)
        pause()
    }

    const swipeHandlers = useSwipeable({
        onSwipedUp: onSwipeUp,
        onSwipedDown: onSwipeDown,
    });

    React.useEffect(() => {
        if(includeInvalid)
            setDisplayTen(true);
    },[includeInvalid])

    //Pause when leaving movie mode and entering event mode
    const toggleMovieMode = () => {
        setDisplayTen(isMovieMode);
        setIsMovieMode(!isMovieMode);
        //setHeight(isMovieMode ? 0 : 'auto');
        setOpen(!isMovieMode);
        //console.log("Moviemode: ", isMovieMode)
        //clear current markers and set correct markers, in the set timout/sleep
        if(!isMovieMode || displayTen)
        {   pause()}
    };

    const content = isMovieMode ? (
        <div className='bg-primary without-ring'>
            {/* Render movie mode controls */}
            <button
                onClick={toggleMovieMode}
                className={`text-white btn btn-outline m-2 border-white without-ring`}
            >
                <FontAwesomeIcon icon={faCalendarDays} inverse /> Event Mode
            </button>
            {renderHistoryControls()}
        </div>
    ) : (
        <>
        <div className="flex justify-center items-center space-x-4 bg-primary without-ring">
        <button
                onClick={toggleMovieMode}
                className={`text-white btn btn-outline m-2 border-white without-ring`}
            >
                <FontAwesomeIcon icon={faFilm} inverse /> Movie Mode
            </button>
            <label className={`swap text-xs pt-1`}>
                <input
                    type="checkbox"
                    onChange={() => historyStore.getState().setIncludeInvalid(!includeInvalid)}
                    checked={includeInvalid}
                />
                <div className={`swap-on text-white btn btn-outline btx-xs border-white`}>
                    Show valid locations only
                </div>
                <div className={`swap-off text-white btn btn-outline btx-xs border-white`}>
                    Show all reports
                </div>
            </label>
            </div>
        </>
    );

    const handle = (
        <div className={'bg-transparent flex justify-center items-center relative'}
             onClick={() => open ? onSwipeDown() : onSwipeUp()}>
            <div className={'bg-primary text-primary-content text-xs w-16 text-center center rounded absolute h-[20px] -top-[22px] p-2'} {...swipeHandlers}>
                <hr className={'bg-primary-content/50 w-8 ml-auto mr-auto'}/>
            </div>
        </div>
    )


    return (
        <div id={'pet-drawer'} className={'h-full w-[100vw]'}>
            {handle}
            <AnimateHeight
                id="pet-drawer"
                duration={500}
                height={height as any}
                className={'h-full w-[100vw]'}
            >
                <div className={'h-full bg-white border-b-accent border-b-2 border-solid'}>
                    {content}
                </div>
            </AnimateHeight>
        </div>
    )
}

import React from "react";
import dataStore from '../../../store/dataStore.ts'
import Select from 'react-select'
import { select2Styles } from "../SelectPet/select2.styles.ts";

interface Props {
	onChange: (p: any) => void
	value: string
	noNew?: boolean
	petId?: string
	disabled: boolean
	listFollowers?: boolean
}

const SelectContact: React.FC<Props> = (props) => {
	const {onChange, value, noNew, petId, disabled, listFollowers } = props
	const contacts = dataStore(s => s.contacts)
	const [options, setOptions] = React.useState<any[]>([])

	React.useEffect(() => {
		if (contacts) {
			const opts = (contacts.map(p => {
					if (!listFollowers) {
						return { value: p._id, label: `${p.first_name} ${p.last_name}` }
					} else {
						if (!p.pet_list.includes(petId)) {
							return { value: p._id, label: `${p.first_name} ${p.last_name}` }
						} else {
							return null
						}
					}
				}).filter(n => n)
			)
			if (!noNew) {
				opts.push({value: 'new', label: 'New family/friend'})
			}
			setOptions([...opts])
		}
	}, [contacts])

	const getSelectedContact = (id: string) => options.find(o => o.value === id)
	const followers = contacts.map(c => {
		if (c.pet_list?.includes(petId)) {
			return `${c.first_name} ${c.last_name}`
		}
		return null
	}).filter(n => n)

	const contactList = (
		<div className="form-control relative pl-2 pr-2 w-full">
			{listFollowers ? (
				<div className={'flex flex-col gap-1 items-center pb-2'}>
					{followers?.map( f => <div key={f} className={'text-xs text-center'}>{f}</div>)}
				</div>
			) : null}
			<Select
				className=""
				onChange={onChange}
				value={getSelectedContact(value)}
				options={options}
				styles={{...select2Styles}}
				isDisabled={disabled}
			/>
		</div>
	)
	return contactList
};

export default SelectContact

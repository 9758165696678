import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { IPet } from '../../../interfaces/types.ts'
import { faList } from '@fortawesome/free-solid-svg-icons/faList'
import { ButtonWrapperSimple } from './ButtonWrapperSimple.tsx'

interface Props {
    iconOnly?: boolean
    noIcon?: boolean
    pet: IPet
    outline?: boolean
    full?: boolean
}

export const PetEventsButton: React.FC<Props> = (props) => {
    const { full, iconOnly, noIcon, pet, outline } = props
    // const [alertsOpen, setAlertsOpen] = React.useState(false)
    const navigate = useNavigate()

    const showEvents = () => {
        const state = { pet }
        navigate('/events', { state })
    }

    return (
        <div className={`cursor-pointer ${full ? 'w-full px-2' : ''}`}>
            <ButtonWrapperSimple icon={faList as any} noIcon={noIcon} iconOnly={iconOnly} label={'Events'} pet={pet}
                                 outline={outline} onClick={() => showEvents()} full/>
        </div>
    )
}

import React from 'react'
import Select from 'react-select'
import { selectStyles } from '../../../forms/rjsf/common/rjsf-daisyui-theme/styles/select.styles.ts'
import { getPetsWIthDevices } from '../../../helpers/dataHelpers.ts'
import dataStore from '../../../store/dataStore.ts'
import { IPet } from '../../../interfaces/types.ts'
import { getTextColor } from '../../../helpers/colorHelper.ts'

interface Props {
    onlyShowActive?: boolean
    onlyShowInactive?: boolean
    onChange: (p: any) => void
    value: string
    noNew?: boolean
	useButtons?: boolean
}

export const SelectPet: React.FC<Props> = (props) => {
    const { onlyShowActive, onlyShowInactive, onChange, value, noNew, useButtons, } = props
    const devices = dataStore(s => s.devices)
    const pets = dataStore(s => s.pets)
    const [options, setOptions] = React.useState<any[]>([])
    const [petList, setPetList] = React.useState<IPet[]>([])

    React.useEffect(() => {
        if (devices && pets) {
            if (onlyShowActive) {
                const petsWithDevices: string[] = getPetsWIthDevices()
                setPetList(dataStore.getState().pets.filter(p => petsWithDevices.includes(p._id)))
                if (noNew) {
                    setOptions([...(petList.map(p => {
                        return { value: p._id, label: p.petName }
                    }))])
                } else {
                    setOptions([...(petList.map(p => {
                        return { value: p._id, label: p.petName }
                    })), { value: 'new', label: 'New pet' }])
                }
            } else if (onlyShowInactive) {
                const petsWithDevices: string[] = getPetsWIthDevices()
                setPetList(dataStore.getState().pets.filter(p => !petsWithDevices.includes(p._id)))
                if (noNew) {
                    setOptions([...(petList.map(p => {
                        return { value: p._id, label: p.petName }
                    }))])
                } else {
                    setOptions([...(petList.map(p => {
                        return { value: p._id, label: p.petName }
                    })), { value: 'new', label: 'New pet' }])
                }
            } else {
                setPetList(dataStore.getState().pets)
                if (noNew) {
                    setOptions([...(petList.map(p => {
                        return { value: p._id, label: p.petName }
                    }))])
                } else {
                    setOptions([...(petList.map(p => {
                        return { value: p._id, label: p.petName }
                    })), { value: 'new', label: 'New pet' }])
                }
            }
        }
    }, [devices, pets])

    const getSelectedPet = (id: string) => options.find(o => o.value === id)

    const buttons = (
        <div className={'flex flex-col gap-4'}>
            {
                petList.map(p => {
                    return (
                        <div key={p._id}
                             className={`flex items-center gap-2 p-1 ${getTextColor(p.iconColor)} rounded hover:!bg-primary ${value === p._id ? 'border-2 border-accent' : ''}`}
                             style={{ backgroundColor: p.iconColor, }}
                             onClick={() => onChange(p._id)}
                        >
                            <div className="avatar">
                                <div className="w-8 rounded">
                                    <img src={p.imageURL}/>
                                </div>
                            </div>

                            <div className={'font-semibold text-lg'}>
                                {p.petName}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )

    const inputStyle = {
        input: (base: any) => ({
            ...base,
            fontSize: '14px',
            fontWeight: 'bold',
            height: '24px',
            paddingTop: '2px',
        }),
        singleValue: (styles: any) => ({
            ...styles,
            fontSize: '14px',
            fontWeight: 'semibold',
            height: '24px',
            paddingTop: '0.25rem',
        }),
        control: (base: any) => ({
            ...base,
            height: '24px',
        }),
        option: (base: any) => ({
            ...base,
            fontSize: '14px',
            fontWeight: 'semibold',
        }),
    }

    return (
        <div className="form-control relative pl-2 pr-2 w-full">
            {/*<label className="label absolute -top-2 z-10">*/}
            {/*	<span className="label-text text-xs opacity-50">Choose a pet</span>*/}
            {/*</label>*/}
            {useButtons ? buttons :
                <Select
                    className=""
                    onChange={onChange}
                    value={getSelectedPet(value)}
                    options={options}
                    styles={{ ...selectStyles, ...inputStyle }}
                />
            }
        </div>
    )
}


import { faEye } from '@fortawesome/free-solid-svg-icons/faEye'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import { faSquarePlus } from '@fortawesome/free-solid-svg-icons/faSquarePlus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { createNewSafeZone, updateSafeZone } from '../../handlers/safeZoneHandler.ts'

import controlStore from '../../store/controlStore.ts'
import dataStore from '../../store/dataStore.ts'
import mapStore from '../../store/mapStore.ts'
import safeZoneStore from '../../store/safeZoneStore.ts'
import drawerStore from '../../store/drawerStore.ts'
import { CompleteSafeZone } from './components/CompleteSafeZone.tsx'
import { Instructions } from './components/Instructions.tsx'
import { MapSearchBox } from './components/MapSearchBox.tsx'
import { SafeZoneDrawing } from './components/SafeZoneDrawing.tsx'
import { SafeZoneDrawingOptions } from './components/SafeZoneDrawingOptions.tsx'
import { SafeZoneList } from './components/SafeZoneList.tsx'

export type DrawingOptionType = 'hand' | 'address' | ''

export const SafeZonePanel = () => {
    const map = mapStore(s => s.map)
    const showSafeZones = controlStore(s => s.showSafeZones)
    const safeZoneBeingEdited = safeZoneStore(s => s.safeZoneBeingEdited)
    const safeZones = dataStore(s => s.safeZones)
    const view = safeZoneStore(s => s.view)
    const isMobile = controlStore(s => s.isOnMobileDevice)
    const iconSize = isMobile ? '2x' : '1x'

    React.useEffect(() => {
        if (safeZoneBeingEdited) {
            const name = safeZones.find(s => s._id === safeZoneBeingEdited)?.zone_name
            if (name) {
                safeZoneStore.getState().setEditedSafeZoneName(name)
            }
        }
    }, [safeZoneBeingEdited])
    const saveNewZone = async () => {
        const zones = await createNewSafeZone(safeZoneStore.getState().editedZoneName, safeZoneStore.getState().newSafeZoneGeometry)
        safeZoneStore.getState().setInstructions(null)
        safeZoneStore.getState().setNewSafeZone(null)
        drawerStore.getState().setDrawerOpen(false)
        safeZoneStore.getState().setView('list')
        if (zones) {
            safeZoneStore.getState().setSafeZonePolygons([])
            setTimeout(() => {
                dataStore.getState().setSafeZones(zones)
            })
        }
    }

    const saveEditedZone = async () => {
        const zones = await updateSafeZone(safeZoneStore.getState().safeZoneBeingEdited, safeZoneStore.getState().editedZoneName, safeZoneStore.getState().updatedZonePath)
        drawerStore.getState().setDrawerOpen(false)
        safeZoneStore.getState().setView('list')
        safeZoneStore.getState().setSafeZoneBeingEdited('')
        safeZoneStore.getState().setInstructions(null)
        if (zones) {
            safeZoneStore.getState().setSafeZonePolygons([])
            setTimeout(() => {
                dataStore.getState().setSafeZones(zones)
            })
        }
    }

    const save = async () => {
        if (safeZoneBeingEdited) {
            await saveEditedZone()
        } else {
            await saveNewZone()
        }
    }

    const cancel = () => {
        safeZoneStore.getState().setActive(false)
        safeZoneStore.getState().setView('list')
        safeZoneStore.getState().setInstructions(null)
        safeZoneStore.getState().setNewSafeZone(null)
        safeZoneStore.getState().setNewSafeZoneGeometry([])
        safeZoneStore.getState().setSafeZoneBeingEdited('')
        safeZoneStore.getState()?.drawingManager?.setMap(null)
    }

    const newZoneButton = (
        <div className={'tooltip tooltip-left'} data-tip={'Add a safe zone'} onClick={() => safeZoneStore.getState().setView(
            'choose')}>
            <FontAwesomeIcon icon={faSquarePlus} size={iconSize}/>
        </div>
    )
    const buttonSpacing = isMobile ? 'w-[2.5rem]' : 'w-fit'

    const showHide = (
        <div className={`${buttonSpacing} tooltip tooltip-left`}
             data-tip={showSafeZones ? 'Hide safe zones' : 'Show safe zones'}
             onClick={() => {controlStore.getState().setShowSafeZone(!showSafeZones)}}>
            {showSafeZones ? <FontAwesomeIcon icon={faEyeSlash} size={iconSize}/> :  <FontAwesomeIcon icon={faEye} size={iconSize}/>}
        </div>
    )

    const rowStyle = isMobile ? 'justify-around ' : 'justify-end gap-4'
    const card = (
        <div
            className={'w-full flex flex-col justify-start items-start gap-1 bg-white p-2'}>
            <div className={`prose flex justify-between items-center w-full bg-white text-primary border-b-2 border-b-accent`}>
                <div className={'w-[50%] self-center'}><h4 className={'m-0 text-2xl text-primary'}>Safe Zones</h4></div>
                <div className={`w-[50%] flex flex-row items-center ${rowStyle} self-center`}>
                    <div className={buttonSpacing} >
                        {view === 'list' ? <span>{newZoneButton}</span> : null}
                    </div>
                    <div>{showHide}</div>
                </div>

            </div>

            <Instructions/>

            {view === 'list' ? <SafeZoneList map={map!}/> : null}

            {view === 'choose' ? <SafeZoneDrawingOptions map={map!}/> : null}

            {view === 'drawing' ? <SafeZoneDrawing cancel={cancel}/> : null}

            {view === 'search' ? <MapSearchBox placeholder={'enter an address'}/> : null}

            {view === 'finish' ? <CompleteSafeZone save={save} cancel={cancel}/> : null}
        </div>
    )

    return (
        <div className={'border-b-2 border-b-accent'}>
            {card}
        </div>
    )
}

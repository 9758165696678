import * as React from 'react'

interface Props {
    title: string
    children?: React.ReactNode
}

export const Header: React.FC<Props> = (props) => {
    const {children, title} = props
    return (
        <div className={'pt-2'}>
            <h1 className="text-3xl font-semibold text-center text-primary mb-2">{title}</h1>
            {children}
        </div>
        )
}

import * as React from 'react'
import { Label } from '../components/Label.tsx'
import { Input } from '../components/Input.tsx'

interface Props {
	formField: string
	title: string,
	errors: Record<string, any>
	inputType?: string
	color?: string
	disabled?: boolean
	complete?: boolean
	scale?: string
}

// eslint-disable-next-line react/display-name
export const LabeledInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
	const { formField, title, errors, inputType, color, disabled, complete, scale, ...rest } = props

	const bundle = {
		...rest,
		type: inputType,
		ref,
		color: color,
		disabled,
		scale,
	} as any
	return (
		<div className={`w-full flex flex-col items-start ${disabled ? '': ''}`}>
			<Label formField={formField} title={title} errors={errors} color={color} complete={complete}/>
			<Input {...bundle}/>
		</div>
	)
})

import { MarkerF } from '@react-google-maps/api'
import * as React from 'react'
import { getMeIcon } from '../components/icons.ts'

interface Props {
    color: string
    position: google.maps.LatLng | null
}

export const MyMarker: React.FC<Props> = (props) => {
    const markerRef = React.useRef(null)
    
    const handleOnLoad = (markerInstance: any) => {
        markerRef.current = markerInstance
    }
    
    React.useEffect(() => {
        if (markerRef.current) {
            // @ts-ignore
            markerRef.current?.setIcon(getMeIcon(props.color))
        }
    }, [props.color])
    
    if (!props.position) {
        return null
    }
    
    return <MarkerF position={props.position} onLoad={handleOnLoad} icon={getMeIcon(props.color)}/>;
}

import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import controlStore from '../../../store/controlStore.ts'
import mapStore from '../../../store/mapStore.ts'

let directionsService: any
let directionsRenderer: any

export const Directions: React.FC = () => {
    const map = mapStore(s => s.map)
    const myPosition = mapStore(s => s.myPosition)
    const directionsTo: google.maps.LatLng | null = mapStore(s => s.directionsTo)
    const travelMode: google.maps.TravelMode = mapStore(s => s.travelMode)
    const [show, setShow] = React.useState(false)

    const [, updateState] = React.useState<any>();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    React.useEffect(() => {
        if (!directionsService) {
            directionsService = new google.maps.DirectionsService()
        }
        if (!directionsRenderer) {
            directionsRenderer = new window.google.maps.DirectionsRenderer()
        }
        return (() => {
            setTimeout(() => onClose())
        })
    }, [])

    React.useEffect(() => {
        if (map) {
            directionsRenderer.setMap(map)
            directionsRenderer.setPanel(document.getElementById('directions-panel'))
        }
    }, [map])

    const doRoute = (myPosition: google.maps.LatLng, directionsTo: google.maps.LatLng) => {
        directionsRenderer.setMap(null)
        if (map) {
            setTimeout(() => {
                directionsService = new google.maps.DirectionsService()
                directionsRenderer = new window.google.maps.DirectionsRenderer()
                directionsRenderer.setMap(map)
                directionsRenderer.setPanel(document.getElementById('directions-panel'))
                setTimeout(() => {
                    directionsRenderer.setMap(map)
                    const listener = window.google.maps.event.addListener(map, 'idle', () => {
                        const zoom = map?.getZoom()
                        mapStore.getState().setZoom(zoom!)
                        window.google.maps.event.removeListener(listener)
                    })
                    directionsService?.route({
                        origin: myPosition,
                        destination: directionsTo,
                        avoidTolls: true,
                        avoidHighways: true,
                        travelMode: travelMode
                    }, function (response: any, status: google.maps.DirectionsStatus) {
                        if (status == google.maps.DirectionsStatus.OK) {
                            if (document.getElementById('directions-panel')) {
                                directionsRenderer.setDirections(response)
                                setShow(true)
                            }
                        } else {
                            window.alert('Directions request failed due to ' + status)
                        }
                    })
                })
            })
        }
    }
    const onClose = () => {
        mapStore.getState().setDirectionsTo(null)
        directionsRenderer.setMap(null)
        setShow(false)
    }

    React.useEffect(() => {
        if (myPosition && directionsTo) {
            directionsRenderer.setMap(null)
            directionsRenderer.setPanel(null)
            forceUpdate()
            doRoute(myPosition, directionsTo)
        }
    }, [myPosition, directionsTo])

    const controls = (
        <div id='directions-header' className={'w-full flex flex-row justify-between -mb-2'}>
            <div className={'prose px-2'}>
                <h4 className={'mb-0'}>{travelMode} Directions</h4>
            </div>
            <div
                className={'px-2 cursor-pointer'}
                onClick={() => onClose()}>
                <FontAwesomeIcon icon={faClose} fontSize={'medium'}/>
            </div>
        </div>
    )
    const isMobile = controlStore(s => s.isOnMobileDevice)
    const size = isMobile ? 'w-[45%] left-54vw' : 'left-[73vw] w-1/4'
    const container = 'absolute top-4 h-1/2 overflow-scroll bg-white rounded border-primary border-solid border-2'
    return (
        <div
             className={`${show ? 'inline-block' : 'hidden'} ${container} ${size}`}
             style={{zIndex: 1}}
        >
            {controls}
        </div>
    )
}

import { faCat, faDog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FemaleIcon from '@mui/icons-material/Female'
import MaleIcon from '@mui/icons-material/Male'
import * as React from 'react'
import { getAge } from '../../helpers/dataHelpers.ts'
import dataStore from '../../store/dataStore.ts'
import { IPet } from '../../interfaces/types.ts'
import { Battery } from './components/Battery.tsx'
import { ControlBar } from './components/ControlBar.tsx'
import { LastEvent } from './components/LastEvent.tsx'
import { LastLocation } from './components/LastLocation.tsx'
import { LastReport } from './components/LastReport.tsx'
import { PetFollowers } from './components/PetFollowers.tsx'
import { PowerSaveStatus } from './components/PowerSaveStatus.tsx'
import { SafeZoneStatus } from './components/SafeZoneStatus.tsx'

interface Props {
    pet: IPet
}

export const DashboardItemDesktop: React.FC<Props> = (props) => {
    const {pet} = props
    const devices = dataStore(s => s.devices)
    const speciesRecord = dataStore.getState().species.find(s => s._id === pet.species)
    const tracker = devices.find(d => d.petId === pet._id)

    const species = speciesRecord?.species_name.toLowerCase() === 'dog' ? <FontAwesomeIcon icon={faDog}/> :
        <FontAwesomeIcon icon={faCat}/>
    const gender = pet.gender.toLowerCase() === 'male' ? <MaleIcon fontSize={'small'}/> :
        <FemaleIcon fontSize={'small'}/>

    const breed = dataStore.getState().breeds?.find(b => b._id === pet.breed)?.breed_name
    const age = getAge(pet.birthDate)

    const petName = (
        <div className={'flex flex-col text-neutral-content px-4 border-l-4 bg-white mt-2'}
             style={{borderColor: pet.iconColor, marginLeft: '4px', marginRight: '4px'}}>
            <div className={'flex flex-row justify-between items-baseline'}>
                <div className={'font-bold text-2xl'}>
                    <div className={'self-start text-left'}>
                        {pet.petName}
                    </div>
                    <div className={'text-xl self-start text-left'}>
                        {breed}
                    </div>
                </div>
                <div className={'self-start text-neutral-content-content opacity-70 text-sm'}>
                    <div className={'flex flex-row justify-end gap-1 items-center'}>
                        {species} {gender}
                    </div>
                    <div className={'flex flex-row justify-end gap-1 items-center'}>
                        {pet.petWeight} lbs
                    </div>
                    <div className={'flex flex-row justify-end gap-1 items-center'}>
                        {age} old
                    </div>
                </div>

            </div>
        </div>
    )
    const noTracker = (
        <div id={'no-tracker'} className={'self-center p-2 text-xl font-semibold'}>
            No Tracker assigned
        </div>
    )

    return (
        <div className={`flex flex-col min-h-[90vh] w-full`}>
            <div className={`w-full`}>
                <img className={'w-full object-cover max-h-[38vh]'} src={pet.imageURL} alt={'pet picture'}/>
            </div>
            {petName}
            <ControlBar pet={pet} tracker={tracker} isMobile={false}/>
            {tracker ?
                <div className={'flex flex-col gap-2 p-2 rounded overflow-y-auto overflow-x-hidden'}>
                    <LastLocation pet={pet}/>
                    <LastReport pet={pet}/>
                    <LastEvent pet={pet}/>
                    <SafeZoneStatus pet={pet}/>
                    <Battery pet={pet} dashboard/>
                    <PowerSaveStatus pet={pet}/>
                    <PetFollowers pet={pet}/>
                </div>
                : noTracker}
        </div>
    )
}

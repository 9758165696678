import axios from 'axios'
import { DateTime, } from 'luxon'
import * as React from 'react'
import { getHistoryByTen } from '../../helpers/historyHelper.ts'
import authStore from '../../store/authStore.ts'
import historyStore from '../../store/historyStore.ts'

export const HistoryEngine: React.FC = () => {
    const startDate = historyStore(s => s.startDate)
    const earliestDate = historyStore(s => s.earliestDate)
    const includeInvalid = historyStore(s => s.includeInvalid)
    const historyPets = historyStore(s => s.historyPets)
    const selectedPetId = historyStore(s => s.selectedPetId)
    // const locations = historyStore(s => s.locations)
    const zone = authStore(s => s.timezone)
    // const pets = dataStore(s => s.pets)

    React.useEffect(() => {
        // if (historyPets.length) {
        //     const getHistory = async () => {
        //         const CancelToken = axios.CancelToken
        //         const source = CancelToken.source()
        //         const iso = DateTime.now().setZone(zone.value).toISO()!
        //         const stateUpdate = await getHistoryByTen(iso, 'reset', historyPets[0], source.token)
        //         historyStore.getState().setStateUpdate(stateUpdate)
        //     }
        //     void getHistory().catch((e) => console.log(e)).then(() => console.log('history set'))
        // }
    }, [historyPets])

    React.useEffect(() => {
        if (earliestDate) {
            const getHistory = async () => {
                const CancelToken = axios.CancelToken
                const source = CancelToken.source()
                const iso = DateTime.now().setZone(zone.value).toISO()!
                const stateUpdate = await getHistoryByTen(iso, 'reset', selectedPetId, source.token)
                historyStore.getState().setStateUpdate(stateUpdate)
            }

            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            if (startDate && historyPets.length) {
                void getHistory()
            }
            return () => {
                // cancel the request before component unmounts
                source.cancel()
            }
        }
    }, [includeInvalid])

    React.useEffect(() => {
        if (selectedPetId) {
            const getHistory = async () => {
                const CancelToken = axios.CancelToken
                const source = CancelToken.source()
                const iso = DateTime.now().setZone(zone.value).toISO()!
                const stateUpdate = await getHistoryByTen(iso, 'reset', selectedPetId, source.token)
                historyStore.getState().setStateUpdate(stateUpdate)
            }

            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            if (startDate && historyPets.length) {
                void getHistory()
            }
            return () => {
                // cancel the request before component unmounts
                source.cancel()
            }
        }
    }, [selectedPetId])

    // const map = historyStore(s => s.map)

    // React.useEffect(() => {
    //     if (map && locations?.length) {
    //         let update: React.ReactNode[] = []
    //         const bounds = new google.maps.LatLngBounds()
    //         const m = locations.map((point: IPoint, i: number) => {
    //             if (point.properties.status) {
    //                 const latLng = new google.maps.LatLng({
    //                     lat: point.properties?.latitude,
    //                     lng: point.properties?.longitude,
    //                 })
    //                 bounds.extend(latLng)
    //                 const pet = pets.find(pet => pet._id === point.properties.itemId)
    //                 return <NumberMarker
    //                     key={`${point.properties.deviceId}-${i}`}
    //                     point={point}
    //                     color={pet.iconColor}
    //                     num={(i % 10) + 1}
    //                     position={latLng}/>
    //             } else return null
    //         }).filter(n => n)
    //         update = update.concat(m)
    //         historyStore.getState().setMarkers(update)
    //         const idleListener = map.addListener("idle", () => {
    //             mapStore.getState().setHistoryZoom(map.getZoom()!)
    //             google.maps.event.removeListener(idleListener)
    //         });
    //         if (update.length) {
    //             map?.fitBounds(bounds, { left: 110, right: 10 })
    //         }
    //     }
    // }, [map, locations])
    return <div/>
}

import * as React from 'react'
import { PropsWithChildren } from 'react'

interface Props {
    children: React.ReactNode
}

export const BlankPageWrapper: React.FC<PropsWithChildren<Props>> = (props) => {

    return (
        <div id="blank-page-wrapper"
             data-theme={'gibi'}
             className={'w-full h-[100vh] bg-base-100 overflow-hidden'}
        >
            {/*<Loader/>*/}
            {props.children}
        </div>
    )
}

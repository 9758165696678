import * as React from 'react'
import { ly } from '../../../../helpers/petHelper.tsx'
import dataStore from '../../../../store/dataStore.ts'
import { RadioGroup } from '../../widgets/RadioGroup.tsx'

interface Props {
    control: any
    errors: any
    onComplete: (b: boolean) => void
    watch: any
    createAccount: boolean
}

export const SelectPlan: React.FC<Props> = (props) => {
    const {control, errors, onComplete, watch, createAccount} = props
    const priceId = watch('priceId')
    const [done, setDone] = React.useState(priceId)

    const plans = dataStore(s => s.plans)

    const planOptions = plans.map(p => {
        const interval = p.interval
        return ({
            value: p.priceID,
            label: <div className={'flex flex-col items-center'}>
                <div className={'text-lg font-semibold'}>${p.productPrice} {ly(interval)}</div>
            </div>,
            meta: p.productDescription,
        })
    })

    // const plan = plans.find(p => p.priceID === priceId)
    if (priceId) {
        if (done !== priceId) {
            setDone(priceId)
        }
    }

    return (
        <div className={`flex flex-col gap-0`}>
            {/*{!priceId ? */}
            <div className={'pb-1 w-full'}>
                <RadioGroup
                    formField={'priceId'}
                    control={control}
                    options={planOptions}
                    title={''}
                    errors={errors}
                />
            </div>
            <button
                className={'btn btn-sm text-primary btn-ghost'}
                disabled={!priceId}
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    onComplete(true)
                }}
            >
                {createAccount ? 'Go to create account' : 'Go to payment'}
            </button>
            {/*    :*/}
            {/*    <div className={'flex flex-col text-xs'}>*/}
            {/*        <div*/}
            {/*            className={'text-lg font-semibold'}>${plan.productPrice} / {plan.productName.split(STUPID_PRODUCT_NAME)[0]}</div>*/}
            {/*        /!*<div className={'text-sm'}>{plan.productName}</div>*!/*/}
            {/*        <div className={'text-xs'}>{plan.productDescription}</div>*/}
            {/*    </div>*/}
            {/*}*/}
        </div>
    )
}

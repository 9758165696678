import * as React from 'react'
import dataStore from '../../../store/dataStore.ts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPersonCircleCheck } from '@fortawesome/free-solid-svg-icons/faPersonCircleCheck'
import { faPersonCircleQuestion } from '@fortawesome/free-solid-svg-icons/faPersonCircleQuestion'
import { faPersonCircleXmark } from '@fortawesome/free-solid-svg-icons/faPersonCircleXmark'
import { useNavigate } from 'react-router-dom'
import { IContact, IPet } from '../../../interfaces/types.ts'
import { RemoveContactButton } from '../../../components/common/buttons/RemoveContactButton.tsx'
import { AddContactButton } from '../../../components/common/buttons/AddContactButton.tsx'
import controlStore from '../../../store/controlStore.ts'
import { EditContactButton } from '../../../components/common/buttons/EditContactButton.tsx'


interface Props {
}

export const smsStatusIcons: Record<string, React.ReactNode> = {
    'Opt-out': <FontAwesomeIcon icon={faPersonCircleXmark} style={{ color: 'hsl(var(--er))' }}/>,
    'Opt-in': <FontAwesomeIcon icon={faPersonCircleCheck} style={{ color: 'hsl(var(--su))' }}/>,
    pending: <FontAwesomeIcon icon={faPersonCircleQuestion} color={'gray'}/>,
}

export const smsStatusLabel: Record<string, string> = {
    'Opt-out': 'Declined',
    'Opt-in': 'Accepted',
    pending: 'Pending acceptance',
}

export const ContactList: React.FC<Props> = () => {
    const contacts = dataStore(s => s.contacts)
    const pets = dataStore(s => s.pets)
    const navigate = useNavigate()
    const isMobile = controlStore(s => s.isOnMobileDevice
    )

    React.useEffect(() => {
        controlStore.getState().setCurrentPage('contacts')
    }, [])

    const header = (
        <div className={'p-4'}>
            <h1 className="text-3xl font-semibold text-center text-primary mb-2">Followers</h1>
        </div>
    )

    const avatar = (p?: IPet) => p ? (
        <div key={p._id + String(Math.random())} className={'flex flex-col items-center p-2'}>
            <div className="avatar">
                <div className="w-8 rounded">
                    <img src={p.imageURL} alt={''}/>
                </div>
            </div>
            <div className={'text-sm font-semibold'}>
                {p.petName}
            </div>
        </div>
    ) : null

    const list = (
        <div className={'flex flex-col gap-4'}>
            {contacts.map((c: IContact, i: number) => (
                <div key={`${c._id}-${i}`}
                     className={'flex flex-col justify-start border-l-primary border-l-4 w-full bg-white px-4'}>

                    <div className={'flex items-center justify-between'}>
                        <div className={'text-lg text-left font-semibold'}>
                            {c.first_name} {c.last_name}
                        </div>

                        <div className={'flex gap-1 items-center'}>
                            <div className={'text-sm'}>
                                {smsStatusLabel[c.smsOptIn]}
                            </div>
                            <div className={''}>
                                {smsStatusIcons[c.smsOptIn]}
                            </div>
                        </div>
                    </div>

                    <div className={'text-left text-sm p-2'}>
                        Following
                    </div>
                    <div className={'flex flex-wrap justify-start w-full rounded-lg bg-primary/5 p-2 border-2 border-primary/10'}>
                        {Array.from(new Set(c.pet_list)).map((id: string) => avatar(pets.find(p => p._id === id)))}
                    </div>

                    <div className={'flex items-center justify-around w-full p-2'}>
                        <EditContactButton contact={c}/>
                        <RemoveContactButton contact={c}/>
                    </div>
                </div>
            ))}
        </div>
    )
    
    const width = !isMobile ? 'w-[32rem]' : ''
    
    return (
        <div className={`${width} h-[90vh] overflow-y-auto`}>
            {header}
            {list}
            <AddContactButton/>
            <input
                type={'button'}
                className={'mt-8 btn btn-ghost text-primary'}
                onClick={() => navigate('/map')}
                value={'Close'}
            />
        </div>
    )
}

import * as React from 'react'
import toast from 'react-hot-toast'
import { unsecurePost } from '../../../helpers/apiHelper.ts'
import { V2_FORGOT_PASSWORD_URL } from '../../../helpers/urls.ts'
import { gibiEmailRegex } from '../../../interfaces/types.ts'
import { AsyncButton } from '../AsyncButton/AsyncButton.tsx'
import { PopupPortal } from '../Popup/PopupPortal.tsx'

interface Props {
    open: boolean
    setOpen: (o: boolean) => void
}

export const ForgotPassword: React.FC<Props> = (props) => {
    const [email, setEmail] = React.useState('')
    const [valid, setValid] = React.useState(false)
    
    const sendCommand = async () => {
        try {
            const requestData = {
                email,
                token: Math.floor(Math.random() * 100000) + 1,
                origin: window.location.origin,
            }
            const response: any = (await unsecurePost(V2_FORGOT_PASSWORD_URL, requestData))!
            if (response.status) {
                toast.success('Check your email for password reset.')
            } else {
                toast.error('Error, password reset not sent. Try again later.')
            }
            setEmail('')
            props.setOpen(false)
        } catch (e) {
            // TODO: handle error
            props.setOpen(false)
        }
    }
    
    const onChange = (e: any) => {
        setEmail(e.target.value)
        if (gibiEmailRegex.test(e.target.value)) {
            setValid(true)
        } else {
            setValid(false)
        }
    }
    
    const cancel = () => {
        props.setOpen(false)
    }
    
    const panelHeader = (
        <div className={'prose flex flex-col justify-start items-center gap-2'}>
            <h2 className={'mt-0 mb-0 text-primary'}>Forgot password</h2>
        </div>
    )
    
    const panel = (
        <div className={'flex flex-col justify-start items-center gap-2 h-fit w-full'}>
            {panelHeader}
            <div className={'prose text-neutral-content flex flex-col justify-start items-center gap-4 w-3/4'}>
                <article>
                    Enter your email, and instructions on how to rest your password will be sent to you
                </article>
                <div className={'flex flex-col justify-start items-center gap-4 w-3/4'}>
                    <input
                        className={'gibi-input w-[28rem]'}
                        onChange={onChange}
                        value={email}
                    />
                </div>
            </div>
        </div>
    )
    
    const buttons = (
        <div className={'p-4 flex justify-between items-center'}>
            <button className={'btn btn-secondary btn-ghost btn-sm'} onClick={cancel}>Dismiss</button>
            <AsyncButton classNames={'btn btn-outlined btn-primary btn-sm'} onClick={sendCommand}
                         disabled={!valid}>Send password reset</AsyncButton>
        </div>
    )
    
    const popUp = (
        <PopupPortal open={props.open}>
            {panel}
            {buttons}
        </PopupPortal>
    )
    
    return (<>
        {popUp}
    </>)
}

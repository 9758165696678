import * as React from 'react'
import { PatternFormat } from 'react-number-format'
import { Controller } from 'react-hook-form'
import { Label } from '../components/Label.tsx'

interface Props {
	control: any
	formField: string
	title: string,
	errors: Record<string, any>
	required?: boolean
	onComplete?: (b: boolean) => void
	hideComplete?: boolean
}

// eslint-disable-next-line react/display-name
export const PhoneNumber: React.FC<Props> = ((props) => {
	const { control, formField, title, errors, required, onComplete, hideComplete } = props
	const [done, setDone] = React.useState(false)

	const rules = required ? {
		required: {
			value: true,
			message: 'Required'
		},
	} : {}

	return (
		<div className={'w-full flex flex-col items-start'}>
			<Label formField={formField} title={title} errors={errors} complete={done && !hideComplete}/>
			<div className={'w-full'}>
				<Controller
					name={formField}
					rules={rules}
					render={({ field }) => <PatternFormat
							defaultValue={field.value?.replace(/\D/g, '')}
							valueIsNumericString={true}
							type="tel"
							format="(###) ###-####"
							mask="_"
							onValueChange={(values) => {
								if (values.value.length === 10) {
									setDone(true)
									onComplete && onComplete(true)
								} else {
									setDone(false)
								}
								// field.onChange(values.formattedValue)
								field.onChange(values.value)
							}}
							onBlur={field.onBlur}
							className={'h-8 border-solid border border-primary/50 rounded w-full px-2 focus-visible:outline-0 text-xl'}
						/>
					}
					control={control}
					/>
				{/*<input id={formField} type={inputType} ref={ref} {...rest} />*/}
			</div>
		</div>
	)
})

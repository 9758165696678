import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { get, put } from '../../helpers/apiHelper.ts'
import { sendCommand } from '../../helpers/commandHelper.ts'
import { STRIPE_GET_SECRET_URL, STRIPE_UPDATE_PAYMENT_URL } from '../../helpers/urls.ts'
import authStore from '../../store/authStore.ts'
import controlStore from '../../store/controlStore.ts'
import dataStore from '../../store/dataStore.ts'
import { FormLayout } from './components/FormLayout.tsx'
import { Section } from './components/Section.tsx'
import { appearance } from './multiStep/stripeElementConfig.ts'
import { ExpirationDate } from './widgets/ExpirationDate.tsx'

const parseExpDate = (exp: string) => {
    if (exp) {
        const bits = exp.split(' ')
        if (bits.length === 3) {
            const my = bits[2].split('/')
            return {
                month: my[0],
                year: my[1]
            }
        }
    }
    return {month: '', year: ''}
}

export const ManagePaymentsForm: React.FC = () => {
    const navigate = useNavigate()
    const paymentMethod = dataStore(s => s.paymentMethod)
    const token = authStore(s => s.token)
    const stripe = useStripe()
    const elements = useElements()

    // @ts-ignore
    elements?.update({appearance})

    const [origExp, setOrigExp] = React.useState({month: '', year: ''})
    const [clientSecret, setClientSecret] = React.useState('')
    const [validClient, setValidClient] = React.useState(false)
    const [newCard, setNewCard] = React.useState(false)
    
    const isMobile = controlStore(s => s.isOnMobileDevice)

    React.useEffect(() => {
        if (paymentMethod) {
            setOrigExp(parseExpDate(paymentMethod))
        }
        return () => setClientSecret('')
    }, [])


    React.useEffect(() => {
        try {
            void get(STRIPE_GET_SECRET_URL).then((d: any) => {
                setClientSecret(d.data?.clientSecret || 'error')
                if (d.data?.clientSecret && d.data?.setClientSecret !== 'error') {
                    setValidClient(true)
                }
            })
        } catch (e) {
            console.log(e)
            controlStore.getState().setShowLoader(false)
        }
    }, [token])

    const {
        formState: {errors},
        watch,
        control,
    } = useForm({
        defaultValues: {
            expiration: parseExpDate(paymentMethod),
        },
        mode: 'onChange',
        reValidateMode: 'onBlur',
    })

    const expiration = watch('expiration')

    const cancel = () => {
        navigate('/' + controlStore.getState().currentPage)
    }

    const createPaymentMethod = async () => {
        if (!stripe || !elements || !clientSecret) {
            return {status: false}
        }
        // Trigger form validation and wallet collection
        const {error: submitError} = await elements.submit()
        if (submitError) {
            // handleError(submitError);
            return
        }
        const eleCard = elements.getElement(CardElement)
        const result = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                // @ts-ignore
                card: eleCard,
                billing_details: {},
            }
        })
        if (result.error) {
            // Display result.error.message in your UI.
        } else {
            // The setup has succeeded. Display a success message and send
            // result.setupIntent.payment_method to your server to save the
            // card to a Customer
            const data = {
                paymentMethod: result.setupIntent.payment_method,
            }
            // const response: any = await put(STRIPE_UPDATE_PAYMENT_URL, data)
            const response: any = await put(STRIPE_UPDATE_PAYMENT_URL, data)
            if (response?.status === 200) {
                if (response.data.paymentMethod) {
                    dataStore.getState().setPaymentMethod(response.data.paymentMethod)
                }
                toast.success('Credit card updated')
            } else {
                toast.error('Error, credit card not updated. Try again later')
            }
            navigate(`/${controlStore.getState().currentPage}`)
        }
    }

    const header = (
        <div className={'pt-2'}>
            <h1 className="text-3xl font-semibold text-center text-primary mb-2">Manage Payments</h1>
        </div>
    )
    
    const width = !isMobile ? 'w-[32rem]' : ''
    
    return (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <div className={`h-full ${width}`}>
            <FormLayout trigger={() => ({}) as Promise<false>} header={header}>
                <div className={'flex flex-col gap-4 h-[90%]'}>
                    <div className={'flex flex-col item-center gap-6'}>

                        <Section title={'Current payment method'}>
                            <div className={'font-semibold text-lg'}>
                                {paymentMethod}
                            </div>
                        </Section>

                        <Section title={'Change expiration date'} disabled={!validClient}>
                            <ExpirationDate
                                control={control}
                                formField={'expiration'}
                                title={''}
                                errors={errors}
                                disabled={!validClient}
                            />

                            <button
                                className={'btn btn-sm text-primary btn-ghost mt-4'}
                                disabled={expiration.month === origExp.month && expiration.year === origExp.year}
                                onClick={async (e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    if (expiration) {
                                        const data = await sendCommand('expiration', '', expiration)
                                        if (typeof data !== 'string') {
                                            dataStore.getState().setPaymentMethod(data.paymentMethod)
                                            toast.success('Expiration date updated')
                                        } else {
                                            toast.error('Error, Expiration date update failed, try again later.')
                                        }
                                        navigate(`/${controlStore.getState().currentPage}`)
                                    }
                                }}
                            >
                                Update Exp Date
                            </button>
                        </Section>

                        <Section title={'Replace current payment method'} disabled={!validClient}>
                            <div className={'bg-white mt-2'}>
                                <CardElement onChange={(event) => {
                                    if (validClient) {
                                        if (event?.complete) {
                                            setNewCard(true)
                                        } else {
                                            setNewCard(true)
                                        }
                                    }
                                }}
                                />
                            </div>
                            <button
                                className={'btn btn-sm text-primary btn-ghost mt-4'}
                                disabled={!newCard}
                                onClick={async (e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    await createPaymentMethod()
                                }}
                            >
                                Update payment method
                            </button>
                        </Section>

                    </div>

                    <input
                        type={'button'}
                        className={'btn btn-ghost text-primary'}
                        onClick={() => cancel()}
                        value={'Close'}
                    />

                </div>

            </FormLayout>
        </div>
    )
}

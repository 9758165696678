import * as React from 'react'
import { IDevice, IPet } from '../../../interfaces/types.ts'
import { AlertSettingsButton } from '../../common/buttons/AlertSettingsButton.tsx'
import { CheckinRateButton } from '../../common/buttons/CheckinRateButton.tsx'
import { EditPetButton } from '../../common/buttons/EditPetButton.tsx'
import { PetEventsButton } from '../../common/buttons/PetEventsButton.tsx'
import { RemovePetButton } from '../../common/buttons/RemovePetButton.tsx'

interface Props {
    pet: IPet
    tracker: IDevice
    isMobile: boolean
}

export const ControlBar: React.FC<Props> = (props) => {
    const {pet, tracker, isMobile} = props
    return (
        <div className={'flex flex-row justify-around items-center mt-2'}>
            <EditPetButton pet={pet} iconOnly={isMobile}/>
            {
                tracker ?
                    <>
                        <PetEventsButton pet={pet} iconOnly={isMobile}/>
                        <CheckinRateButton tracker={tracker} iconOnly={isMobile}/>
                        <AlertSettingsButton pet={pet} iconOnly={isMobile}/>
                    </>
                    : null
            }
            <RemovePetButton pet={pet} iconOnly={isMobile}/>
        </div>
    )
}

import { post, put, remove } from '../helpers/apiHelper.ts'
import { FORM_DATA_URL } from '../helpers/urls.ts'
import { AxiosResponse } from 'axios'
import toast from 'react-hot-toast'

const TypeName: Record<string, string> = {
    'pet': 'Pet',
    'profile': 'Profile',
    'contact': 'Family and Friend',
    'subscription': 'Subscription',
    'homeAddress': 'Home Address',
    'device': 'Tracker',
    'petImage': 'Pet profile picture',
}

export const createFormObject = async (type: string, data: any) => {
    const result: AxiosResponse | null = await post(FORM_DATA_URL, {type, data})
    if (result?.status) {
        toast.success(`${TypeName[type]} saved`)
        return result.data
    } else {
        toast.error(`Error, ${TypeName[type]} not saved, try again later.`)
    }
}

export const updateFormObject = async (type: string, data: any) => {
    const result: AxiosResponse | null = await put(FORM_DATA_URL, {type, data})
    if (result?.status) {
        toast.success(`${TypeName[type]} updated`)
        return result.data
    } else {
        toast.error(`Error, ${TypeName[type]} not updated, try again later.`)
    }
}

export const removeFormObject = async (type: string, data: any) => {
    const result: AxiosResponse | null = await remove(FORM_DATA_URL, {type, data})
    if (result?.status) {
        toast.success(`${TypeName[type]} removed`)
        return result.data
    } else {
        toast.error(`Error, ${TypeName[type]} not removed, try again later.`)
    }
}

// _id: the id of the pet
// petImageKey = the key returned by uploadCropped
export const updatePetImage = async (data: {_id: string, petImageKey: string}) => {
    const result: AxiosResponse | null = await put(FORM_DATA_URL, {type: 'petImage', data})
    if (result?.status) {
        toast.success(`${TypeName.petImage} updated`)
        return result.data
    } else {
        toast.error(`Error, ${TypeName.petImage} not updated, try again later.`)
    }
    //Store result data in data store, updating datastore to rehfresh the pet image
}

import * as React from 'react'
import { deviceFromPet, getPetsWIthDevices } from '../../../helpers/dataHelpers.ts'
import dataStore from '../../../store/dataStore.ts'
import { IPet } from '../../../interfaces/types.ts'
import { FindPetButton } from './FindPetButton.tsx'
import controlStore from '../../../store/controlStore.ts'

interface Props {
    menuAction: (a: IPet) => void
}

export const FindPetMenu: React.FC<Props> = (props) => {
    const petsWithDevices: string[] = getPetsWIthDevices()
    const pets = dataStore.getState().pets.filter(p => petsWithDevices.includes(p._id))
    const isMobile = controlStore(s => s.isOnMobileDevice)

    const instructions =  'This will request an immediate check-in from your pets Gibi Tracker'
    const instructionSize = !isMobile ? 'text-md font-semibold' : 'text-base'

    const label = (
        <div className="prose text-left px-1 pb-4 flex flex-col justify-start gap-0">
            <div>
                <h2 className={'opacity-80 mt-0 mb-0'}>Find your pet</h2>
            </div>
            <div className={`${instructionSize}`}>
                <article className={`p-2 opacity-80`}>
                    {instructions}
                </article>
            </div>
        </div>
    )

    const width = !isMobile ? 'max-w-[65vw]' : 'w-[90vw]'
    return (
        <div
            className={`flex flex-col justify-center item-center ${isMobile?'gap-3':'gap-3 text-lg'} bg-base-100 p-4 pb-8 rounded-md shadow-lg text-primary ${width} max-h-[85vh] overflow-y-auto`}
            data-theme={'gibi'}>
            {label}
            {pets?.map(p => {
                const d = deviceFromPet(p)
                const disabled = !!d.status.deviceCommandInProgressStatus
                return (
                    <div key={p._id} className={'w-full'}>
                        <FindPetButton pet={p} action={props.menuAction} disabled={disabled}/>
                    </div>
                )
            })}
        </div>
    )
}

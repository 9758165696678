import * as React from 'react'
import { backTen, forwardTen } from '../../helpers/historyHelper.ts'
import historyStore from '../../store/historyStore.ts'
import { DateButton } from './components/DateButton.tsx'

interface Props {
}

export const HistoryButtons: React.FC<Props> = () => {
    const startDate = historyStore(s => s.startDate)
    const endDate = historyStore(s => s.endDate)
    const reachedStart = historyStore(s => s.reachedStart)
    const locations = historyStore(s => s.locations)
    const isMovieMode = historyStore(s => s.isMovieMode)

    const controls = (
        <div id={'history-buttons'} className={'flex flex-row w-[100vw] px-8 justify-between'}>
            <DateButton direction={'newer'}
                        reachedStart={reachedStart}
                        historyAvailable={locations.length}
                        backwardTen={async (e) => {
                            const stateUpdate = await backTen(e)
                            historyStore.getState().setStateUpdate(stateUpdate)
                        }}
                        forwardTen={async (e) => {
                            const stateUpdate = await forwardTen(e)
                            historyStore.getState().setStateUpdate(stateUpdate)
                        }}
                        selectingDate={false}
                        date={startDate}
            />
            <DateButton direction={'older'}
                        reachedStart={reachedStart}
                        historyAvailable={locations.length}
                        backwardTen={async (e) => {
                            const stateUpdate = await backTen(e)
                            historyStore.getState().setStateUpdate(stateUpdate)
                        }}
                        forwardTen={async (e) => {
                            const stateUpdate = await forwardTen(e)
                            historyStore.getState().setStateUpdate(stateUpdate)
                        }}
                        selectingDate={false}
                        date={endDate}
            />
        </div>
    )

    return !isMovieMode ? controls : null
}

import React from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import historyStore from '../../../../store/historyStore.ts'
import { LatLng } from '../../types.ts'
import mapStore from '../../../../store/mapStore.ts'
import { createZoomControl } from './zoomControl.ts'
import { createMapTypeControl } from './mapTypeControls.ts'
import controlStore from '../../../../store/controlStore.ts'

const libraries = ['maps', 'geometry', 'places', 'drawing', 'marker', 'routes', 'geocoding']
export const POI_OFF = {
		featureType: "poi",
		stylers: [{ visibility: "off" }],
	}

interface Props {
	height: string
	center: LatLng
	zoom: number
	children?: React.ReactNode
	isHistory?: boolean
}
export function GoogleMapWrapper(props: Props) {
	const {height, center, zoom, isHistory} = props
	const isMobile = controlStore(s => s.isOnMobileDevice)

	const mapOptions = {
		center,
		zoom,
		// we use our own zoom & map type controls, googles look bad
		// mapTypeControl: !isMobile,
		// zoomControl: !isMobile,
		// streetViewControl: !isMobile,
		fullscreenControl: false,
		styles: [POI_OFF],
		disableDefaultUI: true,
		keyboardShortcuts: !isMobile,
		// gestureHandling: 'cooperative',
	}

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
		// @ts-ignore => no good cast for Libraries
		libraries,
	})

	const onLoad = React.useCallback(function callback(map: google.maps.Map) {
		const bounds = new window.google.maps.LatLngBounds(center);
		map.fitBounds(bounds);
		if (!isHistory) {
			if (!mapStore.getState().map) {
				// if (!controlStore.getState().isOnMobileDevice) {
				// 	createZoomControl(map)
				// 	createMapTypeControl(map)
				// }
				// listener to close marker info box when clicking on map
				map.addListener('click',  () => {
					mapStore.getState().setMarkerInfo(null)
				})
			}
			mapStore.getState().setMap(map)
		} else {
			// if (isMobile) {
			// 	const controls: any = map.controls[google.maps.ControlPosition.RIGHT_BOTTOM]
			// 	if (!controls.length) {
			// 		createZoomControl(map)
			// 	}
			// 	// createMapTypeControl(map)
			// }
			historyStore.getState().setMap(map)
		}
		if (!isMobile) {
			const controls: any = map.controls[google.maps.ControlPosition.RIGHT_BOTTOM]
			if (!controls.length) {
				createZoomControl(map)
			}
		}
		createMapTypeControl(map, isHistory)
	}, [])

	const onUnmount = React.useCallback(function callback() {
		if (!isHistory) {
			mapStore.getState().setMap(null)
		} else {
			historyStore.getState().setMap(null)
		}
	}, [])

	const containerStyle = {
		width: '100%',
		height,
	}

	return isLoaded ? (
		<GoogleMap
			mapContainerStyle={containerStyle}
			onLoad={onLoad}
			onUnmount={onUnmount}
			options={mapOptions}
		>
			{props.children}
		</GoogleMap>
	) : <></>
}

export default React.memo(GoogleMapWrapper)

import { DateTime, DateTimeFormatOptions } from 'luxon'
import authStore from '../store/authStore'
import dataStore from '../store/dataStore'
import { IDevice, IDeviceStatus, IPet } from '../interfaces/types'

const eventProps = {
    onCopy: true,
    onCut: true,
    onPaste: true,
    onLoad: true,
    onError: true,
    onWheel: true,
    onScroll: true,
    onCompositionEnd: true,
    onCompositionStart: true,
    onCompositionUpdate: true,
    onKeyDown: true,
    onKeyPress: true,
    onKeyUp: true,
    onFocus: true,
    onBlur: true,
    onChange: true,
    onInput: true,
    onSubmit: true,
    onClick: true,
    onContextMenu: true,
    onDoubleClick: true,
    onDrag: true,
    onDragEnd: true,
    onDragEnter: true,
    onDragExit: true,
    onDragLeave: true,
    onDragOver: true,
    onDragStart: true,
    onDrop: true,
    onMouseDown: true,
    onMouseEnter: true,
    onMouseLeave: true,
    onMouseMove: true,
    onMouseOut: true,
    onMouseOver: true,
    onMouseUp: true,
    onSelect: true,
    onTouchCancel: true,
    onTouchEnd: true,
    onTouchMove: true,
    onTouchStart: true,
    onAnimationStart: true,
    onAnimationEnd: true,
    onAnimationIteration: true,
    onTransitionEnd: true
}

const htmlAttributes = {
    abbr: 'abbr',
    accept: 'accept',
    acceptCharset: 'accept-charset',
    accessKey: 'accesskey',
    action: 'action',
    allowFullScreen: 'allowfullscreen',
    allowTransparency: 'allowtransparency',
    alt: 'alt',
    async: 'async',
    autoComplete: 'autocomplete',
    autoFocus: 'autofocus',
    autoPlay: 'autoplay',
    cellPadding: 'cellpadding',
    cellSpacing: 'cellspacing',
    challenge: 'challenge',
    charset: 'charset',
    checked: 'checked',
    cite: 'cite',
    class: 'class',
    className: 'class',
    cols: 'cols',
    colSpan: 'colspan',
    command: 'command',
    content: 'content',
    contentEditable: 'contenteditable',
    contextMenu: 'contextmenu',
    controls: 'controls',
    coords: 'coords',
    crossOrigin: 'crossorigin',
    data: 'data',
    dateTime: 'datetime',
    default: 'default',
    defer: 'defer',
    dir: 'dir',
    disabled: 'disabled',
    download: 'download',
    draggable: 'draggable',
    dropzone: 'dropzone',
    encType: 'enctype',
    for: 'for',
    form: 'form',
    formAction: 'formaction',
    formEncType: 'formenctype',
    formMethod: 'formmethod',
    formNoValidate: 'formnovalidate',
    formTarget: 'formtarget',
    frameBorder: 'frameBorder',
    headers: 'headers',
    height: 'height',
    hidden: 'hidden',
    high: 'high',
    href: 'href',
    hrefLang: 'hreflang',
    htmlFor: 'for',
    httpEquiv: 'http-equiv',
    icon: 'icon',
    id: 'id',
    inputMode: 'inputmode',
    isMap: 'ismap',
    itemId: 'itemid',
    itemProp: 'itemprop',
    itemRef: 'itemref',
    itemScope: 'itemscope',
    itemType: 'itemtype',
    kind: 'kind',
    label: 'label',
    lang: 'lang',
    list: 'list',
    loop: 'loop',
    manifest: 'manifest',
    max: 'max',
    maxLength: 'maxlength',
    media: 'media',
    mediaGroup: 'mediagroup',
    method: 'method',
    min: 'min',
    minLength: 'minlength',
    multiple: 'multiple',
    muted: 'muted',
    name: 'name',
    noValidate: 'novalidate',
    open: 'open',
    optimum: 'optimum',
    pattern: 'pattern',
    ping: 'ping',
    placeholder: 'placeholder',
    poster: 'poster',
    preload: 'preload',
    radioGroup: 'radiogroup',
    readOnly: 'readonly',
    rel: 'rel',
    required: 'required',
    role: 'role',
    rows: 'rows',
    rowSpan: 'rowspan',
    sandbox: 'sandbox',
    scope: 'scope',
    scoped: 'scoped',
    scrolling: 'scrolling',
    seamless: 'seamless',
    selected: 'selected',
    shape: 'shape',
    size: 'size',
    sizes: 'sizes',
    sortable: 'sortable',
    span: 'span',
    spellCheck: 'spellcheck',
    src: 'src',
    srcDoc: 'srcdoc',
    srcSet: 'srcset',
    start: 'start',
    step: 'step',
    style: 'style',
    tabIndex: 'tabindex',
    target: 'target',
    title: 'title',
    translate: 'translate',
    type: 'type',
    typeMustMatch: 'typemustmatch',
    useMap: 'usemap',
    value: 'value',
    width: 'width',
    wmode: 'wmode',
    wrap: 'wrap'
}

const DATETIME_MED_WITH_WEEKDAY: DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
}

function isValidDOMProp(prop: any) {
    // @ts-ignore
    return eventProps[prop] || htmlAttributes[prop] || /^(data|aria)-/.test(prop)
}

export const filterInvalidDOMProps = (props: any) => {
    const domProps: any = {}
    for (const prop in props) {
        // eslint-disable-next-line no-prototype-builtins
        if (props.hasOwnProperty(prop) && isValidDOMProp(prop)) {
            domProps[prop] = props[prop]
        }
    }
    return domProps
}
export const deepMergeObject = (targetObject = {}, sourceObject = {}) => {
    // clone the source and target objects to avoid the mutation
    const copyTargetObject = JSON.parse(JSON.stringify(targetObject))
    const copySourceObject = JSON.parse(JSON.stringify(sourceObject))
    // Iterating through all the keys of source object
    copySourceObject && Object.keys(copySourceObject).forEach((key) => {
        if (typeof copySourceObject[key] === 'object' && !Array.isArray(copySourceObject[key])) {
            // If property has nested object, call the function recursively
            copyTargetObject[key] = deepMergeObject(
                copyTargetObject[key],
                copySourceObject[key]
            )
        } else {
            // else merge the object source to target
            copyTargetObject[key] = copySourceObject[key]
        }
    })

    return copyTargetObject
}

export const getMostRecent = (d: IDevice) => {
    const mostRecentLocation = d?.lastLocation
    const mostRecentEvent = d?.lastEvent

    const source = mostRecentLocation?.properties.status ? mostRecentLocation?.properties?.gibiMessageType :
        d.lastLocation?.properties?.gibiMessageType
    const valid = mostRecentLocation?.properties.status ? mostRecentLocation : d.lastLocation


    const locationDate = valid ? DateTime.fromSeconds(valid.properties?.timeStamp).setZone(authStore.getState().timezone.value) : null
    const eventDate: DateTime | null = mostRecentEvent ? DateTime.fromISO(mostRecentEvent.createdAt).setZone(authStore.getState().timezone.value) : null

    // get last contact date
    let lastContactDate: DateTime | string | null = locationDate === null && eventDate === null ? null :
        locationDate === null ? eventDate :
            eventDate === null ? locationDate :
                locationDate < eventDate ? eventDate : locationDate

    lastContactDate = lastContactDate ? lastContactDate.toLocaleString(DATETIME_MED_WITH_WEEKDAY) : 'unknown'

    let batteryDate = ''
    let batteryPercent = ''
    let deviceStatus: IDeviceStatus = {} as IDeviceStatus
    if (mostRecentEvent) {
        if (mostRecentLocation) {
            batteryDate = locationDate?.toLocaleString(DATETIME_MED_WITH_WEEKDAY) || ''
            batteryPercent = mostRecentLocation.properties.batteryPercent!
            deviceStatus = mostRecentEvent.deviceUpdate?.deviceStatus || {} as IDeviceStatus
        } else {
            batteryDate = eventDate!.toLocaleString(DATETIME_MED_WITH_WEEKDAY)!
            batteryPercent = mostRecentEvent.deviceUpdate?.deviceStatus.batteryPercent || '--'
            deviceStatus = mostRecentEvent.deviceUpdate?.deviceStatus || {} as IDeviceStatus
        }
    } else if (mostRecentLocation) {
        batteryDate = locationDate?.toLocaleString(DATETIME_MED_WITH_WEEKDAY) || ''
        batteryPercent = mostRecentLocation.properties.batteryPercent || '--'
        deviceStatus = d.status
    }

    return {
        lastContactDate,
        batteryDate,
        batteryPercent,
        deviceStatus,
        locationDate: locationDate?.toLocaleString(DATETIME_MED_WITH_WEEKDAY),
        locationDateMs: locationDate?.toMillis(),
        lastLocation: valid,
        positionSource: source,
    }
}

export const petFromDevice = (device: IDevice): IPet => {
    return dataStore.getState().pets.find(p => p._id == device.petId)
}

export const deviceFromPet = (pet: IPet): IDevice => {
    return dataStore.getState().devices.find(d => d.petId == pet._id)
}

export const ago = (ms: number) => {

    let ago = Math.floor(ms / 1000)
    let part = 0

    if (ago < 2) {
        return 'a moment ago'
    }
    if (ago < 5) {
        return 'moments ago'
    }
    if (ago < 60) {
        return ago + ' seconds ago'
    }

    if (ago < 120) {
        return 'a minute ago'
    }
    if (ago < 3600) {
        while (ago >= 60) {
            ago -= 60
            part += 1
        }
        return part + ' minutes ago'
    }

    if (ago < 7200) {
        return 'an hour ago'
    }
    if (ago < 86400) {
        while (ago >= 3600) {
            ago -= 3600
            part += 1
        }
        return part + ' hours ago'
    }

    if (ago < 172800) {
        return 'a day ago'
    }
    if (ago < 604800) {
        while (ago >= 172800) {
            ago -= 172800
            part += 1
        }
        return part + ' days ago'
    }

    if (ago < 1209600) {
        return 'a week ago'
    }
    if (ago < 2592000) {
        while (ago >= 604800) {
            ago -= 604800
            part += 1
        }
        return part + ' weeks ago'
    }

    if (ago < 5184000) {
        return 'a month ago'
    }
    if (ago < 31536000) {
        while (ago >= 2592000) {
            ago -= 2592000
            part += 1
        }
        return part + ' months ago'
    }

    if (ago < 1419120000) { // 45 years, approximately the epoch
        return 'more than year ago'
    }

    // TODO pass in Date.now() and ms to check for 0 as never
    return 'never'
}

export const calculateAge = (date: string) => {
    const dob = new Date(date)
    const month_diff = Date.now() - dob.getTime()
    const age_dt = new Date(month_diff)
    const year = age_dt.getUTCFullYear()
    return Math.abs(year - 1970)
    // const bd = DateTime.fromISO(date)
    // const currentDate = DateTime.now();
    // const difference = currentDate.toMillis() - bd.toMillis();
    // const currentAge = Math.floor(difference / 31557600000)
    // // dividing by 1000*60*60*24*365.25
    // return currentAge
}

export const getAge = (fromdate: string, desktop?: boolean) => {
    const todate = new Date()

    const fromDate = new Date(fromdate)
    const age = [],
        y = [todate.getFullYear(), fromDate.getFullYear()],
        m = [todate.getMonth(), fromDate.getMonth()],
        d = [todate.getDate(), fromDate.getDate()]

    let mdiff = m[0] - m[1],
        ydiff = y[0] - y[1]
    const ddiff = d[0] - d[1]

    if (mdiff < 0 || (mdiff === 0 && ddiff < 0)) --ydiff
    if (mdiff < 0) mdiff += 12
    if (ddiff < 0) {
        fromDate.setMonth(m[1] + 1, 0)
        // ddiff = fromDate.getDate() - d[1] + d[0]
        --mdiff
    }
    const months = desktop ? ` month${mdiff > 1 ? 's' : ''}` : 'm'
    if (ydiff > 0) age.push(ydiff + ' year' + (ydiff > 1 ? 's ' : ''))
    if (mdiff > 0) age.push(mdiff + months)
    if (!age.length) {
        const days = Math.abs(ddiff)
        age.push(days+ ' day'+(days> 1? 's':''));
    }
    if (age.length > 1) age.splice(age.length - 1, 0, ' ')
    return age.join('')
}

export const deepEqual = (object1: any, object2: any) => {
    const keys1 = Object.keys(object1 || {}) || []
    const keys2 = Object.keys(object2 || {}) || []

    if (keys1.length !== keys2.length) {
        return false
    }

    for (const key of keys1) {
        const val1 = object1[key]
        const val2 = object2[key]
        const areObjects = isObject(val1) && isObject(val2)
        if (
            areObjects && !deepEqual(val1, val2) ||
            !areObjects && val1 !== val2
        ) {
            return false
        }
    }

    return true
}

function isObject(object: any) {
    return object != null && typeof object === 'object'
}

export const getPetLastLocation = (pet: IPet) => {
    const device = deviceFromPet(pet)
    return device.lastLocation
}

export const isPetOn = (pet: IPet) => {
    const device = deviceFromPet(pet)
    return device.status.powerOn
}

export const getPetsWIthDevices = () => {
    const devices = dataStore.getState().devices
    const pets = dataStore.getState().pets
    return pets.map(p => devices.find(d => p._id === d.petId)).filter(n => n).map(d => d.petId)
}

export const petNameImageFromId = (petIds: string[]) => {
    const pets = dataStore.getState().pets
    return pets.filter(p => petIds.includes(p._id)).map(p => ({ name: p.petName, img: p.imageURL }))
}

export const renewsOn = (timeZone: { value: any }, startDate: string, planType: string) => {
    let renews
    if (planType.toLowerCase().includes('month')) {
        const monthsSinceStart = DateTime.fromISO(startDate).diffNow('months').months
        let increment
        if (monthsSinceStart < 1) {
            increment = Math.ceil(Math.abs(monthsSinceStart))
        } else {
            increment = Math.ceil(monthsSinceStart)
        }
        renews = DateTime.fromISO(startDate)
            .setZone(timeZone.value)
            .plus({ month: increment })
            .toLocaleString(DateTime.DATE_MED)
    } else {
        const yearsSinceStart = DateTime.fromISO(startDate).diffNow('years').years
        let increment
        if (yearsSinceStart < 1) {
            increment = Math.ceil(Math.abs(yearsSinceStart))
        } else {
            increment = Math.ceil(yearsSinceStart)
        }
        renews = DateTime.fromISO(startDate)
            .setZone(timeZone.value)
            .plus({ year: increment })
            .toLocaleString(DateTime.DATE_MED)
    }
    return renews
}

// pretty simple complicated as we're dealing with the same object type
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
type Accumulator<T> = { [k in keyof T]?: T[k] | any }

export const deepCompare = <T extends object>(oldFilter: T, newFilter: T): Accumulator<T> => {
    const traverse = <O>(obj: O, filter: O, target: Accumulator<O> = {}): Accumulator<O> => {
        // @ts-ignore
        for (let k in obj)
            if (obj[k] instanceof Object && filter[k]) {

                target[k] = {}     // 4. O[k] for the next prop type
                const targetResult = traverse<O[typeof k]>(obj[k], filter[k], target[k])

                // delete empty entries if so desired
                if (!Object.keys(targetResult).length)
                    delete target[k]
            } else if (obj[k] !== filter[k])
                target[k] = obj[k] // store value

        return target
    }

    // 2. still T on first recursion call
    return traverse<T>(oldFilter, newFilter)
}

export const shapeAddress = (formData: any) => ({
    firstName: formData.firstName,
    lastName: formData.lastName,
    mobile: formData.mobile,
    address1: formData.addr1,
    address2: formData.addr2,
    city: formData.city,
    state: formData.state,
    postalCode: formData.postalCode,
    country: formData.country,
    timeZone: formData.timeZone,
    smsOptIn: formData.smsOptIn,
})

export const readableSeconds = (sec: number) => {
    const label = sec < 60 ? 'seconds' : sec === 60 ? 'minute' : sec/60 < 60 ? 'minutes' : 'hour'
    const val = sec < 60 ? sec : sec / 60 < 60 ? sec / 60 : 1
    return `${val} ${label}`
}

import { faClose } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime } from 'luxon'
import * as React from 'react'
import { clearFindState, getMyFindState } from '../../../interfaces/findState.ts'
import { IPet } from '../../../interfaces/types.ts'
import authStore from '../../../store/authStore.ts'
import dataStore from '../../../store/dataStore.ts'
import findStore from '../../../store/findStore.ts'

interface Props {
    pet: IPet
    action: (p: IPet) => void
    disabled: boolean
}

export const FindPetButton: React.FC<Props> = (props) => {
    const { pet, action, disabled } = props
    const [count, setCount] = React.useState(0)
    const [otherCmdInProgress, setOtherCmdInProgress] = React.useState(false)
    const [toggle, setToggle] = React.useState(false)
    // const isMobile = controlStore(s => s.isOnMobileDevice)

    const devices = dataStore(s => s.devices)
    const inProgress = findStore(s => s.inProgress)
    const findStatus = findStore(s => s.status)

    React.useEffect(() => {
        if (devices) {
            const d = devices.find(d => d.petId === pet._id)
            if (d?.status.deviceCommandInProgressStatus && !d?.status.deviceCommandInProgressStatus.includes(('FIND'))) {
                setOtherCmdInProgress(true)
            } else {
                setOtherCmdInProgress(false)
            }
        }
    }, [devices])

    const hasDevice = devices.find(d => d.petId === pet._id)
    if (hasDevice) {
        if (hasDevice.status.deviceCommandInProgressStatus && !findStore.getState().inProgress[pet._id] && !findStore.getState().status[pet._id]) {
            if (hasDevice.status.deviceCommandInProgressStatus.includes('FIND')) {
                // console.log('hasDevice - setting progress', hasDevice.status.deviceCommandInProgressStatus, findStore.getState().inProgress)
                findStore.getState().setInProgress(pet._id, true)
            }
        }
    }

    const waitOneSec = () => {
        setTimeout(() => {
            setCount(count + 1)
        }, 1000)
    }

    const clearStatus = () => {
        clearFindState(pet)
        setToggle(!toggle)
    }
    
    React.useEffect(() => {
        if (inProgress[pet._id] === undefined) return
        if (!inProgress[pet._id]) setCount(0)
        // console.log('inprogress', pet._id, inProgress[pet._id], findStatus[pet._id])
    }, [inProgress, findStatus])

    React.useEffect(() => {
        if (inProgress[pet._id] === undefined) return
        if (!findStore.getState().inProgress[pet._id]) {
            setCount(0)
        } else if (count && count < 300) {
            waitOneSec()
        } else if (count) {
            setCount(0)
            // findStore.getState().setInProgress(pet._id, false)
        }
    }, [count])

    const startFind = () => {
        const date = DateTime.now().setZone(authStore.getState().timezone.value).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
        findStore.getState().setInProgress(pet._id, true)
        findStore.getState().setStatus(pet._id, { msg: 'Find request sent', date })
        waitOneSec()
        action(pet)
    }

    const avatar = (
        <div className="avatar">
            <div className="w-12 rounded">
                <img src={pet.imageURL} alt={'pet image'}/>
            </div>
        </div>
    )

    // const width = !isMobile ? '' : 'w-[90%] min-h-[4rem]'
    // const statusStyle = !isMobile ? 'text-xs' : 'text-lg'
    //
    // const findTitle = inProgress[pet._id] === true ? 'Finding' : 'Find'
    // const button = (
    //     <div className={`btn btn-wide w-[95%] ${width} h-fit p-2 ${!hasDevice ? 'opacity-30' : 'opacity-80'}`}
    //          style={{ backgroundColor: pet.iconColor }}
    //          onClick={() => hasDevice && !disabled && !otherCmdInProgress && startFind()}
    //     >
    //         <div className={'flex flex-row justify-between items-center w-full'}>
    //             <div className={'flex flex-row justify-start items-center gap-1'}>
    //                 {avatar}
    //                 <div className={`${textColor}`}>{findTitle} {pet.petName}</div>
    //             </div>
    //             {inProgress[pet._id] === true ? <FontAwesomeIcon icon={faBinoculars} beat/> :
    //                 <div className={'w-6'}/>}
    //         </div>
    //         {!otherCmdInProgress && inProgress[pet._id] ?
    //             <progress className="progress progress-primary w-50" value={count} max="300"/> : null
    //         }
    //         {findStatus[pet._id]?.msg && findStatus[pet._id]?.date ?
    //             <div className={'flex flex-col gap-1'}>
    //                 <div className={`${textColor} opacity-100 ${statusStyle} normal-case`}>
    //                     {findStatus[pet._id].msg}
    //                 </div>
    //                 <div className={`${textColor} opacity-100 ${statusStyle} normal-case`}>
    //                     {findStatus[pet._id].date}
    //                 </div>
    //             </div>
    //             : null}
    //         {otherCmdInProgress ? <div className={'text-xs center'}> Another Tracker command is in progress</div> : null}
    //     </div>
    // )

    //////////////////////////////

    const petName = (
        <div className={'flex flex-row justify-between items-center w-full pb-0.5'}>
            <div className={'flex flex-row justify-start items-center gap-2'}>
                {avatar}
                <div className={`text-primary-content text-xl font-semibold`}>{pet.petName}</div>
            </div>
        </div>
    )

    const lastLocal = getMyFindState(pet)

    const lastFind = lastLocal?.requestDate && lastLocal?.responseDate ? (
        <div className={`text-primary-content text-md flex flex-col w-full m-l-auto border-t-2 border-t-primary-content/50`}>
            <div className={'font-semibold'}>
                Last request {lastLocal.responseDate}
            </div>
            <div className={'flex items-center gap-2 mx-auto'}>
                <div>{lastLocal.success ? <FontAwesomeIcon icon={faCircleCheck} color={'green'}/> : <FontAwesomeIcon icon={faTriangleExclamation} color={'red'}/>} </div>
                <div>{lastLocal.responseStatus}</div>
            </div>
        </div>
    ) : null

    const activeFind = lastLocal?.requestDate && !lastLocal?.responseDate ? (
        <div className={`text-primary-content text-md flex flex-col w-full mx-auto border-t-2 border-t-primary-content/50`}>
            <div>{lastLocal.requestDate}</div>
            <div> Find in progress </div>
        </div>
    ) : null

    const findButton = (
        <div onClick={() => hasDevice && !disabled && !otherCmdInProgress && startFind()}>
            <button className={'btn btn-secondary'} disabled={disabled || otherCmdInProgress}>Find {pet.petName}</button>
        </div>
    )

    const panel = lastFind || activeFind

    return (
        <div className={'flex flex-col gap-2 rounded bg-primary/30 p-2 px-4'}>
            <div className={'flex flex-col rounded p-2 bg-primary text-primary-content'}>
                {petName}
                <div className={'w-full flex'}>
                    {panel}
                    {!!panel ? <div className={'p-1 cursor-pointer border-t-2 border-t-primary-content/50'} onClick={clearStatus}>
                        <FontAwesomeIcon icon={faClose} fontSize={'medium'} size={'lg'}/>
                    </div> : null}
                </div>
            </div>
            {findButton}
        </div>
    )
}

import { GroupBase, StylesConfig } from "react-select";
import { Option } from "../../../../../interfaces/Option.interface.ts";

export const selectStyles:StylesConfig<Option, boolean, GroupBase<Option>> = {
    option: (provided, /*state*/) => ({
      ...provided,
        color: 'hsl(var(--bc))',
    }),
    control: (provided) => ({
        ...provided,
        borderRadius: '0.25rem',
        height: '3rem',
        width: '100%',
        background: 'hsl(var(--b1) / var(--tw-bg-opacity));',
        borderWidth: '1px',
        borderColor: 'hsl(var(--bc) / var(--tw-border-opacity))',
        '--tw-border-opacity': '0.2',
        color: 'hsl(var(--bc))',
    }),
    placeholder: (provided) => ({
       ...provided,
        height: '2rem',
        marginTop: '0.75rem'
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
    multiValue: (styles/*, { data }*/) => ({
        ...styles,
        backgroundColor: "#560DF8",
        color: "white",
    }),
    multiValueLabel: (styles/*, { data }*/) => ({
        ...styles,
        color: "white",
      }),
    menu: (provided) => ({
        ...provided,
        zIndex: 20,
    })
  }

import VisibilityOffSharpIcon from '@mui/icons-material/VisibilityOffSharp'
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp'
import { useCallback } from 'react'
import * as React from 'react'

interface Props {
    placeholder: string
    value: string
    onChange: (v: string) => void
}

export const PasswordInput: React.FC<Props> = (props) => {
    const {
        placeholder,
        value,
        onChange,
    } = props
    
    const [showPassword, setShowPassword] = React.useState(false)
    
    let inputValue = value || '';
    
    const _onChange = useCallback(
        (e : React.ChangeEvent<HTMLInputElement>) => {
            e.stopPropagation()
            onChange(e.target.value)
        },
        [onChange]
    );
    
    const passwordControl = (
        <div className={'m-0'}>
            <div className="form-control w-full">
                <div className={'flex justify-between gap-8 align-center relative'}>
                    <input type={showPassword ? 'text' : 'password'}
                           placeholder={placeholder}
                           className="gibi-input w-full text-base"
                           onChange={_onChange}
                           value={inputValue}
                           autoComplete={'off'}
                    />
                    <button
                        className="absolute inset-y-0 right-0 flex items-center px-4 bg-transparent focus:outline-none
                        outline-none hover:border-transparent border-transparent opacity-50"
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            setShowPassword(!showPassword)
                        }}
                    >
                        {!showPassword ? <VisibilitySharpIcon/> : <VisibilityOffSharpIcon/>}
                    </button>
                </div>
            </div>
        </div>
    )
    
    return (
        <>
            {/* attempt to foil chrome autofill */}
            <input type="text" style={{display:'none'}}/>
            <input type="password" style={{display:'none'}}/>
            {passwordControl}
        </>
    );
}

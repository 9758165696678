import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime } from 'luxon'
import * as React from 'react'

interface Props {
    direction: 'older' | 'newer'
    reachedStart: boolean
    historyAvailable: number
    forwardTen: (e: any) => void
    backwardTen: (e: any) => void
    selectingDate: boolean
    date: DateTime
}

export const DateButton: React.FC<Props> = (props) => {
    const {direction, reachedStart, historyAvailable, forwardTen, backwardTen, selectingDate, date} = props

    const disabled = direction === 'older' ? historyAvailable < 10 || selectingDate : reachedStart || !historyAvailable || selectingDate
    const icon = direction === 'older' ? faChevronRight : faChevronLeft
    const isRight = direction === 'older'
    const dateTime = [date.toFormat(`EEE LLL dd`), date.toFormat('hh:mm a')]

    const _back = (e: any) => {
        if (!disabled) {
            backwardTen(e)
        }
    }
    const _forward = (e: any) => {
        if (!disabled) {
            forwardTen(e)
        }
    }
    const onClick = direction === 'older' ? _back : _forward

    const button = (
        <div className={`flex justify-center items-center p-1`}>
            <div className={'btn btn-primary w-full h-fit without-ring normal-case'}
                    onClick={onClick}>

                <div className={'flex flex-row items-center'}>
                    {!isRight ?
                        <div className={`flex flex-col`}  style={{opacity: disabled ? 0.5 : 1}}>
                            <FontAwesomeIcon icon={icon} size={'2xl'}/>
                            <div className={'text-xs'}>
                                {direction.charAt(0).toUpperCase() + direction.slice(1)}
                            </div>
                        </div>
                    : null}

                    <div className={'flex flex-col text-lg'}>
                        <div>{dateTime[0]}</div>
                        <div>{dateTime[1]}</div>
                    </div>

                    {isRight ?
                        <div className={`flex flex-col`} style={{opacity: disabled ? 0.5 : 1}}>
                            <FontAwesomeIcon icon={icon} size={'2xl'}/>
                            <div className={'text-xs'}>
                                {direction.charAt(0).toUpperCase() + direction.slice(1)}
                            </div>
                        </div>
                        : null}
                </div>

            </div>
        </div>
    )

    return button
}

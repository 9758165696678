import * as React from 'react'
import { EventFilter } from './EventFilter.tsx'
import { DATETIME_MED_WITH_WEEKDAY } from './ScrollControl.tsx'
import { DateTime } from 'luxon'
import { IEvent } from '../../interfaces/types.ts'

interface Props {
	setFilters: (f: string[]) => void
	setFiltersChanged: (c: boolean) => void
	startDate: DateTime
	endDate: DateTime
	events: IEvent[]
}

export const EventOptions: React.FC<Props> = (props) => {
	const {setFilters, setFiltersChanged, startDate, endDate, events} = props
	const changeFilters = (update: string[]) => {
		setFiltersChanged(true)
		setFilters(update)
	}

	const style = {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-around',
		width: '100%'
	} as React.CSSProperties

	const dates = (
		<div className={'flex justify-center items-center w-full py-2'}>
			<div className={'dateControl border-y-2 border-neutral-content'} style={style}>
				<div>
					<div className={'font-bold'}>
						{startDate?.toLocaleString(DATETIME_MED_WITH_WEEKDAY)}
					</div>
				</div>
				<div className={'opacity-50'}>to</div>
				<div className={'font-bold'}>
					<div>
						{endDate?.toLocaleString(DATETIME_MED_WITH_WEEKDAY)}
					</div>
				</div>
			</div>
		</div>
	)

    return (
		<div id={'header'} className={'w-full'}>
			<EventFilter onChange={changeFilters} list={events}/>
			{dates}
		</div>
	)
}

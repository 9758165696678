import * as React from 'react'
import mapStore from '../../../store/mapStore.ts'
import { clearRoute, directionsRenderer, doRoute, initDirections } from './directionHelper.tsx'

export const MobileDirections: React.FC = () => {
    const map = mapStore(s => s.map)
    const myPosition = mapStore(s => s.myPosition)
    const directionsTo: google.maps.LatLng | null = mapStore(s => s.directionsTo)
    const travelMode: google.maps.TravelMode = mapStore(s => s.travelMode)

    // const [, updateState] = React.useState<any>()
    // const forceUpdate = React.useCallback(() => updateState({}), [])

    React.useEffect(() => {
        initDirections()
        return (() => {
            setTimeout(() => clearRoute())
        })
    }, [])

    // React.useEffect(() => {
    //     if (map) {
    //         directionsRenderer.setMap(map)
    //         directionsRenderer.setPanel(document.getElementById('directions-panel'))
    //     }
    // }, [map])

    React.useEffect(() => {
        if (myPosition && directionsTo) {
            directionsRenderer.setMap(null)
            directionsRenderer.setPanel(null)
            // forceUpdate()
            doRoute(map, travelMode, myPosition, directionsTo)
        }
    }, [myPosition, directionsTo])

    return null
}

import * as React from 'react'
import dataStore from '../../../../store/dataStore.ts'
import historyStore from '../../../../store/historyStore.ts'
import { IPet, IPoint } from '../../../../interfaces/types.ts'
import { centerMap } from '../../../map/mapHelpers.tsx'
import { HistoryListItem } from './HistoryListItem.tsx'
import { SwitchPet } from './SwitchPet.tsx'

interface Props {
    listRef?: any
}

export const VerticalList: React.FC<Props> = (props) => {
    const locations = historyStore(s => s.locations)
    const selectedPetId = historyStore(s => s.selectedPetId)
    const pets = dataStore(s => s.pets)
    const pet = pets.find(p => p._id === selectedPetId)

    const generateLocationList = (locations: IPoint[], pet: IPet) => {
        return locations.map((location, index) => {
            return <HistoryListItem
                key={location._id}
                location={location}
                pet={pet}
                index={index}
                centerMap={centerMap}
                flex={locations.length < 10 ? 'flex-0.25' : 'flex-1'}
            />
        })
    }

    return (
        <div>
            <div className={'w-[100vw] flex items-center justify-center'}>
                <SwitchPet showName/>
            </div>
            <div className={'flex flex-col justify-between items-center p-2 pb-0 float-left ml-2'} ref={props.listRef}>
                {locations?.length ?
                    <div className={'ml-2 flex flex-col justify-start items-center gap-1 w-full overflow-y-auto'}>
                        {generateLocationList(locations, pet)}
                    </div>
                    :
                    <div className={'mt-12 w-[100vw] overflow-y-hidden'}>
                        <div className={'flex justify-center w-full'}>
                            <div className={'bg-white rounded p-1 text-lg text-accent w-fit text-center'}>
                                No history available
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

import { mdiRun } from '@mdi/js';
import controlStore from '../../../store/controlStore.ts'

export const getPawIcon  = (iconColor: string, google: any) => {
    const isMobile = controlStore.getState().isOnMobileDevice
    return {
        path: "M 281.78125 0 C 280.89861 0.011256107 279.99321 0.05451875 279.09375 0.125 C 243.27015 6.3085 223.57295 44.18935 220.71875 77.59375 C 216.54805 107.90515 229.9058 146.86425 263.1875 153.65625 C 268.015 154.57535 273.0311 154.1553 277.75 152.875 C 317.8269 139.4366 335.76175 91.97195 329.96875 52.65625 C 328.28477 27.263859 309.14316 -0.3489393 281.78125 0 z M 129.90625 1.625 C 107.63131 2.1717807 90.279662 24.7634 86.75 46 C 79.3091 88.0743 98.44834 140.35185 142.28125 153.65625 C 146.38695 154.54915 150.63375 154.6411 154.78125 154 C 184.40765 149.0625 196.95825 115.84615 195.71875 89.03125 C 194.82705 53.65875 176.45285 12.7581 139.71875 2.8125 C 136.36096 1.9220875 133.08838 1.5468885 129.90625 1.625 z M 378.84375 121.125 C 340.30945 123.4304 313.8911 161.87875 310.125 197.78125 C 305.0254 223.67495 318.8299 258.3078 348.375 260.375 C 389.5717 259.8672 417.67605 215.84645 418.84375 177.96875 C 421.44955 152.91745 406.69275 122.5082 378.84375 121.125 z M 41.5625 129.65625 C 25.168497 129.5152 9.0449188 139.33506 3.6875 156 C -10.6052 200.579 18.0964 257.03815 65.3125 266.40625 C 85.0181 269.77835 102.33285 254.6454 107.21875 236.4375 C 117.57335 197.4927 96.3054 152.27225 60.3125 134.59375 C 54.448578 131.30284 47.977544 129.71144 41.5625 129.65625 z M 214.3125 209.59375 C 182.17313 209.66229 149.52974 225.97193 128.71875 250.25 C 106.24135 278.5472 87.8285 311.4765 80.625 347.1875 C 71.8736 372.894 91.70839 402.47795 119.1875 402.65625 C 152.2464 403.57455 180.65825 380.8733 213.53125 379.1875 C 241.42135 374.9358 266.39435 389.4427 291.46875 398.9375 C 312.81805 408.0688 342.31905 404.56945 353.21875 381.59375 C 361.79235 358.17505 349.17285 333.20375 338.71875 312.40625 C 317.39655 278.64925 294.55365 243.17045 259.59375 222.09375 C 245.58084 213.40805 230.00847 209.56028 214.3125 209.59375 z ",
        fillColor: iconColor,
        fillOpacity: 1,
        strokeColor: 'black',
        strokeWeight: 1,
        anchor: new google.maps.Point(240,240),
        scale: !isMobile ? 0.05 : 0.075,
    };
};

export const getMeIcon  = (color: string) => {
    return {
        path: mdiRun,
        fillColor: color,
        fillOpacity: 1,
        strokeColor: color,
    };
};

const numberCircle = (value: string, color: string, textColor: string) =>
    `<svg width="480" height="480" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
 <g class="layer">
  <circle cx="240" cy="240" fill="${color}" id="svg_1" r="240" stroke="#000000" stroke-width="2"/>
  <text fill="${textColor}" font-family="serif" font-size="24" id="svg_3" stroke="#000000" stroke-dasharray="null" stroke-linecap="null" stroke-linejoin="null" stroke-width="0" text-anchor="middle" transform="matrix(18.107 0 0 14.5748 -3058.93 -3238.82)" x="181.5" xml:space="preserve" y="246.5">${value}</text>
 </g>
</svg>`

export const  getNumberCircleIcon = (color: string, num: number, textColor: string) => {
    const icon = numberCircle(String(num), color, textColor)
    return {
        anchor: new google.maps.Point(12, 12),
        url:'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(icon),
        scaledSize: new google.maps.Size(24, 24)
    }
}

export const getNumberCircleSvg = (color: string, num: number, textColor: string) => {
    const icon = numberCircle(String(num), color, textColor)
    return 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(icon)
}

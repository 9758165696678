// @ts-ignore
import React from "react";

const REQUIRED_FIELD_SYMBOL = "*";

export type LabelProps = {
  /** The label for the field */
  label?: string;
  /** A boolean value stating if the field is required */
  required?: boolean;
  /** The id of the input field being labeled */
  id?: string;
};

/** Renders a label for a field
 *
 * @param props - The `LabelProps` for this component
 */
export default function Label(props: LabelProps) {
  const { label, required, id } = props;
  if (!label) {
    return null;
  }
  return (
    <label className="justify-start gap-1 label p-1 absolute -top-[1.5rem] z-10" htmlFor={id}>
      <span className="text-base label-text text-xs opacity-50">{label}</span>
      {required ? <span className="text-error">{REQUIRED_FIELD_SYMBOL}</span> : <span>&nbsp;</span>}
    </label>
  );
}

import * as React from 'react'
import controlStore from '../../store/controlStore.ts'
import historyStore from '../../store/historyStore.ts'
import mapStore from '../../store/mapStore.ts'
import { GoogleMapWrapper, POI_OFF } from '../map/components/GoogleMap/GoogleMapWrapper.tsx'
import { updatedStyles } from '../map/components/GoogleMap/mapStyles.ts'

import '../map/map.css'
import { CHICAGO, DEFAULT_ZOOM } from '../map/types.ts'
 import { HistoryDrawer } from './components/drawer/HistoryDrawer.tsx'
import { HistoryList } from './components/list/HistoryList.tsx'
import { VerticalList } from './components/list/VerticalList.tsx'
import { ClusterMarker } from './components/markers/ClusterMarker.tsx'
import { HighlightMarker } from './components/markers/HighlightMarker.tsx'
import { HistoryControl } from './HistoryControl.tsx'
import { useWindowSize } from "@uidotdev/usehooks";
import { MovieController } from './components/movie/MovieController.tsx'
import { AnimatedMarker } from './components/movie/AnimatedMarker.tsx'

export const HistoryMap: React.FC = () => {
    const [height, setHeight] = React.useState<string | number>('70vh')
    const map = historyStore((s) => s.map)
    const listRef = React.useRef(null)
    const controlRef = React.useRef(null)

    const mapStyle = mapStore(s => s.mapStyle)
    const isMobile = controlStore(s => s.isOnMobileDevice)
    const isMovieMode = historyStore(s => s.isMovieMode)

    const size = useWindowSize();

    const resizeMap = () => {
        let listSize = 0
        let controlSize = 0
        if (listRef?.current) {
            // @ts-ignore
            listSize = listRef.current.clientHeight
        }
        if (controlRef?.current) {
            // @ts-ignore
            controlSize = controlRef.current.clientHeight
        }
        const header = Math.max(Math.round(window.innerHeight * 0.1), 90)
        const h = window.innerHeight - header - listSize - controlSize
        // console.log(h, window.innerHeight, header, listSize, controlSize)
        setHeight(`${h}px`)
    }

    React.useEffect(() => {
        mapStore.getState().setMapContext('history')
        controlStore.getState().setCurrentPage('history')

        return () => {
            historyStore.getState().setLocations([])
            historyStore.getState().setHistoryPerPet({})
            historyStore.getState().setMarkers([])
        }
    }, [])

    React.useEffect(() => {
        if (map) {
            google.maps.event.addListenerOnce(map, 'tilesloaded', function () {
                //this part runs when the mapobject is created and rendered
                // resizeMap()
            })
            // @ts-ignore
            const styles = updatedStyles[mapStyle] ? [...(updatedStyles[mapStyle] as []), POI_OFF] :  updatedStyles.default
            map?.setOptions({styles})
        }
        if (size) {
            resizeMap()
        }
    }, [map, size])

    React.useEffect(() => {
        if (!isMobile) {
            resizeMap()
        }

    }, [isMovieMode])

    const googleMap =/* React.useMemo(() => {
        return*/ (
            <GoogleMapWrapper height='100%' center={CHICAGO} zoom={DEFAULT_ZOOM} isHistory={true}>
                {!isMobile && isMovieMode ? <AnimatedMarker/> : <ClusterMarker/>}
                <HighlightMarker/>
            </GoogleMapWrapper>
        )
    /*}, [])*/

    const historyList = React.useMemo(() => <HistoryList listRef={listRef}/>, [])
    const verticalList = React.useMemo(() =>  <VerticalList listRef={listRef}/>, [])

    const mobileMap = (
        <div>
            <div id={'history-map-mobile'} className={'mainMap relative'}>
                <div className={'absolute z-10 l-50%'}>
                    <HistoryControl controlRef={controlRef} noPets={isMobile} showLabel/>
                </div>
                <div className={'absolute top-[7.5vh] left-0 z-10 w-0 h-0'}>
                    {verticalList}
                </div>
                <div style={{height: '90vh'}}>
                    {googleMap}
                </div>
                <div id={'drawer'} className={'absolute bottom-0 z-[100]'}>
                    <HistoryDrawer/>
                </div>
            </div>
        </div>
    )

    const displayMap = (
        <div>
            <div id={'history-map'} className={'mainMap relative'}>
                {!isMovieMode ? historyList : <MovieController controlRef={listRef} />}
                {!isMovieMode ? <HistoryControl controlRef={controlRef} noPets={isMobile} showLabel/> : null}
                <div style={{height}}>
                    {googleMap}
                </div>
            </div>
        </div>
    )

    return isMobile ? mobileMap : displayMap
}

import * as React from 'react'
import { Suspense } from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { Dashboard } from '../components/dashboard/Dashboard.tsx'
import { ContactListDesktop } from '../forms/v2/lists/ContactListDesktop.tsx'
import { LoginForm } from '../forms/v2/LoginForm.tsx'
import { RegisterUserForm } from '../forms/v2/multiStep/RegisterUserForm.tsx'
import { ManagePaymentsForm } from '../forms/v2/ManagePaymentsForm.tsx'
import { Layout } from '../components/layout/Layout.tsx'
import { ContactForm } from '../forms/v2/ContactForm.tsx'
import { ResetPasswordForm } from '../forms/rjsf/singleForms/ResetPasswordForm/ResetPasswordForm.tsx'
import { SubscriptionList } from '../forms/v2/lists/SubscriptionList.tsx'
import { registrationLoader } from '../handlers/registrationHandler.ts'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { PetForm } from '../forms/v2/PetForm.tsx'
import { ProtectedRoute } from './ProtectedRoute.tsx'
import { gibiStripeOptions } from './stripeElementOptions.ts'
import { PageWrapper } from './PageWrapper.tsx'
import { ProfileForm } from '../forms/v2/ProfileForm.tsx'
import { MainMap } from '../components/map/MainMap/MainMap.tsx'
import { HistoryMap } from '../components/history/HistoryMap.tsx'
import { BlankPageWrapper } from './BlankPageWrapper.tsx'
import { ContactList } from '../forms/v2/lists/ContactList.tsx'
import controlStore from '../store/controlStore.ts'
import { EventViewer } from '../components/events/EventViewer.tsx'
import { TrackerList } from '../forms/v2/lists/TrackerList.tsx'
import { FullPageWrapper } from './FullPageWrapper.tsx'
import { NewTrackerForm } from '../forms/v2/multiStep/NewTrackerForm.tsx'

const stripe = loadStripe(import.meta.env.VITE_STRIPE_PUBLISH_KEY as string)

export const Router: React.FC = () => {
    const isMobile = controlStore(s => s.isOnMobileDevice)

    const routes = [
        {
            path: '/',
            element: <BlankPageWrapper><LoginForm/></BlankPageWrapper>,
        },
        {
            path: '/registration',
            element:
                <Elements stripe={stripe} options={gibiStripeOptions}>
                    <BlankPageWrapper><RegisterUserForm/></BlankPageWrapper>
                </Elements>,
            loader: registrationLoader,
        },
        {
            path: '/reset-password',
            element: <PageWrapper><ResetPasswordForm/></PageWrapper>,
        },
        {
            element: <ProtectedRoute><Layout /></ProtectedRoute>,
            children: [
                {
                    path: "/map",
                    element: <BlankPageWrapper><MainMap/></BlankPageWrapper>,
                },
                {
                    path: "/pets",
                    element: <PageWrapper><PetForm/></PageWrapper>,
                },
                {
                    path: "/dashboard",
                    element: <FullPageWrapper><Dashboard/></FullPageWrapper>,
                },
                {
                    path: "/history",
                    element: <BlankPageWrapper><HistoryMap/></BlankPageWrapper>,
                },
                {
                    path: "/trackers",
                    element: <PageWrapper><TrackerList/></PageWrapper>,
                },
                {
                    path: "/subscriptions",
                    element: <PageWrapper><SubscriptionList/></PageWrapper>,
                },
                {
                    path: "/contacts",
                    element: isMobile ? <PageWrapper><ContactList/></PageWrapper> : <PageWrapper isList><ContactListDesktop/></PageWrapper>,
                },
                {
                    path: "/contactForm",
                    element:<PageWrapper> <ContactForm/></PageWrapper>,
                },
                {
                    path: "/payment",
                    element:
                        <Elements stripe={stripe} options={gibiStripeOptions}>
                            <PageWrapper><ManagePaymentsForm/></PageWrapper>
                        </Elements>,
                },
                {
                    path: '/addTracker',
                    element:
                        <Elements stripe={stripe} options={gibiStripeOptions}>
                            <PageWrapper><NewTrackerForm/></PageWrapper>
                        </Elements>
                },
                {
                    path: "/events",
                    element:<FullPageWrapper> <EventViewer/></FullPageWrapper>,
                },
                {
                    path: "/profile",
                    element:<PageWrapper> <ProfileForm/></PageWrapper>,
                },
                {
                    path: "/basic",
                    element: <Elements stripe={stripe} options={gibiStripeOptions}>
                        <PageWrapper> <NewTrackerForm/></PageWrapper>
                    </Elements>,
                },
                // {
                //     path: "/profile",
                //     children: [
                //         {
                //             index: true,
                //             element: <PageWrapper><ProfileForm /></PageWrapper>,
                //         },
                //         {
                //             path: "usaAddress",
                //             element: <PageWrapper><UsaAddressForm /></PageWrapper>,
                //         },
                //         {
                //             path: "canadaAddress",
                //             element: <PageWrapper><CanadaAddressForm /></PageWrapper>,
                //         }
                //     ]
                // },
                // {
                //     path: "/basic",
                //     element:<PageWrapper> <NewTrackerForm/></PageWrapper>,
                // },
            ],
        },
        {
            path: '*',
            element: <Navigate to='/' replace />
        },
    ]

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
    const router = createBrowserRouter(routes)

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const routeProvider = <RouterProvider router={router}/>

    // const isMobile = window.matchMedia(`(max-width: ${MAX_MOBILE_WIDTH})`)
    // controlStore.getState().setIsOnMobileDevice(isMobile.matches)

    return (
        <Suspense>
            {routeProvider}
        </Suspense>
    )
}

import * as React from 'react'

interface Props {
	children: React.ReactNode
	open: boolean
	menu: React.ReactNode
}

export const MenuDrawer: React.FC<Props> = (props) => {
	const {children, open, menu} = props

	const drawer = (
		<div className="drawer" data-theme={'gibi'}>
			<input id="my-drawer" type="checkbox" className="drawer-toggle" checked={open} onChange={() => undefined}/>
			<div className="drawer-content">
				{children}
			</div>
			<div className="drawer-side z-20 h-[90vh]">
				<div className={'flex justify-center items-center h-full w-full backdrop-blur-sm'}>
					<label htmlFor="my-drawer" aria-label="close sidebar" className="drawer-overlay"></label>
					{menu}
				</div>
			</div>
		</div>
	)

    return drawer
}

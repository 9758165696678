import { IPoint } from '../../../../interfaces/types.ts'
import mapStore from '../../../../store/mapStore.ts'
import movieStore, { MovieState } from '../../../../store/movieStore.ts'
import dataStore from '../../../../store/dataStore.ts'
import { computeHeading } from 'spherical-geometry-js'
import { IconMarker } from '../markers/IconMarker.tsx'
import { DateTime } from 'luxon'
import authStore from '../../../../store/authStore.ts'
import historyStore from '../../../../store/historyStore.ts'
import { post } from '../../../../helpers/apiHelper.ts'
import { HISTORY_BY_TEN_URL } from '../../../../helpers/urls.ts'

export type SearchDirection = 'forward' | 'backward';
const OPACITY_LEVELS = [1, 0.6, 0.31] // if backwards: current => future
const SIZE_LEVELS = [40, 30, 20]
const WINDOW_SIZE = 3

const DELAY = {
    'fast': 50,
    'slow': 200,
}

export const getFrameIndexes = (
    index: number,
    searchDirection: SearchDirection = 'forward',
    locations: any[]
): { startIndex: number, endIndex: number } => {

    let startIndex = 0
    let endIndex = 0
    if (searchDirection === 'backward') {
        startIndex = index
        endIndex = Math.min(locations.length - 1, startIndex + WINDOW_SIZE)
        if (endIndex > locations.length - 1) {
            endIndex = locations.length - 1
        }
    } else {
        // TODO: fix forward
        startIndex = index
        endIndex = Math.max(index - WINDOW_SIZE, 0)
        if (startIndex > endIndex) {
            const t = startIndex
            startIndex = endIndex
            endIndex = t
        }
    }
    return {
        startIndex,
        endIndex,
    }
}

export const renderFrame = (frame: IPoint[], playDirection: SearchDirection) => {
    movieStore.getState().setFrameMarkers([])

    const bounds = new google.maps.LatLngBounds()
    const markers = frame.map((point: IPoint, i: number) => {
        if (point.properties.status) {
            const latLng = new google.maps.LatLng({
                lat: point.properties.latitude,
                lng: point.properties.longitude,
            })
            bounds.extend(latLng)

            // Determine rotation (heading to next point)
            let rotation = 0
            const nextIndex = playDirection === 'forward' ? i - 1 : i + 1

            if (nextIndex >= 0 && nextIndex < frame.length) {
                const nextPoint = frame[nextIndex]
                rotation = computeHeading(
                    {lat: point.properties.latitude, lng: point.properties.longitude},
                    {lat: nextPoint.properties.latitude, lng: nextPoint.properties.longitude}
                )
            }

            // Define opacity levels (oldest = most transparent)
            const size = SIZE_LEVELS[i]
            const opacity = OPACITY_LEVELS[i]
            const color = dataStore.getState().pets.find(p => p._id === point.properties.itemId).iconColor
            //Change index to a random number
            return (
                <IconMarker
                    key={`${point.properties.deviceId}-${i}`}
                    point={point}
                    color={color}
                    num={i}
                    position={latLng}
                    opacity={opacity}
                    rotation={rotation}
                    size={size}
                />
            )
        } else {
            return null
        }
    }).filter(n => n)

    return {
        bounds,
        markers,
    }
}

export const getNextIndex = (index: number, direction: SearchDirection, count: number) => {
    let next = index
    if (direction === 'backward') {
        if (next < count) {
            ++next
        }
    } else {
        if (next > 0) {
            --next
        }
    }
    return next
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

export const playMovieFrame = (direction: 'forward' | 'backward', locations: IPoint[]) => {
    const index = movieStore.getState().playIndex
    const {startIndex, endIndex} = getFrameIndexes(index, direction, locations)

    console.log('index', index, startIndex, 'to', endIndex)
    // backwards only
    const movieFrame = locations.slice(startIndex, endIndex)
    const {bounds, markers} = renderFrame(movieFrame, direction)
    const firstPoint = direction === 'forward' ? movieFrame[0] : movieFrame[movieFrame.length - 1]
    movieStore.getState().setIndexTime(DateTime.fromISO(firstPoint.properties.receivedTime).setZone(authStore.getState().timezone.value))
    const nextIndex = getNextIndex(index, direction, locations.length - 1)
    console.log('nextIndex', nextIndex)

    movieStore.getState().setPlayIndex(nextIndex)
    movieStore.getState().setFrameMarkers(markers)
    if (markers.length) {
        setMapView(bounds)
    }
}

export const setMapViewByIndex = (index: number, locations: IPoint[]) => {
    if (movieStore.getState().playbackView === 'close') {
        const _index = index >= locations.length - 2 ? locations.length - 2 : index
        const view = locations.slice(_index, _index + 2)
        setMapBounds(view)
    } else {
        const _index = index >= locations.length - 11 ? locations.length - 11 : index
        setMapBounds(locations.slice(_index, _index + 10))
    }
}


export const setMapView = (bounds: any) => {
    const map = historyStore.getState().map
    const idleListener = map!.addListener('idle', () => {
        mapStore.getState().setHistoryZoom(map!.getZoom()!)
        google.maps.event.removeListener(idleListener)
    })
    map?.fitBounds(bounds)
}

export const getTenLocations = async (
    start: string,  // ISO date time string
    direction: 'forward' | 'back' | 'init' | 'reset',
    petId?: string
    , cancelToken?: any) => {
    const selectedPet = historyStore.getState().selectedPetId
    const pet = petId || selectedPet
    const includeInvalid = false
    const device = dataStore.getState().devices.find(d => d.petId === pet)

    const apiBundle = {
        pet,
        iccid: device.iccid,
        date: start
    }

    try {
        const res = await post(HISTORY_BY_TEN_URL, {
            petIccid: [apiBundle],
            direction,
            includeInvalid,
        }, cancelToken)
        if (res?.status === 200) {
            const flat = res.data.flat
            const update = {
                locations: flat,
            }
            return update
        }
        return []
    } catch (e) {
        console.log('getHistory error', e)
    }
}

export const setMapBounds = (points: IPoint[]) => {
    const map = historyStore.getState().map
    if (map && points?.length) {
        const bounds = new google.maps.LatLngBounds()
        points.forEach(point => {
            if (point.properties.status) {
                const latLng = new google.maps.LatLng({
                    lat: point.properties.latitude,
                    lng: point.properties.longitude,
                })
                bounds.extend(latLng)
            }
        })
        map?.fitBounds(bounds)
    }
}

export const fetchMovieData = async (direction: 'init' | 'back') => {
    if (direction === 'init') {
        // @ts-ignore
        const ret: { locations: any } | any[] = await getTenLocations(DateTime.now().toISO(), 'init')
        if (ret) {
            // @ts-ignore
            movieStore.getState().setLocations(ret.locations)
            // @ts-ignore
            setMapBounds(ret.locations)
        }
    } else if (direction === 'back') {
        const idx = movieStore.getState().locations.length - 1
        const last = movieStore.getState().locations[idx]
        console.log('last', idx, last.properties.receivedTime)
        // @ts-ignore
        const ret: { locations: any } | any[] = await getTenLocations(last.properties.receivedTime, 'back')
        if (ret) {
            const cur = [...movieStore.getState().locations]
            // @ts-ignore
            const update = cur.concat(ret.locations || [])
            console.log('update', update)
            // @ts-ignore
            movieStore.getState().setLocations(update)
            if (movieStore.getState().playbackView === 'far') {
                // @ts-ignore
                setMapBounds(ret.locations)
            }
        }
    }
}

const moveMarker = async () => {
    const state: MovieState = movieStore.getState()
    let lat = state.animateLat + state.deltaLat
    let lng = state.animateLng + state.deltaLng
    let step = state.curStep + state.step
    while (step < state.distance) {
        movieStore.getState().setAnimatePosition(lat, lng)
        movieStore.getState().setCurStep(step)
        if (movieStore.getState().playForward || movieStore.getState().playReverse) {
            await sleep(DELAY[movieStore.getState().playbackSpeed])
            lat = lat + state.deltaLat
            lng = lng + state.deltaLng
            step = step + state.step
        } else {
            break;
        }
    }
}

export const animateBetweenPoints = async (p1: IPoint, p2: IPoint) => {
    const lat = p1.properties.latitude
    const lng = p1.properties.longitude
    
    const gp1 = new google.maps.LatLng({
        lat: p1.properties.latitude,
        lng: p1.properties.longitude,
    })
    const gp2 = new google.maps.LatLng({
        lat: p2.properties.latitude,
        lng: p2.properties.longitude,
    })
    
    const distance = google.maps.geometry.spherical.computeDistanceBetween(gp1, gp2)
    // const speed = distance / 10
    const step = distance / 150 // (speed * 1000 * delay) / 3600000 // in meters
    // console.log('distance/step', distance, step, Math.round(distance / step))
    const numSteps = Math.floor(distance / step)
    const deltaLat = (p2.properties.latitude - lat) / numSteps
    const deltaLng = (p2.properties.longitude - lng) / numSteps
    const rotation = computeHeading(
        {lat: p1.properties.latitude, lng: p1.properties.longitude},
        {lat: p2.properties.latitude, lng: p2.properties.longitude}
    )
    
    movieStore.getState().setAnimationState({
        animateLat: lat,
        animateLng: lng,
        deltaLat,
        deltaLng,
        step,
        curStep: 0,
        distance,
        // speed,
        rotation,
    })
    if (movieStore.getState().playbackView === 'close') {
        setMapBounds([p1, p2])
    }
    await moveMarker()
    console.log('move done')
}

// export const play = () => {
//     const state = movieStore.getState()
//     const interval = setInterval(() => {
//        moveMarker()
//         if (state.playIndex < state.locations.length - 1) {
//             state.setPlayIndex(state.playIndex + 1)
//         } else {
//             if (state.intervalTimer) {
//                 clearInterval(state.intervalTimer)
//                 state.setIntervalTimer(0)
//             }
//         }
//     }, state.playbackSpeed === 'fast' ? 500 : 1200)
//     state.setIntervalTimer(interval)
// }

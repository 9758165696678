import * as React from 'react'
import dataStore from '../../../../store/dataStore.ts'
import { Alert } from './Alert.tsx'

interface Props {
    selected: string[]
    onChange: (v: { alertId: string, selected: boolean }) => void
}

export const SelectAlerts: React.FC<Props> = (props) => {
    const {selected, onChange} = props
    const alerts = dataStore(s => s.alertTypes)
        .filter(r => r.status)
    const [list, setList] = React.useState(alerts?.map(alert => <Alert key={alert._id} alertLabel={alert.alertType} alertId={alert._id}
                                                                      selected={selected.includes(alert._id)} onClick={onChange}/>))
    
    React.useEffect(() => {
        if (alerts) {
            setList(alerts?.map(alert => <Alert key={alert._id} alertLabel={alert.alertType} alertId={alert._id}
                                                selected={selected.includes(alert._id)} onClick={onChange}/>))
        }
        
    }, [selected])

    return (
        <div className={'grid grid-cols-3 gap-2'}>
            {list}
        </div>
    )
}

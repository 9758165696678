import { useElements, useStripe } from '@stripe/react-stripe-js'
import * as React from 'react'
import dataStore from '../../../../store/dataStore.ts'
import { PromoCode } from '../components/PromoCode.tsx'

import { MIN_MOBILE_HEIGHT } from '../../../../interfaces/types.ts'
import controlStore from '../../../../store/controlStore.ts'
import { Loader } from '../../../../Router/Loader.tsx'
import { payForTracker } from '../../../../helpers/stripeHelper.ts'
import { PatternFormat } from 'react-number-format'


interface Props {
    data: any
    createAccount: boolean
    cancel: () => void
    onDone: (result: {status: boolean, error?: string}) => void
}

export const ConfirmAndPay: React.FC<Props> = (props) => {
    const { data, createAccount, cancel, onDone } = props
    const [agreement, setAgreement] = React.useState<any>(false)
    const [code, setCode] = React.useState({ })
    const [hideButton, setHideButton] = React.useState(false)

    const account = dataStore(s => s.account)
    const showLoader = controlStore(s => s.showLoader)

    const userData = data.useExistingPayment ? {
        name: `${account.firstName} ${account.lastName}`,
        email: account.email,
        firstName: account.firstName,
        lastName: account.lastName,
    } : {
        name: data.address.name,
        email: data.email || account.email,
        firstName : data.address.name.split(' ').slice(0, -1).join(' '),
        lastName : data.address.name.split(' ').slice(-1).join(' '),
        mobile: data.mobile,
    }

    const addressData: any = data.useExistingPayment ? {
        address: {
            ...account.homeAddress,
            line1: account.homeAddress.street_addr || account.homeAddress.address1,
            line2: account.homeAddress.apartment || account.homeAddress.address2,
            state: account.homeAddress.state || account.homeAddress.province,
            postalCode: account.homeAddress.zip_code || account.homeAddress.postal_code,
            city: account.homeAddress.usa_city || account.homeAddress.canada_city
        },
    } : {
        address: {
            ...data.address,
            postalCode: data.address.postal_code
        },
    }

    const stripe = useStripe()
    const elements = useElements()

    const plans = dataStore(s => s.plans)
    const fullPlan = plans.find(p => p.priceID === data.priceId)

    const handleError = (error: any) => {
        // setLoading(false)
        // setErrorMessage(error.message)
        console.log(error.message)
    }

    // eslint-disable-next-line @typescript-eslint/require-await
    const performPurchase = async () => {
        setHideButton(true)
        try {
            controlStore.getState().setShowLoader(true)
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            setTimeout(async () =>{
                const completeData = {
                    ...data,
                    ...userData,
                    ...addressData,
                    ...code,
                }
                const result: {
                    status: boolean,
                    error?: any
                } = await payForTracker(completeData, elements, stripe, handleError, createAccount)
                controlStore.getState().setShowLoader(true)
                onDone(result)
            })
        } catch (e) {
            // @ts-ignore
            onDone({status: false, error: e?.error || e?.message || JSON.stringify(e)})
        }
    }

    const productInformation = (
        <div className={'flex flex-col justify-start items-start text-base-content'}>
            <div className={'text-lg font-bold'}>{fullPlan?.productName}</div>
            <div className={'text-md px-4'}>
                <span className={'font-semibold'}>${fullPlan?.productPrice} per {fullPlan?.interval}</span>
            </div>
            <div className={'text-sm'}>{fullPlan?.productDescription}</div>
        </div>
    )

    const deviceInformation = (
        <div className={'flex flex-col justify-start text-base-content pl-4'}>
            <div className={'text-sm'}> Gibi Tracker ID</div>
            <div className={'text-lg font-bold'}>
                {data?.iccid}
            </div>
        </div>
    )

    const userInformation = (
        <div className={'flex flex-col gap-1 text-md text-base-content'}>
            <div className={'font-bold'}>Register to</div>
            {createAccount ? <div>{userData.name}</div> : null}
            <div>{userData.email}</div>
            {!!userData.mobile ? <PatternFormat format="(###) ###-####" allowEmptyFormatting mask="_" value={userData.mobile}/> : null}
        </div>
    )

    const billingInformation = (
        <div className={'text-md text-base-content pl-4'}>
            <div className={'font-bold'}>Bill to</div>
            {!data.useExistingPayment ? <>
                <div>{userData.name}</div>
                <div>{addressData.address.line1}</div>
                {addressData.address.line2 ? <div>{addressData.address.line2}</div> : null}
                <div className={'flex items-center gap-1'}>
                    <div>
                        {addressData.address.city}
                    </div>
                    <div>
                        {addressData.address.state}
                    </div>
                    <div>
                        {addressData.address.postalCode}
                    </div>
                </div>
            </> :
                <div className={''}>{dataStore.getState().paymentMethod}</div>}

        </div>
    )

    const chargeInfo = (
        <div className={'grid grid-cols-2 w-full -mt-2'}>
            {userInformation}
            {billingInformation}
        </div>
    )

    const legal = 'https://getgibi.com/legal'
    const support = 'https://getgibi.com/legal'
    const isTall = window.matchMedia(`(min-height: ${MIN_MOBILE_HEIGHT})`)

    const disclaimer = (
        <div className={'text-sm text-left text-base-content'}>
            <div className={'px-2'}>
                <p className={`${isTall.matches ? 'mb-4' : 'mb-0'}`}>
                    By placing your order or using Gibi&apos;s subscription plan, you agree to Gibi&apos;s
                    <a href={legal}>Terms of Use</a> and <a href={legal}>Privacy Policy</a>.
                </p>
                <p className={`${isTall.matches ? 'mb-4' : 'mb-0'}`}>
                    You also agree to <a href={legal}>Gibi&apos;s Terms of Service</a>. You authorize us to charge your
                    card when
                    the subscription is attached
                    to a Gibi Tracker in your account. Your subscription will automatically renew and continue until you
                    choose to cancel it.
                </p>
                <p className={`${isTall.matches ? 'mb-4' : 'mb-0'}`}>
                    Cancel anytime by deleting the Gibi Tracker from your account or contacting <a href={support}>Gibi
                    Customer Support</a>.
                    Detailed instructions regarding refunds, if any, are available <a href={legal}>here</a>. We will
                    notify
                    you in advance of any price changes.
                </p>
                <p>
                    For information about Gibi&apos;s Privacy Practices see our <a href={legal}>Privacy Policy</a>.
                </p>
            </div>
        </div>
    )

    const termsOfService = (
        <label className="flex flex-row justify-start items-center gap-3 text-sm text-base-content">
                <span>
                    <input type="checkbox" checked={agreement} className="checkbox checkbox-secondary"
                           onChange={(e) => setAgreement(e.target.checked)}/>
                </span>
            <span>
                    I agree to Gibi Technologies Inc’s Terms of Service and Privacy Policy shown at
                <a
                    href="http://getgibi.com/legal"
                    rel={'noreferrer'}
                    target="_blank"
                    className=""
                > getgibi.com/legal</a>
                </span>
        </label>
    )

    const confirm = (
        <div
            data-theme={'gibi'}
            className={`fixed z-[100000] left-0 top-0 w-[100vw] ${createAccount ? 'h-[100vh]' : 'h-[90vh]'} overflow-auto bg-black/70 flex justify-center items-center`}>

            <div className={'flex flex-col justify-start items-start gap-2 text-left bg-white p-4'}>
                <h2 className={'text-primary text-2xl text-center w-full'}>Confirm and Pay</h2>
                <div className={'divider my-0'}/>

                <div className={'grid grid-cols-2 w-full -mt-2'}>
                    {productInformation}
                    {deviceInformation}
                </div>

                <div className={'divider my-0'}/>
                {chargeInfo}
                <div className={'divider my-0'}/>

                <PromoCode setValue={(code: string) => setCode({ coupon: code })}/>
                <div className={'divider my-0'}/>

                {termsOfService}
                <div className={'flex justify-center items-center w-full'}>
                    <button className={'btn btn-primary btn-wide hover:border-0'} onClick={() => performPurchase()} disabled={!agreement || showLoader || hideButton}>
                        {`Purchase`}
                    </button>
                </div>
                <div className={'divider my-0'}/>

                <div className={''}>
                    {disclaimer}
                </div>
                <Loader className={'absolute -top-[44vh] left-[22vw] z-[100001]'} style={{top: '-44vh'}}/>
                <div>
                    <div className={'btn text-neutral-content btn-ghost'} onClick={cancel}>Cancel</div>
                </div>
            </div>

        </div>
    )

    return confirm
}

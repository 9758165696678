import * as React from 'react'
import { IPet } from '../../../interfaces/types.ts'
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear'
import { ChangeAlertSettings } from '../ChangeAlertSettings/ChangeAlertSettings.tsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import controlStore from '../../../store/controlStore.ts'

interface Props {
    iconOnly?: boolean
    pet: IPet
    textOnly?: boolean
    outline?: boolean
    full?: boolean
}

export const AlertSettingsButton: React.FC<Props> = (props) => {
    const { full, iconOnly, pet, textOnly, outline } = props
    const [open, setOpen] = React.useState(false)
    const isMobile = controlStore(s => s.isOnMobileDevice)

    const button = iconOnly ? (
            <div className={`text-secondary text-xl`} onClick={() => setOpen(true)}>
                <FontAwesomeIcon icon={faGear}/>
            </div>
        ) : outline ? (
        <button className={`btn btn-xs btn-secondary without-ring h-fit ${full ? 'w-full' : ''}`} onClick={() =>  setOpen(true)}>
            Alerts
        </button>
    ) : !textOnly ?
        <div className={`flex flex-col items-center justify-between rounded-lg bg-secondary py-1 px-3 border-white border-2 ${!isMobile ? 'min-h-[64px]' : 'min-h-[55px]'}`}
             onClick={() => setOpen(true)}>
            <button className={`btn btn-xs btn-ghost text-white without-ring h-fit py-0 mt-2`}>
                <FontAwesomeIcon icon={faGear} size={'2xl'}/>
            </button>
            <div className={'text-sm font-semibold text-white'}>
                Alerts
            </div>
        </div>
        : <div className={'font-semibold'}>Alerts</div>

    return (
        <div className={`cursor-pointer ${full ? 'w-full px-2' : ''}`}>
            {button}
            <ChangeAlertSettings pet={pet} open={open} setOpen={setOpen}/>
        </div>
    )
}

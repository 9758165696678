import { create } from 'zustand'
import { DEFAULT_ZOOM } from '../components/map/types.ts'

interface MapState {
    googleLoaded: boolean
    setGoogleLoaded: (b: boolean) => void

    map: google.maps.Map | null
    setMap: (map: google.maps.Map | null) => void

    bounds: google.maps.LatLngBounds | null
    setBounds: (bounds: google.maps.LatLngBounds) => void
    // maps: any  // the google map api
    // setMaps: (maps: any) => void

    google: any  // the google map api
    setGoogle: (maps: any) => void

    circles: any[]
    setCircles: (p: any[]) => void

    mapStyle: string
    setMapStyle: (s: string) => void

    webSocketConnected: boolean
    setWebSocketConnected: (b: boolean) => void

    zoom: number
    setZoom: (z: number) => void

    myPosition: google.maps.LatLng | null
    setMyPosition: (p: google.maps.LatLng) => void

    historyMapHeight: number,
    setHistoryMapHeight: (height: number) => void

    historyZoom: number
    setHistoryZoom: (z: number) => void

    mapContext: 'map' | 'history',
    setMapContext: (c: 'map' | 'history') => void

    markerInfo: string | null,
    setMarkerInfo: (i: string | null) => void

    directionsTo: google.maps.LatLng | null
    setDirectionsTo: (p: google.maps.LatLng | null) => void

    travelMode: google.maps.TravelMode
    setTravelMode: (p: google.maps.TravelMode) => void

    locationEnabled: boolean
    setLocationEnabled: (p: boolean) => void

    reset: () => void
}

// @ts-ignore
const _mapStyle = JSON.parse(localStorage.getItem('mapStyle'))

const useStore = create<MapState>((set) => ({
    googleLoaded: false,
    setGoogleLoaded: (googleLoaded: boolean) =>
        set((state) => ({
            ...state,
            googleLoaded
        })),

    map: null,
    setMap: (map: any) =>
        set((state) => ({
            ...state,
            map
        })),

    maps: null,
    setMaps: (maps: any) =>
        set((state) => ({
            ...state,
            maps
        })),

    bounds: null,
    setBounds: (bounds: google.maps.LatLngBounds) =>
        set((state) => ({
            ...state,
            bounds
        })),

    google: null,
    setGoogle: (google: any) =>
        set((state) => ({
            ...state,
            googleLoaded: !!google,
            google
        })),

    circles: [],
    setCircles: (circles: any[]) =>
        set((state) => ({
            ...state,
            circles
        })),

    mapStyle: _mapStyle || 'default',
    setMapStyle: (mapStyle: string) =>
        set((state) => ({
            ...state,
            mapStyle
        })),

    webSocketConnected: false,
    setWebSocketConnected: (webSocketConnected: boolean) =>
        set((state) => ({
            ...state,
            webSocketConnected
        })),

    zoom: DEFAULT_ZOOM,
    setZoom: (z: number) =>
        set((state) => ({
            ...state,
            zoom: z > 22 ? 22 : z,
        })),

    historyZoom: DEFAULT_ZOOM,
    setHistoryZoom: (historyZoom: number) =>
        set((state) => ({
            ...state,
            historyZoom,
        })),


    myPosition: null,
    setMyPosition: (myPosition: google.maps.LatLng) =>
        set((state) => ({
            ...state,
            myPosition
        })),

    historyMapHeight: 0,
    setHistoryMapHeight: (historyMapHeight: number) =>
        set((state) => ({
            ...state,
            historyMapHeight,
        })),

    mapContext: 'map',
    setMapContext: (mapContext: 'map' | 'history') =>
        set((state) => ({
            ...state,
            mapContext,
        })),

    markerInfo: null,
    setMarkerInfo: (markerInfo: string | null ) =>
        set(state => ({
            ...state,
            markerInfo
        })),

    directionsTo: null,
    setDirectionsTo: (directionsTo: google.maps.LatLng | null) =>
        set((state) => ({
            ...state,
            directionsTo
        })),

    travelMode: 'WALKING' as google.maps.TravelMode,
    setTravelMode: (travelMode: google.maps.TravelMode) =>
        set((state) => ({
            ...state,
            travelMode
        })),

    locationEnabled: true,
    setLocationEnabled: (locationEnabled: boolean) =>
        set((state) => ({
            ...state,
            locationEnabled
        })),

    reset: () =>
        set((state) => ({
            ...state,
            map: null,
            maps: null,
            circles: [],
            mapStyle: 'default',
            zoom: DEFAULT_ZOOM,
            historyZoom: DEFAULT_ZOOM,
            bounds: null,
            historyMapHeight: 0,
            mapContext: 'map',
            markerInfo: null,
            travelMode: google.maps.TravelMode.WALKING,
            directionsTo: null,
        })),
}));

export default useStore;

import './ReloadPrompt.css'
import toast from 'react-hot-toast'

// @ts-ignore
import { useRegisterSW } from 'virtual:pwa-register/react'
// const intervalMS = 60 * 1000


function ReloadPrompt() {
    const {
        offlineReady: [offlineReady, /*setOfflineReady*/],
        needRefresh: [needRefresh, /*setNeedRefresh*/],
        updateServiceWorker,
    } = useRegisterSW({
        onRegisteredSW(swUrl: string, r: any) {
            console.log(`Service Worker at: ${swUrl}`, r)
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            // r && setInterval(async () => {
            //     console.log('Checking for sw update')
            //     // void caches.keys().then(cacheNames => {
            //     //     cacheNames.forEach(cacheName => {
            //     //         void caches.delete(cacheName);
            //     //     });
            //     // });
            //     await r.update()
            // }, intervalMS )
        },
        onRegisterError(error: any) {
            console.log('SW registration error', error)
        },
    })

    // const forceReload = () =>
    //     navigator.serviceWorker
    //         .getRegistrations()
    //         .then((registrations) =>
    //             Promise.all(registrations.map((r) => r.unregister())),
    //         )
    //         .then(() => window.location.reload())

    // const close = () => {
    //     setOfflineReady(false)
    //     setNeedRefresh(false)
    // }

    const doUpdate = async () => {
        // await forceReload()
        void caches.keys().then(cacheNames => {
            cacheNames.forEach(cacheName => {
                void caches.delete(cacheName);
            });
        });
        await updateServiceWorker(true)
    }

    if (offlineReady) {
        toast.success('Gibi app updated')
    }
    return offlineReady ? null : (
        <div className="ReloadPrompt-container">
            { (offlineReady || needRefresh)
                && <div className="ReloadPrompt-toast">
                    <div className="ReloadPrompt-message">
                        <span>Gibi app update available, click on reload button to install.</span>
                    </div>
                    { needRefresh && <button className="ReloadPrompt-toast-button" onClick={() => doUpdate()}>Reload</button> }
                    {/*<button className="ReloadPrompt-toast-button" onClick={() => close()}>Close</button>*/}
                </div>
            }
        </div>
    )
}

export default ReloadPrompt

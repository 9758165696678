import './App.css'
import axios from 'axios'
import React from 'react'
// import { getUserPosition } from './helpers/locationHelper.ts'
import { Router } from './Router/Router.tsx'
import controlStore from './store/controlStore.ts'

function App() {
  console.log('ver. ', import.meta.env.VITE_REACT_APP_VERSION)
  // getUserPosition().then(c => console.log('got position', c)).catch(e => window.alert(JSON.stringify(e)))

  React.useEffect(() => {
    axios.interceptors.request.use(function (config) {
      // spinning start to show
      controlStore.getState().setShowLoader(true)
      return config
    }, function (error) {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      // spinning hide
      controlStore.getState().setShowLoader(false)
      return response;
    }, function (error) {
      return Promise.reject(error);
    });
  },[])

  return (
          <Router/>
  )
}

export default App

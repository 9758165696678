import { DateTime } from 'luxon'
import authStore from '../store/authStore.ts'
import { IPet } from './types.ts'

export interface IFindState {
    id: string
    pet: string
    requestDate: string
    requestError?: string
    waitingOnSystem: boolean
    success?: boolean
    responseDate?: string
    responseStatus?: string
}

export const getFindState = () => {
    const local = localStorage.getItem('find')
    if (local) {
        return JSON.parse(local)
    }
    return []
}
export const getMyFindState = (pet: IPet) => {
    const all = getFindState()
    return all.find((a: IFindState) => a.id === pet._id)
}

export const recordFindRequest = (pet: IPet) => {
    const all = getFindState()
    const idx = all.findIndex((a: IFindState) => a.id === pet._id)
    if (idx >= 0) {
        // update
        all.splice(idx, 1)
    }
    const fs = {
        id: pet._id,
        name: pet.petName,
        requestDate: DateTime.now().setZone(authStore.getState().timezone.value).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY),
        waitingOnSystem: true,
    }
    all.push(fs)
    localStorage.setItem('find', JSON.stringify(all))
    return all
}

export const findIsWaitingOnTracker = (pet: IPet) => {
    let all = getFindState()
    const idx = all.findIndex((a: IFindState) => a.id === pet._id)
    if (idx >= 0) {
        const update = {
            ...all[idx],
            waitingOnSystem: false,
        }
        all = [
            ...all.slice(0, idx),
            update,
            ...all.slice(idx+1, -1),
        ]
    }
    localStorage.setItem('find', JSON.stringify(all))
    return all
}

export const findRequestError = (pet: IPet, error: string) => {
    let all = getFindState()
    const idx = all.findIndex((a: IFindState) => a.id === pet._id)
    if (idx >= 0) {
        const update = {
            ...all[idx],
            waitingOnSystem: false,
            requestError: error,
            success: false
        }
        all = [
            ...all.slice(0, idx),
            update,
            ...all.slice(idx+1, -1),
        ]
    }
    localStorage.setItem('find', JSON.stringify(all))
    return all
}

export const recordFindResponse = (pet: IPet, success: boolean, status: string) => {
    let all = getFindState()
    const idx = all.findIndex((a: IFindState) => a.id === pet._id)
    if (idx >= 0) {
        const update = {
            ...all[idx],
            success,
            responseStatus: status,
            responseDate: DateTime.now().setZone(authStore.getState().timezone.value).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
        }
        all = [
            ...all.slice(0, idx),
            update,
            ...all.slice(idx+1, -1),
        ]
    }
    localStorage.setItem('find', JSON.stringify(all))
    return all
}

export const clearFindState = (pet: IPet) => {
    let all = getFindState()
    const idx = all.findIndex((a: IFindState) => a.id === pet._id)
    if (idx >= 0) {
        all = [
            ...all.slice(0, idx),
            ...all.slice(idx+1, -1),
        ]
    }
    localStorage.setItem('find', JSON.stringify(all))
    return all
}

import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IContact } from '../../../interfaces/types.ts'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan'
import { RemoveContact } from '../RemoveContact/RemoveContact.tsx'

interface Props {
    iconOnly?: boolean
    contact: IContact
    color?: string
}

export const RemoveContactButton: React.FC<Props> = (props) => {
    const {iconOnly, contact, color} = props
    const [open, setOpen] = React.useState(false)

    const textColor = color || 'text-secondary'
    const button = iconOnly ? (
        <div className={`${textColor} text-xl`} onClick={() => setOpen(true)}>
            <div className={'tooltip'} data-tip={`Remove ${contact.last_name}`} onClick={() => setOpen(true)}>
                <FontAwesomeIcon icon={faTrashCan}/>
            </div>
        </div>
        ) :
        <button className={`btn btn-xs btn-ghost ${textColor} without-ring h-fit`}
                onClick={() => setOpen(true)}>
            Remove
        </button>

    return (
        <div>
            {button}
            <RemoveContact contact={contact} open={open} setOpen={setOpen}/>
        </div>
    )
}

import { faSquarePlus } from '@fortawesome/free-solid-svg-icons/faSquarePlus'
import * as React from 'react'
import dataStore from '../../../store/dataStore.ts'
import { centerMapOnZone } from '../helpers/mapHelpers.tsx'
import { removeSafeZone } from '../../../handlers/safeZoneHandler.ts'
import safeZoneStore from '../../../store/safeZoneStore.ts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsToCircle } from '@fortawesome/free-solid-svg-icons/faArrowsToCircle'
import { faPencil } from '@fortawesome/free-solid-svg-icons/faPencil'
import controlStore from '../../../store/controlStore.ts'
import { RemoveSafeZonePopup } from '../../common/RemoveSafeZonePopup.tsx'

interface Props {
    map: google.maps.Map
}

export const SafeZoneList: React.FC<Props> = (props) => {
    const {map} = props
    const safeZones = dataStore(s => s.safeZones)
    const isMobile = controlStore(s => s.isOnMobileDevice)

    const iconSize = isMobile ? '2x' : '1x'

    const onEdit = (id: string) => {
        const coords = safeZones.find(s => s._id === id)?.zone_coordinates.slice(0)
        safeZoneStore.getState().setUpdatedZonePath(coords || [])
        safeZoneStore.getState().setInstructions(null)
        safeZoneStore.getState().setSafeZoneBeingEdited(id)
        safeZoneStore.getState().setView('finish')
    }

    const onRemove = async (id: string) => {
        const zones = await removeSafeZone(id)
        if (zones) {
            safeZoneStore.getState().setSafeZonePolygons([])
            setTimeout(() => {
                dataStore.getState().setSafeZones(zones)
            })
        }
    }

    const zoneList = safeZones.length ? safeZones?.map((sz, i) => {
        return (
            <div key={sz._id} className={`flex justify-between gap-1 items-center flex-1 w-full p-2 ${!isMobile ? 'w-full' : 'w-fit'} ${!(i%2) ? 'bg-primary text-primary-content':'bg-primary-content text-primary'}`}>
                <div className={'text-lg font-semibold w-[50%] shrink-0 text-left'}>
                    {sz.zone_name}
                </div>

                {!isMobile ? <div className={'h-1 w-[20rem]'}/> : null}

                <div className={'flex flex-row gap-4 items-center justify-around w-[50%]'}>
                    <div className={'flex justify-center items-center'}>
                        <div className={`${!isMobile ? 'tooltip tooltip-left' : ''}`} data-tip={`Center map on ${sz.zone_name}`}
                             onClick={() => centerMapOnZone(map, sz)}>
                            <FontAwesomeIcon icon={faArrowsToCircle} size={iconSize}/>
                        </div>
                    </div>

                    <div className={'flex justify-center items-center'}>
                        <div className={`${!isMobile ? 'tooltip tooltip-left' : ''}`} data-tip={`Edit ${sz.zone_name}`} onClick={() => onEdit(sz._id)}>
                            <FontAwesomeIcon icon={faPencil} size={iconSize}/>
                        </div>
                    </div>

                    <div className={'flex justify-center items-center'}>
                        <RemoveSafeZonePopup safeZone={sz} onRemove={onRemove}/>
                    </div>
                </div>
            </div>
        )
    }) :(<div className={'prose w-full -mt-4'}>
            <span><h4 className={'m-0'}>No Safe Zones.</h4></span>
            <span><h4 className={'m-0'}> Use the <FontAwesomeIcon icon={faSquarePlus}/> button to create one</h4></span>
        </div>)

    const listControl = (
        <div className={'max-h-[22vh] overflow-y-auto overflow-x-hidden flex flex-col align-start items-center gap-0 w-full'}>
            {zoneList}
        </div>
    )

    return listControl
}

import * as React from 'react'
import toast from 'react-hot-toast'
import { removeFormObject } from '../../../handlers/formDataHandler.ts'
import dataStore from '../../../store/dataStore.ts'
import { IDevice } from '../../../interfaces/types.ts'
import { AsyncButton } from '../AsyncButton/AsyncButton.tsx'
import { PopupPortal } from '../Popup/PopupPortal.tsx'

interface Props {
    tracker: IDevice
    open: boolean
    setOpen: (o: boolean) => void
}

export const RemoveTracker: React.FC<Props> = (props) => {
    const {tracker, open, setOpen} = props
    
    const sendCommand = async () => {
        try {
            const responseData = await removeFormObject('device', tracker.iccid)
            dataStore.getState().setDevices(responseData.devices)
            dataStore.getState().setDevicePlans(responseData.plans)
            setOpen(false)
        } catch (e) {
            // TODO: handle error
            toast.error('Fatal error, remove failed try again later.')
            setOpen(false)
        }
    }
    
    const cancel = () => {
        setOpen(false)
    }
    
    const panelHeader = (
        <div className={'prose flex flex-col justify-start items-center gap-2'}>
            <h2 className={'mt-0 mb-0 text-primary'}>Remove a Gibi Tracker</h2>
        </div>
    )
    
    const howDeviceIsHandled =
        <div className={'text-left'}>
            <div className={'text-lg leading-6 p-2 pl-4 prose'}>
                This will also cancel any subscriptions associated with this Tracker.
            </div>
        </div>
    
    const panel = (
        <div className={'flex flex-col justify-start items-center gap-2 h-full p-2 w-full'}>
            {panelHeader}
            <div className={'text-neutral-content flex flex-col justify-start items-center gap-4'}>
                <div className={'font-semibold text-center text-lg w-full'}>
                    Are you sure you want to remove Tracker {tracker.deviceName}?
                </div>
                {howDeviceIsHandled}
                <article className={'text-center text-accent'}>
                    This action cannot be undone.
                </article>
            </div>
        </div>
    )
    
    const buttons = (
        <div className={'p-4 flex justify-between items-center w-full'}>
            <button className={'btn text-secondary btn-ghost btn-sm'} onClick={cancel}>Cancel</button>
            <AsyncButton classNames={'btn btn-outlined btn-secondary btn-sm'} onClick={sendCommand}
                         disabled={false}>Remove Tracker</AsyncButton>
        </div>
    )
    
    const popUp = (
        <PopupPortal open={open}>
            {panel}
            {buttons}
        </PopupPortal>
    )
    
    return (<>
        {popUp}
    </>)
}

import axios from 'axios'
import { DateTime } from 'luxon'
import { petFromIccid } from '../helpers/petHelper.tsx'
import { findIsWaitingOnTracker, recordFindResponse } from '../interfaces/findState.ts'
import authStore from '../store/authStore'
import dataStore from '../store/dataStore.ts'
import findStore from '../store/findStore.ts'

export const getUserIPaddress  = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json")
    authStore.getState().setUserIP(res.data.ip);
};


export const processGibiMessage = (message: any) => {
    const date = DateTime.now().setZone(authStore.getState().timezone.value).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
    switch (message.type) {
        case 'POSITION_UPDATE': {
            if (message.locationReport.properties.status === 1) {
                dataStore.getState().setLastLocation(message.iccid, message.locationReport)
            } else {
                dataStore.getState().setLastReport(message.iccid, message.locationReport)
            }
            if (message.isFind) {
                const success = message.locationReport.properties.status === 1
                const findStatus = `${success ? 'latest location is displayed on the map' : 'Tracker responded with weak gps signal'}`
                const pet = petFromIccid(message.iccid)
                recordFindResponse(pet, success, findStatus)
                findStore.getState().setStatus(message.itemId, {msg: findStatus, date})
                findStore.getState().setInProgress(message.itemId, false)
            }
            const status = Object.assign({}, message.deviceStatus)
            dataStore.getState().updateDeviceStatus(message.iccid, status)
        }
            break
        case'COMMAND_RESPONSE': {
            const status = Object.assign({}, message.deviceStatus)
            dataStore.getState().updateDeviceStatus(message.iccid, status)
            dataStore.getState().setLastEvent(message.iccid, message)
            if (message.commandResponse.command === 'FIND') {
                const success = message.locationReport?.properties?.status === 1
                const findStatus = `${success ? 'Location displayed on map' : 'No response from Tracker'}`
                const pet = petFromIccid(message.iccid)
                recordFindResponse(pet, success, findStatus)
                setTimeout(() => {
                    findStore.getState().setStatus(message.itemId, {msg: findStatus, date})
                    findStore.getState().setInProgress(message.itemId, false)
                }, 150)
            }
        }
            break
        case 'SAFE_ZONE': {
            const status = Object.assign({}, message.deviceStatus)
            dataStore.getState().updateDeviceStatus(message.iccid, status)
            // dataStore.getState().updateLastTenEvents(message.iccid, message)
        }
            break
        case 'DEVICE_UPDATE': {
            const status = Object.assign({}, message.deviceStatus)
            dataStore.getState().updateDeviceStatus(message.iccid, status)
            // a little hacky here
            if (message.deviceUpdate.subType !== 'DeviceStatusChanged') {
                dataStore.getState().setLastEvent(message.iccid, message)
            }
        }
            break
        case 'DEVICE_COMMAND_SENT': {
            if (message.deviceUpdate?.commandRequest?.cmd === 'FIND' || message.deviceUpdate?.commandRequest?.commandRequest?.cmd === 'FIND') {
                const petId = message.itemId
                findStore.getState().setStatus(petId, {msg: 'Command sent to Gibi Tracker, awaiting reply', date})
                const pet = petFromIccid(message.iccid)
                findIsWaitingOnTracker(pet)
            }
        }
            break
        case 'FIND_RESPONSE': {
            const petId = message.itemId
            let msg
            if (message.commandResponse.success) {
                msg = `Location displayed on the map`
                findStore.getState().setStatus(petId, {msg, date})
            } else {
                msg = `Location not updated, weak gps signal`
                findStore.getState().setStatus(petId, {msg, date})
            }
            const pet = dataStore.getState().pets.find(p => p._id === petId)
            recordFindResponse(pet, message.commandResponse.success, msg)
            findStore.getState().setInProgress(petId, false)
        }
            break
        default:
            break
    }
}

import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPersonRunning } from '@fortawesome/free-solid-svg-icons/faPersonRunning'
interface Props {
    color: string
    onClick: (id: string) => void
    selected?: boolean
}

export const Color: React.FC<Props> = (props) => {
    const {color, selected, onClick} = props

    return (
        <div className="form-control">
            <label className="label cursor-pointer flex flex-col justify-center items-center gap-2" onClick={() => onClick(color)}>
                <div className={`p-2 ${selected ? 'bg-primary/30':''}`}>
                    <FontAwesomeIcon icon={faPersonRunning} color={color} size={'xl'}/>
                </div>
                {/*<input type="radio" name="radio-Interval" className="radio checked:bg-primary"*/}
                {/*       onChange={() => onClick(color)} checked={selected}/>*/}
            </label>
        </div>
    )
}

import { StripeElementsOptions } from '@stripe/stripe-js'

export const gibiStripeOptions: StripeElementsOptions = {
    mode: 'subscription',
    amount: 0,
    currency: 'usd',
    payment_method_types: ['card'],
    appearance: {
        theme: 'flat',
        variables: {
            colorPrimary: '#139dcc',
            colorBackground: '#ffffff',
            colorText: '#30313d',
            colorDanger: '#ff0000',
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            // spacingUnit: '2px',
            borderRadius: '4px',
            spacingGridRow: '0.25rem'
            // See all possible variables below
        },
        "rules": {
            ".Input": {
                "color": 'rgb(0, 16, 20)',
                "fontWeight": "400",
                "fontSize": "14px",
                "letterSpacing": "0",
                "padding": '0.5rem',
                'lineHeight': '1rem',
                'borderWidth': "1px",
                'borderColor': 'rgba(41, 19, 52, 0.2)',
                'backgroundColor': 'rgb(250, 247, 245)',
            },
            ".Input:hover": {
                "backgroundColor": "#fff",
                'borderColor': 'rgba(41, 19, 52, 0.2)',
                "color": 'rgb(0, 16, 20)',
            },
            ".Input:hover:focus": {
                "backgroundColor": "#fff",
                'borderColor': 'rgba(41, 19, 52, 0.2)',
                "boxShadow": "0 0 0 0 transparent, 0 0 0 3px rgba(32, 76, 229, 0.3)",
                "color": 'rgb(0, 16, 20)',
            },
            ".Input:focus": {
                outlineOffset: '2px',
                "backgroundColor": "#fff",
                'borderColor': 'rgba(41, 19, 52, 0.2)',
                "boxShadow": "0 0 0 0 transparent, 0 0 0 3px rgba(32, 76, 229, 0.3)",
                "color": 'rgb(0, 16, 20)',
            },
            ".Input:disabled": {
                "backgroundColor": "#F5F5F5",
                'borderColor': 'rgba(41, 19, 52, 0.2)',
                "color": "rgba(17, 35, 55, 0.6)"
            },
            ".Input:disabled:hover": {
                "backgroundColor": "#F5F5F5",
                'borderColor': 'rgba(41, 19, 52, 0.2)',
                "color": "rgba(17, 35, 55, 0.6)"
            },
            ".Input--invalid": {
                "color": "#112337",
                "backgroundColor": "#F5F5F5",
                'borderColor': 'rgba(41, 19, 52, 0.2)',
                "boxShadow": "0"
            },
            ".Input--invalid:hover": {
                "borderColor": "#c02b0a"
            },
            ".Input::placeholder": {
                "fontFamily": "-apple-system, \"system-ui\", \"Segoe UI\", Roboto, Oxygen-Sans, Ubuntu, Cantarell, \"Helvetica Neue\", sans-serif",
                "fontSize": "14px",
                "fontWeight": "400",
                "letterSpacing": "0"
            },
        }
    }
}

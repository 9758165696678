import * as React from 'react'
import { PropsWithChildren } from 'react'
import controlStore from '../store/controlStore.ts'

interface Props {
    isList?: boolean
    children: React.ReactNode
}

export const PageWrapper: React.FC<PropsWithChildren<Props>> = (props) => {
    const {isList} = props
    const isMobile = controlStore(s => s.isOnMobileDevice)
    const style = {
        width: isMobile ? '85vw' : '',
        minWidth: isList ? '50vw' : isMobile ? '' : '30vw',
        maxWidth: isList ? '90vw' : isMobile ? '' : '50vw',
        height: '100%',
    } as React.CSSProperties
    return (
        <div id="page-wrapper"
             data-theme={'gibi'}
             className={'flex flex-col justify-center items-center h-full w-full bg-base-100'}
        >
            <div style={style}>
                {props.children}
            </div>
        </div>
    )
}

import * as React from 'react'
import { Password } from '../../widgets/Password.tsx'

interface Props {
    disabled?: boolean
    register: any
    errors: any
    onComplete: (b: boolean) => void
    setValue: (n: any, v: any) => void
    getValues: any
    STEPS: any
    watch: any
}

export const SetPassword: React.FC<Props> = (props) => {
    const {errors, register, onComplete, getValues, watch} = props
    const [done, setDone] = React.useState(false)

    const password = watch('password')
    if (!password && done) {
        setDone(false)
    }

    return (
        <div>

            <Password
                formField={'password'}
                title={'Password'}
                {...register('password' as any, {
                    minLength: {
                        value: 6,
                        message: 'Password must be at least 6 characters'
                    }
                })}
                errors={errors}
                complete={done}
            />

            {!done ? <Password
                formField={'confirm'}
                title={'Confirm password'}
                {...register('confirm' as any, {
                    validate: (value: string) => {
                        if (value === getValues().password) {
                            setDone(true)
                            onComplete(true)
                        }
                        return value === getValues().password || 'Passwords must match'
                    },
                })}
                errors={errors}
            /> : null}

        </div>
    )
}

import { CircleF } from '@react-google-maps/api'
import * as React from 'react'
import historyStore from '../../../../store/historyStore.ts'
import { estimateDeviationFromReport, getMeanAndStd } from './highlightHelpers.ts'

const accuracyStyle = {
    strokeColor: 'blue',
    strokeOpacity: 0,
    strokeWeight: 1,
    fillColor: 'blue',
    fillOpacity: 0.1,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1
}

export const HighlightMarker: React.FC = () => {
    const point = historyStore(s => s.highlight)
    if (!point) return

    const latLng = new google.maps.LatLng({
        lat: point.properties?.latitude,
        lng: point.properties?.longitude,
    })

    let stats = getMeanAndStd(point.properties.accuracy?.toFixed(1))
    if (!stats) {
        stats = {
            mean: estimateDeviationFromReport(point),
            std: estimateDeviationFromReport(point)
        }
    }

    const marker = (
        <div>
            <div>
                <CircleF
                    center={latLng}
                    radius={stats.std}
                    options={accuracyStyle}
                />
                <CircleF
                    center={latLng}
                    radius={stats.mean}
                    options={accuracyStyle}
                />
                <CircleF
                    center={latLng}
                    radius={0.5}
                    options={{...accuracyStyle, fillOpacity: 1}}
                />
            </div>
        </div>
    )

    return marker
}

import * as React from 'react'
import { Controller } from 'react-hook-form'

interface Props {
	label: string
	fieldName: string
	disabled?: boolean
	control: any
	checked: boolean
}

export const Checkbox: React.FC<Props> = (props) => {
	const { fieldName, disabled, control, label, checked } = props
	const [isChecked, setIsChecked] = React.useState(checked)

	return <Controller
		name={fieldName}
		render={({ field }) => {
			const onChange = () => {
				setIsChecked(!isChecked)
				field.onChange(!isChecked)
			}
			return (
				<div className={'flex items-center justify-start px-4 w-full'}>
					<div className={'flex items-center gap-2'}>
						<input
							type={'checkbox'}
							className={'checkbox checkbox-primary'}
							onChange={onChange }
							checked={isChecked}
							disabled={disabled}
						/>
						<div className={'text-neutral-content text-md font-medium'}>
							{label}
						</div>
					</div>
				</div>
			)
		}}
		control={control}
	/>
}

import { DateTime } from 'luxon'
import * as React from 'react'
import { getHistoryLabel } from '../../../../helpers/historyHelper.ts'
import authStore from '../../../../store/authStore.ts'
import historyStore from '../../../../store/historyStore.ts'
import { IPet, IPoint } from '../../../../interfaces/types.ts'
import { getNumberCircleSvg } from '../../../map/components/icons.ts'
import { getTextColor } from '../../../../helpers/colorHelper.ts'
// import { getTextColor } from '../../../../helpers/colorHelper.ts'

interface Props {
    location: IPoint
    pet: IPet
    index: number
    centerMap: (p: IPoint, map: google.maps.Map) => void
    flex: string
    desktop?: boolean
}

export const HistoryListItem: React.FC<Props> = (props) => {
    const {location, index, centerMap, flex, desktop, pet} = props

    const date = DateTime.fromISO(location?.properties?.receivedTime, {zone: authStore.getState().timezone?.value}).toLocaleString(desktop ? DateTime.DATE_MED_WITH_WEEKDAY : DateTime.DATE_SHORT)
    const time = DateTime.fromISO(location?.properties?.receivedTime, {zone: authStore.getState().timezone?.value}).toLocaleString(DateTime.TIME_SIMPLE)
    const eventLabel = getHistoryLabel(location.properties.gibiMessageType)
    const map = historyStore(s => s.map)
    const valid = location.properties.status
    const tc = getTextColor(pet?.iconColor)

    const svg = getNumberCircleSvg(pet?.iconColor, index + 1, tc === '!text-white' ? 'white' : 'black')
    // const svg = getNumberCircleSvg('#009ecc', index + 1, 'white')
    // const style = {
    //     borderColor: pet?.iconColor
    // } as React.CSSProperties
    return (
        <div
            className={`relative p-1 border-2 border-solid border-primary rounded ${flex} cursor-pointer min-w-[8rem] bg-primary-content`}
            key={`${location._id}`}
            onClick={() => centerMap(location, map!)}
            onMouseEnter={() => historyStore.getState().setHighlight(location)}
            onMouseLeave={() => historyStore.getState().setHighlight(null)}
            // style={style}
        >
            <div className={'w-4 h-4 absolute bottom-1 left-0.5 '}>
                <img className={`w-4 h-4`} src={svg} alt={String(index+1)}/>
            </div>

            <div className={`text-md flex flex-col justify-start gap-0 ${!valid ? 'bg-black/30 text-white' : ''}`}>
                {eventLabel ?
                    <div
                        className={`self-center badge text-primary-content border-primary bg-primary border-2`}
                    >
                        {eventLabel}
                    </div> : null}
                <div>{date}</div>
                <div className={'-mt-2'}>{time}</div>
            </div>
        </div>
    )
}

import * as React from 'react'
import { IPet } from '../../../interfaces/types.ts'

interface Props {
    pet: IPet
    title: string,
    icon: any
    children: React.ReactNode
    className?: string
    mobile?: boolean
}

export const SubItem: React.FC<Props> = (props) => {
    const { pet, title, icon, children, mobile } = props

    const style = {
        borderColor: pet.iconColor
    }

    return (
        <div className={`flex items-center justify-between border-l-4 bg-white px-1 pr-2 py-1 items-center !h-[36px]`} style={style}>
            {/*<div className={'flex items-center'}>*/}
                <div className={`flex items-center gap-1 ${mobile ? '-ml-2' : ''}`}>
                    {icon}
                    <div className={'font-bold text-lg text-left'}>
                        {title}
                    </div>
                </div>

            {/*</div>*/}
            <div className={'text-right w-[67%]'}>
                {children}
            </div>
        </div>
    )
}

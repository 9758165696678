import * as React from 'react'
import controlStore from '../../../store/controlStore'
import mapStore from '../../../store/mapStore'
import { MyMarker } from './MyMarker'

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
export const TrackMyLocation: React.FC = () => {

    const [watching, setWatching] = React.useState(false)
    // const [available, setAvailable] = React.useState(false)

    const position = mapStore(s => s.myPosition)
    const color = controlStore(s => s.myPositionColor)
    const locationEnabled = mapStore(s => s.locationEnabled)

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const successCallback = (position: any) => {
        // console.log("Your current position is (" + "Latitude: " + position.coords.latitude + ", " + "Longitude: " + position.coords.longitude + ")")
        mapStore.getState().setLocationEnabled(true)
        // setAvailable(true)
        // window.alert("Your current position is (" + "Latitude: " + position.coords.latitude + ", " + "Longitude: " + position.coords.longitude + ")")
    }
    const errorCallback = (error: any) => {
        if (locationEnabled) {
            if (error.code == 1) {
                window.alert('You have blocked access to your locations. To enable - go to Apple Menu > System Settings > Privacy & Security > Location Services and enable Safari.')
            } else if (error.code == 2) {
                window.alert('The network is down or the positioning service can\'t be reached.')
            } else if (error.code == 3) {
                window.alert('The attempt timed out before it could get the location data.')
            } else {
                window.alert('Geolocation failed due to unknown error.')
            }
            // mapStore.getState().setLocationEnabled(false)
        }
    }
    React.useEffect(() => {
        if (navigator.geolocation) {
            void navigator.permissions.query({name:'geolocation'}).then(function(result) {
                console.log('geolocation result:', result)
                if (result.state == 'granted' || result.state === 'prompt') {
                    // window.alert(result.state);
                    navigator.geolocation.getCurrentPosition(successCallback, errorCallback)
                    startTracking()
                } else if (result.state == 'denied') {
                    window.alert('To see your location on the map, enable location services in your devices settings')
                }
                result.onchange = function () {
                    window.alert(result.state);
                }
            });
            // while (!available) {
            //     navigator.geolocation.getCurrentPosition(successCallback, errorCallback)
            // }
            // startTracking()
        }
    }, [])

    const startTracking = () => {
        if (navigator.geolocation) {
            if (!watching) {
                navigator.geolocation.watchPosition(updatePosition)
                setWatching(true)
            }
            // navigator.geolocation.getCurrentPosition(success, error);
        } else {
            console.log('Geolocation not supported')
            // window.alert('Geolocation not supported')
        }
    }

    const updatePosition = (position: any) => {
        // window.alert('update position')
        const update = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude)
        mapStore.getState().setMyPosition(update)
    }

    return <MyMarker position={position} color={color}/>
}


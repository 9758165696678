import * as React from 'react'
import dataStore from '../../../../store/dataStore.ts'
import { Rate } from './Rate.tsx'

interface Props {
    selected: string
    onChange: (i: string) => void
}

export const SelectRate: React.FC<Props> = (props) => {
    const {selected, onChange} = props
    const rates = dataStore(s => s.rates)
        .filter(r => r.checkInRateType === 'localRate')
        .sort((a, b) => Number(a.seconds) < Number(b.seconds) ? -11 : 1)

    return (
        <div className={'grid grid-cols-3 gap-2 p-4'}>
            {rates.map(r => <Rate key={r._id} rate={r} selected={selected === r.seconds} onClick={onChange}/>)}
        </div>
    )
}

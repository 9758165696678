import { faLocationDot } from '@fortawesome/free-solid-svg-icons/faLocationDot'
import { faSlash } from '@fortawesome/free-solid-svg-icons/faSlash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime } from 'luxon'
import * as React from 'react'
import authStore from '../../../store/authStore.ts'
import dataStore from '../../../store/dataStore.ts'
import { IDevice, IPet } from '../../../interfaces/types.ts'
import { SubItem } from './SubItem.tsx'
import { Timestamp } from './Timestamp.tsx'

interface Props {
    pet: IPet
    changeRate?: () => void
}

export const LastReport = (props: Props) => {
    const {pet, changeRate} = props
    const [lastReportDate, setLastReportDate] = React.useState<DateTime | null>(null)

    const [device, setDevice] = React.useState<IDevice | null>(null)
    const [goodGPS, setGoodGPS] = React.useState(false)

    const devices = dataStore((s) => s.devices)
    const timeZone = authStore(s => s.timezone)

    React.useEffect(() => {
        if (devices) {
            const dev = devices.find(d => d.petId === pet._id)
            if (dev) {
                setDevice(dev)
                const lastPos = dev.lastLocation
                const lastReport = dev.lastReport
                let lpDate = null
                let lrDate = null
                if (lastPos && Object.keys(lastPos).length) {
                    lpDate = DateTime.fromSeconds(lastPos.properties.timeStamp).setZone(timeZone.value)//.toLocaleString(DateTime.DATETIME_MED)
                }
                if (lastReport && Object.keys(lastReport).length) {
                    lrDate = DateTime.fromSeconds(lastReport.properties.timeStamp).setZone(timeZone.value)//.toLocaleString(DateTime.DATETIME_MED)
                }
                if (lpDate && lrDate) {
                    if (lpDate !== lrDate) {
                        setLastReportDate(lrDate)
                        setGoodGPS(!!lastReport.properties.status)
                    }
                } else {
                    if (lpDate) {
                        setLastReportDate(lpDate)
                        setGoodGPS(!!lastPos.properties.status)
                    } else {
                        setLastReportDate(lrDate)
                        setGoodGPS(!!lastReport?.properties?.status)
                    }
                }
            }
        }
    }, [devices, pet])

    const subItem = (
        <div onClick={() => changeRate && changeRate()}>
            <SubItem pet={pet} title={'Last contact'}
                     icon={goodGPS ? <FontAwesomeIcon icon={faLocationDot} className={'text-neutral-content/50'}/> :
                         <span className="fa-layers fa-fw fa-md">
                            <FontAwesomeIcon icon={faSlash} fontSize={'12px'} className={'text-neutral-content/50'} style={{color:'red'}}/>
                            <FontAwesomeIcon icon={faLocationDot} fontSize={'18px'}
                                             className={'text-neutral-content/50'}/>
                        </span>}>
                <div className={''}>
                    <Timestamp date={lastReportDate}/>
                </div>
            </SubItem>
        </div>
    )

    return device ? subItem : null
}

import { faCat, faDog, faMars, faVenus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { getAge, getPetsWIthDevices } from '../../helpers/dataHelpers'
// import { getAge } from '../../helpers/dataHelpers.ts'
import dataStore from '../../store/dataStore'
import { PetEventsButton } from '../common/buttons/PetEventsButton'
import { MiniBattery } from '../dashboard/components/MiniBattery'
import { MiniSafeZoneStatus } from '../dashboard/components/MinSafeZonStatus'
import './PetDrawer.css'
import { centerMapOnPet } from '../map/mapHelpers'

const iconSize = 'xl'

export const DesktopPetList: React.FC = () => {
    const petsWithDevices: string[] = getPetsWIthDevices() || []
    const pets = dataStore.getState().pets.filter(p => petsWithDevices.includes(p._id)).sort((a, b) => a.petName > b.petName ? 1 : -1)
    const account = dataStore(s => s.account)
    const breeds = dataStore(s => s.breeds)
    const imperial = account?.homeAddress?.country?.toLowerCase() === 'usa' || account?.homeAddress?.country?.toLowerCase() === 'us'

    const list = pets.map(pet => {
        const petHasDevice = petsWithDevices.includes(pet._id)
        const speciesRecord = dataStore.getState().species.find(s => s._id === pet.species)
        const species = speciesRecord?.species_name.toLowerCase() === 'dog' ? <FontAwesomeIcon icon={faDog} size={iconSize}/> :
            <FontAwesomeIcon icon={faCat} size={iconSize}/>
        const gender = pet.gender.toLowerCase() === 'male' ?  <FontAwesomeIcon icon={faMars} size={iconSize}/> :
            <FontAwesomeIcon icon={faVenus} size={iconSize}/>
        const breed = breeds?.find(b => b._id === pet.breed)?.breed_name
        const age = getAge(pet.birthDate, true)

        const petStats = (
            <div className={'flex flex-col justify-center items-center bg-primary text-primary-content p-1'}>
                <div className={'flex flex-col gap-2 items-center h-fit -mt-4'}>
                    <div className={'self-center text-sm col-span-4 text-center text-md'}>
                        {breed}
                    </div>
                    <div className={'col-span-1 opacity-70 text-xs w-full'}>
                        <div className={'flex flex-row justify-around items-center'}>
                            {species} {gender}
                        </div>
                    </div>
                    <div className={'col-span-1 opacity-70 text-xs'}>
                        <div className={'flex flex-row justify-around gap-6 items-center'}>
                            <div className={'self-end flex flex-row justify-start gap-0.5 items-center pl-1'}>
                                {pet.petWeight} {imperial ? 'lbs' : 'kg'}
                            </div>
                            <div>
                                {age} old
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

        return (
            <div key={pet._id} className={'flex items-center bg-primary text-primary-content'} style={{borderTop: '1px solid hsl(var(--pc))'}}>
                <div className="w-[120px] p-[10px] flex justify-center items-center"
                     style={{backgroundColor: pet.iconColor}}
                >
                    <div className={`h-[100px] object-cover ${petHasDevice ? 'cursor-pointer': ''}`} onClick={() => centerMapOnPet(pet)}>
                        <img src={pet.imageURL} alt={'pet'}/>
                    </div>
                </div>

                <div className={'flex flex-col gap-4 flex-1'}>
                    <div className={'flex items-center justify-between p-2 mt-2 p-1 rounded w-full'}>
                        <div className={`text-left text-2xl font-semibold px-1`}>
                            {pet.petName}
                        </div>
                        <div className={'pr-2'}>
                            <PetEventsButton pet={pet} noIcon/>
                        </div>
                    </div>


                    <div className={'grid grid-cols-[33%_33%_34%] -mt-3 w-full'}>
                        {petStats}
                        <div className={'p-1 m-1 border-l-primary-content/75 border-l-2'}>
                            <MiniBattery pet={pet} desktop/>
                        </div>
                        <div className={'p-2 m-1 border-l-primary-content/75 border-l-2'}>
                            <MiniSafeZoneStatus pet={pet} desktop/>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    return <div className={'bg-accent pb-1'}>{list}</div>
}


const FILL_COLOR = '#3ccbc9'
const STROKE_COLOR = '#3ccbc9'
const EDIT_FILL_COLOR = '#e98585'
const EDIT_STROKE_COLOR = '#d92424'

export const showStyle = {
    strokeColor: STROKE_COLOR,
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: FILL_COLOR,
    draggable: false,
    editable: false,
}

export const editStyle = {
    strokeColor: EDIT_STROKE_COLOR,
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: EDIT_FILL_COLOR,
    draggable: true,
    editable: true,
}

const factor = 0.00008
export const getDefaultSafeZoneCoords = (lat: number, lng: number): any[] => {
    const add_lat_factor = lat + factor
    const add_lng_factor = lng + factor

    const sub_lat_factor = lat - factor
    const sub_lng_factor = lng - factor

    const lat_lng_list = [
        {sub_lng_factor, sub_lat_factor},
        {sub_lng_factor, add_lat_factor},
        {
            add_lng_factor,
            add_lat_factor,
        },
        {add_lng_factor, sub_lat_factor},
        {sub_lng_factor, sub_lat_factor},
    ]

    const arr: any[] = []
    Object.values(lat_lng_list).map((key) => {
        if (key.sub_lat_factor && key.sub_lng_factor) {
            arr.push({lat: key.sub_lat_factor, lng: key.sub_lng_factor})
        }
        if (key.sub_lng_factor && key.add_lat_factor) {
            arr.push({lat: key.add_lat_factor, lng: key.sub_lng_factor})
        }
        if (key.add_lng_factor && key.add_lat_factor) {
            arr.push({lat: key.add_lat_factor, lng: key.add_lng_factor})
        }
        if (key.add_lng_factor && key.sub_lat_factor) {
            arr.push({lat: key.sub_lat_factor, lng: key.add_lng_factor})
        }
    })
    return arr
}

import { GroupBase, StylesConfig } from "react-select";

//  text-base-content border-solid border ${color ? 'border-'+ color : 'border-primary/50'} rounded w-full px-2 focus-visible:outline-0 ${isSub ? 'text-md' : 'text-xl'}
export const select2Styles:StylesConfig<any, boolean, GroupBase<any>> = {
    control: (provided) => ({
        ...provided,
        background: '#fff',
        // borderColor: 'hsl(var(--p) / var(--tw-border-opacity)',
        borderColor: 'var(--p/50)',
        minHeight: '24px',
        height: '24px',
        boxShadow: 'unset',
        borderRadius: '0.25rem',
    }),

    valueContainer: (provided) => ({
        ...provided,
        height: '24px',
        padding: '0 6px'
    }),

    input: (provided) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '24px',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 20,
    }),
    menuList:(p) => ({
        ...p,
        margin: 0,
        padding: 'auto 0',
        paddingTop: '4px',
        fontSize:' 14px',
    }),
    option: (provided, /*state*/) => ({
        ...provided,
        minHeight: '24px',
        height: '24px',
        color: 'var(--bc)',
        padding: 0,
    }),
  }

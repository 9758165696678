
export const getRGB = (c: string | number): number => {
    return parseInt(String(c), 16) || Number(c)
}

export const getsRGB = (c: string) => {
    return getRGB(c) / 255 <= 0.03928
        ? getRGB(c) / 255 / 12.92
        : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4)
}

export const getLuminance = (hexColor: string) => {
    return (
        0.2126 * getsRGB(hexColor.substr(1, 2)) +
        0.7152 * getsRGB(hexColor.substr(3, 2)) +
        0.0722 * getsRGB(hexColor.substr(-2))
    )
}

export const getContrast = (f: string, b: string) => {
    const L1 = getLuminance(f)
    const L2 = getLuminance(b)
    return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05)
}

export const getTextColor = (bgColor: string) => {
    const whiteContrast = getContrast(bgColor, '#ffffff')
    const blackContrast = getContrast(bgColor, '#000000')

    // console.log('contrast', bgColor, whiteContrast, blackContrast)
    return whiteContrast > blackContrast ? '!text-white' : '!text-black'
}

export const getTextColoHex = (bgColor: string) => {
    const whiteContrast = getContrast(bgColor, '#ffffff')
    const blackContrast = getContrast(bgColor, '#000000')
    
    // console.log('contrast', bgColor, whiteContrast, blackContrast)
    return whiteContrast > blackContrast ? '#ffffff' : '#000000'
}

export const hexToRgbA = (hex: string, opacity: string) => {
    if (!hex) return 'rgba(0,0,0,0)'
    let c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        // @ts-ignore
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+opacity+')';
    }
    throw new Error('Bad Hex');
}

export interface ThemeColors {
    primary: string;
    primaryFocus: string;
    primaryContent: string;
    secondary: string;
    secondaryFocus: string;
    secondaryContent: string;
    accent: string;
    accentFocus: string;
    accentContent: string;
    neutral: string;
    neutralFocus: string;
    neutralContent: string;
    base100: string;
    base200: string;
    base300: string;
    baseContent: string;
    info: string;
    infoContent: string;
    success: string;
    successContent: string;
    warning: string;
    warningContent: string;
    error: string;
    errorContent: string;
}

export function extractThemeColorsFromDOM(): ThemeColors {
    const computedStyles = getComputedStyle(document.querySelector(':root')!);
    return {
        primary: `hsl(${computedStyles.getPropertyValue('--p')})`,
        primaryFocus: `hsl(${computedStyles.getPropertyValue('--pf')})`,
        primaryContent: `hsl(${computedStyles.getPropertyValue('--pc')})`,
        secondary: `hsl(${computedStyles.getPropertyValue('--s')})`,
        secondaryFocus: `hsl(${computedStyles.getPropertyValue('--sf')})`,
        secondaryContent: `hsl(${computedStyles.getPropertyValue('--sc')})`,
        accent: `hsl(${computedStyles.getPropertyValue('--a')})`,
        accentFocus: `hsl(${computedStyles.getPropertyValue('--af')})`,
        accentContent: `hsl(${computedStyles.getPropertyValue('--ac')})`,
        neutral: `hsl(${computedStyles.getPropertyValue('--n')})`,
        neutralFocus: `hsl(${computedStyles.getPropertyValue('--nf')})`,
        neutralContent: `hsl(${computedStyles.getPropertyValue('--nc')})`,
        base100: `hsl(${computedStyles.getPropertyValue('--b1')})`,
        base200: `hsl(${computedStyles.getPropertyValue('--b2')})`,
        base300: `hsl(${computedStyles.getPropertyValue('--b3')})`,
        baseContent: `hsl(${computedStyles.getPropertyValue('--bc')})`,
        info: `hsl(${computedStyles.getPropertyValue('--in')})`,
        infoContent: `hsl(${computedStyles.getPropertyValue('--inc')})`,
        success: `hsl(${computedStyles.getPropertyValue('--su')})`,
        successContent: `hsl(${computedStyles.getPropertyValue('--suc')})`,
        warning: `hsl(${computedStyles.getPropertyValue('--wa')})`,
        warningContent: `hsl(${computedStyles.getPropertyValue('--wac')})`,
        error: `hsl(${computedStyles.getPropertyValue('--er')})`,
        errorContent: `hsl(${computedStyles.getPropertyValue('--erc')})`,
    };
}

import toast from 'react-hot-toast'
import {
    get,
    unsecurePost
} from '../helpers/apiHelper.ts'
import authStore from '../store/authStore.ts'
import controlStore from '../store/controlStore.ts'
import dataStore from '../store/dataStore'
import { ACCOUNT_DATA_URL, APPLICATION_DATA_URL, V2_LOGIN_URL, MAP_DATA_URL } from '../helpers/urls.ts'

export const loadLoginData = async (token: string, userDetails: any, cancelToken?: any): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    return new Promise(async (resolve, reject) => {
        if (token && userDetails) {
            try {
                authStore.getState().setToken(token)
                authStore.getState().setUserDetails(userDetails)
                authStore.getState().setTimezone(userDetails.timezone)
                const mapData = await get(MAP_DATA_URL, cancelToken)
                if (mapData) {
                    dataStore.getState().loadMapData(mapData.data)
                    void get(ACCOUNT_DATA_URL).then(account => dataStore.getState().loadAccountData(account?.data))
                    void get(APPLICATION_DATA_URL).then(application => dataStore.getState().loadApplicationData(application?.data))
                    return resolve()
                }
                return reject()
            } catch (e: any) {
                if (e.response.data.message === 'jwt expired') {
                    toast.error('Saved credentials expired. Please log in again.')
                } else {
                    toast.error('Server unavailable')
                }
                controlStore.getState().setShowLoader(false)
                throw (e)
            }
        }
        resolve()
    })
}

export const loginHandler = async (formData: any):Promise<string> => {
    let route = '/'
    try {
        const result: any = await unsecurePost(V2_LOGIN_URL, formData)
        if (result?.data?.status) {
            await loadLoginData(result.data.token, result.data.userDetails)
            if (formData.rememberMe) {
                localStorage.setItem('gibi', JSON.stringify({t: result.data.token}))
                localStorage.setItem('gibiUser', JSON.stringify(result.data.userDetails))
            }
            route = '/map'
        } else {
            toast.error(result?.response?.data?.message || 'Login error')
        }
        // console.log(result)
    }
    catch (e) {
        toast.error('Login error')
    }
    return route
}

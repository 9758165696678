import { StripeAddressElementOptions } from '@stripe/stripe-js'

export const appearance = {
    theme: 'stripe',
    // variables: {
    //     colorPrimary: '#0570de',
    //     colorBackground: '#ffffff',
    //     colorText: '#30313d',
    //     colorDanger: 'rgb(236, 37, 19)',
    //     fontFamily: 'Ideal Sans, system-ui, sans-serif',
    //     spacingUnit: '1px',
    //     // See all possible variables below
    // },
    // h-8 text-base-content border-solid border border-primary/50 rounded w-full px-2 focus-visible:outline-0 text-xl
    rules: {
        '.Input': {
            lineHeight: '21px',
            fontSize: '14px',
            border: 'none',
            backgroundColor: '#ffffff',
            padding: '0 6px',
            boxShadow: 'unset',
            outline: 0,
            borderRadius: '0.25rem',
        },
        '.Input:focus': {
            outline: 0,
            boxShadow: 'unset',
            border: 'none',
            borderRadius: '0.25rem',
        },
        '.Input--invalid': {
            outline: 0,
            boxShadow: 'unset',
            border: 'none',
            borderRadius: '0.25rem',
        },
        '.Label': {
            fontWeight: 500,
            fontSize: '12px',
            color: 'rgb(0, 158, 204)',
            marginTop: '-6px',
        },
        '.Error': {
            color: 'rgb(236, 37, 19)',
            fontSize: '10.5px',
        }
    }
}

export const addressOptions = {
    mode: 'billing',
    autocomplete: {
        mode: 'google_maps_api',
        apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    },
    allowedCountries: ['US','CA'],
    blockPoBox: true,
} as StripeAddressElementOptions

export const cvcElementOptions = {
    style: {
        width: '50px',
        base: {
            fontSize: '14px',
            color: '#424770',
            letterSpacing: '0.025em',
            fontFamily: 'Source Code Pro, monospace',
            '::placeholder': {
                color: '#00ff00',
            },
            padding: '2px 4px',
        },
        invalid: {
            color: '#9e2146',
        },
    },
}

import { faLocationDot } from '@fortawesome/free-solid-svg-icons/faLocationDot'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime } from 'luxon'
import * as React from 'react'
import authStore from '../../../store/authStore.ts'
import dataStore from '../../../store/dataStore.ts'
import { IDevice, IPet } from '../../../interfaces/types.ts'
import { SubItem } from './SubItem.tsx'
import { Timestamp } from './Timestamp.tsx'

interface Props {
    pet: IPet
    changeRate?: () => void
}

export const LastLocation = (props: Props) => {
    const {pet, changeRate} = props
    const timeZone = authStore(s => s.timezone)
    // const [lastPosDate, setLastPosDate] = React.useState<string[]>([])
    const [lastDate, setLastDate] = React.useState<DateTime | null>(null)

    const [device, setDevice] = React.useState<IDevice | null>(null)

    const devices = dataStore((s) => s.devices)

    React.useEffect(() => {
        if (devices) {
            const dev = devices.find(d => d.petId === pet._id)
            console.log('pet', pet.petName)
            if (dev) {
                setDevice(dev)
                const lastPos = dev.lastLocation
                let date = null
                if (lastPos && Object.keys(lastPos).length) {
                    date = DateTime.fromSeconds(lastPos.properties.timeStamp).setZone(timeZone.value)//.toLocaleString(DateTime.DATETIME_MED)
                    // setLastPosDate(date.split(','))
                    setLastDate(date)
                } else if (lastDate) {
                    setLastDate(null)
                }
            }
        }
    }, [devices, pet])

    const subItem = (
        <div onClick={() => changeRate && changeRate()}>
        <SubItem pet={pet} title={'Last check-in'}
                 icon={<FontAwesomeIcon icon={faLocationDot} fontSize={'18px'} className={'text-neutral-content/50'}/>}>
            <div className={''}>
                <Timestamp date={lastDate}/>
            </div>
        </SubItem>
        </div>
    )

    return device ? subItem : null
}


import * as turf from '@turf/turf'
import { IPet, IPoint } from '../../interfaces/types.ts'
import mapStore from '../../store/mapStore.ts'
import dataStore from '../../store/dataStore.ts'


export const getCenterOfSafeZone = (path: { lat: number, lng: number }[]) => {
    const coords = path.map(p => [p.lng, p.lat])
    // const last = coords.length - 1
    //
    // // TODO: sometimes the polygons aren't closed - fix that issue
    // if (coords[0][0] !== coords[last][0] && coords[0][1] !== coords[last][1]) {
    //     coords.push(coords[0])
    // }
    let polygon
    let turfCenter
    try {
        polygon = turf.polygon([coords], {})

        // @ts-ignore
        turfCenter = turf.center(polygon)
    } catch (e) {
        const last = coords.length - 1
        if (coords[0][0] !== coords[last][0] && coords[0][1] !== coords[last][1]) {
            coords.push(coords[0])
            polygon = turf.polygon([coords], {})
            // @ts-ignore
            turfCenter = turf.center(polygon)
        }
    }
    return { lng: turfCenter?.geometry.coordinates[0], lat: turfCenter?.geometry.coordinates[1] }
}

export const centerMap = (point: IPoint, map: google.maps.Map) => {
    if (point.properties.status) {
        const latLng = new google.maps.LatLng({
            lat: point.properties?.latitude,
            lng: point.properties?.longitude,
        })
        map.panTo(latLng)
    }
}

export const centerMapOnPet = (pet: IPet) => {
    const map = mapStore.getState().map!
    const device = dataStore.getState().devices?.find(d => d.petId === pet._id)
    if (device?.lastLocation && Object.keys(device.lastLocation).length) {
        const position = new window.google.maps.LatLng({
            lat: device.lastLocation?.properties?.latitude,
            lng: device.lastLocation?.properties?.longitude
        })
        map.panTo(position)
    }
}

export const centerMapOnPets = () => {
    const map = mapStore.getState().map!
    let bounds = mapStore.getState().bounds
    const myPosition = mapStore.getState().myPosition
    let hasBounds = true
    if (!bounds) {
        bounds = new google.maps.LatLngBounds()
        hasBounds = false
    }
    if (myPosition) {
        bounds.extend(myPosition)
        hasBounds = true
    }
    if (hasBounds) {
        map.fitBounds(bounds)
        setTimeout(() => {
            const zoom = map.getZoom() || 0
            if (zoom > 16) {
                map.setZoom(16)
                mapStore.getState().setZoom(16)
            } else {
                mapStore.getState().setZoom(zoom)
            }
        })
    } else {
        mapStore.getState().setZoom(map.getZoom() || 16)
    }
}

const leftButton = {
    background: 'none padding-box rgb(255, 255, 255)',
    display: 'table-cell',
    border: '0px',
    margin: '0px',
    padding: '0px 17px',
    textTransform: 'none',
    appearance: 'none',
    position: 'relative',
    cursor: 'pointer',
    userSelect: 'none',
    overflow: 'hidden',
    textAlign: 'center',
    height: '40px',
    verticalAlign: 'middle',
    color: 'rgb(86, 86, 86)',
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: '18px',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
    minWidth: '66px',
}

const rightButton = {
    background: 'none padding-box rgb(255, 255, 255)',
    display: 'table-cell',
    border: '0px',
    margin: '0px',
    padding: '0px 17px',
    textTransform: 'none',
    appearance: 'none',
    position: 'relative',
    cursor: 'pointer',
    userSelect: 'none',
    overflow: 'hidden',
    textAlign: 'center',
    height: '40px',
    verticalAlign: 'middle',
    color: 'rgb(0, 0, 0)',
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: '18px',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
    minWidth: '36px',
}

const mapTypeControl = (controlDiv: HTMLDivElement, map: google.maps.Map, isHistory?: boolean) => {
    // Creating divs & styles for custom zoom control
    // controlDiv.style.padding = '5px';

    // Set CSS for the control wrapper
    const controlWrapper = document.createElement('div')
    // controlWrapper.style.backgroundColor = 'hsl(var(--b1))'
    controlWrapper.style.color = 'hsl(var(--nc))'
    controlWrapper.style.width = '8rem'
    controlWrapper.style.height = '3rem'
    controlWrapper.style.borderRadius = '0.5rem'
    controlWrapper.style.userSelect = 'none'
    controlWrapper.style.margin = '0.5rem'
    if (isHistory) {
        controlWrapper.style.marginBottom = '3rem'
    }

    controlDiv.appendChild(controlWrapper)

    // Set CSS for the zoomIn
    const mapButton = document.createElement('div')
    // @ts-ignore => indexing
    Object.keys(leftButton).forEach(style => mapButton.style[style] = leftButton[style])
    mapButton.innerText = 'Map'

    controlWrapper.appendChild(mapButton)

    // Set CSS for the zoomOut
    const satelliteButton = document.createElement('div')
    // @ts-ignore => indexing
    Object.keys(rightButton).forEach(style => satelliteButton.style[style] = rightButton[style])
    satelliteButton.innerText = 'Satellite'
    controlWrapper.appendChild(satelliteButton)

    const cur = map.getMapTypeId()
    if (cur === google.maps.MapTypeId.ROADMAP) {
        mapButton.style.fontWeight = '500'
        satelliteButton.style.fontWeight = '300'
    } else {
        mapButton.style.fontWeight = '300'
        satelliteButton.style.fontWeight = '500'
    }

    // Setup the click event listener - zoomIn
    mapButton.addEventListener('click', function () {
        map.setMapTypeId(google.maps.MapTypeId.ROADMAP)
        mapButton.style.fontWeight = '500'
        satelliteButton.style.fontWeight = '300'
    })

    // Setup the click event listener - zoomOut
    satelliteButton.addEventListener('click', function () {
        map.setMapTypeId(google.maps.MapTypeId.SATELLITE)

    })
}

export const createMapTypeControl = (map: google.maps.Map, isHistory?: boolean) => {
    const mapTypeDiv = document.createElement('div')
    mapTypeControl(mapTypeDiv, map, isHistory)
    // @ts-ignore
    mapTypeDiv.index = 0
    if (isHistory) {
        map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(mapTypeDiv)
    } else {
        map.controls[google.maps.ControlPosition.LEFT_TOP].push(mapTypeDiv)
    }
}

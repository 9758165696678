import { faShield } from '@fortawesome/free-solid-svg-icons/faShield'
import { faShieldCat } from '@fortawesome/free-solid-svg-icons/faShieldCat'
import { faShieldDog } from '@fortawesome/free-solid-svg-icons/faShieldDog'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { petFromDevice } from '../../../helpers/dataHelpers'
import dataStore from '../../../store/dataStore'
import { IPet } from '../../../interfaces/types'

interface Props {
    pet: IPet
    desktop?: boolean
}

export const MiniSafeZoneStatus = (props: Props) => {
    const {pet, desktop} = props
    const [color, setColor] = React.useState('text-neutral-content')
    const [status, setStatus] = React.useState('')
    const [zone, setZone] = React.useState('')
    const [icon, setIcon] = React.useState<React.ReactNode | null>(null)
    const devices = dataStore((s) => s.devices)
    const safeZones = dataStore(s => s.safeZones)

    React.useEffect(() => {
        if (devices) {
            const dev = devices.find(d => d.petId === pet._id)
            if (dev) {
                if (safeZones.length) {
                    if (dev?.status.safeZoneDate) {
                        const pet = petFromDevice(dev)
                        const speciesRecord = dataStore.getState().species.find(s => s._id === pet.species)
                        const speciesIcon = speciesRecord?.species_name.toLowerCase() === 'dog' ? faShieldDog : faShieldCat
                        if (dev.status.safeZoneViolation) {
                            setIcon(true)
                            setStatus('Out of')
                            setZone('all zones')
                            setColor('text-error')
                            setIcon(<FontAwesomeIcon icon={faTriangleExclamation} fontSize={'18px'}
                                                     className={'text-error'}/>)
                        } else {
                            setIcon(<FontAwesomeIcon icon={speciesIcon} fontSize={'18px'} className={'text-success'}/>)
                            setColor('text-green-600')
                            setStatus(`In zone`)
                            setZone(dev.status.occupiedZone)
                        }
                        // setIcon(getIcon(dev.status, color))
                    } else {
                        setStatus('No Safe Zone Events')
                        // setIcon(getIcon(dev?.status, color))
                        setColor('text-primary')
                        setIcon(<FontAwesomeIcon icon={faShield} fontSize={'18px'}
                                                 className={'text-neutral-content/50'}/>)
                    }
                } else {
                    setStatus('No Zones')
                    setColor('text-primary')
                    setIcon(<FontAwesomeIcon icon={faShield} fontSize={'18px'} className={'text-neutral-content/50'}/>)
                    // setIcon(getIcon(dev.status, color))
                    // setBackgroundColor('bg-info')
                }
            }
        }
    }, [devices])

    return (
            <div className={`flex items-center justify-center h-full ${!desktop ? 'bg-white px-4' : 'px-1 '}`}>
                <div className={`flex flex-col flex-1 text-md  ${color} ${desktop ? 'p-2 rounded bg-white -mt-3' : ''}`}>
                    <div>
                        {icon} {status}
                    </div>
                    <div>
                        {zone}
                    </div>
                </div>
            </div>
    )
}

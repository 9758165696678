import * as React from 'react'
import toast from 'react-hot-toast'
import { sendCommand } from '../../../helpers/commandHelper.ts'
import dataStore from '../../../store/dataStore.ts'
import { IDevice } from '../../../interfaces/types.ts'
import { AsyncButton } from '../AsyncButton/AsyncButton.tsx'
import { PopupPortal } from '../Popup/PopupPortal.tsx'
import { SelectRate } from './SelectRate/SelectRate.tsx'

interface Props {
    tracker: IDevice
    open: boolean
    setOpen: (o: boolean) => void
    full?: boolean
}

export const ChangeCheckInRate: React.FC<Props> = (props) => {
    const {tracker, open, setOpen, full} = props
    
    // const [selectedTrackerId, setSelectedTrackerId] = React.useState((tracker as IDevice)._id)
    const [selectedInterval, setSelectedInterval] = React.useState('')
    
    // don't send command if nothing has changed - selection must be different from the actual device setting
    const [deviceInterval, setDeviceInterval] = React.useState('0')
    
    // decoration
    const [petName, setPetName] = React.useState('')
    
    const [buttonEnabled, setButtonEnabled] = React.useState(false)
    
    // const devices = dataStore(s => s.devices)
    const pets = dataStore(s => s.pets)
    
    React.useEffect(() => {
        if (tracker && open) {
            setSelectedInterval(String(tracker.status.backgroundInterval))
            setDeviceInterval(String(tracker.status.backgroundInterval))
            const pet = pets.find(p => p._id === tracker.petId)
            setPetName(pet?.petName || '')
            setButtonEnabled(false)
        } else {
            setSelectedInterval('')
        }
    }, [open])
    
    const doRateChange = async () => {
        // const device = devices.find(d => d._id === selectedTrackerId)
        const success = await sendCommand('bgi', tracker?.iccid as string, selectedInterval)
        setOpen(false)
        if (success) {
            toast.success('Check-in rate command sent')
        } else {
            toast.error('Error, Check-in rate command failed. Try again later.')
        }
    }
    
    // const changeTracker = (tracker: any) => {
    //     const selection = devices.find(p => p._id === tracker.value)
    //     setSelectedTrackerId(selection?._id)
    // }
    
    const changeInterval = (interval: string) => {
        if (interval !== deviceInterval) {
            setButtonEnabled(true)
            setSelectedInterval(interval)
        }
    }
    
    const cancel = () => {
        setSelectedInterval('')
        setOpen(false)
    }
    
    const panelHeader = (
        <div className={'prose flex flex-col justify-start items-center gap-1 p-2'}>
            <h2 className={'mt-0 mb-0 text-primary'}>Change the Check-in Rate</h2>
            <div className={'text-primary flex items-center gap-2'}>
                <div>for</div> <div className={'text-xl font-semibold'}>{petName}</div>
            </div>
        </div>
    )
    
    const panel = (
        <div className={'flex flex-col justify-center items-center gap-4 w-full p-2'}>
            {panelHeader}
            {/*<SelectTracker onlyShowActive={false} onChange={changeTracker} value={selectedTrackerId}/>*/}
            <SelectRate selected={selectedInterval} onChange={changeInterval}/>
        </div>
    )
    
    const buttons = (
        <div className={'p-4 flex justify-between items-center w-full'}>
            <button className={`btn btn-secondary btn-ghost text-secondary btn-sm ${full ? 'w-full': ''}`} onClick={cancel}>Cancel</button>
            <AsyncButton classNames={'btn btn-outlined btn-secondary btn-sm'} onClick={doRateChange}
                         disabled={!buttonEnabled}>Change</AsyncButton>
        </div>
    )
    
    return (
        <PopupPortal open={open}>
            {panel}
            {buttons}
        </PopupPortal>
    )
}

import { create } from 'zustand'
import { DateTime } from 'luxon'
import { IPoint } from '../interfaces/types.ts'

export interface MovieState {
    playbackSpeed: 'fast' | 'slow'
    playIndex: number
    frameMarkers: any[]
    indexTime: DateTime
    intervalTimer: any
    locations: IPoint[]

    animateLat: number
    animateLng: number
    deltaLat: number
    deltaLng: number
    step: number
    curStep: number
    distance: number
    speed: number
    rotation: number

    startDate: DateTime,
    endDate: DateTime,

    playForward: boolean,
    playReverse: boolean,
    playbackView: 'close' | 'far'

    setPlaybackSpeed: (s: 'fast' | 'slow') => void
    setPlayIndex: (i: number) => void
    setFrameMarkers: (m: any[]) => void
    setIndexTime: (t: DateTime) => void
    setIntervalTimer: (i: any) => void
    setLocations: (locations: IPoint[]) => void
    setAnimatePosition: (lat: number, lng: number) => void
    setAnimateDelta: (lat: number, lng: number) => void
    setStep: (steps: number) => void
    setDistance: (distance: number) => void
    setCurStep: (curStep: number) => void
    setSpeed: (speed: number) => void
    setPlaybackView: (s: 'close' | 'far') => void

    setAnimationState: (animationState: {
        animateLat: number,
        animateLng: number,
        deltaLat: number,
        deltaLng: number,
        step: number,
        curStep: number,
        distance: number,
        speed?: number
        rotation: number,
    }) => void

    setPlayForward: (b: boolean) => void
    setPlayReverse: (b: boolean) => void

    setFrameDates: (startDate: DateTime, endDate: DateTime) => void
}

// const isoSort = (a: IPoint, b: IPoint) => {
//     return (a.properties.receivedTime < b.properties.receivedTime) ? -1
//         : ((a.properties.receivedTime > b.properties.receivedTime) ? 1 : 0)
// }

const useStore = create<MovieState>((set) => ({
    playbackSpeed: 'fast',
    setPlaybackSpeed: (playbackSpeed: 'fast' | 'slow') => {
        set((state) => {
            return {
                ...state,
                playbackSpeed
            }
        })
    },
    playIndex: 0,
    setPlayIndex: (playIndex: number) => {
        set((state) => {
            return {
                ...state,
                playIndex
            }
        })
    },
    frameMarkers: [],
    setFrameMarkers: (frameMarkers: any[]) => {
        set((state) => {
            return {
                ...state,
                frameMarkers,
            }
        })
    },
    indexTime: DateTime.now(),
    setIndexTime: (indexTime: DateTime) => {
        set((state) => {
            return {
                ...state,
                indexTime
            }
        })
    },
    locations: [],
    setLocations: (locations: IPoint[]) => {
        set((state) => {
            return {
                ...state,
                locations,
            }
        })
    },
    intervalTimer: 0,
    setIntervalTimer: (i: any) => {
        set((state) => {
            return {
                ...state,
                intervalTimer: i,
            }
        })
    },

    animateLat: 0,
    animateLng: 0,
    setAnimatePosition: (lat: number, lng: number) => {
        set((state) => {
            return {
                ...state,
                animateLat: lat,
                animateLng: lng,
            }
        })
    },

    deltaLat: 0,
    deltaLng: 0,
    setAnimateDelta: (lat: number, lng: number) => {
        set((state) => {
            return {
                ...state,
                deltaLat: lat,
                deltaLng: lng,
            }
        })
    },

    step: 0,
    setStep: (step: number) => {
        set((state) => {
            return {
                ...state,
                step,
            }
        })
    },

    distance: 0,
    setDistance: (distance: number) => {
        set((state) => {
            return {
                ...state,
                distance,
            }
        })
    },

    curStep: 0,
    setCurStep: (curStep: number) => {
        set((state) => {
            return {
                ...state,
                curStep,
            }
        })
    },

    speed: 100,  // kph
    setSpeed: (speed: number) => {
        set((state) => {
            return {
                ...state,
                speed,
            }
        })
    },
    rotation: 0,
    setAnimationState: (animationState: {
        animateLat: number,
        animateLng: number,
        deltaLat: number,
        deltaLng: number,
        step: number,
        curStep: number,
        distance: number,
        speed?: number
        rotation: number
    }) => {
        set((state) => {
            return {
                ...state,
                ...animationState,
            }
        })
    },

    startDate: DateTime.now(),
    endDate: DateTime.now(),
    setFrameDates: (startDate: DateTime, endDate: DateTime) => {
        set((state) => {
            return {
                ...state,
                startDate,
                endDate,
            }
        })
    },

    playForward: false,
    setPlayForward: (playForward: boolean) => {
        set((state) => {
            return {
                ...state,
                playForward,
            }
        })
    },
    playReverse: false,
    setPlayReverse: (playReverse: boolean) => {
        set((state) => {
            return {
                ...state,
                playReverse,
            }
        })
    },

    playbackView: 'close',
    setPlaybackView: (playbackView: 'close' | 'far') => {
        set((state) => {
            return {
                ...state,
                playbackView
            }
        })
    },

    reset: () =>
        set((state) => ({
            ...state,
            playIndex: 0,
            intervalTimer: 0,
            playbackSpeed: 'fast',
        })),
}))

export default useStore

import * as React from 'react'

interface Props {
	cancel: () => void
	errors: any
	isCreate?: boolean
	changed?: boolean
}

export const SubmitCancelButtons: React.FC<Props> = (props) => {
	const {cancel, errors, isCreate, changed} = props

	const isDisabled = changed !== undefined ? !changed || Object.keys(errors).length > 0 : Object.keys(errors).length > 0
    return (
		<div className={'flex items-center justify-around'}>
			<input
				type={'button'}
				className={'btn btn-ghost text-primary'}
				onClick={() => cancel()}
				value={'Close'}
			/>
			<input
				type={'submit'}
				className={'btn btn-primary'}
				value={`${isCreate ? 'Create' : 'Update'}`}
				disabled={isDisabled}
			/>
		</div>
	)
}

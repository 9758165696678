import { Navigate } from "react-router-dom";
import authStore from '../store/authStore.ts'
import dataStore from '../store/dataStore.ts'

// @ts-ignore
export const ProtectedRoute = ({ children }) => {
    const token = authStore.getState().token;
    if (!token) {
        // user is not authenticated
        authStore.getState().reset()
        dataStore.getState().reset()
        return <Navigate to="/" />;
    }
    return children;
};

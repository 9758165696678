import * as React from 'react'
import controlStore from '../../store/controlStore.ts'
import { PopupPortal } from './Popup/PopupPortal.tsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan'
import { AsyncButton } from './AsyncButton/AsyncButton.tsx'

interface Props {
    safeZone: any
    onRemove: (id: string) => Promise<any>
}

export const RemoveSafeZonePopup: React.FC<Props> = (props) => {
    const {safeZone, onRemove} = props
    const [open, setOpen] = React.useState(false)

    const isMobile = controlStore(s => s.isOnMobileDevice)
    const iconSize = isMobile ? '2x' : '1x'

    const cancel = () => {
        setOpen(false)
    }

    const doRemove = async () => {
        await onRemove(safeZone._id)
        setOpen(false)
    }

    const panelHeader = (
        <div className={'prose flex flex-col justify-start items-center gap-2'}>
            <h2 className={'mt-0 mb-0 text-primary'}>Remove a Safe Zone</h2>
        </div>
    )

    const panel = (
        <div className={'flex flex-col justify-start items-center gap-2 h-full p-4 w-full'}>
            {panelHeader}
            <div className={'text-neutral-content flex flex-col justify-start items-center gap-4'}>
                <div className={'font-semibold text-center text-lg w-full'}>
                    Are you sure you want to remove the safe zone {safeZone.zone_name}?
                </div>
                <article className={'text-center text-accent'}>
                    This action cannot be undone.
                </article>
            </div>
        </div>
    )

    const button = (
        <div className={`${!isMobile ? 'tooltip tooltip-left' : ''}`} data-tip={`Remove ${safeZone.zone_name}`} onClick={() => setOpen(true)}>
            <FontAwesomeIcon icon={faTrashCan} size={iconSize}/>
        </div>
    )

    const panelButtons = (
        <div className={'p-4 flex justify-between items-center w-full'}>
            <button className={'btn btn-secondary btn-ghost text-secondary btn-sm'} onClick={cancel}>Cancel</button>
            <AsyncButton classNames={'btn btn-outlined btn-secondary btn-sm'} onClick={doRemove}
                         disabled={false}>Remove</AsyncButton>
        </div>
    )

    const popUp = (
        <PopupPortal open={open}>
            {panel}
            {panelButtons}
        </PopupPortal>
    )

    return !open ? button : (<>
        {popUp}
    </>)
}

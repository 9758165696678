import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye'
import { Label } from '../components/Label.tsx'
import { Input } from '../components/Input.tsx'

interface Props {
    formField: string
    title: string,
    errors: Record<string, any>
    color?: string
    complete?: boolean
}

// eslint-disable-next-line react/display-name
export const Password = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    const { formField, title, errors, color, complete, ...rest } = props
    const [showPassword, setShowPassword] = React.useState(false)

    const bundle = {
        ...rest,
        type: showPassword ? 'text' : 'password',
        ref,
        color: color,
    } as any

    return (
        <div className={'w-full flex flex-col items-start'}>
            <Label formField={formField} title={title} errors={errors} color={color} complete={complete}/>
            <div className={'w-full relative'}>
                    <Input {...bundle}/>

                <div className={'absolute top-[3px] right-4'} onClick={() => setShowPassword(!showPassword)}>
                    {!showPassword ? <FontAwesomeIcon icon={faEye} color={'gray'}/> : <FontAwesomeIcon icon={faEyeSlash} color={'gray'}/>}
                </div>
            </div>
        </div>
    )
})

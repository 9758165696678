import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { deviceFromPet, readableSeconds } from '../../helpers/dataHelpers.ts'
import { IPet } from '../../interfaces/types.ts'
import dataStore from '../../store/dataStore.ts'
import drawerStore from '../../store/drawerStore.ts'
import { AlertSettingsButton } from '../common/buttons/AlertSettingsButton.tsx'
import { CheckinRateButton } from '../common/buttons/CheckinRateButton.tsx'
import { PetEventsButton } from '../common/buttons/PetEventsButton.tsx'
import { Battery } from '../dashboard/components/Battery.tsx'
import './Dashboard2.css'
import { DeviceDetail } from './DeviceDetail.tsx'
import { centerMapOnPet } from '../map/mapHelpers.tsx'
import { DateTime } from 'luxon'
import authStore from '../../store/authStore.ts'

interface Props {

}

const createBooleanArray = (length: number, initialValue = false): boolean[] => {
    return Array.from({length}, () => initialValue)
}

export const Dashboard2: React.FC<Props> = (props) => {
    const {} = props
    const devices = dataStore((s) => s.devices)
    const pets = dataStore(s => s.pets)
    const navigate = useNavigate()
    const showPetList = drawerStore(s => s.petList)
    
    const [open, setOpen] = React.useState<boolean[]>(createBooleanArray(devices.length, false))
    const [more, setMore] = React.useState<boolean[]>(createBooleanArray(devices.length, false))
    const [showOtherPets, setShowOtherPets] = React.useState<boolean>(false)
    
    const doEdit = (pet: IPet) => {
        const state = {pet}
        navigate('/pets', {state})
    }

    const petsWithSorted = pets.filter((p: IPet) => deviceFromPet(p)).sort((a: IPet, b: IPet) => a.petName > b.petName ? 1 : -1)

    const petsWithDevices = petsWithSorted.map((pet: IPet, index: number) => {
        const d = deviceFromPet(pet)
        let safeZone = 'In'
        if (d.status.safeZoneViolation) {
            safeZone = 'Out'
        }
        const rate = readableSeconds(d.status.backgroundInterval).split(' ')
        const locationDate = d.lastLocation?.properties?.receivedTime ? DateTime.fromISO(d.lastLocation.properties.receivedTime)
        .setZone(authStore.getState().timezone.value).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
            : 'No locations received'
        const powerSavingMode = d.status.beaconInRange

        const locationInSeconds = d.lastLocation?.properties?.receivedTime ? DateTime.fromISO(d.lastLocation.properties.receivedTime)
        .setZone(authStore.getState().timezone.value).toSeconds() : 0
        const now = DateTime.now().setZone(authStore.getState().timezone.value).toSeconds()

        let staleData = false
        if (now - locationInSeconds > (2 * d.status.backgroundInterval)) {
            staleData = true
        }

        const collapse = (
            <div>
                <button type="button"
                        className={`collapsible p-0`}
                        onClick={() => {
                            const update = open.slice(0)
                            update[index] = !update[index]
                            if (!update[index]) {
                                const u = more.slice(0)
                                u[index] = false
                                setMore(u)
                            }
                            setOpen(update)
                        }}
                >
                    <div className="py-0 flex items-center justify-between gap-4">
                        <div className={'flex-1 font-semibold text-2xl'}>{pet.petName}</div>
                        <div className={'text-md'}>{d.deviceName}</div>
                        {open[index] ? <FontAwesomeIcon icon={faChevronRight} className={'ml-2'}/> :
                            <FontAwesomeIcon icon={faChevronDown} className={'ml-2'}/>
                        }
                    </div>
                </button>
                <div className={`content ${open[index] ? 'block' : 'hidden'} p-2`}>
                    <div className="pb-0 flex flex-col justify-center gap-2 py-0">
                        <div className={'flex items-center justify-around'}>
                            <PetEventsButton pet={pet} outline />
                            <CheckinRateButton tracker={d} outline textColor={'text-primary-content'}/>
                            <AlertSettingsButton pet={pet} outline />
                        </div>
                        <div>
                            <button className={'btn btn-xs btn-ghost text-secondary pb-2'}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        const update = more.slice(0)
                                        update[index] = !update[index]
                                        setMore(update)
                                    }}
                            >
                                Detailed Report
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )

        const staleWarning = staleData && !powerSavingMode ? (
            <div className={'rounded px-1 h-4 m-auto w-fit text-center bg-primary-content text-sm text-accent font-bold leading-[14px]'}>
                Check-in interval missed
            </div>
        ) : null

        const inPowerSavingMode = powerSavingMode ?
            <div className={'rounded px-1 h-4 m-auto w-fit text-center bg-primary-content text-sm text-accent font-bold leading-[14px]'}>
                Power Save Mode
            </div>
            : null
        
        const off =  !d.status.powerOn ?
            <div className={'rounded px-1 h-4 m-auto w-fit text-center bg-primary-content text-sm text-accent font-bold leading-[14px]'}>
                Gibi Off
            </div>
            : null
        
        const displayedDate = (
            <div className={'flex flex-col'}>
                <div className={'text-primary-content text-lg'}>{locationDate}</div>
                {off || inPowerSavingMode || staleWarning}
            </div>
        )

        return (
            <div key={d._id} id={d._id} className={`flex items-center ${showPetList ? 'show' : ''} popup`}>
                <div onClick={() => centerMapOnPet(pet)}>
                    <div
                        className={'flex flex-col p-2 w-[20rem] gap-1 bg-primary text-primary-content rounded-xl ml-2'}>
                        {displayedDate}
                        <div className={'flex items-center justify-start gap-4 w-full'}>
                            <div className={'rounded-full border border-2 border-white'}>
                                <div className={'rounded-full border border-4'} style={{borderColor: pet.iconColor}}>
                                    <div className={'rounded-full border border-2 border-white cursor-pointer'} onClick={() => doEdit(pet)}>
                                        <img className={'rounded-full w-[60px] h-[60px]'} src={pet.imageURL}/>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`font-semibold text-lg flex items-center justify-center rounded-full w-[40px] h-[40px] border-2 border-white ${safeZone === 'In' ? 'bg-success' : 'bg-error'}`}>
                                {safeZone}
                            </div>
                            <div className={'self-end'}>
                                <Battery pet={pet} iconOnly/>
                            </div>
                            <div className={'flex-1'}>
                                <div className={'flex flex-col items-center justify-center'}>
                                    <div className={'text-sm text-primary-content'}>
                                        Check-in
                                    </div>
                                    <div className={'text-2xl font-semibold text-primary-content'}>
                                        {rate[0]}
                                    </div>
                                    <div className={'text-sm text-primary-content'}>
                                        {rate[1]}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {collapse}

                    </div>
                </div>
                {open[index] ?
                    <DeviceDetail device={d} show={more[index]}/>
                    : null}
            </div>
        )
    })
    
    const petsWithoutDevices = pets.map((pet: IPet) => {
        const device = deviceFromPet(pet)
        if (!device) {
            return (
                <div key={pet._id} id={pet._id} className={`flex items-center ${showPetList ? 'show' : ''} popup`}>
                    <div className={'cursor-pointer'}>
                        <div
                            className={'flex flex-col p-2 pl-0 w-[16rem] bg-primary/90 text-primary-content rounded-xl ml-0'}>
                            <div className={'flex items-center justify-start gap-4 w-full'}
                                 onClick={() => doEdit(pet)}
                            >
                                <div className={'rounded-full border border-2 border-white'}>
                                    <div className={'rounded-full border border-4'}
                                         style={{borderColor: pet.iconColor}}>
                                        <div className={'rounded-full border border-2 border-white'}>
                                            <img className={'rounded-full w-[40px] h-[40px]'} src={pet.imageURL}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className={'font-semibold text-xl text-left'}>{pet.petName}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }).filter(n => n)
    
    
    const otherPets = showPetList ? (
        <div className={`${open.includes(true) ? !showOtherPets ? 'ml-[5rem]' : 'ml-[2rem]' : 'ml-auto mr-auto'}`}>
            <button type="button"
                    className={`collapsible p-0 ml-4`}
                    onClick={() => setShowOtherPets(!showOtherPets)}
            >
                <div className="py-2 flex items-center justify-center gap-4">
                    <div className={'font-semibold text-xl text-center text-primary'}>Untracked Pets</div>
                    {showOtherPets ?
                        <FontAwesomeIcon icon={faChevronRight} className={'ml-1 text-primary font-bold'} size={'xl'}/> :
                        <FontAwesomeIcon icon={faChevronDown} className={'ml-1 text-primary font-bold'} size={'xl'}/>
                    }
                </div>
            </button>
            <div className={`content ${showOtherPets ? 'block' : 'hidden'}`}>
                <div className={'flex flex-col gap-2 bg-transparent'}>
                    {petsWithoutDevices}
                </div>
            </div>
        </div>
    ) : null
    
    return (
        <div className={'flex flex-col items-start gap-2 overflow-y-auto max-h-[75vh]'}>
            {petsWithDevices}
            {otherPets}
        </div>
    )
}

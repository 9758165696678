import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import authStore from '../../../store/authStore.ts'
import controlStore from '../../../store/controlStore.ts'
import dataStore from '../../../store/dataStore.ts'
import historyStore from '../../../store/historyStore.ts'
import mapStore from '../../../store/mapStore.ts'


interface Props {
	menuAction: (a: string) => void
}

export const MainMenu: React.FC<Props> = (props) => {
	const {menuAction} = props
	const navigate = useNavigate()
	const isMobile = controlStore(s => s.isOnMobileDevice)

	const color = 'primary'

	const logout = () => {
		authStore.getState().reset()
		dataStore.getState().reset()
		mapStore.getState().reset()
		historyStore.getState().reset()
		controlStore.getState().reset()
		localStorage.removeItem('gibi')
		localStorage.removeItem('gibiUser')
		navigate('/')
	}

	const label = (
		<div className="prose text-left flex flex-col justify-start gap-3 p-4">
			<div>
				<h2 className={`text-${color} mb-0 mt-0`}>Main menu</h2>
			</div>
		</div>
	)

	const size = !isMobile ? '' : 'w-[80vw]'
	return (
		<div className={`flex flex-col justify-center item-center gap-4 bg-white p-6 rounded-md shadow-lg text-${color} ${size}`} data-theme={'gibi'}>
			{label}
			<div onClick={() => menuAction('/profile')}>
				<button className={`btn btn-wide btn-${color} btn-base-100 w-full text-lg`}>
					<span>
						Edit Profile
						<FontAwesomeIcon icon={faChevronRight} className={'ml-2'}/>
					</span>
				</button>
			</div>

			<div onClick={() => menuAction('/contacts')}>
				<button className={`btn btn-wide btn-${color} w-full text-lg`}>
					<span>
						Followers
						<FontAwesomeIcon icon={faChevronRight} className={'ml-2'}/>
					</span>
				</button>
			</div>

			<div onClick={() => menuAction('/trackers')}>
				<button className={`btn btn-wide btn-${color} w-full text-lg`}>
					<span>
						Gibi Trackers
						<FontAwesomeIcon icon={faChevronRight} className={'ml-2'}/>
					</span>
				</button>
			</div>

			<div onClick={() => menuAction('/subscriptions')}>
				<button className={`btn btn-wide btn-${color} w-full text-lg`}>
				<span>
						Gibi Subscriptions
						<FontAwesomeIcon icon={faChevronRight} className={'ml-2'}/>
					</span>
				</button>
			</div>
			
			<div className={`divider divider-${color} m-0`}></div>

			<div onClick={() => menuAction('/addTracker')}>
				<button className={`btn btn-wide btn-${color} btn-outline w-full text-lg`}>
					<span>
						Add a Gibi Tracker
						<FontAwesomeIcon icon={faChevronRight} className={'ml-2'}/>
					</span>
				</button>
			</div>
			
			<div onClick={() => menuAction('/pets')}>
				<button className={`btn  btn-wide btn-${color} btn-outline w-full text-lg`}>
					<span>
						New Pet
						<FontAwesomeIcon icon={faChevronRight} className={'ml-2'}/>
					</span>
				</button>
			</div>
			
			<div className={`divider divider-${color} m-0`}></div>
			
			<div onClick={() => menuAction('/payment')}>
				<button className={`btn btn-wide btn-${color} btn-outline w-full text-lg`}>
					<span>
						Manage Payment Method
						<FontAwesomeIcon icon={faChevronRight} className={'ml-2'}/>
					</span>
				</button>
			</div>

			<div className={`divider divider-${color} m-0`}></div>

			<div onClick={() => logout()}>
				<button className={`btn btn-wide btn-neutral text-lg`}>Logout</button>
			</div>

			<div className={'text-xs text-neutral-content/50 mt-4'}>
				<span>Version </span><span>{import.meta.env.VITE_REACT_APP_VERSION}</span>
			</div>
			{/*<div className={`divider divider-${color} m-0`}></div>*/}

			{/*<div onClick={() => menuAction('/basic')}>*/}
			{/*	<button className={`btn btn-wide btn-neutral text-lg`}>Test page</button>*/}
			{/*</div>*/}

		</div>
	)
}

import { DateTime } from 'luxon'
import * as React from 'react'
import { getEventIcon, getEventName } from '../../helpers/eventHelper.tsx'
import { IEvent } from '../../interfaces/types.ts'
import controlStore from '../../store/controlStore.ts'

interface Props {
    event: IEvent,
    index: number
}

export const EventItem: React.FC<Props> = (props) => {
    const { event, index  } = props
    const isMobile = controlStore(s => s.isOnMobileDevice)

    const dateTime = DateTime.fromISO(event.createdAt).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
    const name = getEventName(event)
    // const severity = getEventSeverity(event)
    const icon = getEventIcon(event)

    const textStyle = isMobile ? 'text-md' : 'text-lg'
    return (
        <div
            key={event._id}
            className={`flex justify-between items-center w-full ${index%2 ? 'bg-info/60' : 'bg-info/30'}`}
        >
            <div className={`flex align-start items-center gap-2 pl-2`}>
                {icon}
                <div className={`${textStyle} font-bold`}>
                    {`${name}`}
                </div>
            </div>
            <div className={`${textStyle} pr-2`}>
                {`${dateTime}`}
            </div>
        </div>
    )
}

import { DateTime } from 'luxon'
import * as React from 'react'
import controlStore from '../../../store/controlStore.ts'

interface Props {
    date: DateTime | null
    safeZone?: boolean
}

export const Timestamp: React.FC<Props> = (props) => {
    const {date, safeZone} = props
    const monthDay = date ? date.toLocaleString(DateTime.DATE_MED) : ''
    const time = date ? date.toLocaleString(DateTime.TIME_SIMPLE) : ''
    const isMobile = controlStore(s => s.isOnMobileDevice)

    const layout = isMobile ? (
        <div className={'flex flex-row text-md font-semibold gap-2 justify-end'}>
            <div>{monthDay}</div>
            <div>{time}</div>
        </div>
    ) : (
        <div className={'flex flex-row text-md font-semibold gap-1 justify-end'}>
            <div>{monthDay}</div>
            <div>{time}</div>
        </div>
    )

    return date ? layout : safeZone ? 'No safe zone events' : 'No check-ins received'
}

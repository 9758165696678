import { OverlayView, OverlayViewF } from '@react-google-maps/api'
import * as React from 'react'

interface Props {
	onClick: () => void
	position: google.maps.LatLng
	children: React.ReactNode
}

export const OverlayPanel: React.FC<Props> = (props) => {
	const {onClick, position} = props

	return (
		<OverlayViewF
			position={position}
			mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
			getPixelPositionOffset={(width, height) => ({
				x: -(width * 0.58),
				y: -(height * 1.9),
			})}
		>
			<div onClick={onClick}>
				<div className={'relative'}>
					<div className="bubble shadow-xl">
						{props.children}
					</div>
					<div className="pointer shadow-xl"></div>
				</div>
				{/*<div>*/}
				{/*	<div className="bubble blurred"></div>*/}
				{/*	<div className="pointer blurred"></div>*/}
				{/*</div>*/}
			</div>
		</OverlayViewF>
	)
}

import { faShieldHeart } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

interface Props {
    active: boolean
}
export const SafeZoneIcon: React.FC<Props> = (props) => {
    return (
        <div className={'flex justify-center items-center h-fit w-fit rounded-full bg-white border-primary border-solid border-4'}>
            <span className="fa-layers fa-fw fa-lg h-[36px] w-[36px]">
                <FontAwesomeIcon style={{color: props.active ? 'white' : 'hsl(var(--p))'}} icon={faCircle} fontSize={'36px'}/>
                <FontAwesomeIcon  inverse icon={faShieldHeart} fontSize={'36px'} transform="shrink-3" mask={faCircle} style={{color: props.active ? 'hsl(var(--p))' : 'white'}}/>
            </span>
        </div>
    )
}

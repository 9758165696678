import axios from 'axios'
import authStore from '../store/authStore'
import controlStore from '../store/controlStore.ts'

export const post = async (url: string, data: any, cancelToken?: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const token = authStore.getState().token
    try {
        const response = await axios({
            method: 'post',
            url: `${import.meta.env.VITE_REST_SERVER}${url}`,
            headers: {Authorization: `Bearer ${token}`} ,
            data,
            cancelToken,
        })
        if (response.status === 200) {
            return response;
        }
        return null
    } catch(err: any) {
        if (axios.isCancel(err)) {
            console.log('successfully aborted');
        } else {
            controlStore.getState().setShowLoader(false)

            // handle error
            console.log('post', err);
            if (err?.message === 'jwt expired') {

            }
        }
        return null
    }
}

export const get = async (url: string, cancelToken?: any) => {
    const token = authStore.getState().token
    try {
        const response = await axios({
            method: 'get',
            url: `${import.meta.env.VITE_REST_SERVER}${url}`,
            headers: {Authorization: `Bearer ${token}`},
            cancelToken,
        })
        if (response.status === 200) {
            return response
        }
        return null
    } catch(err) {
        if (axios.isCancel(err)) {
            console.log('successfully aborted');
            return null;
        } else {
            controlStore.getState().setShowLoader(false)
            console.log('getWithCredentials', err);
            throw err
        }
    }
}

export const unsecureGet = async (url: string) => {
    try {
        const response = await axios({
            method: 'get',
            url: `${import.meta.env.VITE_REST_SERVER}${url}`,
        })
        if (response.status === 200) {
            return response
        }
        return []
    } catch(err) {
        controlStore.getState().setShowLoader(false)
        console.log('unsecureGet', err);
        return []
    }
}

export const unsecurePut = async (url: string, data: any, notGibi: boolean) => {
    try {
        const actualUrl = notGibi ? url : `${import.meta.env.VITE_REST_SERVER}${url}`
        const response = await axios({
            method: 'put',
            url: actualUrl,
            data,
        })
        if (response.status === 200) {
            return response
        }
        return []
    } catch(err) {
        controlStore.getState().setShowLoader(false)
        console.log('unsecurePut', err);
        return []
    }
}

export const unsecurePost = async (url: string, data: any) => {
    try {
        const response = await axios({
            method: 'post',
            url: `${import.meta.env.VITE_REST_SERVER}${url}`,
            data
        })
        if (response.status === 200) {
            return response
        }
        return null
    } catch(err) {
        controlStore.getState().setShowLoader(false)
        console.log('unsecurePost', err);
        return err
    }
}

export const remove = async (url: string, data: any)=>{
    const token = authStore.getState().token
    try {
        const response = await axios({
            method: 'delete',
            url: `${import.meta.env.VITE_REST_SERVER}${url}`,
            headers: {Authorization: `Bearer ${token}`},
            data
        })
        if (response.status === 200) {
            return response
        }
        return null
    } catch(err) {
        controlStore.getState().setShowLoader(false)
        console.log('deleteWithCredentials', err);
        return null
    }
}

export const put = async (url: string, data: any,) => {
    const token = authStore.getState().token
    try {
        const response = await axios({
            method: 'put',
            url: `${import.meta.env.VITE_REST_SERVER}${url}`,
            headers: {Authorization: `Bearer ${token}`},
            data
        })
        if (response.status === 200) {
            return response;
        }
        return null
    } catch(err) {
        controlStore.getState().setShowLoader(false)
        console.log('putWithCredentials', err);
        return null
    }
}

export const awsFileUpload = async (url: string, data: any) => {
    try {
        const response = await axios({
            method: 'put',
            url: url,
            // headers: {Authorization: `Bearer ${userToken}`},
            data
        })
        if (response.status === 200) {
            return response;
        }
        return []
    } catch(err) {
        controlStore.getState().setShowLoader(false)
        console.log('awsFileUpload Error', err);
        return []
    }
}

export const setupResponseInterceptor = (navigate: any) => {
    axios.interceptors.response.use(
        (response) => {
            return response
        },
        (error) => {
            controlStore.getState().setShowLoader(false)
            if (error.response?.status === 500) {
                navigate('/')
            } else {
                return Promise.reject(error)
            }
        }
    )
}

import { editStyle } from './safeZoneHelper.ts'
import { SafeZonePolygon } from '../mapComponents/SafeZonePolygon.tsx'
import safeZoneStore from '../../../store/safeZoneStore.ts'

export function drawSafeZone(map: google.maps.Map) {
    const drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYLINE,
        drawingControl: false,
        polygonOptions: {
            ...editStyle,
            editable: true,
            zIndex: 1,
        },
        polylineOptions: {
            ...editStyle,
            editable: true,
            zIndex: 1,
        },
    })
    safeZoneStore.getState().setDrawingManager(drawingManager)
    window.google.maps.event.addListener(drawingManager, 'overlaycomplete', (event: any) => {
        if (event.type === google.maps.drawing.OverlayType.POLYLINE) {
            if (!safeZoneStore.getState().drawingManager?.getDrawingMode()) {
                return
            }
            const coords = event.overlay.getPath().getArray().map((p: any) => {
                return {lat: p.lat(), lng: p.lng()}
            })
            drawingManager.setDrawingMode(null)
            safeZoneStore.getState().setDrawingManager(null)
            event.overlay.setMap(null)
            const poly = <SafeZonePolygon isNew={true} coords={coords} options={editStyle}/>
            safeZoneStore.getState().setNewSafeZoneGeometry(coords)
            safeZoneStore.getState().setNewSafeZone(poly)
            safeZoneStore.getState().setView('finish')
        }
    })
    drawingManager.setMap(map)
    return drawingManager
}

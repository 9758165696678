import dataStore from '../../../store/dataStore.ts'
import { IPet } from '../../../interfaces/types.ts'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SubItem } from './SubItem.tsx'

interface Props {
    pet: IPet
}

export const PetFollowers = (props: Props) => {
    const { pet } = props

    const contacts = dataStore(s => s.contacts)
    const myFollowers = contacts.filter(c => c.pet_list?.includes(pet._id))

    const subItem = (
        <SubItem pet={pet} title={`Followers (${myFollowers.length})`}
                 icon={<FontAwesomeIcon icon={faUser} fontSize={'18px'}/>}>
            <div className={'flex items-center justify-start flex-wrap gap-4'}>
                {myFollowers.map(c => (
                    <div key={c._id} className={'text-sm flex items-center gap-1'}>
                        {c.first_name.charAt(0)} {c.last_name}
                    </div>
                ))}
            </div>

        </SubItem>
    )

    return subItem
}

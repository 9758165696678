import { faPaw } from '@fortawesome/free-solid-svg-icons'
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons/faClockRotateLeft'
import { faEarthAmericas } from '@fortawesome/free-solid-svg-icons/faEarthAmericas'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons/faEllipsisVertical'
import { faMagnifyingGlassLocation } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlassLocation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

export const menuItems = [
    'map',
    // 'pets',
    'history',
]
export const menuIcons: Record<string, React.ReactNode> = {
    map: <FontAwesomeIcon icon={faEarthAmericas}/>,
    pets: <FontAwesomeIcon icon={faPaw} />,
    history: <FontAwesomeIcon icon={faClockRotateLeft}/>,
    find: <FontAwesomeIcon icon={faMagnifyingGlassLocation}/>,
    more: <FontAwesomeIcon icon={faEllipsisVertical}/>,
}

export const menuRoute: Record<string, string> = {
    'current': 'map',
    'pets': 'dashboard',
    'history': 'history',

}

export const buttons = [
    'find',
    'more'
]



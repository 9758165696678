import * as React from 'react'
import { IDevice } from '../../../interfaces/types.ts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShuffle } from '@fortawesome/free-solid-svg-icons/faShuffle'
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons/faCircleMinus'
import { ChangeTrackerAssignment } from '../ChangeTrackerAssignment/ChangeTrackerAssignment.tsx'
import { sendCommand } from '../../../helpers/commandHelper.ts'
import dataStore from '../../../store/dataStore.ts'
import toast from 'react-hot-toast'

interface Props {
    iconOnly?: boolean
    tracker: IDevice
    short?: boolean
    unassign?: boolean
}

export const TrackerAssignmentButton: React.FC<Props> = (props) => {
    const {iconOnly, tracker, short, unassign} = props
    const [open, setOpen] = React.useState(false)
    const devices = dataStore(s => s.devices)
    const pets = dataStore(s => s.pets)

    const devicesWithPets: IDevice[] = devices.filter(d => !!d.petId)
    const canMove = pets.length !== devicesWithPets.length

    const doUnAssign = async () => {
        const resultData = await sendCommand('un-assign', tracker.iccid, undefined)
        if (resultData) {
            dataStore.getState().setDevices(resultData)
            toast.success('Tracker un-assigned')
        } else {
            toast.error('Error, Gibi Tracker un-assignment failed, try again later.')
        }
    }

    const unAssignButton =  iconOnly ? (
            <div className={'text-secondary text-xl'} onClick={() => setOpen(true)}>
                <FontAwesomeIcon icon={faCircleMinus}/>
            </div>
        ) :
        <button className={`btn btn-secondary btn-xs btn-ghost text-secondary without-ring h-fit ${!tracker?.petId ? 'hidden' : ''}`}
                disabled={!tracker?.petId}
                onClick={doUnAssign}>
            Un-assign
        </button>

    const buttonText = tracker?.petId ? short ? 'Move' : 'Assign to another Pet' :
        short ? 'Assign' : 'Assign to a Pet'

    const button = iconOnly ? (
            <div className={'text-secondary text-xl'} onClick={() => setOpen(true)}>
                <FontAwesomeIcon icon={faShuffle}/>
            </div>
        ) :
        <button className={`btn btn-secondary btn-xs btn-ghost text-secondary without-ring h-fit ${!canMove ? 'hidden' : ''}`}
                onClick={() => setOpen(true)}
                disabled={!canMove}
        >
            {buttonText}
        </button>

    return (
        <div>
            {unassign ? unAssignButton : button}
            <ChangeTrackerAssignment tracker={tracker} open={open} setOpen={setOpen}/>
        </div>
    )
}

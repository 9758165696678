import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import '../Layout.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlassLocation } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlassLocation'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons/faEllipsisVertical'
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons/faClockRotateLeft'
import { faEarthAmericas, faPaw } from '@fortawesome/free-solid-svg-icons'
import { Loader } from '../../../Router/Loader.tsx'

interface Props {
    selected: string
    setSelected: (s: string) => void
    menuOpen: boolean
    setMenuOpen: (o: boolean) => void
    findOpen: boolean
    setFindOpen: (o: boolean) => void
}

export const TapBar: React.FC<Props> = (props) => {
    const {selected, setSelected, menuOpen, setMenuOpen, findOpen, setFindOpen} = props
    const navigate = useNavigate()

    const openMenu = () => {
        if (!findOpen) {
            setMenuOpen(!menuOpen)
        }
    }

    const openFind = () => {
        if (!menuOpen) {
            setFindOpen(!findOpen)
        }
    }
    const menuItems = [
        'map',
        'history',
        'dashboard',
    ]
    const menuIcons: Record<string, React.ReactNode> = {
        map: <FontAwesomeIcon icon={faEarthAmericas}/>,
        dashboard: <FontAwesomeIcon icon={faPaw} />,
        history: <FontAwesomeIcon icon={faClockRotateLeft}/>,
        find: <FontAwesomeIcon icon={faMagnifyingGlassLocation}/>,
        more: <FontAwesomeIcon icon={faEllipsisVertical}/>,
    }

    const routeLabel: Record<string, string> = {
        map: 'map',
        history: 'history',
        dashboard: 'pets',
    }

    const buttons = [
        'find',
        'more'
    ]

    const btnActions: Record<string, { open: boolean, set: (b: boolean) => void }> = {
        find: {open: findOpen, set: openFind},
        more: {open: menuOpen, set: openMenu}
    }

    const goToThere = (place: string) => {
        setSelected(place)
        navigate(`/${place}`)
    }

    const tapBar = (
        <div id='tap-bar' data-theme={'gibi'} className="w-full h-[10vh] bg-primary tapBarContainer relative z-[100000]">
            <div className={'w-0'}>
                <Loader className={'absolute left-[27vw] -top-[60vh] z-[10000]'}/>
            </div>
            <div className="grid h-full grid-cols-5 mx-auto font-medium bg-white">
                {/* radio buttons*/}
                {menuItems.map(item => {
                    const style = selected === item ? {
                        color: 'hsl(var(--p))',
                    } : {color: 'hsl(var(--nc))', opacity: 0.8}
                    return (
                        <button key={item}
                                type="button" onClick={() => goToThere(item)}
                                className="inline-flex flex-col items-center justify-around px-4 group tapBar bg-white text-[1.5rem]"
                                style={style}
                        >
                            {menuIcons[item]}
                            <span
                                className={`text-sm capitalize`}
                                style={style}
                            >{routeLabel[item]}</span>

                        </button>
                    )
                })}

                {/* toggle buttons*/}
                {buttons.map(item => {
                    const action = btnActions[item]
                    const style = action.open ? {color: 'hsl(var(--s))'} : {color: 'hsl(var(--nc))', opacity: 0.8}
                    return (
                        <button key={item} type="button" onClick={() => action.set(!action.open)}
                                className="inline-flex flex-col items-center justify-around px-4 bg-white group tapBar text-[1.5rem]"
                                style={style}
                        >
                            {menuIcons[item]}
                            <span
                                className="text-sm capitalize"
                                style={style}
                            >{item}</span>
                        </button>
                    )
                })}
            </div>
        </div>
    )
    return tapBar
}

import * as React from 'react'
import toast from 'react-hot-toast'
import { removeFormObject } from '../../../handlers/formDataHandler.ts'
import { ly } from '../../../helpers/petHelper.tsx'
import dataStore from '../../../store/dataStore.ts'
import { IDevice, ISubscription } from '../../../interfaces/types.ts'
import { AsyncButton } from '../AsyncButton/AsyncButton.tsx'
import { PopupPortal } from '../Popup/PopupPortal.tsx'

interface Props {
    subscription: ISubscription
    setOpen: (o: boolean) => void
    open: boolean
    tracker?: IDevice
}

export const CancelTrackerSubscription: React.FC<Props> = (props) => {
    const {subscription, open, setOpen, tracker} = props
    const devices = dataStore(s => s.devices)
    const device = devices?.find(d => d.iccid === subscription.device_id)

    const doCancel = async () => {
        try {
            const data = await removeFormObject('subscription', {s: subscription.subscription, i: tracker?.iccid})
            if (data.plans && data.devices) {
                dataStore.getState().setDevicePlans(data.plans)
                dataStore.getState().setDevices(data.devices)
            }
            setOpen(false)
        } catch (e) {
            toast.error('Fatal Error, cancel failed. Try again later.')
            setOpen(false)
        }
    }

    const cancel = () => {
        setOpen(false)
    }

    const panelHeader = (
        <div className={'prose flex flex-col justify-center items-center gap-2'}>
            <h2 className={'mt-0 mb-0 text-primary text-center'}>Cancel a Gibi subscription</h2>
        </div>
    )

    const deviceName = device?.deviceName ? <b>{device?.deviceName}</b> : null
    const howDeviceIsHandled =
        <div className={'text-left'}>
            <div className={'text-md leading-6 p-2'}>
                {deviceName ? <span> This will also remove Gibi Tracker {deviceName} from your account.</span>
                    : <span>The Gibi Tracker associated with this subscription has already been removed from your account </span>}

            </div>
        </div>

    const panel = (
        <div className={'flex flex-col justify-start items-center gap-2 h-full w-full p-4'}>
            {panelHeader}
            <div className={'text-neutral-content flex flex-col justify-start items-center gap-4'}>
                <div className={'font-semibold text-center text-lg w-full'}>
                    Are you sure you want to cancel this
                    {' '}{subscription.plan_type.includes('ly') ? subscription.plan_type : ly(subscription.plan_type)} subscription?
                </div>
                {howDeviceIsHandled}
                <div className={'text-center text-accent'}>
                    This action cannot be undone.
                </div>
            </div>
        </div>
    )

    const buttons = (
        <div className={'p-4 flex justify-between items-center w-full'}>
            <button className={'btn text-secondary btn-ghost btn-sm'} onClick={cancel}>Dismiss</button>
            <AsyncButton classNames={'btn btn-outlined btn-secondary btn-sm'} onClick={doCancel}
                         disabled={false}>Cancel Subscription</AsyncButton>
        </div>
    )

    return (
        <PopupPortal open={open}>
            {panel}
            {buttons}
        </PopupPortal>
    )
}

import * as React from 'react'
import safeZoneStore from '../../../store/safeZoneStore.ts'

export enum SafeZoneInstructions  {
    DRAW = 'DRAW',
    ADDRESS = 'ADDRESS',
    COMPLETE = 'COMPLETE',
    CHOOSE = 'CHOOSE'
}
export const Instructions: React.FC = () => {
    const instructions = safeZoneStore(s => s.instructions)

    const handDrawInstructions = (
        <div className={'prose'}>
            <article>
                A cross-hairs will appear on your screen.
                Draw the outline of your polygon by clicking around the outside of the area.
                Click on the starting point to close and complete drawing the safe zone.
            </article>
        </div>
    )

    const byAddressInstructions = (
        <div className={'prose'}>
            <article>
                Enter the address where you want to make safe.
                You can edit the shape once its visible on the map.
            </article>
        </div>
    )

    const completeInstructions = (
        <div className={'prose'}>
            <article>
                Make any adjustments to the Safe Zone&apos;s shape,
                give it a name then press Save.
            </article>
        </div>
    )

    const chooseMode = (
        <div className={'prose text-md'}>
            <p>
                You can create a safe zone by entering an address or drawing on the map.
                Choose a method.
            </p>
        </div>
    )

    let content = null
    switch (instructions) {
        case SafeZoneInstructions.DRAW:
            content = handDrawInstructions
            break
        case SafeZoneInstructions.ADDRESS:
            content = byAddressInstructions
            break
        case SafeZoneInstructions.COMPLETE:
            content = completeInstructions
            break
        case SafeZoneInstructions.CHOOSE:
            content = chooseMode
            break

    }
    return content ? (
        <div id={'instruction-container'} className={''}>
            <div className={'bg-base-100/90 text-neutral-content p-2 m-1 rounded font-semibold'}>
                {content}
            </div>
        </div>
    ) : null
}

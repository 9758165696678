import * as React from 'react'
import { Controller } from 'react-hook-form'
import { Label } from '../components/Label.tsx'

interface Props {
    control: any
    formField: string
    title: string,
    errors: Record<string, any>
    options: { label: string | React.ReactElement, value: string, meta?: string }[]
    required?: boolean
    onComplete?: (b: boolean) => void
}

// eslint-disable-next-line react/display-name
export const RadioGroup: React.FC<Props> = ((props) => {
    const {control, formField, title, errors, options, required, onComplete} = props
    const [meta, setMeta] = React.useState('')
    const rules = required ? {
        required: {
            value: true,
            message: 'Required'
        },
    } : {}

    return (
        <div className={'w-full flex flex-col items-start'}>
            <Label formField={formField} title={title} errors={errors}/>
            <div className={'w-full py-1'}>
                <Controller
                    key={formField}
                    name={formField}
                    rules={rules}
                    render={({field}) => {
                        return (
                            <div className={'flex flex-col gap-2'}>
                                <div className={'flex flex-row items-center h-6'}>
                                    {options.map(o => <div
                                            key={o.value}
                                            className={`first:rounded-l-2xl border-r border-r-primary/20 last:border-r-0 last:rounded-r-2xl flex-grow basis-0 h-auto btn-outline p-1 ${field.value === o.value ? 'text-white bg-primary/60' : 'text-primary bg-white'}`}
                                            onClick={() => {
                                                onComplete && onComplete(true)
                                                o.meta && setMeta(o.meta)
                                                field.onChange(o.value)
                                            }}
                                        >
                                            {o.label}
                                        </div>
                                    )}
                                </div>
                                {meta ? <div className={'text-sm'}>{meta}</div> : null}
                            </div>
                        )
                    }}
                    control={control}
                />
            </div>
        </div>
    )
})

import * as React from 'react'
import dataStore from '../../../store/dataStore.ts'
import { DEFAULT_ZOOM } from '../types.ts'
import { PetMarker } from './PetMarker.tsx'
import controlStore from '../../../store/controlStore.ts'
import mapStore from '../../../store/mapStore.ts'

export const LastKnownLocations = () => {
    const devices = dataStore(s => s.devices)
    const pets = dataStore(s => s.pets)
    const [petLocations, setPetLocations] = React.useState<React.ReactNode[]>([])

    const follow = controlStore(s => s.followPets)
    const map = mapStore(s => s.map)

    React.useEffect(() => {
        if (devices && pets && map) {
            const update: React.ReactNode[] = []
            const bounds = new window.google.maps.LatLngBounds()
            let hasLocations = false
            devices?.forEach(d => {
                if (d.petId && d.lastLocation && Object.keys(d.lastLocation).length) {
                    const pet = pets.find(p => d.petId === p._id)
                    if (pet) {
                        const position = new window.google.maps.LatLng({
                            lat: d.lastLocation?.properties?.latitude,
                            lng: d.lastLocation?.properties?.longitude
                        })
                        update.push(<PetMarker key={pet._id} pet={pet} position={position}
                                               status={d.lastLocation.properties}/>)
                        bounds.extend(position)
                        hasLocations = true
                    }
                }
            })
            if (hasLocations) {
                setPetLocations(update)
                if (follow) {
                    mapStore.getState().setBounds(bounds)
                    map.fitBounds(bounds)
                    const listener = window.google.maps.event.addListener(map, 'idle', () => {
                        let mapZoom = map.getZoom()!
                        mapZoom = mapZoom > DEFAULT_ZOOM ? DEFAULT_ZOOM : mapZoom
                        map.setZoom(mapZoom)
                        mapStore.getState().setZoom(mapZoom)
                        window.google.maps.event.removeListener(listener)
                    })
                }
            }
        }

    }, [devices, pets, map])

    return <>{petLocations}</>
}

import { DateTime } from 'luxon'
import * as React from 'react'
import { IPoint } from '../../../../interfaces/types.ts'
import dataStore from '../../../../store/dataStore.ts'
import historyStore from '../../../../store/historyStore.ts'
import movieStore from '../../../../store/movieStore.ts'
import { IconMarker } from '../markers/IconMarker.tsx'
import { animateBetweenPoints, fetchMovieData, setMapBounds } from './movieHelper.tsx'

interface Props {
}

export const AnimatedMarker: React.FC<Props> = (props) => {
    const {} = props
    const state = movieStore(s => s)
    const selectedPetId = historyStore(s => s.selectedPetId)
    
    const position = new google.maps.LatLng({
        lat: state.animateLat,
        lng: state.animateLng,
    })
    
    // this is where all the animation happens, this effect controls all marker drawing
    let isAnimating = false
    React.useEffect(() => {
        const animate = async (l1: IPoint, l2: IPoint) => {
            isAnimating = true
            await animateBetweenPoints(l1, l2)
            isAnimating = false
        }
        if (state.playForward) {
            if (!isAnimating && state.playIndex + 1 < state.locations?.length) {
                const next = state.playIndex + 1
                movieStore.getState().setFrameDates(
                    DateTime.fromISO(state.locations[state.playIndex].properties.receivedTime),
                    DateTime.fromISO(state.locations[next].properties.receivedTime)
                )
                void animate(state.locations[state.playIndex], state.locations[next]).then(() => {
                    // sanity check if index was updated while animating
                    if (state.playIndex + 1 === next) {
                        movieStore.getState().setPlayIndex(state.playIndex + 1)
                    }
                })
            } else {
                // when index reaches the end of the array fetch more data
                if (state.locations.length) {
                    void fetchMovieData('back')
                }
            }
        } else if (state.playReverse) {
            if (!isAnimating && state.playIndex > 0) {
                // reverse just relies on previously fetched data only
                const prev = state.playIndex - 1
                movieStore.getState().setFrameDates(
                    DateTime.fromISO(state.locations[prev].properties.receivedTime),
                    DateTime.fromISO(state.locations[state.playIndex].properties.receivedTime)
                )
                void animate(state.locations[state.playIndex], state.locations[prev]).then(() => {
                    if (state.playIndex - 1 === prev) {
                        movieStore.getState().setPlayIndex(prev)
                    }
                })
            } else {
                movieStore.getState().setPlayReverse(false)
            }
        } else {
            if (state.locations.length) {
                if (state.playIndex + 1 < state.locations?.length) {
                    // display the marker at the current playIndex
                    const lat = state.locations[state.playIndex].properties.latitude
                    const lng = state.locations[state.playIndex].properties.longitude
                    movieStore.getState().setAnimatePosition(lat, lng)
                } else {
                    // when index reaches the end of the array fetch more data
                    if (state.locations.length) {
                        void fetchMovieData('back')
                    }
                }
            }
        }
    }, [state.playIndex, state.locations, state.playForward, state.playReverse, selectedPetId])
    
    React.useEffect(() => {
        if (state.locations.length) {
            if (state.playbackView === 'close') {
                setMapBounds([state.locations[state.playIndex], state.locations[state.playIndex + 1]])
            } else if (state.playbackView === 'far') {
                const s = Math.trunc(state.playIndex/10)
                setMapBounds(state.locations.slice(s, s+10))
            }
        }
    }, [state.playbackView])
    
    let marker = null
    if (state.locations.length) {
        const color = dataStore.getState().pets.find(p => p._id === state.locations?.[state.playIndex]?.properties?.itemId)?.iconColor
        marker = (
            <IconMarker
                key={`animatedMarker`}
                point={state.locations[state.playIndex]}
                color={color}
                num={1}
                position={position}
                opacity={1}
                rotation={state.rotation}
                size={30}
            />
        )
    }
    
    return marker
}

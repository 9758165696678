import * as React from 'react'
import { Loader } from '../../../Router/Loader.tsx'
import authStore from '../../../store/authStore.ts'
import { NavMenu } from '../menus/NavMenu.tsx'
import { menuIcons } from '../navHelper.tsx'
import { MapToggle } from './MapToggle.tsx'
import './MapToggle.css'
import { ModeToggle } from '../../history/components/movie/ModeToggle.tsx'

interface Props {
    selected: string
    setSelected: (s: string) => void
    findOpen: boolean
    setFindOpen: (o: boolean) => void
}

export const NavBar: React.FC<Props> = (props) => {
    const {setSelected, findOpen, setFindOpen, selected} = props

    const openFind = () => {
        setFindOpen(!findOpen)
    }

    const buttons = [
        'find',
    ]

    const btnActions: Record<string, { open: boolean, set: (b: boolean) => void }> = {
        find: {open: findOpen, set: openFind},
    }

    const navButtons = buttons.map(item => {
        const action = btnActions[item]

        return (
            <div key={item}
                 className={`bg-secondary rounded p-1 mr-2 ${action.open ? 'border-2 border-secondary-focus' : 'border-2 border-white'}`}
                 onClick={() => action.set(!action.open)}>
                <button key={item} type="button"
                        className="inline-flex flex-col items-center justify-around group without-ring text-xl bg-secondary text-secondary-content p-0"
                >
                    {menuIcons[item]}
                    <span
                        className="text-xs capitalize"
                    >{item}</span>
                </button>
            </div>
        )
    })

    const logo = (
        <div className={'h-full'}>
            <div className={'flex flex-col items-start h-full'}>
                <div className="flex items-center gap-2 h-full">
                    {/*<div className="h-full">*/}
                    {/*    <img className={'object-contain float-right'} src={'/maskable-icon.png'}*/}
                    {/*         alt={'Gibi Pets'}/>*/}
                    {/*</div>*/}
                    <div className="h-full ml-4 w-fit">
                        <img className={'object-cover h-full'} src={'/logo-white.png'}
                             alt={'Gibi GPS Pet Tracking'}/>
                    </div>
                </div>
                <div className={'text-xs text-primary-content w-max -mt-2'}>
                    <span className={'ml-12'}>Version </span><span>{import.meta.env.VITE_REACT_APP_VERSION}</span>
                </div>
            </div>
        </div>
    )

    return (
        <div id="navbar" className="navbar bg-primary h-[10vh] border-accent border-b-2 min-h-[90px]" data-theme={'gibi'}>
            <div className={'flex items-center justify-between w-full h-full'}>
                {logo}

                <div className={'h-full flex justify-center items-center gap-3'}>
                    <MapToggle setSelected={setSelected}/>
                    {selected === 'history' && <ModeToggle/>}
                </div>

                <div className={'flex items-center gap-8'}>
                    <div className="flex-col justify-start gap-2">
                        <div className={'flex items center gap-4 text-primary-content pb-2'}>
                            <div className={'text-lg font-semibold'}>
                                Welcome {authStore.getState().firstName}
                            </div>
                        </div>
                        {navButtons}
                    </div>
                    <div className={'pt-4'}>
                        <NavMenu setFindOpen={setFindOpen} findOpen={findOpen}/>
                    </div>
                </div>

            </div>
            <div className={'w-0 h-0'}>
                <Loader className={'absolute -left-[59vw] z-[10000]'}/>
            </div>
        </div>
    )
}

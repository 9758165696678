import * as React from 'react'

interface Props {
    rate: any
    onClick: (id: string) => void
    selected?: boolean
}

export const Rate: React.FC<Props> = (props) => {
    const {rate, selected, onClick} = props

    return (
        <div className={'bg-primary/10 rounded-lg p-2'}
             onClick={(e) => {
                 e.stopPropagation()
                 onClick(rate.seconds)}
             }
        >
            <label className="label cursor-pointer flex flex-col justify-center items-center gap-2">
                <input type="radio" name="radio-Interval" className="radio checked:bg-primary"
                       checked={selected} readOnly/>
                <div className="label-text text-lg text-center text-primary font-semibold">{rate.checkRate}</div>
            </label>
        </div>
    )
}

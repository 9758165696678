import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan'
import * as React from 'react'
import { IPet } from '../../../interfaces/types.ts'
import { RemovePet } from '../RemovePet/RemovePet.tsx'
import controlStore from '../../../store/controlStore.ts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
    iconOnly?: boolean
    pet: IPet
}

export const RemovePetButton: React.FC<Props> = (props) => {
    const { iconOnly, pet } = props
    const [open, setOpen] = React.useState(false)
    const isMobile = controlStore(s => s.isOnMobileDevice)

    const button = iconOnly ? (
            <div className={`text-secondary text-xl`} onClick={() => setOpen(true)}>
                <FontAwesomeIcon icon={faTrashCan}/>
            </div>
        ) :
        <div
            className={`flex flex-col items-center justify-between rounded-lg bg-secondary py-1 px-3 border-white border-2 ${!isMobile ? 'min-h-[64px]' : 'min-h-[55px]'}`}>
            <button className={`btn btn-xs btn-ghost text-white without-ring h-fit py-0 mt-2`}
                    onClick={() => setOpen(true)}>
                <FontAwesomeIcon icon={faTrashCan} size={'2xl'}/>
            </button>
            <div className={'text-sm font-semibold text-white'}>
                Delete
            </div>
        </div>

    return (
        <div className={`cursor-pointer`} onClick={() => setOpen(!open)}>
            {button}
            <RemovePet pet={pet} open={open} setOpen={setOpen}/>
        </div>
    )
}

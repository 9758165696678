import { MarkerF } from '@react-google-maps/api'
import { DateTime, DateTimeFormatOptions } from 'luxon'
import * as React from 'react'
import TimeAgo from 'react-timeago'
import { IPoint } from '../../../../interfaces/types.ts'
import { OverlayPanel } from '../../../map/components/OverlayPanel.tsx'
import { getNumberCircleIcon } from '../../../map/components/icons.ts'
import { getHistoryLabel } from '../../../../helpers/historyHelper.ts'
import { getTextColor } from '../../../../helpers/colorHelper.ts'

const DATETIME_MED_WITH_WEEKDAY: DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
}

interface Props {
    point: IPoint
    color: string
    num: number
    position: google.maps.LatLng | null
    clusterer?: any
}

export const NumberMarker: React.FC<Props> = (props) => {
    const markerRef = React.useRef(null)
    const [open, setOpen] = React.useState(false)

    const handleOnLoad = (markerInstance: any) => {
        markerRef.current = markerInstance
    }

    React.useEffect(() => {
        const tc = getTextColor(props.color)
        if (markerRef.current) {
            (markerRef.current as google.maps.Marker)?.setIcon(getNumberCircleIcon(props.color, props.num, tc === '!text-white' ? 'white' : 'black'))
        }
    }, [props.color])

    if (!props.position) {
        return null
    }

    const petInfo = (
        <div className={'flex flex-col justify-start gap-2'}>
            <div className={'flex flex-row justify-center gap-4 align-center'}>
                <div>{DateTime.fromISO(props.point.properties.receivedTime).toLocaleString(DATETIME_MED_WITH_WEEKDAY)}</div>
                <TimeAgo date={new Date(props.point.properties.receivedTime)}/>
            </div>
            <div className={'flex flex-row justify-around align-center'}>
                <div>Battery {props.point.properties.batteryPercent}</div>
                <div className="badge badge-accent">{getHistoryLabel(props.point.properties.gibiMessageType)}</div>
            </div>
        </div>
    )

    const tc = getTextColor(props.color)
    const marker = (
        <div>
            {open ?
                <OverlayPanel onClick={() => setOpen(!open)} position={props.position}>
                    {petInfo}
                </OverlayPanel>
                : null}
            <MarkerF
                position={props.position}
                onLoad={handleOnLoad}
                icon={getNumberCircleIcon(props.color, props.num, tc === '!text-white' ? 'white' : 'black')}
                onClick={() => setOpen(!open)}
                clusterer={props.clusterer}
            />
        </div>
    )

    return marker
}

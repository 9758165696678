import { unsecureGet } from '../helpers/apiHelper.ts'
import dataStore from '../store/dataStore'
import { AxiosResponse } from 'axios'
import { defer } from 'react-router-dom'
import { REGISTRATION_DATA_URL } from '../helpers/urls.ts'

export const registrationLoader = async (): Promise<any> => {
    try {
        const response = await unsecureGet(REGISTRATION_DATA_URL) as AxiosResponse
        if (response.data) {
            dataStore.getState().loadRegistrationData(response.data)
        }
    } catch (e) {
        console.log('registration data error', e)
    }
    return defer({})
}

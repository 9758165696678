import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { createContactAndAssignPet } from '../../helpers/petHelper.tsx'
import { useForm as useFormHandler } from '../../hooks/useForm.ts'
import controlStore from '../../store/controlStore.ts'
import { Section } from './components/Section.tsx'
import { LabeledInput } from './widgets/LabeledInput.tsx'
import { PhoneNumber } from './widgets/PhoneNumber.tsx'
import { RadioGroup } from './widgets/RadioGroup.tsx'
import { FormLayout } from './components/FormLayout.tsx'
import { SubmitCancelButtons } from './components/SubmitCancelButtons.tsx'
import { IContact, userEmailRegex } from '../../interfaces/types.ts'
import { SelectPets } from './widgets/SelectPets.tsx'
import { Note } from './components/Note.tsx'

const SMSOptIn = [
    { label: 'Yes', value: 'Opt-in' },
    { label: 'No', value: 'Opt-out' },
]

interface Props {
    init?: IContact
}

export const ContactForm: React.FC<Props> = (props) => {
    const {init} = props
    const location = useLocation()
    const contact = location?.state?.contact || init || {} as IContact
    const addTrackerData = location?.state?.data
    
    const isMobile = controlStore(s => s.isOnMobileDevice)

    const defaultValues = {
        _id: contact?._id || 'new',
        firstName: contact?.first_name || '',
        lastName: contact?.last_name || '',
        email: contact?.email || '',
        mobile: contact?.mobile_no || '',
        petList: !!addTrackerData ? [addTrackerData.petId] : contact?.pet_list || [],
        smsOptIn: contact?.smsOptIn || 'Opt-in',
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        trigger,
        reset,
    } = useForm({
        defaultValues: defaultValues,
        mode: 'onChange',
        reValidateMode: 'onBlur',
    })

    React.useEffect(() => {
        void trigger()
    }, [])
    
    React.useEffect(() => {
        if (init) {
            reset({
                _id: init?._id,
                firstName: init?.first_name,
                lastName: init?.last_name,
                email: init?.email,
                mobile: init?.mobile_no,
                petList: init?.pet_list,
                smsOptIn: init?.smsOptIn,
            })
        }
    }, [init])

    const navigate = useNavigate()

    const [onSubmit] = useFormHandler()

    const cancel = () => {
        const state = {
            data: {
                ...addTrackerData,
                contactId: ''
            },
        }
        navigate('/' + controlStore.getState().currentPage, {state})
    }
    const processForm = async (data: any) => {
        if (addTrackerData) {
            const contactId = await createContactAndAssignPet(addTrackerData.iccid, data)
            const state = { data: {
                    ...addTrackerData,
                    contactId
                }}
            navigate('/' + controlStore.getState().currentPage, {state})
        } else {
            void onSubmit('contact', data)
        }
    }

    const header = (
        <div className={'pt-2'}>
            <h1 className="text-3xl font-semibold text-center text-primary mb-2">Followers</h1>
        </div>
    )
    
    const width = !isMobile ? 'w-[32rem]' : ''

    return (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <form onSubmit={handleSubmit(processForm)} className={`${width} h-full`}>
            <FormLayout trigger={trigger} header={header}>

                <div className={'flex flex-col gap-2 h-[90%]'}>
                    <div className={'flex flex-col item-center gap-3'}>
                        {/*  name  */}
                        <Section>
                            <LabeledInput
                                formField={'firstName'}
                                title={'First Name'}
                                {...register('firstName' as any, {
                                    required: {
                                        value: true,
                                        message: 'Required'
                                    },
                                })}
                                errors={errors}
                            />
                            <LabeledInput
                                formField={'lastName'}
                                title={'Last Name'}
                                {...register('lastName' as any, {
                                    required: {
                                        value: true,
                                        message: 'Required'
                                    },
                                })}
                                errors={errors}
                            />
                        </Section>

                        {/*  email / cell  */}
                        <Section>
                            <PhoneNumber
                                formField={'mobile'}
                                title={'Mobile number'}
                                errors={errors}
                                control={control}
                                required
                                hideComplete
                            />
                            <LabeledInput
                                formField={'email'}
                                title={'Email'}
                                inputType={'text'}
                                {...register('email' as any, {
                                    required: {
                                        value: true,
                                        message: 'Required'
                                    },
                                    validate: (value) =>
                                        userEmailRegex.test(value) || 'not a valid email address'
                                    ,
                                })}
                                errors={errors}
                            />
                        </Section>

                        <Section>
                            <RadioGroup
                                formField={'smsOptIn'}
                                control={control}
                                options={SMSOptIn}
                                title={'Receive Text Alerts'}
                                errors={errors}
                                required
                            />
                            <Note>
                                By opting in, recipient agrees to receive texts from <b>Gibi Technologies Inc</b> to
                                the number provided.
                                <br/>
                                Message & Data Rates May Apply.
                            </Note>
                        </Section>

                        {/*  pets  */}
                        <Section>
                            <SelectPets
                                formField={'petList'}
                                title={'Alert for pets'}
                                errors={errors}
                                control={control}
                            />
                        </Section>
                    </div>

                    <SubmitCancelButtons errors={errors} cancel={cancel} isCreate={!!addTrackerData}/>

                </div>

            </FormLayout>
        </form>
    )
}

import mapStore from '../../../../store/mapStore.ts'

const button = {
    background: 'none',
    display: 'block',
    border: '0px',
    margin: '0px',
    padding: '0px',
    textTransform: 'none',
    appearance: 'none',
    position: 'relative',
    cursor: 'pointer',
    userSelect: 'none',
    overflow: 'hidden',
    width: '40px',
    height: '40px',
    top: '0px',
    left: '0px',
    fontSize: '2.25rem'
}

const wrapper = {
    userSelect: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
    borderRadius: '2px',
    cursor: 'pointer',
    backgroundColor: 'rgb(255, 255, 255)',
    width: '40px',
    height: '81px',
}

export const createZoomControl = (map: google.maps.Map) => {
    const zoomDiv = document.createElement('div')
    ZoomControl2(zoomDiv, map)
    // @ts-ignore
    zoomDiv.index = 0
    // @ts-ignore
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomDiv)
}

const MAX_ZOOM = 22
const MIN_ZOOM = 2

const ZoomControl2 = (controlDiv: HTMLDivElement, map: google.maps.Map) => {
    // Creating divs & styles for custom zoom control
    controlDiv.style.padding = '5px'

    // Set CSS for the control wrapper
    const controlWrapper = document.createElement('div')
    // @ts-ignore
    Object.keys(wrapper).forEach(style => controlWrapper.style[style] = wrapper[style])
    controlDiv.appendChild(controlWrapper)

    // Set CSS for the zoomIn
    const zoomInButton = document.createElement('div')
    // @ts-ignore => indexing
    Object.keys(button).forEach(style => zoomInButton.style[style] = button[style])
    zoomInButton.innerText = '+'
    controlWrapper.appendChild(zoomInButton)

    // Set CSS for the zoomOut
    const zoomOutButton = document.createElement('div')
    // @ts-ignore => indexing
    Object.keys(button).forEach(style => zoomOutButton.style[style] = button[style])
    zoomOutButton.innerText = '-'
    controlWrapper.appendChild(zoomOutButton)

    // Setup the click event listener - zoomIn
    zoomInButton.addEventListener( 'click', function () {
        if (mapStore.getState().mapContext === 'map') {
            const curZoom = mapStore.getState().zoom
            if (curZoom < MAX_ZOOM) {
                map.setZoom(curZoom + 1)
                mapStore.getState().setZoom(curZoom + 1)
            }
        } else {
            const curZoom = mapStore.getState().historyZoom
            if (curZoom < MAX_ZOOM) {
                map.setZoom(curZoom + 1)
                mapStore.getState().setHistoryZoom(curZoom + 1)
            }
        }
    })

    // Setup the click event listener - zoomOut
    zoomOutButton.addEventListener( 'click', function () {
        if (mapStore.getState().mapContext === 'map') {
            const curZoom = mapStore.getState().zoom
            if (curZoom > MIN_ZOOM) {
                map.setZoom(curZoom - 1)
                mapStore.getState().setZoom(curZoom - 1)
            }
        } else {
            const curZoom = mapStore.getState().historyZoom
            if (curZoom > MIN_ZOOM) {
                map.setZoom(curZoom - 1)
                mapStore.getState().setHistoryZoom(curZoom - 1)
            }
        }
    })
    return null
}

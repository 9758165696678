import { faCircleDot } from '@fortawesome/free-regular-svg-icons/faCircleDot'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import controlStore from '../../../store/controlStore.ts'
import { centerMapOnPets } from '../../map/mapHelpers.tsx'

interface Props {

}

export const CenterMapButton: React.FC<Props> = () => {

    const isOnMobile = controlStore(s => s.isOnMobileDevice)

    const size = isOnMobile ? '3x' : 'xl'

    return (
        <div onClick={centerMapOnPets} className={'w-fit h-fit cursor-pointer'}>
            <FontAwesomeIcon icon={faCircleDot} size={size}/>
        </div>
    )
}

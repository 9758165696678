import { AlertColor } from '@mui/material'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety'
import GppMaybeIcon from '@mui/icons-material/GppMaybe'
import BlurOnIcon from '@mui/icons-material/BlurOn'
import BlurOffIcon from '@mui/icons-material/BlurOff'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import SensorsIcon from '@mui/icons-material/Sensors'
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert'
import { IEvent } from '../interfaces/types.ts'
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons/faPaperPlane'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// filters
const POWER = [
    'powerUp',
    'powerDown',
    'batteryAlert',
    'beaconInRange',
    'beaconOutOfRange'
]
const CHARGER = [
    'outOfCharger',
    'inCharger',
    'fullCharge'
]
const BEACON: string[] = []

const COMMAND = [
    'DeviceCommandSent',
    'find',
    'sleepResponse',
    'wakeResponse',
    'otaUpgrade',
    'setLed',
    'ledResponse',
    'bleScanResponse',
    'noCellSignalTimerResponse',
    'alertsUpdate',
    'DeviceIsSleeping',
    'DEVICE_COMMAND_SENT',
    'COMMAND_RESPONSE',
    'START_OTA_UPGRADE',
    'DeviceIsSleeping',
    'alertsUpdate',
]

const COMMAND_LABELS: Record<string, string> = {
    START_OTA_UPGRADE: 'Firmware upgrade',
    BACKGROUND_INTERVAL: 'Check-in rate'
}

export const getEventName = (event: IEvent) => {
    if (!event) return
    const subType = event.deviceUpdate?.subType || event.commandResponse?.command || event.type
    let eventName = ''
    switch (subType) {
        case 'Feature':
            eventName = 'Position'
            break
        case 'powerUp':
            eventName = 'Power On'
            break
        case  'powerDown':
            eventName = 'Power Off'
            break
        case 'fullCharge':
            eventName = 'Full Charge'
            break
        case 'batteryAlert':
            eventName = 'Low Battery Alert'
            break
        case 'find': {
            const success = event?.commandResponse?.success
            eventName = `Find Response ${success ? 'Success' : 'Failed'}`
        }
            break
        case 'backgroundInterval':
            eventName = 'Check In Rate Changed'
            break
        case 'sleepResponse':
            eventName = 'Sleep Set'
            break
        case 'wakeResponse':
            eventName = `Gibi is Awake: ${event.deviceUpdate?.deviceStatus?.wakeReason}`
            break
        case 'otaUpgrade':
            eventName = 'Gibi Firmware Upgrade'
            break
        case 'beaconInRange':
            eventName = 'Beacon in Range'
            break
        case 'beaconOutOfRange':
            eventName = 'Beacon out of Range'
            break
        case 'inCharger':
            eventName = 'Gibi in Charger'
            break
        case 'outOfCharger':
            eventName = 'Gibi out of Charger'
            break
        case 'setLed':
            eventName = `Gibi LED Change Request`
            break
        case 'ledResponse':
            eventName = `Gibi LED Turned ${event.deviceUpdate?.deviceStatus?.ledState ? 'On' : 'Off'}`
            break
        case 'bleScanResponse':
            eventName = `Power Save Scan Rate Changed`
            break
        case 'noCellSignalTimerResponse':
            eventName = `No Cell Signal Scan Rate Changed`
            break
        case 'alertsUpdate':
            eventName = 'Alert settings updated'
            break
        case 'ledResponseOn':
            eventName = 'Gibi LED on'
            break
        case 'ledResponseOff':
            eventName = 'Gibi LED off'
            break
        case 'ledResponseAuto':
            eventName = 'Gibi LED Auto off'
            break
        case 'SET_LED':
            eventName = `Gibi LED Command ${event?.commandResponse?.success ? '' : 'Failed'}`
            break
        case 'DeviceIsSleeping':
            eventName = `Gibi has gone to sleep`
            break
        default:
            if (event?.eventType === 'COMMAND_RESPONSE') {
                eventName = getCommandNameAndStatus(event)
            }
            else if (event.eventType === 'SAFE_ZONE') {
                if (event.safeZoneEvent!.outOfZone) {
                    eventName = `Out of Safe Zone`
                }
                else {
                    eventName = 'Return to Safe Zone'
                }
            }
            else if (event.eventType === 'DEVICE_COMMAND_SENT') {
                const cmd = event.deviceUpdate?.commandRequest?.cmd
                eventName = !cmd ? event.eventType : `Sent ${COMMAND_LABELS[cmd] ? COMMAND_LABELS[cmd] : cmd} command`
            }
            else {
                eventName = event.eventType
            }
            break
    }
    return eventName
}

export const getCommandNameAndStatus = (m: IEvent) => {
    const isGood = m.commandResponse!.success
    let name
    switch (m.commandResponse!.command) {
        case 'BACKGROUND_INTERVAL':
        case 'backgroundInterval':
            name = 'Change Check in Rate'
            break
        case 'SET_BLE_SCAN':
        case 'bleScanResponse':
            name = 'Change Power Saving Rate'
            break
        case 'SET_NSC_TIMER':
        case 'noCellSignalTimerResponse':
            name = 'Change No Cell Signal Sleep'
            break
        case 'SET_MOTION_THRESHOLD':
        case 'motionThresholdResponse':
            name = 'Change Motion Threshold'
            break
        case 'ledResponse':
            name = 'Set LED'
            break
        case 'SLEEP':
            name = 'Configure Sleep'
            break
        case 'FIND':
            name = 'Find Response'
            break
        case 'START_OTA_UPGRADE':
            name = 'Upgrade firmware'
            break;
        default:
            name = m.commandResponse!.command
            break
    }
    return `${name} ${isGood ? 'Success' : 'Failure'}`
}

function getMessageTypeIcon(subType: string, size: string) {
    switch (subType) {
        // power events
        case 'powerUp':
        case  'powerDown':
        case 'fullCharge':
        case 'inCharger':
        case 'outOfCharger':
            return <PowerSettingsNewIcon sx={{fontSize: size}}/>
        case 'batteryAlert':
            return <BatteryAlertIcon sx={{fontSize: size}}/>

        // beacon events
        case 'beaconInRange':
            return <BlurOnIcon sx={{fontSize: size}}/>
        case 'beaconOutOfRange':
            return <BlurOffIcon sx={{fontSize: size}}/>

        // device command events
        case 'find':
        case 'sleepResponse':
        case 'wakeResponse':
        case 'otaUpgrade':
        case 'setLed':
        case 'ledResponse':
        case 'bleScanResponse':
        case 'noCellSignalTimerResponse':
        case 'alertsUpdate':
        case 'DeviceIsSleeping':
            return <SensorsIcon sx={{fontSize: size}}/>

        default:
            return null
    }
}

export const getEventIcon = (event: IEvent, size: string = '24px') => {
    if (!event) return null
    const subType = event.deviceUpdate?.subType || event.commandResponse?.command
    const icon = getMessageTypeIcon(subType!, size)
    if (!icon) {
        if (event.eventType === 'COMMAND_RESPONSE') {
            return event.commandResponse!.success ? <CheckCircleOutlineIcon sx={{fontSize: size}}/> : <ErrorOutlineIcon sx={{fontSize: size}}/>
        }
        if (event.eventType === 'SAFE_ZONE') {
            if (!!event.safeZoneEvent!.outOfZone || !!event.deviceUpdate?.deviceStatus?.safeZoneViolation) {
                return <GppMaybeIcon sx={{fontSize: size}}/>
            } else {
                return <HealthAndSafetyIcon sx={{fontSize: size}}/>
            }
        }
        if (event.eventType === 'DEVICE_COMMAND_SENT') {
            return <FontAwesomeIcon icon={faPaperPlane} style={{fontSize: size}}/>
        }
    }
    return icon
}

export const getEventSeverity = (event: IEvent): AlertColor => {
    const subType = event.deviceUpdate?.subType || event.commandResponse?.command
    let severity = 'info'
    switch (subType) {
        case 'batteryAlert':
            severity = 'warning'
            break
        case 'find': {
            const success = event?.commandResponse?.success
            severity = `${success ? 'success' : 'error'}`
        }
            break
        case 'wakeResponse':
            severity = `success`
            break
        case 'otaUpgrade':
            severity = 'success'
            break
        default:
            if (event?.eventType === 'COMMAND_RESPONSE') {
                severity = event.commandResponse!.success ? 'success' : 'error'
            }
            else if (event.eventType === 'SAFE_ZONE') {
                    severity = event.safeZoneEvent!.outOfZone ? 'error' : 'success'

            }
            break
    }
    return severity as AlertColor
}

export const countEventTypes = (streamData: IEvent[]) => {
    let safeCount = 0
    let chargerCount = 0
    let powerCount = 0
    let beaconCount = 0
    let commandCount = 0
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < streamData.length; i++) {
        const e: IEvent = streamData[i]
        const subType = e.deviceUpdate?.subType || e.commandResponse?.command || e.type
        if (e.eventType === 'SAFE_ZONE') {
            ++safeCount
        } else
        if (POWER.includes(e.deviceUpdate?.subType || '')) {
            ++powerCount
        } else
        if (CHARGER.includes(e.deviceUpdate?.subType || '')) {
            ++chargerCount
        } else
        if (BEACON.includes(e.deviceUpdate?.subType || '')) {
            ++beaconCount
        } else
        if (COMMAND.includes(e.eventType) ||
            COMMAND.includes(subType || '')) {
            ++commandCount
        }
    }
    return {
        safeCount,
        powerCount,
        beaconCount,
        commandCount,
        chargerCount,
    }
}

export enum GibiMessageType {
    'LOCATION_RESPONSE' = 'locationResponse',
    'BACKGROUND_LOCATION' = 'backgroundLocation',
    'POWER_UP' = 'powerUp',
    'POWER_DOWN' = 'powerDown',
    'FULL_CHARGE' = 'fullCharge',
    'BATTERY_ALERT' = 'batteryAlert',
    'FIND' = 'find',
    'BACKGROUND_INTERVAL_RESPONSE' = 'backgroundInterval',
    'SLEEP_RESPONSE' = 'sleepResponse',
    'IS_SLEEPING' = 'isSleeping',
    'WAKE_RESPONSE' = 'wakeResponse',
    'OTA_UPGRADE' = 'otaUpgrade',
    'UNKNOWN' = 'unknown',
    'BEACON_IN_RANGE' = 'beaconInRange',
    'BEACON_OUT_OF_RANGE' = 'beaconOutOfRange',
    'IN_CHARGER' = 'inCharger',
    'OUT_OF_CHARGER' = 'outOfCharger',
    'SET_LED' = 'setLed',
    'LED_RESPONSE_OFF' = 'ledResponseOff',
    'LED_RESPONSE_ON' = 'ledResponseOn',
    'LED_RESPONSE_AUTO' = 'ledResponseAuto',
    'BLE_SCAN_RESPONSE' = 'bleScanResponse',
    'NCS_RESPONSE' = 'noCellSignalTimerResponse',
    'ALERTS_UPDATE' = 'alertsUpdate',
    'MT_RESPONSE' = 'motionThresholdResponse',
    'DeviceStatusChanged' = 'DeviceStatusChanged',
    'DeviceIsSleeping' = 'DeviceIsSleeping',
    'DeviceAwoke' = 'DeviceAwoke',
    'DeviceCommandSent' = 'DeviceCommandSent',
    'SAFE_ZONE_OUT' = 'SafeZoneOut',
    'SAFE_ZONE_IN' = 'SafeZoneIn'
}

export const categorizeEvents = (filters: string[], events: IEvent[]) => {
    const powerEvents = filters.includes('powerEvents') ? events.filter(e => e.eventType === 'DEVICE_UPDATE' &&
        (e.deviceUpdate?.subType === GibiMessageType.POWER_UP ||
            e.deviceUpdate?.subType === GibiMessageType.POWER_DOWN ||
            e.deviceUpdate?.subType === GibiMessageType.BATTERY_ALERT))
    : []

    const beaconEvents = filters.includes('beaconEvents') ? events.filter(e => e.eventType === 'DEVICE_UPDATE' &&
        (e.deviceUpdate?.subType === GibiMessageType.BEACON_IN_RANGE ||
            e.deviceUpdate?.subType === GibiMessageType.BEACON_OUT_OF_RANGE))
        : []

    const chargerEvents = filters.includes('chargerEvents') ? events.filter(e => e.eventType === 'DEVICE_UPDATE' &&
        (e.deviceUpdate?.subType === GibiMessageType.IN_CHARGER || e.deviceUpdate?.subType === GibiMessageType.OUT_OF_CHARGER ||
            e.deviceUpdate?.subType === GibiMessageType.FULL_CHARGE))
        : []

    const safeZoneEvents = filters.includes('safeZoneEvents') ? events.filter(e => e.eventType === 'SAFE_ZONE') : []

    const commandEvents = filters.includes('commandEvents') ? events.filter(e => COMMAND.includes(e.eventType) ||
        COMMAND.includes(e?.deviceUpdate?.subType || '')) : []

    return {
        powerEvents,
        chargerEvents,
        beaconEvents,
        safeZoneEvents,
        commandEvents,
    }
}

export const applyFiltersToEvents = (filters: string[], events: IEvent[]) => {
    return events.filter((e:IEvent) => {
        const subType = e.deviceUpdate?.subType || e.commandResponse?.command || e.type

        if (e.eventType === 'DEVICE_UPDATE' &&
            (e.deviceUpdate?.subType === GibiMessageType.POWER_UP ||
                e.deviceUpdate?.subType === GibiMessageType.POWER_DOWN ||
                e.deviceUpdate?.subType === GibiMessageType.BATTERY_ALERT)) {
                return filters.includes('powerEvents')
        } else if (e.eventType === 'DEVICE_UPDATE' &&
            (e.deviceUpdate?.subType === GibiMessageType.BEACON_IN_RANGE ||
                e.deviceUpdate?.subType === GibiMessageType.BEACON_OUT_OF_RANGE)) {
            return filters.includes('powerEvents')
        } else if (e.eventType === 'DEVICE_UPDATE' &&
            (e.deviceUpdate?.subType === GibiMessageType.IN_CHARGER || e.deviceUpdate?.subType === GibiMessageType.OUT_OF_CHARGER ||
                e.deviceUpdate?.subType === GibiMessageType.FULL_CHARGE)) {
            return filters.includes('chargerEvents')
        } else if (e.eventType === 'SAFE_ZONE') {
            return filters.includes('safeZoneEvents')
        } else if (COMMAND.includes(e.eventType) ||
            (e.eventType === 'FIND_RESPONSE' || (e.eventType === 'DEVICE_UPDATE' && COMMAND.includes(subType || '')))) {
            return filters.includes('commandEvents')
        }
        return false
    })
}
export const ALL_FILTERS_ON = [
    'powerEvents',
    'chargerEvents',
    'beaconEvents',
    'safeZoneEvents',
    'commandEvents'
]
export const MAX_FILTERS = 5

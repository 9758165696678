import { MarkerF } from '@react-google-maps/api'
import { DateTime, DateTimeFormatOptions } from 'luxon'
import * as React from 'react'
import TimeAgo from 'react-timeago'
import { IPoint } from '../../../../interfaces/types.ts'
import { OverlayPanel } from '../../../map/components/OverlayPanel.tsx'
// import { getNumberCircleIcon } from '../../../map/components/icons.ts'
import { getHistoryLabel } from '../../../../helpers/historyHelper.ts'
// import { getTextColor } from '../../../../helpers/colorHelper.ts'
import { icon } from '@fortawesome/fontawesome-svg-core';
import { faPaw } from '@fortawesome/free-solid-svg-icons'; // Import paw icon

const DATETIME_MED_WITH_WEEKDAY: DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
}

interface Props {
    point: IPoint
    color: string
    num: number
    position: google.maps.LatLng | null
    opacity?: number;  // New Opacity Prop
    rotation?: number; // New Rotation Prop
    size?:number; //New Size Prop
}

export const IconMarker: React.FC<Props> = (props) => {
    const markerRef = React.useRef(null)
    const [open, setOpen] = React.useState(false)

    if (!props.point) return null

    const handleOnLoad = (markerInstance: any) => {
        markerRef.current = markerInstance
    }


    const getPawIcon = (color: string, opacity: number, rotation: number, size: number) => {
        // Convert the FontAwesome icon to an SVG string
        const faIcon = icon(faPaw); // Generate an SVG object for the icon
        const svgHtml = faIcon.html[0]; // Get the SVG as a string
    
        // Customize the color and size of the SVG using inline styles
        const styledSvg = svgHtml.replace(
            "<svg",
            `<svg fill="${color}" style="color: ${color}; opacity: ${opacity}; transform: rotate(${rotation}deg);" width="${size}" height="${size}"`
        );

    
        // Return the Google Maps marker icon configuration
        return {
            url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(styledSvg)}`,
            scaledSize: new google.maps.Size(size, size), // Size of the marker
            anchor: new google.maps.Point(size/2, size), // Adjust the anchor point if necessary
        };
    };

    if (!props.position) {
        return null;
    }

    // React.useEffect(() => {
    //     const tc = getTextColor(props.color)
    //     if (markerRef.current) {
    //         (markerRef.current as google.maps.Marker)?.setIcon(getNumberCircleIcon(props.color, props.num, tc === '!text-white' ? 'white' : 'black'))
    //     }
    // }, [props.color])

    if (!props.position) {
        return null
    }

    const petInfo = (
        <div className={'flex flex-col justify-start gap-2'}>
            <div className={'flex flex-row justify-center gap-4 align-center'}>
                <div>{DateTime.fromISO(props.point.properties.receivedTime).toLocaleString(DATETIME_MED_WITH_WEEKDAY)}</div>
                <TimeAgo date={new Date(props.point.properties.receivedTime)}/>
            </div>
            <div className={'flex flex-row justify-around align-center'}>
                <div>Battery {props.point.properties.batteryPercent}</div>
                <div className="badge badge-accent">{getHistoryLabel(props.point.properties.gibiMessageType)}</div>
            </div>
        </div>
    )

    //const tc = getTextColor(props.color)
    const marker = (
        <div>
            {open ?
                <OverlayPanel onClick={() => setOpen(!open)} position={props.position}>
                    {petInfo}
                </OverlayPanel>
                : null}
            <MarkerF
                position={props.position}
                onLoad={handleOnLoad}
                icon={getPawIcon(props.color, props.opacity || 1, props.rotation || 0, props.size || 40)}
                onClick={() => setOpen(!open)}
            />
        </div>
    )

    return marker
}

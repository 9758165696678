import { faCat, faChevronLeft, faChevronRight, faDog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FemaleIcon from '@mui/icons-material/Female'
import MaleIcon from '@mui/icons-material/Male'
import * as React from 'react'
import { useSwipeable } from 'react-swipeable'
import { getAge } from '../../helpers/dataHelpers.ts'
import dataStore from '../../store/dataStore.ts'
import { IPet } from '../../interfaces/types.ts'
import { Battery } from './components/Battery.tsx'
import { ControlBar } from './components/ControlBar.tsx'
import { LastEvent } from './components/LastEvent.tsx'
import { LastLocation } from './components/LastLocation.tsx'
import { LastReport } from './components/LastReport.tsx'
import { PowerSaveStatus } from './components/PowerSaveStatus.tsx'
import { SafeZoneStatus } from './components/SafeZoneStatus.tsx'
import { PetFollowers } from './components/PetFollowers.tsx'

interface Props {
    pet: IPet
    scrollPet: (n: number) => void
    hasPrev: boolean
    hasNext: boolean
    width?: string
    cur: number
    setCur: (cur: number) => void
}

export const DashboardItemMobile: React.FC<Props> = (props) => {
    const {pet, scrollPet, hasPrev, hasNext, width, cur, setCur} = props
    const devices = dataStore(s => s.devices)
    const pets = dataStore(s => s.pets)
    const speciesRecord = dataStore.getState().species.find(s => s._id === pet.species)
    const tracker = devices.find(d => d.petId === pet._id)

    const species = speciesRecord?.species_name.toLowerCase() === 'dog' ? <FontAwesomeIcon icon={faDog}/> :
        <FontAwesomeIcon icon={faCat}/>
    const gender = pet.gender.toLowerCase() === 'male' ? <MaleIcon fontSize={'small'}/> :
        <FemaleIcon fontSize={'small'}/>

    const breed = dataStore.getState().breeds?.find(b => b._id === pet.breed)?.breed_name
    const age = getAge(pet.birthDate)

    const petName = (
        <div className={'flex flex-col text-neutral-content px-4 border-l-4 bg-white mt-2'}
             style={{borderColor: pet.iconColor, marginLeft: '4px', marginRight: '4px'}}>
            <div className={'flex flex-row justify-between items-baseline'}>
                <div className={'font-bold text-2xl'}>
                    <div className={'self-start text-left'}>
                        {pet.petName}
                    </div>
                    <div className={'text-xl self-start text-left'}>
                        {breed}
                    </div>
                </div>
                <div className={'self-start text-neutral-content-content opacity-70 text-sm'}>
                    <div className={'flex flex-row justify-end gap-1 items-center'}>
                        {species} {gender}
                    </div>
                    <div className={'flex flex-row justify-end gap-1 items-center'}>
                        {pet.petWeight} lbs
                    </div>
                    <div className={'flex flex-row justify-end gap-1 items-center'}>
                        {age} old
                    </div>
                </div>

            </div>
        </div>
    )
    const noTracker = (
        <div id={'no-tracker'} className={'self-center p-2 text-xl font-semibold text-primary-content'}>
            No Tracker assigned
        </div>
    )

    const prevArrow = (
        <div className={'absolute top-[50%] left-0'} onClick={(e) => {
            e.stopPropagation()
            scrollPet(-1)
        }
        }>
            <FontAwesomeIcon icon={faChevronLeft} size={'8x'} className={'text-black/30'}/>
        </div>
    )

    const nextArrow = (
        <div className={'absolute top-[50%] right-0'} onClick={(e) => {
            e.stopPropagation()
            scrollPet(1)
        }
        }>
            <FontAwesomeIcon icon={faChevronRight} size={'8x'} className={'text-black/30'}/>
        </div>
    )

    const swipeHandlers = useSwipeable({
        onSwipedRight: () => hasPrev && scrollPet(-1),
        onSwipedLeft: () => hasNext && scrollPet(1),
    })

    const _width = width || 'w-[100vw]'

    const navButtons = (
        <div className={`flex w-full justify-center gap-4 py-2 bg-primary`}>
            {pets.map((p: IPet, i: number) =>
                <a key={p._id}
                   onClick={() => setCur(i)}
                   className={`btn btn-md text-primary-content ${i == cur ? `bg-base-300 hover:text-white` : ``}`}>
                    {i + 1}
                </a>)}
        </div>
    )

    return (
        <div className={`flex flex-col justify-between h-full`}>
            <div id={pet._id}
                 className={`flex flex-col w-full carousel-item max-h-[83vh]`} {...swipeHandlers}>
                <div className={`relative ${_width}`}>
                    {hasPrev ? prevArrow : null}
                    {hasNext ? nextArrow : null}
                    <img className={'min-w-[100vw] max-h-[305px] object-cover'} src={pet.imageURL} alt={'pet picture'}/>

                </div>
                {petName}
                <ControlBar pet={pet} tracker={tracker} isMobile={false}/>
                {tracker ?
                    <div className={'flex flex-col gap-2 p-2 rounded overflow-y-auto overflow-x-hidden'}>
                        <LastLocation pet={pet}/>
                        <LastReport pet={pet}/>
                        <LastEvent pet={pet}/>
                        <SafeZoneStatus pet={pet}/>
                        <Battery pet={pet} mobile dashboard/>
                        <PowerSaveStatus pet={pet}/>
                        <PetFollowers pet={pet}/>
                    </div>
                    : noTracker}
            </div>
            {navButtons}
        </div>
    )
}

import * as React from 'react'
import { Color } from './Color.tsx'

interface Props {
    selected: string
    onChange: (i: string) => void
}

const colors = [
    "#ae000d",
    "#5e9aff",
    "#fbb212",
    "#e21595",
    "#6fbb1c",
    '#000000'
]

export const SelectMyMarkerColor: React.FC<Props> = (props) => {
    const {selected, onChange} = props

    return (
        <div className={'grid grid-cols-6 justify-evenly flex-wrap items-center gap-2'}>
            {colors.map(r => <Color key={r} color={r} selected={selected === r} onClick={onChange}/>)}
        </div>
    )
}

import { create } from 'zustand'
import { isMobile } from '../helpers/platform.ts'

interface ControlState {
    currentPage: string;
    setCurrentPage: (p: string) => void

    myPositionColor: string
    setMyPositionColor: (c: string) => void

    followPets: boolean
    setFollowPets: (followPets: boolean) => void

    showSafeZones: boolean
    setShowSafeZone: (b: boolean) => void

    showLoader: boolean
    setShowLoader: (b: boolean) => void

    purchaseError: string,
    setPurchaseError: (e: string) => void

    changePassword: boolean,
    setChangePassword: (b: boolean) => void

    isOnMobileDevice: boolean,
    // setIsOnMobileDevice: (b: boolean) => void

    reset: () => void
}

const useStore = create<ControlState>((set) => ({
    currentPage: 'map',
    setCurrentPage: (page: string) =>
        set((state) => ({
            ...state,
            currentPage: page
        })),

    // @ts-ignore
    myPositionColor: JSON.parse(localStorage.getItem('myMarker')) || '#000000',
    setMyPositionColor: (myPositionColor: string) =>
        set((state) => ({
            ...state,
            myPositionColor
        })),

    followPets: true,
    setFollowPets: (followPets: boolean) =>
        set((state) => ({
            ...state,
            followPets
        })),

    showSafeZones: true,
    setShowSafeZone: (showSafeZone: boolean) =>
        set((state) => ({
            ...state,
            showSafeZones: showSafeZone,
        })),

    showLoader: false,
    setShowLoader: (showLoader: boolean) =>
        set((state) => ({
            ...state,
            showLoader,
        })),

    purchaseError: '',
    setPurchaseError: (purchaseError: string) =>
        set((state) => ({
            ...state,
            purchaseError
        })),

    changePassword: false,
    setChangePassword: (changePassword: boolean) =>
        set((state) => ({
            ...state,
            changePassword,
        })),

    isOnMobileDevice: isMobile(),
    // setIsOnMobileDevice: (isOnMobileDevice: boolean) =>
    //     set((state) => ({
    //         ...state,
    //         isOnMobileDevice,
    //     })),

    reset: () =>
        set((state) => ({
            ...state,
            currentPage: 'map',
            followPets: true,
            myPositionColor: '#000000',
            showSafeZones: true,
            showLoader: false,
            purchaseError: '',
            changePassword: false,
            isOnMobileDevice: false,
        })),

}));

export default useStore;

import dataStore from '../../store/dataStore.ts'
import controlStore from '../../store/controlStore.ts'
import React from 'react'
import { editStyle, showStyle } from './helpers/safeZoneHelper.ts'
import { SafeZonePolygon } from './mapComponents/SafeZonePolygon.tsx'
import safeZoneStore from '../../store/safeZoneStore.ts'

export const SafeZones = () => {
    // zones from server
    const safeZones = dataStore(s => s.safeZones)
    const editingSafeZoneId = safeZoneStore(s => s.safeZoneBeingEdited)
    const showSafeZones = controlStore(s => s.showSafeZones)
    // zones on map
    const safeZonePolygons = safeZoneStore(s => s.safeZonePolygons)

    React.useEffect(() => {
        if (safeZones) {
            clear()
            setTimeout(() => {
                draw()
            })
        }
    }, [safeZones])

    React.useEffect(() => {
        clear()
        if (safeZones && showSafeZones) {
            draw()
        }
    },[showSafeZones])

    React.useEffect(() => {
        clear()
        if (showSafeZones) {
            draw()
        }
    },[editingSafeZoneId])

    const clear = () => {
        safeZoneStore.getState().setSafeZonePolygons([])
    }

    const draw = () => {
        if (showSafeZones && safeZones?.length) {
            const polys = safeZones.map(zone => {
                const style = zone._id === editingSafeZoneId ? editStyle : showStyle
                return <SafeZonePolygon key={zone._id} zone={zone} options={style}/>
            }).filter(n => n)
            safeZoneStore.getState().setSafeZonePolygons(polys)

        }
    }

    return <>{safeZonePolygons}</>
}

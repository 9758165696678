import create from 'zustand'
import * as React from 'react'

interface DrawerState {
    drawerOpen: boolean;
    setDrawerOpen: (drawerOpen: boolean) => void;

    children: React.ReactNode;
    setChildren: (children: React.ReactNode) => void;

    drawerSize: number | string
    
    showPetList: (b: boolean) => void
    petList: boolean

    reset: () => void;
}

const useStore = create<DrawerState>((set) => ({
    drawerOpen: false,
    setDrawerOpen: (drawerOpen: boolean) => set(() => (
        {
            drawerOpen,
            drawerSize: drawerOpen ? 'auto' : 0
        })),
    
    drawerSize: 0,

    children: null,
    setChildren: (children: React.ReactNode) => set(() => ({ children })),
    
    petList: true,
    showPetList: (petList: boolean) => set(() => (
        {
            petList,
        })),
    
    reset: () => set(() => ({
        drawerOpen: false,
        children: null,
        drawerSize: 10,
    })),
}))

export default useStore

export const MAX_MOBILE_WIDTH = '768px'
export const MIN_MOBILE_HEIGHT = '700px'
export const SHORT_HEIGHT = '500px'


export type IAlertType =  string[]

export enum ItemTypesEnum {
    PET = 'pet',
    PERSON = 'person',
}

export interface IItem {
    _id: string
    accountId: string
    ownerId: string
    deviceId: string
    type: ItemTypesEnum
    name: string
    description: string
}

export interface IDeviceStatus {
    safeZoneDate: string
    safeZoneViolation: boolean
    occupiedZone?: string
    sleeping: boolean
    sleepStart: number,
    sleepDuration: number
    sleepRepeat: boolean
    wakeReason?: string
    backgroundInterval: number
    lastContact: string
    powerOn: boolean
    chargerStatus: string
    charging: boolean
    batteryAlert: number
    beaconInRange: boolean
    firmwareVersion: string
    batteryPercent: string
    powerSavingsScanRate: number
    noCellularScanRate: number
    mt: number
    ledState: number
    deviceCommandInProgressStatus: string
    alerts: string[]
}

export interface IDevice {
    _id: string
    accountId: string
    ownerId: string
    petId: string
    itemId: string
    mfg: string
    iccid: string
    status: IDeviceStatus
    item: IItem
    mdn: string
    imsi: string
    deviceName: string
    lastLocation: IPoint
    lastReport: IPoint
    lastEvent: IEvent
}

export interface IGibiLocationCommand {
    createdAt: string
    'featureType': 'gibiPosition'
    'gibiMessageType': string
    'account': string
    'status': number
    'timeStamp': number
    'latitude': number
    'longitude': number
    'altitude': number
    'speed': number
    'heading': number
    'accuracy': number
    'ttf': number
    'battery': string
    'chargerStatus': string
    'chargingStatus': number
    'deviceId': string
    'receivedTime': string
    'rawData': string
    batteryPercent?: string
    itemId?: string
}

export interface IPoint {
    _id: string
    type: 'Feature',
    geometry: {
        type: string,
        coordinates: number[]
    },
    properties: IGibiLocationCommand
}

export interface ICommandRequest {
    cmd: string
}

export interface IDeviceUpdate {
    subType: string;
    deviceStatus: IDeviceStatus
    commandRequest?: ICommandRequest
}

export interface ICommandResponse {
    command: string;
    success: boolean
    errorMessage?: string
}

export interface ISafeZoneEvent {
    outOfZone: boolean
    zoneName?: string
}

export interface IEvent {
    type?: string
    _id: string
    createdAt: string
    eventType: string
    userId: string
    iccid: string
    itemId: string
    deviceUpdate?: IDeviceUpdate
    commandResponse?: ICommandResponse
    safeZoneEvent?: ISafeZoneEvent
    read: boolean
}

export interface IPet {
    colorId: string
    _id: string
    accountID: string
    microchip: string
    iconColor: string
    gender: string
    species: string
    breed: string
    petName: string
    birthDate: string
    petImage: string
    imageURL: string
    petWeight: number
    status: string
}

export interface DeviceData {
    lastValidPosition: IPoint
    lastTenPositions: IPoint[]
    lastTenEvents: IEvent[]
    unreadEvents: IEvent[]
}

export interface IAccount {
    firstName: string
    lastName: string
    email: string
    mobile: string
    smsOptIn: string
    timeZone: string
    homeAddress: IUsaAddress | ICanadaAddress
}

export interface MapDataSet {
    pets: any[]
    devices: any[]
    safeZones: IZone[]
    setSafeZones: (z: IZone[]) => void
    powerZones: any[]
}

export interface AccountDataSet {
    account: any
    billing: any[]
    contacts: any[]
    devicePlans: any[]
    paymentMethod: string
}

export interface ApplicationDataSet {
    species: any[]
    breeds: any[]
    timeZones: any[]
    iconColors: any[]
    rates: any[]
    alertTypes: any[]
    plans: any[]
    codes: any[]
}

export interface RegistrationDataSet {
    timeZones: any[]
    plans: any[]
    species: any[]
    breeds: any[]
    iconColors: any[]
}

export interface IStripePlan {
    account_id: string
    createdAt: string
    description: string
    device_id: string
    plan_amount: string
    plan_end: string
    plan_start: string
    plan_type: string
    status: string
    stripePlanID: string
    stripePriceID: string
    updatedAt: string
}

export interface EventsDataState {
    events: any[]
    unreadCount: number
}

export interface IUsaAddress {
    streetAddr: string
    apartment: string
    city: string
    state: string
    zipCode: string
}

export interface ICanadaAddress {
    address1: string
    address2: string
    address3: string
    province: string
    city: string
    postalCode: string
}

export interface IBillingInfo {
    firstName: string
    lastName: string
    country: string
    address: IUsaAddress | ICanadaAddress
}

export interface ISubscription {
    plan_amount: string
    interval: 'month' | 'year'
    id: string
    device_name: string
    plan_type: string
    description: string
    planStart: string
    planEnd: string
    plan_status: string
    billing_info: IBillingInfo
    device_id: string
    subscription: string // stripe id
    _id: string
    plan_start?: string
    plan_end?: string
}

export const gibiEmailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+(\|\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+)*$/
export const userEmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const usPhoneRegex = /^(\+0?1\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

export interface IPromoCode {
    code: string
    id: string
    name: string
}

export type ZonePath = {lat: number, lng: number}[]

export interface IZone {
    _id: string
    zone_name: string
    zone_type: 'safe' | 'power'
    zone_coordinates: ZonePath
}

export interface IContact {
    _id: any
    owner: string
    first_name: string
    last_name: string
    email: string
    mobile_no: string
    pet_list: any[]
    smsOptIn: 'Opt-in' | 'Opt-out' | 'pending'
}

import { unsecureGet, unsecurePut } from './apiHelper.ts'
import { AxiosResponse } from 'axios'
import toast from 'react-hot-toast'

export const uploadCroppedFile = async (origFile: {name: string, type: string}, croppedBase64: string) => {
    // strip off data type
    const base64 = croppedBase64.split(',')
    let blob = b64toBlob(base64[1], origFile.type)
    blob = blobToFile(blob, origFile.name)
    const fileKey = `${origFile.name}-${Date.now()}.${origFile.type}`

    const uploadUrl = await getUploadUrl(fileKey, origFile.type) as AxiosResponse<any, any>
    if (uploadUrl?.data) {
        const response = await uploadFileToAWS(uploadUrl.data, blob)
        if (response?.status === 200) {
            return fileKey
        }
    }
    toast.error('Image not saved, file upload failed')
    return ''
}

export const getUploadUrl = async (fileKey: string, fileType: string) => {
    try {
        const response = await unsecureGet('/api/v1/imagePut?key=' + fileKey + '&fileType=' + fileType) as AxiosResponse<any,any>
        console.log(response.status)
        if (response.status) {
            return response
        }
        else {
            return ''
        }

    } catch (error) {
        console.log(error)
    }
}
export const uploadFileToAWS = async (awsURL: string, data: any) => {
    try {
        const response = await unsecurePut(awsURL, data, true) as AxiosResponse<any,any>
        // console.log(response);
        if (response.status) {
            return response
        }
        else {
            return null
        }
    } catch (error) {
        console.log(error)
    }
}

/**
 * Convert a base64 string in a Blob according to the data and contentType.
 *
 * @param b64Data {String} Pure base64 string without contentType
 * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
 * @param sliceSize {Int} SliceSize to process the byteCharacters
 * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
 * @return Blob
 */
export const b64toBlob = (b64Data: string, contentType: string, sliceSize?: number) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

export const blobToFile = (theBlob: any, fileName: string) => {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
};

export const uploadAndSavePhoto = async (data: any) => {
    // if there is one, upload the pet picture first, we need save the petImageFileKey
    if (data.imageURL?.file) {
        let petImageFileKey = ''
        const origFile = data.imageURL.file
        const imgData = data.imageURL.img
        if (origFile) {
            petImageFileKey = await uploadCroppedFile(origFile, imgData)
        }
        // save the petImageFileKey and decorate the profile
        data.imageFile = petImageFileKey
        delete data.imageURL
    }
    if (data.uploadImage?.file) {
        let petImageFileKey = ''
        const origFile = data.uploadImage.file
        const imgData = data.uploadImage.img
        if (origFile) {
            petImageFileKey = await uploadCroppedFile(origFile, imgData)
        }
        data.imageFile = petImageFileKey
        delete data.uploadImage
    }
}

import * as React from 'react'
import dataStore from '../../../store/dataStore.ts'
import { IPet } from '../../../interfaces/types.ts'
import { getIcon } from './Battery.tsx'

interface Props {
    pet: IPet
    desktop?: boolean
}

export const MiniBattery: React.FC<Props> = (props) => {
    const {pet, desktop} = props

    const devices = dataStore(s => s.devices)
    const device = devices.find(d => d.petId === pet._id)

    let textColor = 'inherit'
    let percent = ''
    let status = ''
    let icon

    let isOff = false
    let isCharging = false

    if (device) {
        percent = device.status.batteryPercent
        let color
        if (!device.status.powerOn) {
            status = 'Off'
            color = 'black'
            isOff = true
        } else if (device.status.charging) {
            status = 'Charging'
            color = 'info'
            isCharging = true
        } else if (device.status?.batteryAlert) {
            status = 'Low'
            color = 'accent'
        } else {
            status = 'On'
        }
        const val = parseFloat(percent)
        if (!isCharging && !isOff) {
            if (val >= 50 && val <= 100) {
                color = 'success'
            } else if (val < 50 && val > 20) {
                color = 'warning'
            }
            if (val < 20) {
                color = 'accent'
            }
        }

        icon = getIcon(device.status, color, val)
        textColor =  color !== 'info' ? `text-${color}` : 'text-primary-content'
    }

    return (
        <div className={`flex flex-col items-center justify-center ${!desktop ? 'bg-primary text-primary-content w-[100px]' :'text-primary-content mt-2'}`}>
            <div className={'flex justify-around items-center w-full'}>
                <div>
                    {icon}
                </div>
                <div className={'text-3xl font-bold'}>
                    {!isOff ? percent : 'Off'}
                </div>
            </div>

            {desktop && !isOff ? <div className={`${textColor} justify-start font-bold`}>
                {status}
            </div> : null}
        </div>
    )
}

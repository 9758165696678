import * as React from 'react'
import { Controller } from 'react-hook-form'
import dataStore from '../../../store/dataStore.ts'
import { Label } from '../components/Label.tsx'

const selectedStyle = 'border-solid border-2 border-primary'
const excludedStyle = 'opacity-10 border-2 border-black'

interface Props {
    control: any
    formField: string
    title: string,
    errors: Record<string, any>
    required: boolean
}

// eslint-disable-next-line react/display-name
export const ColorPicker: React.FC<Props> = ((props) => {
    const { control, formField, title, errors, required } = props
    const iconColors = dataStore(s => s.iconColors)
    const pets = dataStore(s => s.pets)

    const excluded: string[] = pets && iconColors ? pets.map(p => p.colorId).filter(n => n) : []
    const rules = required ? {
        required: {
            value: true,
            message: 'Required'
        },
    } : {}

    return (
        <div className={'w-full flex flex-col items-start'}>
            <Label formField={formField} title={title} errors={errors}/>
            <div>
                <Controller
                    name={formField}
                    rules={rules}
                    render={({ field }) => {
                        const swatches =
                            <div
                                className={'flex align-start items-center gap-4 flex-wrap bg-base-100 p-2 border-solid border-1 h-full max-w[13rem]'}>
                                {
                                    iconColors.map(c =>
                                        <Swatch
                                            key={c._id}
                                            color={c.colorCode}
                                            selected={field.value === c._id}
                                            excluded={excluded.includes(c._id)}
                                            onClick={() => {
                                                if (!excluded.includes(c._id)) {
                                                    field.onChange(c._id)
                                                }
                                            }}
                                        />)
                                }
                            </div>

                        const selected = (
                            <div className={'flex justify-center items-center w-1/4'}>
                                <div className={'rounded-full border-solid border-4 border-slate-600 h-8 w-8'}>
                                    <div className={'h-full w-full rounded-full'}
                                         style={{ background: iconColors.find(i => i._id === field.value)?.colorCode as string }}
                                    />
                                </div>
                            </div>
                        )
                        return (
                            <div className={'flex items-center align-start rounded bg-white'}>
                                {swatches}
                                {selected}
                            </div>
                        )
                    }}
                    control={control}
                />
            </div>
        </div>
    )
})

interface SwatchProps {
    color: string
    onClick: () => void
    excluded?: boolean
    selected?: boolean
}

const Swatch = (props: SwatchProps) => {
    const { color, excluded, selected, onClick } = props
    return (
        <div className={'rounded-full border-solid border-1 border-slate-200'} onClick={onClick}>
            <div className={`w-6 h-6 rounded-full ${selected ? selectedStyle : ''} ${excluded ? excludedStyle : ''}`}
                 style={{ background: `${color}` }}
            />
        </div>
    )
}

// import { faCat, faDog, faVenus, faMars } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { deviceFromPet, getPetsWIthDevices, readableSeconds } from '../../helpers/dataHelpers.ts'
// import { getAge } from '../../helpers/dataHelpers.ts'
import dataStore from '../../store/dataStore.ts'
// import { MiniBattery } from '../dashboard/components/MiniBattery.tsx'
// import { MiniSafeZoneStatus } from '../dashboard/components/MinSafeZonStatus.tsx'
import './PetDrawer.css'
import { centerMapOnPet } from '../map/mapHelpers.tsx'
import { Battery } from '../dashboard/components/Battery.tsx'
import { DateTime } from 'luxon'
import authStore from '../../store/authStore.ts'
import { CheckinRateButton } from '../common/buttons/CheckinRateButton.tsx'
import { AlertSettingsButton } from '../common/buttons/AlertSettingsButton.tsx'
import { PetEventsButton } from '../common/buttons/PetEventsButton.tsx'

// const iconSize = 'xl'
export const MiniPetList: React.FC = () => {
    const petsWithDevices: string[] = getPetsWIthDevices()
    // const breeds = dataStore(s => s.breeds)
    const pets = dataStore.getState().pets.filter(p => petsWithDevices.includes(p._id)).sort((a, b) => a.petName > b.petName ? 1 : -1)

    const list = pets.map(pet => {
        // const speciesRecord = dataStore.getState().species.find(s => s._id === pet.species)
        // const species = speciesRecord?.species_name.toLowerCase() === 'dog' ? <FontAwesomeIcon icon={faDog} size={iconSize}/> :
        //     <FontAwesomeIcon icon={faCat} size={iconSize}/>
        // const gender = pet.gender.toLowerCase() === 'male' ?  <FontAwesomeIcon icon={faMars} size={iconSize}/> :
        //     <FontAwesomeIcon icon={faVenus} size={iconSize}/>
        // const breed = breeds?.find(b => b._id === pet.breed)?.breed_name
        // const age = getAge(pet.birthDate)

        // const petDetails = (
        //     <div className={'flex items-center justify-center text-primary-content pb-2'}>
        //         <div className={'flex flex-col items-center gap-1'}>
        //             <div className={'text-md min-w-[65px]'}>
        //                 {breed}
        //             </div>
        //             <div className={'opacity-70 text-md'}>
        //                 <div className={'self-center flex flex-row gap-4 justify-end items-center'}>
        //                     {species} {gender}
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // )

        const d = deviceFromPet(pet)
        let safeZone = 'In'
        if (d.status.safeZoneViolation) {
            safeZone = 'Out'
        }
        const rate = readableSeconds(d.status.backgroundInterval).split(' ')
        const locationDate = d.lastLocation?.properties?.receivedTime ? DateTime.fromISO(d.lastLocation.properties.receivedTime)
            .setZone(authStore.getState().timezone.value).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
            : 'No locations received'
        const powerSavingMode = d.status.beaconInRange

        const locationInSeconds = d.lastLocation?.properties?.receivedTime ? DateTime.fromISO(d.lastLocation.properties.receivedTime)
        .setZone(authStore.getState().timezone.value).toSeconds() : 0
        const now = DateTime.now().setZone(authStore.getState().timezone.value).toSeconds()

        let staleData = false
        if (now - locationInSeconds > (2 * d.status.backgroundInterval)) {
            staleData = true
        }

        const staleWarning = staleData && !powerSavingMode ? (
            <div className={'rounded px-1 h-4 m-auto w-fit text-center bg-primary-content text-sm text-accent font-bold leading-[14px]'}>
                Check-in interval missed
            </div>
        ) : null

        const inPowerSavingMode = powerSavingMode ?
            <div className={'rounded px-1 h-4 m-auto w-fit text-center bg-primary-content text-sm text-accent font-bold leading-[14px]'}>
                Power Save Mode
            </div>
            : null

        const off =  !d.status.powerOn ?
            <div className={'rounded px-1 h-4 m-auto w-fit text-center bg-primary-content text-sm text-accent font-bold leading-[14px]'}>
                Gibi Off
            </div>
            : null

        const displayedDate = (
            <div className={'flex flex-col'}>
                <div className={'text-primary-content text-lg'}>{locationDate}</div>
                {off || inPowerSavingMode || staleWarning}
            </div>
        )

        const indicators = (
            <div onClick={() => centerMapOnPet(pet)}>
                <div
                    className={'flex flex-col p-2 gap-1'}>
                    {displayedDate}
                    <div className={'flex items-center justify-around gap-4 w-full'}>
                        <div className={'rounded-full border border-2 border-white'}>
                            <div className={'rounded-full border border-4'} style={{borderColor: pet.iconColor}}>
                                <div className={'rounded-full border border-2 border-white cursor-pointer'}
                                     onClick={() => console.log(pet)}>
                                    <img className={'rounded-full w-[60px] h-[60px]'} src={pet.imageURL}/>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`font-semibold text-lg flex items-center justify-center rounded-full w-[40px] h-[40px] border-2 border-white ${safeZone === 'In' ? 'bg-success' : 'bg-error'}`}>
                            {safeZone}
                        </div>
                        <div className={'self-end pb-2'}>
                            <Battery pet={pet} iconOnly/>
                        </div>
                        <div className={''}>
                            <div className={'flex flex-col items-center justify-center'}>
                                <div className={'text-sm text-primary-content'}>
                                    Check-in
                                </div>
                                <div className={'text-2xl font-semibold text-primary-content'}>
                                    {rate[0]}
                                </div>
                                <div className={'text-sm text-primary-content'}>
                                    {rate[1]}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

        const buttons = (
            <div className={'h-full'}>
                <div className={'flex flex-col items-center justify-evenly h-full'}>
                    <CheckinRateButton tracker={d} outline full textColor={'text-primary-content'}/>
                    <AlertSettingsButton pet={pet} outline full/>
                    <PetEventsButton pet={pet} outline full/>
                </div>
            </div>
        )

        return (
            <div key={pet._id} className={'grid grid-cols-[66%_34%] bg-primary text-primary-content rounded-xl mx-1'}>
                {indicators}
                {buttons}
            </div>
        )

        // return (
        //     <div key={pet._id} className={'grid grid-cols-[75px_1fr] border-b-2 border-b-primary-content'}>
        //         <div className="w-[20vw] flex justify-center items-center"
        //              style={{backgroundColor: pet.iconColor}}
        //         >
        //             <div className={`w-[16vw]`}>
        //                 <img src={pet.imageURL} alt={'pet image'}/>
        //             </div>
        //         </div>
        //
        //         <div className={'flex flex-col bg-primary'}>
        //             <div
        //                 className={`text-primary-content text-left text-2xl font-semibold px-1 border-b-2 border-white/50`}>
        //                 {pet.petName}
        //             </div>
        //
        //             <div className={'grid grid-cols-[100px_90px_1fr] gap-2 bg-primary'}>
        //                 <MiniBattery pet={pet}/>
        //                 <MiniSafeZoneStatus pet={pet}/>
        //                 {petDetails}
        //             </div>
        //         </div>
        //     </div>
        // )
    })

    return <div className={'bg-primary-content/50 border-b-2 border-b-accent flex flex-col gap-2'}>{list}</div>
}

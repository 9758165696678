export const CREATE_GIBI_ACCOUNT = '/api/v2/data/user'
export const CREATE_GIBI_ACCOUNT_AND_LINK_SUBSCRIPTION = '/api/v2/data/user/new'

export const STRIPE_CREATE_CUSTOMER_AND_SUBSCRIPTION_URL = '/api/v2/stripe/create'
export const STRIPE_GET_SECRET_URL = '/api/v2/stripe/secret'
export const STRIPE_UPDATE_PAYMENT_URL = '/api/v2/stripe/payment'
export const STRIPE_SUBSCRIPTIONS_URL = '/api/v2/stripe/subscribe'
export const CHECK_DEVICE_EXISTS = '/api/v2/data/tracker/check'
export const CHECK_EMAIL_EXISTS = '/api/v2/data/user/check'
export const SAFE_ZONE_URL = '/api/v2/data/safeZone'
export const PET_EVENTS_URL = '/api/v2/data/events'

export const MAP_DATA_URL = '/api/v2/data/map'
export const ACCOUNT_DATA_URL = '/api/v2/data/account'
export const APPLICATION_DATA_URL = '/api/v2/data/application'
export const REGISTRATION_DATA_URL = '/api/v2/data/registration'
export const HISTORY_BY_TEN_URL = '/api/v2/data/history/ten'
export const TRACKER_COMMAND_URL = '/api/v2/data/command'
export const FORM_DATA_URL = '/api/v2/data/form'
export const ADD_TRACKER_URL = '/api/v2/data/tracker'
export const ADD_TRACKER2_URL = '/api/v2/data/tracker2'

export const V2_FORGOT_PASSWORD_URL = '/api/v2/auth/forgotPassword'
export const V2_RESET_PASSWORD_URL = '/api/v2/auth/resetPassword'
export const V2_CHECK_RESET_TOKEN = '/api/v2/auth/checkResetToken'
export const V2_LOGIN_URL = '/api/v2/auth/local'

export const CREATE_PET_URL = '/api/v2/data/pets'
export const CREATE_CONTRACT_URL = '/api/v2/data/contacts '

import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faClose } from '@fortawesome/free-solid-svg-icons'
import controlStore from '../../store/controlStore.ts'
// import SelectPet from '../common/SelectPet/SelectPet.tsx'
import { ScrollControl } from './ScrollControl.tsx'
// import { useLocation, useNavigate } from 'react-router-dom'


export const EventViewer: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const pet = location?.state?.pet
    
    // // const navigate = useNavigate()
    // const isMobile = controlStore(s => s.isOnMobileDevice)
    //
    const containerStyle = ''// !isMobile ? 'max-w-[75vw]' : ''
    return (
        <div className={`flex flex-col w-full justify-start gap-2 p-2 bg-base-100 ${containerStyle}`}>
            <div className={`flex flex-col w-full justify-start gap-2 p-2 ${containerStyle}`}>
                <div className={'flex flex-row w-full items-center justify-between px-4'}>
                    <div className={'text-xl font-bold'}>
                        {pet?.petName}'s Events
                    </div>
                </div>
                <ScrollControl selectedPetId={pet!._id}/>
            </div>
            <input
                type={'button'}
                className={'mt-4 btn btn-ghost text-primary'}
                onClick={() => navigate('/' + controlStore.getState().currentPage)}
                value={'Close'}
            />
        </div>
    )
}

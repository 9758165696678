import * as React from 'react'
import { drawSafeZone } from '../helpers/drawingHelper.tsx'
import { SafeZoneInstructions } from './Instructions.tsx'
import safeZoneStore from '../../../store/safeZoneStore.ts'

interface Props {
    map: google.maps.Map
}

export const SafeZoneDrawingOptions: React.FC<Props> = (props) => {
    const {map} = props
    React.useEffect(() => {
        safeZoneStore.getState().setInstructions(SafeZoneInstructions.CHOOSE)
    }, [])

    const drawZone = () => {
        safeZoneStore.getState().setInstructions(SafeZoneInstructions.DRAW)
        safeZoneStore.getState().setView('drawing')
        drawSafeZone(map)
    }

    const enterAddress = () => {
        safeZoneStore.getState().setInstructions(SafeZoneInstructions.ADDRESS)
        safeZoneStore.getState().setView('search')
    }

    const drawingChoices = (
        <div className={'w-full flex justify-around items-center'}>
            <button className={'btn btn-md btn-secondary'} onClick={drawZone}>
                Draw on map
            </button>
            <button className={'btn btn-md btn-secondary'} onClick={enterAddress}>
                Enter an address
            </button>
        </div>
    )

    const doCancel = () => {
        safeZoneStore.getState().setInstructions(null)
        safeZoneStore.getState().setView('list')
    }

    const cancel = (
        <div className={'flex justify-start gap-6 items-center'}>
            <button className={'btn btn-ghost text-secondary'} onClick={doCancel}>
                cancel
            </button>
        </div>
    )

    return (
        <div className={'flex flex-col justify-start items-center gap-2 w-full'}>
            {drawingChoices}
            <div className={'mt-4'}>
                {cancel}
            </div>
        </div>
    )
}

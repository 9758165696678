import * as React from 'react'
import { IZone, ZonePath } from '../../../interfaces/types.ts'
import { PolygonF } from '@react-google-maps/api'
import { getCenterOfSafeZone } from '../../map/mapHelpers.tsx'
import { SafeZoneLabel } from './SafeZoneLabel.tsx'
import safeZoneStore from '../../../store/safeZoneStore.ts'
import { LatLng } from '../../map/types.ts'

interface Props {
	zone?: IZone
	options: google.maps.PolygonOptions
	isNew?: boolean
	coords?: ZonePath
}

export const SafeZonePolygon: React.FC<Props> = (props) => {
	const {zone, options, isNew, coords} = props
	const polygonRef = React.useRef(null);
	const listenersRef = React.useRef([]);
	const [labelPosition, setLabelPosition] = React.useState({lat: 0, lng: 0})
	const [showLabel, setShowLabel] = React.useState(false)

	const safeZoneBeingEdited = safeZoneStore(s => s.safeZoneBeingEdited)
	const editedZoneCoordinates = safeZoneStore(s => s.updatedZonePath)
	const zoneId = zone ? zone._id : 'new-zone'

	const onDrag = React.useCallback(() => {
		const nextPath = (polygonRef.current! as google.maps.Polygon)
			.getPath()
			.getArray()
			.map((latLng: google.maps.LatLng) => {
				return { lat: latLng.lat(), lng: latLng.lng() };
			});
		if (!isNew) {
			const position = getCenterOfSafeZone(nextPath)
			setLabelPosition(position as LatLng)
		} else {
			safeZoneStore.getState().setNewSafeZoneGeometry(nextPath)
		}
	}, [])


	// Call setPath with new edited path
	const onEdit = () => {
		if (!isNew && safeZoneBeingEdited !== zoneId) {
			return // mapStore.getState().setSafeZoneBeingEdited(zoneId)
		}
		if (polygonRef.current && (isNew || (zoneId === safeZoneBeingEdited))) {
			const nextPath = (polygonRef.current as google.maps.Polygon)
				.getPath()
				.getArray()
				.map((latLng: google.maps.LatLng) => {
					return { lat: latLng.lat(), lng: latLng.lng() };
				});
			if (!isNew) {
				safeZoneStore.getState().setUpdatedZonePath(nextPath)
				const position = getCenterOfSafeZone(nextPath)
				setLabelPosition(position as LatLng)
			} else {
				safeZoneStore.getState().setNewSafeZoneGeometry(nextPath);
			}
		}
	}

	const onEditEnd = () => {
		if (isNew || zoneId === safeZoneBeingEdited) {
			!isNew && safeZoneStore.getState().setSafeZoneBeingEdited('')
			if (polygonRef.current) {
				const nextPath = (polygonRef.current as google.maps.Polygon)
					.getPath()
					.getArray()
					.map((latLng: google.maps.LatLng) => {
						return {lat: latLng.lat(), lng: latLng.lng()};
					});
				if (!isNew) {
					safeZoneStore.getState().setUpdatedZonePath(nextPath)
					const position = getCenterOfSafeZone(nextPath)
					setLabelPosition(position as LatLng)
				} else {
					safeZoneStore.getState().setNewSafeZoneGeometry(nextPath);
				}
			}
		}
	}

	// Bind refs to current Polygon and listeners
	const onLoad = React.useCallback(
		(polygon: google.maps.Polygon) => {
			// @ts-ignore => this is a bit of a hack, but its typescript that's complaining not react
			polygonRef.current = polygon
			const path = polygon.getPath()
			if (path) {
				listenersRef.current.push(
					// @ts-ignore
					path.addListener('set_at', onEdit),
					path.addListener('insert_at', onEdit),
					path.addListener('remove_at', onEdit)
				)
				if (!isNew) {
					const position = getCenterOfSafeZone(zone!.zone_coordinates)
					setLabelPosition(position as LatLng)
				}
			}
		},
		[onEdit]
	);

	// Clean up refs
	const onUnmount = React.useCallback(() => {
		// @ts-ignore
		listenersRef.current.forEach(lis => lis.remove());
		polygonRef.current = null;
	}, []);

	const zoneCoords = (safeZoneBeingEdited && safeZoneBeingEdited === zoneId ? editedZoneCoordinates : zone?.zone_coordinates) || []
	const polygon = (
		<PolygonF
			key={zoneId}
			options={{...options, editable: options.editable || false, draggable: options.draggable || false}}
			paths={!isNew ? zoneCoords : coords!}
			// Event used when manipulating and adding points
			onMouseUp={onEdit}
			// Event used when dragging the whole Polygon
			onDragEnd={onEdit}
			onDblClick={onEditEnd}
			onLoad={onLoad}
			onUnmount={onUnmount}
			onDrag={onDrag}
			onMouseOut={() => setShowLabel(false)}
			onMouseOver={() => setShowLabel(true)}
		/>
	)

    return (
		<div key={zoneId}>
			{polygon}
			{!isNew && showLabel ? <SafeZoneLabel position={labelPosition} name={zone!.zone_name}/> : null}
		</div>
	)
}

import drawerStore from '../../../store/drawerStore.ts'
import mapStore from '../../../store/mapStore.ts'
import { DesktopPetList } from '../../petDrawer/DesktopPetList.tsx'
import { MiniPetList } from '../../petDrawer/MiniPetList.tsx'
import { isMobile } from '../MainMap/MainMap.tsx'

export let directionsService: any
export let directionsRenderer: any

export const initDirections = () => {
    directionsService = new window.google.maps.DirectionsService()
    directionsRenderer = new window.google.maps.DirectionsRenderer()
}
export const doRoute = (map: any, travelMode: any, myPosition: google.maps.LatLng, directionsTo: google.maps.LatLng) => {
    if (map) {
        directionsRenderer.setMap(map)
        drawerStore.getState().setChildren(null)
        directionsRenderer.setPanel(document.getElementById('directions-panel'))

        // keep zoom in sync after google changes it
        const listener = window.google.maps.event.addListener(map, 'idle', () => {
            const zoom = map?.getZoom()
            mapStore.getState().setZoom(zoom)
            window.google.maps.event.removeListener(listener)
        })

        directionsService?.route({
            origin: myPosition,
            destination: directionsTo,
            avoidTolls: true,
            avoidHighways: true,
            travelMode: travelMode
        }).then ((response: any) => {
            if (response.status == google.maps.DirectionsStatus.OK) {
                // if (document.getElementById('directions-panel')) {
                    directionsRenderer.setDirections(response)
                    drawerStore.getState().setDrawerOpen(true)
                // }
            } else {
                clearRoute()
            }
        }).catch((e: any) => {
            const m = e.message.split(':')
            window.alert(m[2])
            clearRoute()
        });
    }
}
export const clearRoute = () => {
    mapStore.getState().setDirectionsTo(null)
    directionsRenderer.setMap(null)
    directionsRenderer.setPanel(null)
    drawerStore.getState().setDrawerOpen(false)
    drawerStore.getState().setChildren(null)
    setTimeout(() => isMobile ? drawerStore.getState().setChildren(<MiniPetList/>) : drawerStore.getState().setChildren(<DesktopPetList/>)
        , 500)
}

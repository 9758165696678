import * as React from 'react'
import { createPopupWidget } from '../components/popupHelper.tsx'

interface Props {
    control: any
    formField: string
    title: string,
    errors: Record<string, any>
    options: { label: string, value: string }[]
    required?: boolean
    showValueLabel?: boolean
    btnSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
    watch: any
    hideComplete?: boolean
}

// eslint-disable-next-line react/display-name
export const PopupSelect: React.FC<Props> = ((props) => {
    const { control, formField, title, errors, options, required, showValueLabel, btnSize, watch, hideComplete } = props
    const [open, setOpen] = React.useState('')

    const cols = Math.floor(options.length / 15) || 1

    const rules = required ? {
        required: {
            value: true,
            message: 'Required'
        },
    } : {}

    const shadowVal = watch(formField)

    return createPopupWidget({
        name: formField,
        title,
        rules,
        options,
        cols,
        setOpen,
        open,
        control,
        errors,
        showValueLabel,
        btnSize,
        shadowVal,
        hideComplete,
    })
})

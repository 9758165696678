import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ISubscription } from '../../../interfaces/types.ts'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan'
import { UpgradeTrackerSubscription } from '../UpgradeTrackerSubscription/UpgradeTrackerSubscription.tsx'

interface Props {
    iconOnly?: boolean
    subscription: ISubscription
}

export const UpgradeSubscriptionButton: React.FC<Props> = (props) => {
    const {iconOnly, subscription} = props
    const [open, setOpen] = React.useState(false)

    const button = iconOnly ? (
        <div className={'text-secondary text-xl'} onClick={() => setOpen(true)}>
            <FontAwesomeIcon icon={faTrashCan}/>
        </div>
        ) :
        <button className={'btn btn-xs btn-ghost text-secondary without-ring h-fit'}
                disabled={subscription.plan_type.toLowerCase().includes('year')}
                onClick={() => setOpen(true)}
        >
            Upgrade Subscription
        </button>

    return (
        <div>
            {button}
            <UpgradeTrackerSubscription subscription={subscription} open={open} setOpen={setOpen}/>
        </div>
    )
}

import * as React from 'react'

interface Props {
    alertLabel: string
    alertId: string
    onClick: (v: {alertId: string, selected: boolean}) => void
    selected?: boolean
}

export const Alert: React.FC<Props> = (props) => {
    const {alertLabel, alertId, selected, onClick} = props

    const _onChange = (e: any) => {
        e.stopPropagation()
        onClick({alertId, selected: !selected})
    }

    return (
        <div className={'form-control text-center bg-primary/10 rounded-lg p-2'}>
            <label className="label pt-0 cursor-pointer flex flex-col justify-center items-center gap-2 rounded-md">
                <input type="checkbox" name="radio-Interval" className="checkbox checkbox-primary"
                       onChange={_onChange} checked={selected}/>
                <div className="label-text text-lg text-primary font-semibold">{alertLabel}</div>
            </label>
        </div>
    )
}

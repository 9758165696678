import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { RemovePetButton } from '../../components/common/buttons/RemovePetButton.tsx'
import { createPetAndAssignTracker, parseBirthDate } from '../../helpers/petHelper.tsx'
import { useForm as useFormHandler } from '../../hooks/useForm.ts'
import controlStore from '../../store/controlStore.ts'
import { Section } from './components/Section.tsx'
import { LabeledInput } from './widgets/LabeledInput.tsx'
import { RadioGroup } from './widgets/RadioGroup.tsx'
import { FormLayout } from './components/FormLayout.tsx'
import { SubmitCancelButtons } from './components/SubmitCancelButtons.tsx'
import { IPet } from '../../interfaces/types.ts'
import { LabeledSelect } from './widgets/LabeledSelect.tsx'
import { ColorPicker } from './widgets/ColorPicker.tsx'
import { BirthDate } from './widgets/BirthDate.tsx'
import { Header } from './components/Header.tsx'
import { GenderOptions } from './widgets/options.tsx'
import dataStore from '../../store/dataStore.ts'
import { ImageUpload } from './widgets/ImageUpload.tsx'

export const PetForm: React.FC = () => {
    const location = useLocation()
    const pet = location?.state?.pet as IPet
    const addTrackerData = location?.state?.data
    const speciesOptions = dataStore(s => s.species).map(b => ({value: b?._id, label: b?.species_name}))
    const breeds = dataStore(s => s.breeds)
    const account = dataStore(s => s.account)
    const isMobile = controlStore(s => s.isOnMobileDevice)

    const birthDate = pet ? typeof pet.birthDate === 'string' ? parseBirthDate(pet.birthDate) : pet.birthDate : ''

    const [petPhoto, setPetPhoto] = React.useState('')

    const defaultValues = {
        _id: pet?._id || 'new',
        petName: pet?.petName || '',
        birthDate,
        species: pet?.species || '',
        breed: pet?.breed || '',
        gender: pet?.gender || '',
        petWeight: pet?.petWeight || '',
        imageURL: pet?.imageURL || '',
        colorId: pet?.colorId || '',
        uploadImage: {file: '', img: ''}
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        control,
        trigger,
        watch,
        resetField,
        getValues,
    } = useForm({
        defaultValues: defaultValues,
        mode: 'onChange',
        reValidateMode: 'onBlur',
    })

    React.useEffect(() => {
        void trigger()
    }, [])

    // clear breed when species changes
    const species = watch('species')
    React.useEffect(() => {
        resetField('breed')
    }, [species])

    const breed = watch('breed')
    React.useEffect(() => {
        if (breed && !breeds.find(b => b.species_type === species && b.breed_name === breed)) {
            dataStore.getState().setBreeds([...breeds, {
                breed_name: breed,
                species_type: species,
                _id: breed,
            } ])
        }
    }, [breed])

    const uploadImage = watch('uploadImage')
    React.useEffect(() => {
        if (uploadImage.img) {
            setPetPhoto(uploadImage.img)
        } else {
            setPetPhoto(getValues().imageURL)
        }
    }, [uploadImage])

    const navigate = useNavigate()
    const [onSubmit] = useFormHandler()

    const chooseBreedOptions = (species: string) =>
        breeds?.filter(b => b.species_type === species).map(d => ({label: d.breed_name, value: d._id})) || []

    const cancel = () => {
        const state = {
            data: {
                ...addTrackerData,
                petId: ''
            },
        }
        navigate('/' + controlStore.getState().currentPage, {state})
    }
    const processForm = async (data: any) => {
        if (addTrackerData) {
            const petId = await createPetAndAssignTracker(addTrackerData.iccid, data)
            const state = { data: {
                ...addTrackerData,
                petId
                }}
            navigate('/' + controlStore.getState().currentPage, {state})
        } else {
            void onSubmit('pet', data)
        }
    }
    
    const width = !isMobile ? 'w-[32rem]' : ''
    
    return (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <form onSubmit={handleSubmit(processForm)} className={`${width} h-full mb-6`}>
            <FormLayout trigger={trigger} header={<Header title={`${!(!!pet) ? 'New pet' : 'Edit ' + pet.petName}`}/>}>

                <div className={'flex flex-col gap-2 h-[90%]'}>
                    <div className={'flex flex-col item-center gap-3'}>

                        <Section>
                            <LabeledInput
                                formField={'petName'}
                                title={'Pet Name'}
                                {...register('petName' as any, {
                                    required: {
                                        value: true,
                                        message: 'Required'
                                    },
                                })}
                                errors={errors}
                            />
                        </Section>

                        <Section>
                            <LabeledSelect
                                control={control}
                                formField={'species'}
                                title={'Pet Species'}
                                errors={errors}
                                options={speciesOptions}
                                required
                            />

                            <LabeledSelect
                                control={control}
                                formField={'breed'}
                                title={'Pet Breed'}
                                errors={errors}
                                options={chooseBreedOptions(species)}
                                required
                                creatable
                            />
                        </Section>

                        <Section>

                            <BirthDate
                                control={control}
                                formField={'birthDate'}
                                title={'Pets birth date'}
                                errors={errors}
                            />

                            <RadioGroup
                                formField={'gender'}
                                control={control}
                                options={GenderOptions}
                                title={'Gender'}
                                errors={errors}
                                required
                            />

                            <LabeledInput
                                formField={'petWeight'}
                                title={'Weight'}
                                {...register('petWeight' as any, {
                                    required: {
                                        value: true,
                                        message: 'Required'
                                    },
                                })}
                                errors={errors}
                                scale={account.country ? account.country === 'US' ? 'lbs' : 'kg' : 'lbs'}
                            />

                        </Section>

                        <Section>
                            <ColorPicker
                                control={control}
                                formField={'colorId'}
                                title={'Map Marker Color'}
                                errors={errors}
                                required
                            />

                            <ImageUpload
                                control={control}
                                formField={'uploadImage'}
                                title={'Photo'}
                                errors={errors}
                                imageUrl={petPhoto}
                            />

                        </Section>

                    </div>

                    <SubmitCancelButtons errors={errors} cancel={cancel} isCreate={defaultValues._id === 'new'} changed={defaultValues._id !== 'new' ? isDirty : undefined}/>
                    <RemovePetButton pet={pet} iconOnly/>
                </div>

            </FormLayout>
        </form>
    )
}

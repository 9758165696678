import controlStore from '../../store/controlStore.ts'
import drawerStore from '../../store/drawerStore.ts'
import { DesktopPetList } from '../petDrawer/DesktopPetList.tsx'
import { SafeZoneIcon } from './SafeZoneIcon.tsx'
import { MiniPetList } from '../petDrawer/MiniPetList.tsx'
import { SafeZonePanel } from './SafeZonePanel.tsx'
import safeZoneStore from '../../store/safeZoneStore.ts'

export const SafeZoneButton = () => {
    const safeZoneActive = safeZoneStore(s => s.active)
    const isMobile = controlStore(s => s.isOnMobileDevice)
    const button = (
        <div
            onClick={() =>
            {
                if (drawerStore.getState().drawerOpen) {
                    // deactivate in this case
                    if (safeZoneActive) {
                        safeZoneStore.getState().setActive(false)
                        // close
                        drawerStore.getState().setDrawerOpen(false)
                        // wait for it to close before changing the child
                        setTimeout(() => isMobile ? drawerStore.getState().setChildren(<MiniPetList/>) : drawerStore.getState().setChildren(<DesktopPetList/>), 500)
                    } else {
                        // pet drawer is open
                        // close
                        drawerStore.getState().setDrawerOpen(false)
                        setTimeout(() => {
                            drawerStore.getState().setChildren(<SafeZonePanel/>)
                            safeZoneStore.getState().setActive(true)
                            drawerStore.getState().setDrawerOpen(true)
                        }, 500)
                    }
                 } else {
                    if (!safeZoneActive) {
                        drawerStore.getState().setChildren(<SafeZonePanel/>)
                        drawerStore.getState().setDrawerOpen(true)
                        safeZoneStore.getState().setActive(true)
                    } else {
                        safeZoneStore.getState().setActive(false)
                        isMobile ? drawerStore.getState().setChildren(<MiniPetList/>) : drawerStore.getState().setChildren(<DesktopPetList/>)
                    }
                }
            }}
        >
            <SafeZoneIcon active={safeZoneActive}/>
        </div>
    )
    return (
        <div className={'relative'}>
            {button}
        </div>
    )
}

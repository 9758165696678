import * as React from 'react'
import toast from 'react-hot-toast'
import { sendCommand } from '../../../helpers/commandHelper.ts'
import dataStore from '../../../store/dataStore.ts'
import { IDevice, ISubscription } from '../../../interfaces/types.ts'
import { AsyncButton } from '../AsyncButton/AsyncButton.tsx'
import { PopupPortal } from '../Popup/PopupPortal.tsx'

interface Props {
    subscription: ISubscription
    tracker?: IDevice
    open: boolean
    setOpen: (o: boolean) => void
}

export const UpgradeTrackerSubscription: React.FC<Props> = (props) => {
    const {subscription, open, setOpen, tracker} = props
    const [upgradePlan, setUpgradePlan] = React.useState<any>({})

    const stripePlans = dataStore.getState().plans

    //{plan.productPrice} per ${plan.interval}

    React.useEffect(() => {
        if (stripePlans) {
            const yearly = stripePlans.find(s => s.interval === 'year')
            if (yearly) {
                setUpgradePlan(yearly)
            }
        }

    }, [stripePlans])


    const doUpgrade = async () => {
        try {
            const resultData = await sendCommand('upgradeSubscription', tracker?.iccid || '', subscription.subscription)
            setOpen(false)
            if (resultData) {
                dataStore.getState().setDevicePlans(resultData)
                toast.success('Subscription upgraded')
            } else {
                toast.error('Error, upgrade failed. Try again later.')
            }
        } catch (e) {
            toast.error('Fatal Error, upgrade failed. Try again later.')
            setOpen(false)
        }
    }

    const cancel = () => {
        setOpen(false)
    }

    const howDeviceIsHandled =
        <div className={'text-left'}>
            <div className={'text-md leading-6 p-2 flex flex-col gap-6'}>

                <div className="stats shadow text-center w-fit self-center">
                    <div className="stat">
                        <div className="stat-title">{upgradePlan.productName}</div>
                        <div className="stat-value">${upgradePlan?.productPrice}</div>
                        <div className="stat-desc">{upgradePlan.productDescription}</div>
                    </div>
                </div>

                <div>
                    Your credit card on file will be charged.
                    Any remaining service in your monthly plan will be pro-rated to your account.
                </div>
            </div>
        </div>

    const panelHeader = (
        <div className={'prose flex flex-col justify-center items-center gap-2'}>
            <h2 className={'mt-0 mb-0 text-primary text-center'}>Upgrade your monthly Gibi subscription</h2>
        </div>
    )

    const panel = (
        <div className={'flex flex-col justify-start items-center gap-2 h-full w-full p-4'}>
            {panelHeader}
            <div className={'text-neutral-content flex flex-col justify-start items-center gap-4'}>
                <div className={'px-2'}>
                    You can upgrade your monthly Gibi service subscription to a yearly plan.
                </div>
                {howDeviceIsHandled}
                <div className={'text-center text-accent'}>
                </div>
            </div>
        </div>
    )

    const buttons = (
        <div className={'p-4 flex justify-between items-center w-full'}>
            <button className={'btn text-secondary btn-ghost btn-sm'} onClick={cancel}>Cancel</button>
            <AsyncButton classNames={'btn btn-outlined btn-secondary btn-sm'} onClick={doUpgrade}
                         disabled={false}>Upgrade Subscription</AsyncButton>
        </div>
    )

    const popUp = (
        <PopupPortal open={open}>
            {panel}
            {buttons}
        </PopupPortal>
    )

    return (<>
        {popUp}
    </>)
}

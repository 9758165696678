import React from "react";
import { getPetsWIthDevices } from '../../../helpers/dataHelpers.ts'
import dataStore from '../../../store/dataStore.ts'
import Select from 'react-select'
import { select2Styles } from "./select2.styles.ts";

interface Props {
	onlyShowActive?: boolean
	onlyShowInactive?: boolean
	onChange: (p: any) => void
	value: string
	noNew?: boolean
	disabled?: boolean
	displayOnly?: boolean
}

const SelectPet2: React.FC<Props> = (props) => {
	const {onlyShowActive, onlyShowInactive, onChange, value, noNew, disabled, displayOnly } = props
	const devices = dataStore(s => s.devices)
	const pets = dataStore(s => s.pets)
	const [options, setOptions] = React.useState<any[]>([])

	React.useEffect(() => {
		if (devices && pets) {
			if (onlyShowActive) {
				const petsWithDevices: string[] = getPetsWIthDevices()
				const petList = dataStore.getState().pets.filter(p => petsWithDevices.includes(p._id))
				if (noNew) {
					setOptions([...(petList.map(p => {return {value: p._id, label: p.petName}}))])
				} else {
					setOptions([...(petList.map(p => {
						return {value: p._id, label: p.petName}
					})), {value: 'new', label: 'New pet'}])
				}
			} else if (onlyShowInactive) {
				const petsWithDevices: string[] = getPetsWIthDevices()
				const petList = dataStore.getState().pets.filter(p => !petsWithDevices.includes(p._id))
				if (noNew) {
					setOptions([...(petList.map(p => {return {value: p._id, label: p.petName}}))])
				} else {
					setOptions([...(petList.map(p => {
						return {value: p._id, label: p.petName}
					})), {value: 'new', label: 'New pet'}])
				}
			} else {
				const petList = dataStore.getState().pets
				if (noNew) {
					setOptions([...(petList.map(p => {return {value: p._id, label: p.petName}}))])
				} else {
					setOptions([...(petList.map(p => {
						return {value: p._id, label: p.petName}
					})), {value: 'new', label: 'New pet'}])
				}
			}
		}
	}, [devices, pets, value])

	const getSelectedPet = (id: string) => options.find(o => o.value === id)
	const selectedPet = pets?.find(p => p._id === value)?.petName
	console.log('selected Pet', selectedPet)

	const petSelect = (
		<div className="form-control relative pl-2 pr-2 w-full">
			{!displayOnly ? <Select
				className=""
				onChange={onChange}
				value={getSelectedPet(value)}
				options={options}
				styles={{ ...select2Styles }}
				isDisabled={disabled}
			/> : (
				<div
					className={'h-8 text-base-content bg-white border-solid border border-primary/50 rounded w-full px-2 focus-visible:outline-0 text-xl'}>
					Assigned to {selectedPet}
				</div>
			)}
		</div>
	)
	return petSelect
};

export default SelectPet2;

import * as React from 'react'
import { checkImage } from '../../../helpers/petHelper.tsx'
import controlStore from '../../../store/controlStore.ts'
import dataStore from '../../../store/dataStore.ts'
import mapStore from '../../../store/mapStore.ts'
import { WebSocketIndicator } from '../../../webSockets/WebSocketIndicator.tsx'
import { CenterMapButton } from '../../common/buttons/CenterMapButton.tsx'
import { HelpButton } from '../../common/buttons/HelpButton.tsx'
import { Dashboard2 } from '../../dashboard2/Dashboard2.tsx'
import { DesktopPetDrawer } from '../../petDrawer/DesktopPetDrawer.tsx'
import { DesktopPetDrawerButton } from '../../petDrawer/DesktopPetDrawerButton.tsx'
import { DesktopPetList } from '../../petDrawer/DesktopPetList.tsx'
import { PetDrawer } from '../../petDrawer/PetDrawer.tsx'
import { NewSafeZone } from '../../safeZones/NewSafeZone.tsx'
import { SafeZoneButton } from '../../safeZones/SafeZoneButton.tsx'
import { SafeZones } from '../../safeZones/SafeZones.tsx'
import { GoogleMapWrapper, POI_OFF } from '../components/GoogleMap/GoogleMapWrapper.tsx'
import { updatedStyles } from '../components/GoogleMap/mapStyles.ts'
import { Directions } from '../Directions/Directions.tsx'
import { LastKnownLocations } from '../LastKnownLocations/LastKnownLocations.tsx'

import '../map.css'
import { MapSettingsButton } from '../mapSettings/MapSettingsButton.tsx'
import { TrackMyLocation } from '../TrackMyLocation/TrackMyLocation.tsx'
import { CHICAGO, DEFAULT_ZOOM, LatLng } from '../types.ts'

import { centerMapOnPets } from '../mapHelpers.tsx'
import drawerStore from '../../../store/drawerStore.ts'
import { MiniPetList } from '../../petDrawer/MiniPetList.tsx'
import { MobileDirections } from '../Directions/MobileDirections.tsx'
import safeZoneStore from '../../../store/safeZoneStore.ts'

export let isMobile: boolean

export const MainMap: React.FC = () => {
    const devices = dataStore((s) => s.devices)
    const google = mapStore((s) => s.google)
    const map = mapStore((s) => s.map)
    isMobile = controlStore(s => s.isOnMobileDevice)
    const pets = dataStore(s => s.pets)

    const mapStyle = mapStore(s => s.mapStyle)

    React.useEffect(() => {
        mapStore.getState().setMapContext('map')
        controlStore.getState().setCurrentPage('map')
        drawerStore.getState().setDrawerOpen(false)
        isMobile ? drawerStore.getState().setChildren(<MiniPetList/>) : drawerStore.getState().setChildren(<DesktopPetList/>)
        if (pets) {
            void Promise.all(pets.map(p => checkImage(p.imageURL))).then(() => {})
        }
        controlStore.getState().setCurrentPage('map')
        return () => {
            drawerStore.getState().setDrawerOpen(false)
            isMobile ? drawerStore.getState().setChildren(<MiniPetList/>) : drawerStore.getState().setChildren(<DesktopPetList/>)
            safeZoneStore.getState().setActive(false)
        }
    }, [])

    React.useEffect(() => {

    },[])

    React.useEffect(() => {
        if (map) {
            // @ts-ignore
            const styles = updatedStyles[mapStyle] ? [...(updatedStyles[mapStyle] as []), POI_OFF] : updatedStyles.default
            map.setOptions({ styles })
        }
    }, [map])

    React.useEffect(() => {
        if (map) {
            // const zoom = map.getZoom()
            // const center = map.getCenter()
            // const styles = styleMap[mapStyle] ? [...(styleMap[mapStyle] as []), POI_OFF] : [POI_OFF]
            // @ts-ignore
            const styles = updatedStyles[mapStyle] ? [...(updatedStyles[mapStyle] as []), POI_OFF] : updatedStyles.default
            map.setOptions({ styles })
            const idleListener = map.addListener('idle', () => {
                setTimeout(() => {
                    centerMapOnPets()
                })
                window.google.maps.event.removeListener(idleListener)
            })

            // const bounds = mapStore.getState().bounds
            // if (bounds) {
            //     map.fitBounds(bounds)
            //     map.setZoom(zoom)
            // }
        }
    }, [mapStyle])

    if (google && map) {
        const bounds = new google.maps.LatLngBounds()
        devices?.forEach(d => bounds.extend({
            lat: d.lastLocation?.properties?.latitude || CHICAGO.lat,
            lng: d.lastLocation?.properties?.longitude || CHICAGO.lng
        }))
        // map.fitBounds(bounds)
        // console.log('Map - map.fitBounds()')
        map.setZoom(mapStore.getState()?.zoom || DEFAULT_ZOOM)
    }

    const miniDrawer = isMobile ? (
        <div className={'absolute bottom-0'}>
            <PetDrawer/>
        </div>
            ) : (
        <div className={'absolute bottom-0'}>
            <DesktopPetDrawer/>
        </div>
        )

    const center = mapStore.getState().map ? mapStore.getState().map?.getCenter() : CHICAGO
    return (
        <div id={'main-map'} className={'mainMap relative overflow-hidden border-transparent focus-visible:border-transparent focus-visible:ring-0'}>
            <GoogleMapWrapper height={'90vh'} center={center as LatLng} zoom={DEFAULT_ZOOM}>
                <TrackMyLocation/>
                <LastKnownLocations/>
                <SafeZones/>
                <NewSafeZone/>
                {isMobile ? <MobileDirections/> : <Directions/>}

                <div className={'absolute top-2 right-2'}>
                    <WebSocketIndicator/>
                </div>
                <div className={`absolute ${!isMobile ? 'top-20 right-5' : 'top-20 right-3'}`}>
                    <div className={`flex flex-col justify-start items-center ${!isMobile ? 'gap-4' : 'gap-6'}`}>
                        {!isMobile ? <DesktopPetDrawerButton/> : null}
                        <SafeZoneButton/>
                        <MapSettingsButton/>
                        <HelpButton/>
                    </div>
                </div>
                <div className={`absolute ${!isMobile ? 'bottom-[8rem] right-4' : 'bottom-[2rem] right-5'}`}>
                    <CenterMapButton/>
                </div>
                {!isMobile && <div id={'dash-2'} className={'absolute top-[10%]'}>
                    <Dashboard2/>
                </div>}
                {miniDrawer}
            </GoogleMapWrapper>
        </div>
    )
}

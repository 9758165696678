import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { useLocation, useNavigate } from 'react-router-dom'
import controlStore from '../../../store/controlStore.ts'

interface Props {
    iconOnly?: boolean
}

export const AddContactButton: React.FC<Props> = (props) => {
    const {iconOnly} = props
    const navigate = useNavigate()
    const location = useLocation()

    const doEdit = () => {
        const state = {contact:{_id: 'new'}}
        controlStore.getState().setCurrentPage(location.pathname.slice(1))
        navigate('/contactForm',{state})
    }

    const button = iconOnly ? (
        <div className={'text-primary text-xl'}onClick={doEdit}>
            <div className={'tooltip tooltip-bottom'} data-tip={`New follower`}>
                <FontAwesomeIcon icon={faPlus}/>
            </div>
        </div>
        ) :
        <button className={'btn btn-primary without-ring'}
                onClick={doEdit}>
            Add a follower
        </button>

    return (
        <div className={'p-4'}>
            {button}
        </div>
    )
}

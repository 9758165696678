import * as React from 'react'
import mapStore from '../../../store/mapStore.ts'
import { rotatePolygon } from '../helpers/geometryHelpers.tsx'
import safeZoneStore from '../../../store/safeZoneStore.ts'
import { SafeZoneInstructions } from './Instructions.tsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateLeft, faRotateRight } from '@fortawesome/free-solid-svg-icons'

interface Props {
    save: () => void
    cancel: () => void
}

export const CompleteSafeZone: React.FC<Props> = (props) => {
    const { save, cancel } = props
    const map = mapStore(s => s.map)
    const newCoords = safeZoneStore(s => s.newSafeZoneGeometry)
    const editedCoords = safeZoneStore(s => s.updatedZonePath)
    const safeZoneBeingEdited = safeZoneStore(s => s.safeZoneBeingEdited)
    const zoneName = safeZoneStore(s => s.editedZoneName)

    React.useEffect(() => {
        safeZoneStore.getState().setInstructions(SafeZoneInstructions.COMPLETE)
        safeZoneStore.getState().setEditedSafeZoneName('')
    }, [])

    const onChange = (e: any) => {
        safeZoneStore.getState().setEditedSafeZoneName(e.target.value)
    }

    const rotateLeft = () => {
        const coords = safeZoneBeingEdited ? editedCoords : newCoords
        rotatePolygon(map!, coords, -10)
    }

    const rotateRight = () => {
        const coords = safeZoneBeingEdited ? editedCoords : newCoords
        rotatePolygon(map!, coords, 10)
    }

    return (
        <div className={'flex flex-col justify-center items-center w-full gap-2'}>
            <div className={'flex flex-col gap-1 items-center w-full justify-center pb-1'}>
                <div className={'text-lg font-semi-bold text-neutral-content/50'}>Zone name</div>
                <div className={'w-[75%]'}><input className={'gibi-input w-full'} type={'text'} value={zoneName}
                                                    onChange={onChange}/> </div>
            </div>
            <div className={'flex justify-around items-center py-2 w-[50%]'}>
                <div onClick={rotateLeft}>
                    <FontAwesomeIcon icon={faRotateLeft} size={'2x'}/>
                </div>
                <div className={'text-lg font-semi-bold text-neutral-content/50'}>Rotate</div>
                <div onClick={rotateRight}>
                    <FontAwesomeIcon icon={faRotateRight} size={'2x'}/>
                </div>
            </div>
            <div className={'flex justify-around items-center w-full'}>
                <button className={'btn btn-md btn-ghost text-secondary'} onClick={cancel}>
                    Cancel
                </button>
                <button className={'btn btn-md btn-secondary'} onClick={save} disabled={!zoneName}>
                    Save
                </button>
            </div>
        </div>
    )
}

import * as React from 'react'
import { MarkerClusterer } from '@react-google-maps/api'
import { IPoint } from '../../../../interfaces/types.ts'
import dataStore from '../../../../store/dataStore.ts'
import historyStore from '../../../../store/historyStore.ts'
import { NumberMarker } from './NumberMarker.tsx'

interface Props {

}

const clusterOptions = {
    // imagePath:
    //     'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // Path to the cluster icons
    // gridSize: 30,
    // minimumClusterSize: 2,
    // maxZoom: 17
};

export const ClusterMarker: React.FC<Props> = () => {
    const locations = historyStore(s => s.locations)
    const pets = dataStore(s => s.pets)
    const map = historyStore(s => s.map)
    
    let cluster = null
    
    if (locations?.length) {
        const bounds = new google.maps.LatLngBounds()
        const markers = locations.filter(l => l.properties.status)
        cluster = (
            <MarkerClusterer options={clusterOptions}>
                {(clusterer) =>
                    <div>{
                        markers.map((point: IPoint, i: number) => {
                            const latLng = new google.maps.LatLng({
                                lat: point.properties!.latitude,
                                lng: point.properties!.longitude,
                            })
                            bounds.extend(latLng)
                            map!.fitBounds(bounds)
                            const pet = pets.find(pet => pet._id === point.properties.itemId)
                            return <NumberMarker
                                key={`${point.properties.deviceId}-${i}`}
                                point={point}
                                color={pet.iconColor}
                                num={(i % 10) + 1}
                                position={latLng}
                                clusterer={clusterer}
                            />
                        })
                    }</div>
                }
            </MarkerClusterer>
        )
    }
    return cluster
}

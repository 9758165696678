import axios from 'axios'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ForgotPassword } from '../../components/common/ForgotPassword/ForgotPassword'
import { Toast } from '../../components/common/Toast/Toast'
import { loadLoginData } from '../../handlers/loginHandler'
import { useForm as useFormHandler } from '../../hooks/useForm'
import { Loader } from '../../Router/Loader'
import controlStore from '../../store/controlStore'
import { gibiEmailRegex } from '../../interfaces/types'
import { LabeledInput } from './widgets/LabeledInput'
import { Password } from './widgets/Password'


export const LoginForm: React.FC = () => {
    const [openForgotPassword, setOpenForgotPassword] = React.useState(false)
    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        defaultValues: {
            email: '',
            password: '',
            rememberMe: true,
        },
        mode: 'onChange',
        reValidateMode: 'onBlur',
    })
    const [onSubmit] = useFormHandler()
    const isMobile = controlStore(s => s.isOnMobileDevice)

    React.useEffect(() => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()

        const gibiToken = JSON.parse(localStorage.getItem('gibi') || '{}') ?? ''
        const gibiUser = JSON.parse(localStorage.getItem('gibiUser') || '{}') ?? ''
        if (Object.keys(gibiToken).length === 1 && Object.keys(gibiUser).length) {
            void loadLoginData(gibiToken.t, gibiUser, source.token).then(() => {
                navigate('/map')
            }).finally(() => {
                null
            })
        }

        return () => {
            // cancel the request before component unmounts
            source?.cancel()
        }
    }, [])

    const header = (
        <div className={'pt-8'}>
            <div className="flex justify-center">
                <div>
                    <img className={'w-full'} src={'/logo.svg'}/>
                </div>
            </div>
            <h1 className="text-3xl font-semibold text-center text-primary mb-2">Sign In</h1>
        </div>
    )

    const createAccount = (
        <div className="flex gap-3 mt-8 text-md font-light text-center text-gray-700">
            <div>
                Don&apos;t have an account?
            </div>
            <div>
                <a href={'/registration'}
                   className="font-medium hover:underline hover:text-secondary text-primary ml-2">
                    Create one here
                </a>
            </div>
        </div>
    )
    const handleForgotPassword = (e: any) => {
        e.stopPropagation()
        setOpenForgotPassword(true)
    }

    const forgotPassword = (
        <div className={''} onClick={handleForgotPassword}>
            <a href="#" className="text-sm text-gray-600 hover:underline hover:text-secondary text-primary">Forget
                Password?</a>
            <div style={{width: '25%'}}/>
        </div>
    )

    const processForm = (data: any) => {
        // console.log('onsubmit', schema.formData)
        void onSubmit('login', data)
    }

    const width = isMobile ? 'w-[75%]' : 'w-[28rem]'

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    return <form onSubmit={handleSubmit(processForm)}>
        <div className={'flex flex-col justify-center items-center h-[100vh]'}>
            <Loader className={'absolute -left-[7.5rem] z-[10000]'} style={{top: '37vh'}}/>
            <div className={`${width} p-6 m-auto bg-white rounded-md shadow-2xl`}>
                {header}
                <div className={'flex flex-col item-center gap-4'}>
                    <LabeledInput
                        formField={'email'}
                        title={'Email'}
                        {...register('email' as any, {
                            required: {
                                value: true,
                                message: 'Required'
                            },
                            validate: (value) =>
                                gibiEmailRegex.test(value) || 'Enter an valid email'
                            ,
                        })}
                        errors={errors}
                        // color={'neutral-content'}
                    />

                    <Password
                        formField={'password'}
                        title={'Password'}
                        {...register('password' as any, {
                            required: {
                                value: true,
                                message: 'Required'
                            },
                            minLength: {
                                value: 6,
                                message: '6 characters minimum'
                            }
                        })}
                        errors={errors}
                        // color={'neutral-content'}
                    />

                    <div className={'flex items-center justify-center'}>
                        <div className={'flex items-center gap-2'}>
                            <input
                                type={'checkbox'}
                                className={'checkbox checkbox-primary'}
                                {...register('rememberMe', {})}
                            />
                            <div className={'text-primary text-sm'}>
                                Remember me
                            </div>
                        </div>
                    </div>

                    <div>
                        <input type={'submit'} className={'btn btn-primary btn-wide'} value={'Login'}/>
                    </div>
                    {forgotPassword}
                </div>

                <div className={'flex justify-center'}>
                    {createAccount}
                </div>

            </div>
            <Toast/>
            <ForgotPassword open={openForgotPassword} setOpen={setOpenForgotPassword}/>
        </div>
    </form>
}

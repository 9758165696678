import { faBan } from '@fortawesome/free-solid-svg-icons/faBan'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime } from 'luxon'
import * as React from 'react'
import { getEventIcon, getEventName } from '../../../helpers/eventHelper.tsx'
import authStore from '../../../store/authStore.ts'
import dataStore from '../../../store/dataStore.ts'
import { IDevice, IEvent, IPet } from '../../../interfaces/types.ts'
import { SubItem } from './SubItem.tsx'
import { Timestamp } from './Timestamp.tsx'

interface Props {
    pet: IPet
    viewEvents?: () => void
}

export const LastEvent = (props: Props) => {
    const {pet, viewEvents} = props
    const [eventDate, setEventDate] = React.useState<DateTime | null>(null)
    const [lastEvent, setLastEvent] = React.useState<IEvent | null>(null)

    const [device, setDevice] = React.useState<IDevice | null>(null)

    const devices = dataStore((s) => s.devices)
    const timeZone = authStore(s => s.timezone)

    React.useEffect(() => {
        if (devices) {
            const dev = devices.find(d => d.petId === pet._id)
            if (dev) {
                setDevice(dev)
                const lastEvent = dev.lastEvent
                let date = null
                if (lastEvent && Object.keys(lastEvent).length) {
                    setLastEvent(lastEvent)
                    date = DateTime.fromISO(lastEvent.createdAt).setZone(timeZone.value)//.toLocaleString(DateTime.DATETIME_MED)
                    setEventDate(date)
                }
            }
        }
    }, [device, pet])

    const eventLabel = lastEvent ? getEventName(lastEvent) : 'No events reported'

    const subItem = (
        <div onClick={() => viewEvents && viewEvents()}>
            <SubItem pet={pet} title={'Last event'}
                     icon={lastEvent ? getEventIcon(lastEvent, '18px') :  <FontAwesomeIcon icon={faBan} fontSize={'18px'}/>}>
                <div className={'flex items-center justify-between'}>
                    <div className={'text-md font-md text-center'}>
                        {eventLabel}
                    </div>
                    <div className={'min-w-[60%]'}>
                        <Timestamp date={eventDate} />
                    </div>
                </div>
            </SubItem>
        </div>
    )

    return device ? subItem : null
}

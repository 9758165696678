import * as React from 'react'
import { createPortal } from 'react-dom'

interface Props {
    open: boolean,
    children: React.ReactNode
}

export const PopupPortal: React.FC<Props> = (props) => {
    const {children, open} = props
    
    const pop = (
        <div id={'modal'} data-theme={'gibi'} className={'fixed z-[100000] left-0 top-0 w-[100vw] h-[100vh] overflow-auto bg-black/70 flex justify-center items-center'}>
            <div className={'flex flex-col items-center bg-white border-4 border-primary rounded-xl m-8'}>
                {children}
            </div>
        </div>
    )
    return  open ? createPortal(pop, document.body) : null
}

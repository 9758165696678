import * as React from 'react'
import { Section } from '../../components/Section.tsx'
import { SetPassword } from './SetPassword.tsx'
import { PhoneNumber } from '../../widgets/PhoneNumber.tsx'
import { LabeledInput } from '../../widgets/LabeledInput.tsx'
import { userEmailRegex } from '../../../../interfaces/types.ts'
import { unsecurePost } from '../../../../helpers/apiHelper.ts'
import { CHECK_EMAIL_EXISTS } from '../../../../helpers/urls.ts'
import { PopupSelect } from '../../widgets/PopupSelect.tsx'
import dataStore from '../../../../store/dataStore.ts'

interface Props {
    errors: any
    disabled: boolean
    register: any
    watch: any
    setValue: (n: any, v: any) => void
    getValues: any
    STEPS: any
    control: any
    onComplete: (b: boolean) => void
}

export const AccountDetails: React.FC<Props> = (props) => {
    const { disabled, register, errors, watch, STEPS, getValues, setValue, control, onComplete } = props
    const [nameDone, setNameDone] = React.useState(false)
    const [passwordDone, setPasswordDone] = React.useState(false)
    const [mobileDone, setMobileDone] = React.useState(false)
    const [overrideStep, setOverrideStep] = React.useState(false)
    const timezones = dataStore(s => s.timeZones)
        .filter(t => t.status === 'active')
        .sort((a, b) => a.offset <= b.offset ? 1 : -1)
        .map(t => ({ label: t.timezone_name, value: t._id }))

    const timeZone = watch('timeZone')
    // const email = watch('email')
    // const mobile = watch('mobile')

    const validateUserEmail = async (value: string) => {
        if (!userEmailRegex.test(value)) {
            setNameDone(false)
            return 'not a valid email address'
        } else {
            const result: any = await unsecurePost(CHECK_EMAIL_EXISTS, { email: value })
            if (result?.status === 200) {
                if (result.data.result) {
                    setNameDone(false)
                    return 'Email address in use, select another'
                } else {
                    setNameDone(true)
                }
            }
        }
    }

    const reselect = () => {
        setOverrideStep(true)
    }

    const logicalDisabled = overrideStep ? false : disabled

    return (
        <Section disabled={logicalDisabled} title={'Create your user profile'} step={STEPS.email} done={nameDone && passwordDone && mobileDone} onTap={reselect}>
            {!logicalDisabled ? <div className={`flex flex-col gap-2`}>

                <LabeledInput
                    formField={'email'}
                    title={'Email'}
                    inputType={'text'}
                    {...register('email' as any, {
                        validate: validateUserEmail,
                    })}
                    errors={errors}
                    disabled={logicalDisabled}
                    complete={nameDone}
                />

                <SetPassword
                    disabled={passwordDone}
                    STEPS={STEPS}
                    errors={errors}
                    getValues={getValues}
                    onComplete={() => setPasswordDone(true)}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                />

                <div className={'flex flex-col gap-2'}>
                    <PhoneNumber
                        formField={'mobile'}
                        title={'Mobile number'}
                        errors={errors}
                        control={control}
                        onComplete={() => setMobileDone(true)}
                    />
                    {!mobileDone ?
                    <div className={'text-sm text-neutral-content'}>
                        By providing my mobile number to <b>Gibi Technologies Inc</b>, I agree to receive texts from the
                        number provided, for my configured pet notifications.
                        <br/>
                        Message & Data Rates May Apply.
                    </div>
                    : null}
                </div>

                <PopupSelect
                    control={control}
                    formField={'timeZone'}
                    title={'Timezone'}
                    errors={errors}
                    options={timezones}
                    required
                    showValueLabel
                    btnSize={'lg'}
                    watch={watch}
                />

                <button
                    className={'btn btn-sm text-primary btn-ghost'}
                    disabled={!(nameDone && passwordDone && mobileDone && timeZone)}
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        setOverrideStep(false)
                        onComplete(true)
                    }}
                >
                    Go to payment
                </button>

            </div> : null}
        </Section>
    )
}

import { AddressElement, CardCvcElement, PaymentElement } from '@stripe/react-stripe-js'
import { StripePaymentElementOptions } from '@stripe/stripe-js/types/stripe-js/elements/payment'
import * as React from 'react'
import { createPortal } from 'react-dom'
import { Controller } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Section } from '../../components/Section.tsx'
import { Checkbox } from '../../widgets/Checkbox.tsx'
import { addressOptions, } from '../stripeElementConfig.ts'
import { ConfirmAndPay } from './ConfirmAndPay.tsx'
import dataStore from '../../../../store/dataStore.ts'

interface Props {
    disabled: boolean
    control: any
    errors: any
    register: any
    createAccount: boolean
    watch: any
    getValues: any
    onComplete: (b: {status: boolean, error?: string}) => void
    STEPS: any
}

export const Payment: React.FC<Props> = (props) => {
    const {disabled, control, createAccount, watch, getValues, onComplete, STEPS} = props
    const [cardComplete, setCardComplete] = React.useState(false)
    const [addressComplete, setAddressComplete] = React.useState(false)
    const [confirmAndPay, setConfirmAndPay] = React.useState(false)
    const [paymentDone, setPaymentDone] = React.useState(false)
    const useExistingCard = watch('useExistingPayment')

    const cvcSet = watch('cvcEntered')

    const profile = dataStore.getState().account
    const defaultAddress =  {
        name: `${profile?.firstName || ''} ${profile?.lastName || ''}`,
        address: {
            line1: profile.homeAddress?.address1 || profile.homeAddress?.street_addr,
            line2: profile.homeAddress?.address2 || profile.homeAddress?.apartment,
            city: profile.homeAddress?.usa_city || profile.homeAddress?.canada_city,
            state: profile.homeAddress?.state || profile.homeAddress?.province,
            postal_code: profile.homeAddress?.zip_code || profile.homeAddress?.postal_code,
            country: profile?.country || profile?.homeAddress?.country,
        }
    }

    React.useEffect(() => {
        if (useExistingCard) {
            setAddressComplete(true)
        } else {
            setAddressComplete(false)
            setCardComplete(false)
        }
    }, [useExistingCard])

    const options: StripePaymentElementOptions = {
        defaultValues: {
            billingDetails: {
                email: 'test@example.com',
            }
        }
    }

    const onDone = (r: {status: boolean, error?: string}) => {
        // status is payment status
        setConfirmAndPay(false)
        if (r.status) {
            toast.success('Payment succeeded')
            setPaymentDone(true)
        } else {
            setCardComplete(false)
            setPaymentDone(false)
        }
        if (r.error) {
            toast.error(`Error: ${r.error}`)
            // navigate('/map')
        }
        onComplete(r)
    }

    return (
        <Section disabled={disabled} title={'Payment'} step={STEPS.payDetails} done={paymentDone}>
            {!disabled ? <div className={``}>
                {!createAccount ?
                    <Checkbox checked={useExistingCard} control={control} fieldName={'useExistingPayment'}
                              label={'Use current payment method'}/>
                    : null}

                {useExistingCard && !createAccount ?
                    <div className={'pt-2'}>
                        <div className={'text-neutral-content text-md font-medium p-2'}>
                            Enter the saved cards CVC
                        </div>
                        <div className={'flex justify-center'}>
                            <Controller
                                name={'cvcEntered'}
                                render={({ field }) => {
                                    return (
                                        <div className={'flex flex-col items-center gap-2'}>
                                            <CardCvcElement
                                                className={'w-[4rem] bg-white h-8 text-base-content border-solid border border-primary/50 rounded px-2 focus-visible:outline-0 text-xl center pt-1'}
                                                onChange={(event) => {
                                                    if (event?.complete) {
                                                        field.onChange(true)
                                                    } else {
                                                        field.onChange(false)
                                                        setCardComplete(false)
                                                    }
                                                }}
                                            />
                                            <button
                                                className={'btn btn-sm text-primary btn-ghost'}
                                                disabled={!cvcSet}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    e.preventDefault()
                                                    setConfirmAndPay(true)
                                                }}
                                            >
                                                Review & Confirm
                                            </button>
                                        </div>
                                    )
                                }}
                                control={control}
                            />
                        </div>
                    </div>
                    :
                    <div className={`mt-4 ${disabled ? 'hidden' : ''}`}>
                        <Controller
                            name={'address'}
                            render={({ field }) => {
                                return <AddressElement options={{ ...addressOptions, defaultValues: defaultAddress }}
                                                       onChange={(event) => {
                                                           if (event.complete) {
                                                               const val = {
                                                                   ...event.value.address,
                                                                   name: event.value.name,
                                                               }
                                                               // console.log(' address complete', val)
                                                               field.onChange(val)
                                                               setAddressComplete(true)
                                                           }
                                                       }}/>
                            }}
                            control={control}
                        />
                        <div className={'h-1'}/>
                        <PaymentElement className={'!p-1 !text-xs'} onChange={(event) => {
                            if (event?.complete) {
                                // console.log('payment complete', event.value)
                                setCardComplete(true)
                            }
                        }}
                                        options={options}
                        />
                        <button
                            className={'btn btn-sm text-primary btn-ghost'}
                            disabled={!(cardComplete && addressComplete)}
                            onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                setConfirmAndPay(true)
                            }}
                        >
                            Review & Confirm
                        </button>
                    </div>
                }
            </div> : null}
            {confirmAndPay ? createPortal(
                <ConfirmAndPay
                    data={getValues()}
                    createAccount={createAccount}
                    cancel={() => {
                        setConfirmAndPay(false)
                        setPaymentDone(false)
                    }}
                    onDone={onDone}/>,
                document.body)
                : null}
        </Section>
    )
}

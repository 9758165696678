import { ZonePath } from '../interfaces/types.ts'
import { post, put, remove } from '../helpers/apiHelper.ts'
import { SAFE_ZONE_URL } from '../helpers/urls.ts'
import { AxiosResponse } from 'axios'
import toast from 'react-hot-toast'

export const createNewSafeZone = async (name: string, path: ZonePath) => {
    const result: AxiosResponse | null = await post(SAFE_ZONE_URL, {name, path})
    if (result?.status) {
        toast.success(`Safe Zone ${name} saved`)
        return result.data
    } else {
        toast.error(`Error, Safe Zone not saved, try again later.`)
    }
}

export const updateSafeZone = async (id: string, name: string, path: ZonePath) => {
    const result: AxiosResponse | null = await put(SAFE_ZONE_URL, {id, name, path})
    if (result?.status) {
        toast.success(`Safe Zone ${name} updated`)
        return result.data
    } else {
        toast.error(`Error, Safe Zone not updated, try again later.`)
    }
}

export const removeSafeZone = async (id: string) => {
    const result: AxiosResponse | null = await remove(SAFE_ZONE_URL, {id})
    if (result?.status) {
        toast.success(`Safe Zone ${name} removed`)
        return result.data
    } else {
        toast.error(`Error, Safe Zone not removed, try again later.`)
    }
}

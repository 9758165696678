import * as React from 'react'
import dataStore from '../../../../store/dataStore.ts'
import { IPromoCode } from '../../../../interfaces/types.ts'


interface Props {
	setValue: (code: string) => void
}

export const PromoCode: React.FC<Props> = (props) => {
	const {setValue} = props
	const [promoCode, setPromoCode] = React.useState('')
	const [promoIsValid, setPromoIsValid] = React.useState(false)
	const [promoMessage, setPromoMessage] = React.useState('')

	const promos: IPromoCode[] = dataStore.getState().codes

	const checkPromoCode = (code: string): IPromoCode | undefined => {
		return promos.find(p => p.code === code)
	}

	const promoInput = (
		<div className="flex flex-col justify-start items-center">
			<input
				type="text"
				className={'h-8 border-solid border border-primary/50 rounded w-full px-2 focus-visible:outline-0 text-xl'}
				value={promoCode}
				name="promoCode"
				placeholder="Promo Code"
				onChange={(e) => {
					setPromoCode(e.target.value)
				}}
			/>
		</div>
	)

	const applyButton = (
		<div className="flex justify-center items-center">
			<button
				type="button"
				className={'btn btn-secondary btn-sm btn-outline'}
				onClick={() => {
					const promo = checkPromoCode(promoCode)
					setPromoIsValid(!!promo)
					if (promo?.id) {
						setPromoMessage(`${promo.name} applied`)
						setValue(promo.id)
					} else {
						setPromoMessage(`${promoCode} is not valid`)
						setValue('')
					}
				}}
				disabled={!promoCode}
			>
				Apply
			</button>
		</div>
	)

	const promoControl = (
		<div className="flex justify-start items-center gap-6">
			{promoInput}
			{applyButton}
		</div>
	)

    return (
		<div className={'flex flex-col justify-start items-start'}>
			<div className={'text-md font-bold pb-1 -mt-2'}>Have a promo code?</div>
			{promoControl}
			<div className={promoIsValid ? 'text-success' : 'text-error'}>
				{promoMessage}
			</div>
		</div>
	)

}

import * as React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { findPet } from '../../helpers/commandHelper.ts'
import { gibiTheme } from '../../helpers/materialTheme.ts'
import { Toast } from '../common/Toast/Toast.tsx'
import { MenuDrawer } from './drawers/MenuDrawer.tsx'
import { FindDrawer } from './drawers/FindDrawer.tsx'
import { MainMenu } from './menus/MainMenu.tsx'
import { NavBar } from './bars/NavBar.tsx'
import { TapBar } from './bars/TapBar.tsx'
import { IPet } from '../../interfaces/types.ts'
import { FindPetMenu } from './menus/FindPetMenu.tsx'
import './Layout.css'
import controlStore from '../../store/controlStore.ts'
import { WebSocket } from '../../webSockets/WebSocket.tsx'

export const Layout: React.FC = () => {
    const selected = controlStore(s => s.currentPage)
    const [menuOpen, setMenuOpen] = React.useState(false)
    const [findOpen, setFindOpen] = React.useState(false)
    const navigate = useNavigate()
    const isMobile = controlStore(s => s.isOnMobileDevice)

    const menuAction = (page: string) => {
        setMenuOpen(false)
        setFindOpen(false)
        navigate(page)
    }

    const doFind = async (pet: IPet) => {
        await findPet(pet)
    }
    
    const layout = (
        <div className={'wrapper'}>
            {isMobile ? <aside>
                <TapBar selected={selected} setSelected={controlStore.getState().setCurrentPage} menuOpen={menuOpen}
                        setMenuOpen={setMenuOpen} findOpen={findOpen} setFindOpen={setFindOpen}/>
            </aside> : null}
            {!isMobile ? <NavBar selected={selected} setSelected={controlStore.getState().setCurrentPage} findOpen={findOpen} setFindOpen={setFindOpen}/>
            : null}
            <main>
                <div className={'wrapper-inner'}>
                    <Outlet/>
                    <MenuDrawer open={menuOpen} menu={<MainMenu menuAction={menuAction}/>}>
                        <FindDrawer open={findOpen} menu={<FindPetMenu menuAction={doFind}/>}>
                            <div/>
                        </FindDrawer>
                    </MenuDrawer>
                </div>
            </main>
            <WebSocket/>
        </div>
    )

    return (
        <ThemeProvider theme={gibiTheme}>
            {layout}
            <Toast/>
        </ThemeProvider>
    )
}

import * as React from 'react'
import { unsecurePost } from '../../../../helpers/apiHelper.ts'
import { CHECK_DEVICE_EXISTS } from '../../../../helpers/urls.ts'
import { LabeledInput } from '../../widgets/LabeledInput.tsx'

interface Props {
    trackerId: string
    errors: any
    onComplete: (b: boolean) => void
    register: any
}

// eslint-disable-next-line react/display-name
export const ValidateTrackerId: React.FC<Props> = (props) => {
    const {trackerId, errors, register, onComplete} = props
    const [idIsValid, setIdIsValid] = React.useState(false)

    React.useEffect(() => {
        if (!trackerId) {
            !idIsValid && setIdIsValid(false)
        }
    }, [trackerId])

    const filterInputOnlyNumbers = (e: any) => {
        if (!e.code?.includes('Digit') && e.code !== 'Backspace') {
            e.stopPropagation()
            e.preventDefault()
        } else {
            if (e.target.value.length > 19 && e.code !== 'Backspace') {
                e.stopPropagation()
                e.preventDefault()
            } else {
                return e
            }
        }
    }
    const onKeyDown = {onKeyDown: filterInputOnlyNumbers}

    const validateTrackerId = async (value: string) => {
        if (idIsValid && value === trackerId) {
            return
        }
        if (idIsValid) {
            setIdIsValid(false)
        }
        if (!(/^\d+$/.test(value))) {
            setIdIsValid(false)
            return 'id is a 20 digit number'
        }
        if (value.length === 20) {
            const result: any = await unsecurePost(CHECK_DEVICE_EXISTS, {deviceID: value})
            if (result?.data?.status) {
                if (value !== trackerId) {
                    onComplete(true)
                    setIdIsValid(true)
                }
            } else {
                return result?.data?.message || 'Server error'
            }
        } else {
            setIdIsValid(false)
        }
    }

    return (
        <div className={`-mt-2`}>
            {
                <LabeledInput
                    formField={'iccid'}
                    title={'Tracker ID'}
                    inputType={'text'}
                    {...register('iccid' as any, {
                        validate: validateTrackerId,
                    })}
                    {...onKeyDown}
                    errors={errors}
                />
                // :
                // <div
                //     className={'h-8 text-base-content bg-white border-solid border border-primary/50 rounded w-full px-2 focus-visible:outline-0 text-xl'}>
                //     {trackerId}
                // </div>
            }
        </div>
    )
}

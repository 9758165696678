import * as React from 'react'
import toast from 'react-hot-toast'
import { sendCommand } from '../../../helpers/commandHelper.ts'
import dataStore from '../../../store/dataStore.ts'
import { IDevice, IPet } from '../../../interfaces/types.ts'
import { AsyncButton } from '../AsyncButton/AsyncButton.tsx'
import { PopupPortal } from '../Popup/PopupPortal.tsx'
import { SelectAlerts } from './SelectAlerts/SelectAlerts.tsx'

interface Props {
    pet: IPet
    open: boolean
    setOpen: (o: boolean) => void
}

export const ChangeAlertSettings: React.FC<Props> = (props) => {
    const {pet = '', open, setOpen} = props

    // const [selectedPetId, setSelectedPetId] = React.useState<string>((pet as IPet)._id || '')
    const [selectedAlerts, setSelectedAlerts] = React.useState<string[]>([])

    // don't send command if nothing has changed - selection must be different from the actual device setting
    const [deviceAlerts, setDeviceAlerts] = React.useState<string[]>([])

    // decoration
    const [petName, setPetName] = React.useState('')

    const [buttonEnabled, setButtonEnabled] = React.useState(false)

    const devices: IDevice[] = dataStore(s => s.devices)
    const pets = dataStore(s => s.pets)

    React.useEffect(() => {
        if ((pet as IPet)._id && pets && devices) {
            const device = devices.find(d => d.petId === (pet as IPet)._id)
            if (device) {
                const alerts = device?.status?.alerts || []
                setSelectedAlerts(alerts)
                setDeviceAlerts(device?.status?.alerts)
                const pet2: IPet = pets.find(p => p._id === (pet as IPet)._id)
                setPetName(pet2?.petName || '')
                setButtonEnabled(false)
            }
        } else {
            setSelectedAlerts([])
        }
    }, [open, pet, pets, devices])

    const updateAlerts = async () => {
        const device = devices.find(d => d.petId === (pet as IPet)._id)
        if (device) {
            const resultData = await sendCommand('alerts', device.iccid, selectedAlerts)
            setOpen(false)
            if (resultData) {
                dataStore.getState().setDevices(resultData)
                toast.success('Alert settings updated')
            } else {
                toast.error('Error, Alert update failed. Try again later.')
            }
        }
    }

    // const changePet = (pet: any) => {
    //     if (pet.value !== selectedPetId) {
    //         setSelectedPetId(pet.value)
    //         setButtonEnabled(true)
    //     } else {
    //         setButtonEnabled(false)
    //     }
    // }

    const uniqueFilter = (value: string, index: number, self: string[]) => {
        return self.indexOf(value) === index
    }
    const isEqual = (a: string[], b: string[]) => JSON.stringify(a.sort()) === JSON.stringify(b.sort())

    const changeAlerts = (v: { alertId: string, selected: boolean }) => {
        let update = selectedAlerts.slice(0)
        if (!v.selected) {
            update = update.filter(a => a !== v.alertId)
        } else {
            update.push(v.alertId)
            update = update.filter(uniqueFilter)
        }

        setSelectedAlerts(update)
        if (!isEqual(deviceAlerts, update)) {
            setButtonEnabled(true)
        } else {
            setButtonEnabled(false)
        }
    }

    const cancel = () => {
        setSelectedAlerts([])
        setOpen(false)
    }

    const panelHeader = (
        <div className={'prose flex flex-col justify-start items-center gap-1'}>
            <h2 className={'mt-0 mb-0 text-primary'}>Change the Alerts you receive</h2>
            <div className={'text-primary flex items-center gap-2'}>
                <div>for</div> <div className={'text-xl font-semibold'}>{petName}</div>
            </div>
        </div>
    )

    const panel = (
        <div className={'flex flex-col justify-center items-center gap-4 p-4'}>
            {panelHeader}
            {/*<SelectPet onlyShowActive={false} onChange={changePet} value={selectedPetId}/>*/}
            <SelectAlerts selected={selectedAlerts} onChange={changeAlerts}/>
        </div>
    )

    const buttons = (
        <div className={'p-4 w-full flex justify-between items-center'}>
            <button className={'btn btn-secondary btn-ghost text-secondary btn-sm'} onClick={cancel}>Cancel</button>
            <AsyncButton classNames={'btn btn-outlined btn-secondary btn-sm'} onClick={updateAlerts}
                         disabled={!buttonEnabled}>Change</AsyncButton>
        </div>
    )

    return (
        <PopupPortal open={open}>
            {panel}
            {buttons}
        </PopupPortal>
    )
}

import { PaymentIntentResult, StripeElements } from '@stripe/stripe-js'
import dataStore from '../store/dataStore.ts'
import { post } from './apiHelper.ts'
import { uploadCroppedFile } from './uploadHelper.ts'
import {
    ADD_TRACKER2_URL,
    ADD_TRACKER_URL,
    CREATE_GIBI_ACCOUNT, CREATE_GIBI_ACCOUNT_AND_LINK_SUBSCRIPTION,
    STRIPE_CREATE_CUSTOMER_AND_SUBSCRIPTION_URL,
    STRIPE_SUBSCRIPTIONS_URL
} from './urls.ts'
import toast from 'react-hot-toast'
import authStore from '../store/authStore.ts'

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export const payForSubscription = async (data: any, elements: StripeElements | null, stripe: any, handleError: (error: any) => void, noAccount: boolean, existingCard: boolean): Promise<{
    status: boolean,
    error?: any
}> => {
    const createSubscription = async (): Promise<{ status: boolean, error?: any }> => {
        try {
            if (!data || !elements) {
                // Stripe.js hasn't yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return {status: false}
            }
            // setLoading(true);

            // Trigger form validation and wallet collection
            const {error: submitError} = await elements.submit()
            if (submitError) {
                handleError && handleError(submitError)
                // setLoading(false)
                return {status: false, error: submitError || 'stripe elements error'}
            }

            let payResult
            if (existingCard) {
                payResult = await payWithExisingCard(stripe, elements, data, handleError)
            } else {
                payResult = await payWithNewCard(stripe, elements, data, noAccount, handleError)
            }
            // only if we get paid do we do the work
            if (payResult?.paid) {
                let petImageFileKey = ''
                // TODO - fix mismatch of update using pet and register using petProfile
                // if there is one, upload the pet picture first, we need save the petImageFileKey
                if (data.petProfile?.imageFile?.file || data.pet?.imageFile?.file) {
                    const origFile = data.petProfile?.imageFile?.file || data.pet.imageFile?.file
                    const imgData = data.petProfile?.imageFile?.img || data.pet.imageFile?.img
                    if (origFile) {
                        petImageFileKey = await uploadCroppedFile(origFile, imgData)
                    }
                    if (data.petProfile) {
                        delete data.petProfile.imageFile
                        data.petProfile.imageFile = petImageFileKey
                    }
                    if (data.pet) {
                        delete data.pet.imageFile
                        data.pet.imageFile = petImageFileKey
                    }
                }

                // save the petImageFileKey and decorate the profile
                data.imageFile = petImageFileKey
                const body: any = {
                    data: data,
                    paymentMethod: payResult.paymentMethod,
                    stripeSubscriptionId: payResult.subscriptionId,
                    // @ts-ignore
                    stripeCustomerId: payResult.stripeCustomer,
                }
                let errMsg = ''
                if (noAccount) {
                    const res = await post(ADD_TRACKER_URL, body)
                    if (res?.status === 200) {
                        const {pets, devices, billing, devicePlans} = res.data
                        dataStore.getState().setPets(pets)
                        dataStore.getState().setDevices(devices)
                        dataStore.getState().setDevicePlans(devicePlans)
                        dataStore.getState().setBilling(billing)
                        toast.success('Tracker registered')
                    } else {
                        errMsg = 'Tracker registration failed.'
                    }
                } else {
                    // @ts-ignore
                    body.data.profile.stripeCustomer = payResult.stripeCustomer
                    const res = await post(CREATE_GIBI_ACCOUNT, body)
                    if (res?.status !== 200) {
                        errMsg = 'Create account failed.'
                    }
                }
                return {status: true, error: errMsg}
            } else {
                console.log('Payment failed')
                return ({status: false, error: 'Payment failed'})
            }
        } catch (error) {
            console.log(error)
            return {status: false, error}
        }
    }
    return await createSubscription()
}

export const payWithNewCard = async (stripe: any, elements: any, data: any, noAccount: boolean, handleError: any) => {
    let res: any
    const body: any = {
        name: data.name,
        email: data.email,
        address: data.address,
        priceId: data.priceId,
        iccid: data.iccid,
        coupon: data.coupon,
    }
    if (!noAccount) {
        // if customer already exists another won't be created
        res = await post(STRIPE_CREATE_CUSTOMER_AND_SUBSCRIPTION_URL, body)
    } else {
        res = await post(STRIPE_SUBSCRIPTIONS_URL, body)
    }

    if (res?.data) {
        const {clientSecret, paymentMethod, subscriptionId, stripeCustomer} = res.data

        const retVal = await stripe.confirmPayment({
            elements,
            clientSecret,
            confirmParams: {
                return_url: 'https://go.no.where.at.all.com',
            },
            redirect: 'if_required'
        })
        if (!retVal || retVal?.error) {
            // This point is only reached if there's an immediate error when confirming the Intent.
            // Show the error to your customer (for example, "payment details incomplete").
            handleError?.(retVal?.error || 'unknown error')
            return ({paid: false, error: retVal?.error || 'unknown error'})
        } else {
            const pi = (retVal as PaymentIntentResult).paymentIntent
            if (pi?.status === 'succeeded') {
                return {paid: true, paymentMethod: pi.payment_method || paymentMethod, subscriptionId, stripeCustomer}
            }
        }
    }
    return {paid: false, error: 'customer & subscription failed'}
}

export const payWithExisingCard = async (stripe: any, elements: any, data: any, handleError: any) => {
    const body: any = {
        email: data.profile?.email || data.email,
        priceId: data.plan || data.priceId,
        iccid: data.iccid,
        coupon: data.coupon,
    }
    const res: any = await post(STRIPE_SUBSCRIPTIONS_URL, body)

    if (res?.data) {
        const {clientSecret, paymentMethod, subscriptionId} = res.data

        const cardCvcElement = elements.getElement('cardCvc')
        const retVal = await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethod,
            payment_method_options: {
                card: {
                    cvc: cardCvcElement
                }
            },
        })
        if (!retVal || retVal?.error) {
            // This point is only reached if there's an immediate error when confirming the Intent.
            // Show the error to your customer (for example, "payment details incomplete").
            handleError?.(retVal?.error || 'unknown error')
            return ({paid: false, error: retVal?.error || 'unknown error'})
        } else {
            const pi = (retVal as PaymentIntentResult).paymentIntent
            if (pi?.status === 'succeeded') {
                return {paid: true, paymentMethod, subscriptionId}
            }
        }
    }
    return {paid: false, error: 'subscription failed'}
}

// payForTracker(data, elements, stripe, handleError, noAccount, existingMethod)
export const payForTracker = async (data: any, elements: StripeElements | null, stripe: any, handleError: (error: any) => void, createAccount: boolean): Promise<{
    status: boolean,
    error?: any
}> => {
    try {
        if (!data || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return {status: false}
        }
        // setLoading(true);

        // Trigger form validation and wallet collection
        const {error: submitError} = await elements.submit()
        if (submitError) {
            handleError && handleError(submitError)
            // setLoading(false)
            return {status: false, error: submitError || 'stripe elements error'}
        }
        let payResult
        let status, error
        if (data.useExistingPayment) {
            // payWithExistingCard
            // add Tracker to account
            payResult = await payWithExisingCard(stripe, elements, data, handleError)
            if (payResult?.paid) {
                status = payResult.paid
                const addTrackerData = {
                    ...payResult,
                    data,
                }
                const res: any =  await post(ADD_TRACKER2_URL, addTrackerData)
                if (res?.status === 200) {
                    const {pets, devices, billing, devicePlans} = res.data
                    dataStore.getState().setPets(pets)
                    dataStore.getState().setDevices(devices)
                    dataStore.getState().setDevicePlans(devicePlans)
                    dataStore.getState().setBilling(billing)
                    status = true
                } else {
                    error = 'Add Gibi Tracker failed.'
                }
            } else {
                error = 'Payment failed'
            }
        } else {
            // pay with new card
            //  create new account OR add tracker to account
            payResult = await payWithNewCard(stripe, elements, data, !createAccount, handleError)
            if (payResult?.paid) {
                status = payResult.paid
                const completeData = {
                    ...payResult,
                    data,
                }
                let res: any
                if (createAccount) {// todo: data structure passed to url
                    // @ts-ignore
                    completeData.stripeCustomer = payResult.stripeCustomer
                    res = await post(CREATE_GIBI_ACCOUNT_AND_LINK_SUBSCRIPTION, completeData)
                } else {
                    const addTrackerData = {
                        ...payResult,
                        data,
                    }
                    // todo: data structure passed to url
                    const res: any = await post(ADD_TRACKER2_URL, addTrackerData)
                    if (res?.status === 200) {
                        const {pets, devices, billing, devicePlans} = res.data
                        dataStore.getState().setPets(pets)
                        dataStore.getState().setDevices(devices)
                        dataStore.getState().setDevicePlans(devicePlans)
                        dataStore.getState().setBilling(billing)
                        status = true
                    } else {
                        error = 'Add Gibi Tracker failed.'
                    }
                }
                if (res?.status === 200) {
                    status = true
                    authStore.getState().setToken(res.data.token)
                    dataStore.getState().setAccount(res.data.account)
                } else {
                    status = false
                    error = 'Payment failed'
                }
            } else {
                status = false
                error = 'Payment failed'
            }
        }
        // @ts-ignore
        return {status, error}
    } catch (error) {
        console.log(error)
        return {status: false, error}
    }
}

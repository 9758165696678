import * as React from 'react'
// import { hexToRgbA } from '../../helpers/colorHelper.ts'
import { checkImage } from '../../helpers/petHelper.tsx'
import { IPet } from '../../interfaces/types.ts'
import controlStore from '../../store/controlStore.ts'
import dataStore from '../../store/dataStore.ts'
import { DashboardItemMobile } from './DashboardItemMobile.tsx'
import { DesktopDashboard } from './DesktopDashboard.tsx'


export const Dashboard: React.FC = () => {
    const pets = dataStore(s => s.pets)
    const devices = dataStore(s => s.devices)
    const [cur, setCur] = React.useState(0)
    const [ready, setReady] = React.useState(false)
    const isMobile = controlStore(s => s.isOnMobileDevice)

    React.useEffect(() => {
        if (pets) {
            void Promise.all(pets.map(p => checkImage(p.imageURL))).then(() => setReady(true))
        }
        controlStore.getState().setCurrentPage('dashboard')
    },[])

    const scrollPet = (n: number) => {
        if (n > 0) {
            setCur(cur + 1)
        } else {
            setCur( cur - 1)
        }
    }
    const byDevice = (a: IPet, b: IPet) => {
        const aHas = devices.find(d => d.petId === a._id)
        const bHas = devices.find(d => d.petId === b._id)

        if (aHas && !bHas) {
            return 1
        }
        if (bHas && !aHas) {
            return -1
        }
        return 0
    }

    const byName = (a: IPet, b: IPet) => {
        return a.petName > b.petName ? 1 : -1
    }
    const items = pets.sort(byDevice).sort(byName)

    const pet = items[cur]

    // const style = {
    //     backgroundColor: hexToRgbA(pet?.iconColor, '.5')
    // }

    const skeleton = (
        <div className="flex flex-col min-h-[90vh] w-full animate-pulse">
            <div className="relative w-[100vw]">
                <div className="w-[100vw] h-[330px] bg-gray-200"></div>
            </div>
            <div className="h-8 bg-gray-200 my-4 ml-2 w-full"></div>
            <div className={'flex justify-around item-center w-full'}>
                <div className={'h-10 w-10 bg-gray-200'}/>
                <div className={'h-10 w-10 bg-gray-200'}/>
                <div className={'h-10 w-10 bg-gray-200'}/>
                <div className={'h-10 w-10 bg-gray-200'}/>
                <div className={'h-10 w-10 bg-gray-200'}/>
            </div>
            <div className="flex flex-col gap-2 p-2 rounded ml-2 mr-2 mt-2">
                <div className="h-6 w-full bg-gray-200 my-2"></div>
                <div className="h-6 w-full bg-gray-200 my-2"></div>
                <div className="h-6 w-full bg-gray-200 my-2"></div>
                <div className="h-6 w-full bg-gray-200 my-2"></div>
                <div className="h-6 w-full bg-gray-200 my-2"></div>
                <div className="h-6 w-full bg-gray-200 my-2"></div>
            </div>
        </div>
    )

    return (
        <div className={'flex flex-col overflow-hidden h-[90vh] bg-primary w-full carousel'}>
            {isMobile ? ready ?
                <DashboardItemMobile key={pet?._id} pet={pet} scrollPet={scrollPet} hasNext={cur < items.length - 1}
                                                 hasPrev={cur > 0} cur={cur} setCur={setCur} />
                    : skeleton
                : <DesktopDashboard/>
            }
        </div>
    )
}

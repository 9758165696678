import { DateTime } from 'luxon'
import { create } from 'zustand'
import { IPoint } from '../interfaces/types.ts'

export interface IHistoryStep {
    location: IPoint
    age: number

}

interface prevDateType {
    start: DateTime,
    end: DateTime
}

export interface HistoryDataSet {
    historyPets: string[]
    setHistoryPets: (pets: string[]) => void

    selectedPetId: string
    setSelectedPetId: (pets: string) => void

    startDate: DateTime
    setStartDate: (date: DateTime) => void

    endDate: DateTime
    setEndDate: (date: DateTime) => void

    earliestDate: DateTime
    setEarliestDate: (date: DateTime) => void

    locations: IPoint[]
    setLocations: (l: IPoint[]) => void

    includeInvalid: boolean
    setIncludeInvalid: (i: boolean) => void

    reachedStart: boolean
    setReachedStart: (i: boolean) => void

    setStateUpdate: (update: any) => void

    prevDate : prevDateType

    historyPerPet: Record<string, IPoint[]>
    petsInView: string[]

    highlight: IPoint | null
    setHighlight: (p: IPoint | null) => void

    validLocations: IPoint[]
    markers: any[]
    playIndex: number
    intervalTimer: any
    bounds: any;
    playbackSpeed: 'fast' | 'slow'

    currentIndex: number
    setCurrentIndex: (index: number) => void

    displayTen: boolean;              
    setDisplayTen: (value: boolean) => void;

    isMovieMode: boolean;              
    setIsMovieMode: (value: boolean) => void;

    playDirection: 'forward' | 'reverse'
    setPlayDirection: (s: 'forward' | 'reverse') => void

    setMarkers: (m: any[]) => void
    setPlayIndex: (i: number) => void
    setIntervalTimer: (i: any) => void
    setPlaybackSpeed: (s: 'fast' | 'slow') => void
    setHistoryPerPet:(l: Record<string, IPoint[]>) => void
    setPrevDate: (d: prevDateType) => void
    setPetsInView: (pets: string[]) => void

    map: google.maps.Map | null
    setMap: (map: google.maps.Map | null) => void

    reset: () => void
}

const isoSort = (a: IPoint, b: IPoint) => {
    return (a.properties.receivedTime < b.properties.receivedTime) ? -1
        : ((a.properties.receivedTime > b.properties.receivedTime) ? 1 : 0)
}

const useStore = create<HistoryDataSet>((set) => ({
    setStateUpdate: (update: any) => {
        set((state) => {
            return {
                ...state,
                ...update,
            }
        })

    },

    historyPets: [],
    setHistoryPets: (pets: string[]) => {
        set((state) => {
            return {
                ...state,
                historyPets: pets
            }
        })

    },

    selectedPetId: '',
    setSelectedPetId: (selectedPetId: string) => {
        set((state) => {
            return {
                ...state,
                selectedPetId,
            }
        })

    },

    startDate: DateTime.now(),
    setStartDate: (date: DateTime) => {
        set((state) => {
            return {
                ...state,
                startDate: date
            }
        })
    },

    endDate: DateTime.now(),
    setEndDate: (date: DateTime) => {
        set((state) => {
            return {
                ...state,
                endDate: date
            }
        })
    },

    earliestDate: DateTime.now(),
    setEarliestDate: (date: DateTime) => {
        set((state) => {
            return {
                ...state,
                endDate: date
            }
        })
    },

    prevDate: {
        start:DateTime.now(),
        end: DateTime.now(),
    },
    setPrevDate: (prevDate: prevDateType) => {
        set((state) => {
            return {
                ...state,
                prevDate
            }
        })
    },
    locations: [],
    validLocations: [],
    bounds: null,
    setLocations: (l: IPoint[]) => {
        set((state) => {
            const valid =  l.filter(p => p.properties.status !== 0).sort(isoSort)
            const bounds = new google.maps.LatLngBounds()
            valid.forEach(v => bounds.extend({
                lat: v.properties?.latitude,
                lng: v.properties?.longitude
            }))
            return {
                ...state,
                locations: l,
                validLocations: valid,
                bounds,
            }
        })
    },

    markers: [],
    setMarkers: (m: any[]) => {
        set((state) => {
            return {
                ...state,
                markers: m,
            }
        })
    },

    playIndex: 0,
    setPlayIndex: (i: number) => {
        set((state) => {
            return {
                ...state,
                playIndex: i,
            }
        })
    },

    intervalTimer: 0,
    setIntervalTimer: (i: any) => {
        set((state) => {
            return {
                ...state,
                intervalTimer: i,
            }
        })
    },

    playbackSpeed: 'fast',
    setPlaybackSpeed: (s: 'fast' | 'slow') => {
        set((state) => {
            return {
                ...state,
                playbackSpeed: s,
            }
        })
    },

    historyPerPet: {},
    setHistoryPerPet:  (historyList: Record<string, IPoint[]>) => {
        set((state) => {
            return {
                ...state,
                historyPerPet: historyList,
            }
        })
    },

    highlight: null,
    setHighlight:  (highlight: IPoint | null) => {
        set((state) => {
            return {
                ...state,
                highlight,
            }
        })
    },

    currentIndex: 0, 
    setCurrentIndex: (index: number) => {
        set((state) => ({
            ...state,
            currentIndex: index,
        }))
    },

    displayTen: true, 
    setDisplayTen: (value: boolean) => {
        set((state) => ({
            ...state,
            displayTen: value,
        }));
    },

    isMovieMode: false,
    setIsMovieMode: (value: boolean) => {
        set((state) => ({
            ...state,
            isMovieMode: value,
        }));
    },

    playDirection: 'forward',
    setPlayDirection: (s: 'forward' | 'reverse') => {
        set((state) => {
            return {
                ...state,
                playDirection: s,
            }
        })
    },


    map: null,
    setMap: (map: any) =>
        set((state) => ({
            ...state,
            map
        })),

    includeInvalid: true,
    setIncludeInvalid: (includeInvalid: boolean) =>
        set((state) => ({
            ...state,
            includeInvalid
        })),

    reachedStart: false,
    setReachedStart: (reachedStart: boolean) =>
        set((state) => ({
            ...state,
            reachedStart
        })),

    petsInView: [],
    setPetsInView: (petsInView: string[]) => {
        set((state) => {
            return {
                ...state,
                petsInView
            }
        })

    },
    reset: () =>
        set((state) => ({
            ...state,
            historyPets: [],
            startDate: DateTime.now(),
            endDate: DateTime.now(),
            locations: [],
            historyPerPet: {},
            validLocations: [],
            markers: [],
            playIndex: 0,
            intervalTimer: 0,
            bounds: null,
            playbackSpeed: 'fast',
            highlight: null,
            map: null,
            includeInvalid: false,
            petsInView: [], 
            currentIndex: 0,
            displayTen: true,
            isMovieMode: false,
            playDirection: 'forward',
        })),
}))

export default useStore

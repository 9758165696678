import * as React from 'react'
import { PropsWithChildren } from 'react'

interface Props {
    children: React.ReactNode
}

export const FullPageWrapper: React.FC<PropsWithChildren<Props>> = (props) => {
    return (
        <div id="full-page-wrapper"
             data-theme={'gibi'}
             className={'flex flex-col justify-center items-center w-[100vw] h-full bg-white'}
        >
            {props.children}
        </div>
    )
}

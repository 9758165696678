import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons/faEllipsisVertical'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
// import classnames and useOnClickOutside
import { useOnClickOutside } from 'usehooks-ts'
import authStore from '../../../store/authStore.ts'
import controlStore from '../../../store/controlStore.ts'
import dataStore from '../../../store/dataStore.ts'
import historyStore from '../../../store/historyStore.ts'
import mapStore from '../../../store/mapStore.ts'

interface Props {
    setFindOpen: (o: boolean) => void
    findOpen: boolean
}

export const NavMenu: React.FC<Props> = (props) => {
    const {setFindOpen, findOpen} = props
    // add a state to toggle the dropdown
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate()
    const [iconColor, setIconColor] = React.useState('white');


    const handleClickOutside = () => {
        setOpen(false);
    };

    //close the dropdown when clicking outside the referenced element
    const ref = React.useRef(null);
    useOnClickOutside(ref, handleClickOutside)

    const menuAction = (page: string) => {
        setOpen(false);
        setFindOpen(false)
        navigate(page)
    }

    const logout = () => {
        authStore.getState().reset()
        dataStore.getState().reset()
        mapStore.getState().reset()
        historyStore.getState().reset()
        controlStore.getState().reset()
        localStorage.removeItem('gibi')
        localStorage.removeItem('gibiUser')
        navigate('/')
    }

    return (
        <>
            <div className={`dropdown dropdown-end ${open ? 'dropdown-open' : ''}`} ref={ref}>
                <label
                    tabIndex={0}
                    className="inline-flex flex-col items-center justify-around group tapBar focus:ring-0 p-0"
                    // toggle open when the button is clicked.
                    onClick={() => !findOpen && setOpen((prev) => !prev)}
                    onMouseEnter={() => setIconColor('#00779a')}
                    onMouseLeave={() => setIconColor('white')}
                >
                    <FontAwesomeIcon
                        icon={faEllipsisVertical}
                        style={{ color: iconColor }}
                        size={'2xl'}
                    />
                    <span
                        className={`pt-1 text-sm capitalize select-none ${iconColor === 'white' ? 'text-primary-content' : 'text-primary-focus'}`}
                    > More </span>
                </label>
                <ul
                    tabIndex={0}
                    // add hidden class when the dropdown is not open
                    className={`mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded w-52 ${!open ? 'hidden': ''}`}
                >
                    <li onClick={() => menuAction('/profile')}>
                        <a className={'text-lg text-primary'}>Profile</a>
                    </li>
                    
                    <li onClick={() => menuAction('/contacts')}>
                        <a className={'text-lg text-primary'}>Followers</a>
                    </li>

                    <li onClick={() => menuAction('/trackers')}>
                        <a className={'text-lg text-primary'}>Gibi Trackers</a>
                    </li>

                    <li onClick={() => menuAction('/subscriptions')}>
                        <a className={'text-lg text-primary'}>Subscriptions</a>
                    </li>
                    
                    <div className="divider m-0"></div>
                    
                    <li onClick={() => menuAction('/pets')}>
                        <a className={'text-lg text-primary'}>New Pet</a>
                    </li>
                    
                    <li onClick={() => menuAction('/addTracker')}>
                        <a className={'text-lg text-primary'}>New Gibi Tracker</a>
                    </li>
                    
                    <div className="divider m-0"></div>
                    
                    <li onClick={() => menuAction('/payment')}>
                        <a className={'text-lg text-primary'}>Manage Payment Method</a>
                    </li>

                    <div className="divider divider-primary m-0"></div>

                    <li onClick={() => logout()}>
                        <a className={'text-lg text-primary'}>Logout</a>
                    </li>
                </ul>
            </div>
        </>
    );
};

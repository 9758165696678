import * as React from 'react'
import toast from 'react-hot-toast'
import { sendCommand } from '../../../helpers/commandHelper.ts'
import dataStore from '../../../store/dataStore.ts'
import { IDevice } from '../../../interfaces/types.ts'
import { AsyncButton } from '../AsyncButton/AsyncButton.tsx'
import { PopupPortal } from '../Popup/PopupPortal.tsx'
import { SelectPet } from '../SelectPet/SelectPet.tsx'

interface Props {
    tracker: IDevice
    open: boolean
    setOpen: (o: boolean) => void
}

export const ChangeTrackerAssignment: React.FC<Props> = (props) => {
    const {tracker, open, setOpen} = props

    const [selectedPetId, setSelectedPetId] = React.useState(tracker?.petId || '')
    const [selectedTracker, setSelectedTracker] = React.useState(tracker || {} as IDevice)

    const [buttonEnabled, setButtonEnabled] = React.useState(false)

    // const pets = dataStore(s => s.pets)
    // const trackers = dataStore(s => s.devices)

    const doAssign = async () => {
        const resultData = await sendCommand('assign', selectedTracker.iccid, selectedPetId)
        setOpen(false)
        if (resultData) {
            dataStore.getState().setDevices(resultData)
            toast.success('Gibi Tracker assignment changed')
        } else {
            toast.error('Error, Gibi Tracker assignment failed, try again later.')
        }
    }

    const changePet = (pet: any) => {
        if (pet !== selectedPetId) {
            setSelectedPetId(pet)
            setButtonEnabled(true)
        } else {
            setButtonEnabled(false)
        }
    }

    // const changeTracker = (trackerId: any) => {
    //     const tracker = trackers.find(p => p._id === trackerId.value)
    //     setSelectedTracker(tracker)
    //     setSelectedPetId(tracker.petId)
    // }

    const cancel = () => {
        setSelectedTracker({} as IDevice)
        setSelectedPetId('')
        setOpen(false)
    }

    const panelHeader = (
        <div className={'prose flex flex-col justify-start items-center gap-1 p-2'}>
            <h2 className={'mt-0 mb-0 text-primary'}>Assign a Tracker to a Pet</h2>
        </div>
    )

    const instruction =
        <div className={'text-left'}>
            <div className={'text-lg font-semibold leading-6 p-4 pt-0'}>
                Select a pet to assign to {tracker.deviceName}
            </div>
        </div>

    const panel = (
        <div className={'flex flex-col justify-start items-center gap-2 p-4'}>
            {panelHeader}
            {instruction}
            <SelectPet onlyShowInactive={true} noNew={true} onChange={changePet} value={selectedPetId} useButtons/>
            {/*<SelectTracker onlyShowInactive={true} onChange={changeTracker} value={selectedTracker._id}/>*/}
        </div>
    )

    const buttons = (
        <div className={'p-4 flex justify-between items-center w-full'}>
            <button className={'btn text-secondary btn-ghost btn-sm'} onClick={cancel}>Dismiss</button>
            <AsyncButton classNames={'btn btn-outlined btn-secondary btn-sm'} onClick={doAssign}
                         disabled={!buttonEnabled}>Assign</AsyncButton>
        </div>
    )

    return (
        <PopupPortal open={open}>
            {panel}
            {buttons}
        </PopupPortal>
    )
}

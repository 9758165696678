import { faPencil } from '@fortawesome/free-solid-svg-icons/faPencil'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { IPet } from '../../../interfaces/types.ts'
import { ButtonWrapperSimple } from './ButtonWrapperSimple.tsx'

interface Props {
    iconOnly?: boolean
    pet: IPet
    textOnly?: boolean
}

export const EditPetButton: React.FC<Props> = (props) => {
    const {iconOnly, pet, textOnly} = props
    const navigate = useNavigate()

    const doEdit = () => {
        const state = { pet }
        navigate('/pets', { state })
    }

    return !textOnly ? (
        <div className={'cursor-pointer'}>
            <ButtonWrapperSimple icon={faPencil as any} iconOnly={iconOnly} label={'Edit'} pet={pet} onClick={() => doEdit()}/>
        </div>
    ) : <div className={'font-semibold text-primary'}>Edit</div>
}

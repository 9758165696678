import * as React from 'react'

interface Props {
    children: React.ReactNode
}

export const Note: React.FC<Props> = (props) => {
    const {children} = props
    return (
        <div>
            <p className={'prose text-neutral-content text-xs pt-2'}>
            {children}
            </p>
        </div>
        )
}

import * as React from 'react'

interface Props {
    formField: string
    errors: Record<string, any>
}

export const Error: React.FC<Props> = (props) => {
    const { formField, errors } = props
    const error = errors[formField]?.message
    
    return (
        <div className={'text-sm text-error'}>
            {error ? <p>{error}</p> : null}
        </div>
    )
}
